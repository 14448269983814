<template>
	<div
		id="usersettingsview"
		class="table responsive"
	>
		<div
			v-if="countries.length > 1"
			class="tr"
		>
			<div class="td td3">
				{{ $t('country') }}
			</div>
			<div class="td td3x2">
				<select
					v-model="countryid"
					name="countryid"
					class="selectBox"
					@change="onChange"
				>
					<option
						v-for="country in countries"
						:key="country.id"
						:value="country.id"
					>
						{{ country.name }}
					</option>
				</select>
			</div>
		</div>

		<div
			v-if="languages.length"
			class="tr"
		>
			<div class="td td3">
				{{ $t('language') }}
			</div>
			<div class="td td3x2">
				<select
					v-model="userLanguage"
					name="language"
					class="selectBox"
					@change="onChange"
				>
					<option
						v-for="language in languages"
						:key="language.id"
						:value="language.id"
					>
						{{ language.name }}
					</option>
				</select>
			</div>
		</div>

		<div
			v-if="currencies.length"
			class="tr"
		>
			<div class="td td3">
				{{ $t('currency') }}
			</div>
			<div class="td td3x2">
				<select
					v-model="userCurrency"
					name="currency"
					class="selectBox"
					@change="onChange"
				>
					<option
						v-for="currency in currencies"
						:key="currency.id"
						:value="currency.id"
					>
						{{ currency.name }} ({{ currency.sign }})
					</option>
				</select>
			</div>
		</div>
	</div>
</template>

<style lang="less" scoped>
@import '~root/css/components/animation.less';
@import '~root/css/components/button.less';
@import '~root/css/components/input.less';

.selectBox {
    margin: 2px 0;

    @media @mobile {
        width: 100%;
    }
    @media @desktop {
        width: 330px;
        max-width: 100%;
    }
}
</style>
