import '@root/css/web.less';
import EventBus from 'components/event-bus';
import AppRouterView, { configureRouter } from 'components/router';
import analytics from 'controllers/analytics';
import i18next from 'i18next';
import i18nextIntervalPlural from 'i18next-intervalplural-postprocessor';
import I18NextVue from 'i18next-vue';
import {
	configureBugsnag,
	launch,
} from 'ops/launch';
import {
	AppStateModule,
	configureStore,
	ProductStateModule,
} from 'store';
import { viewport as viewportTools } from 'tools';
import getLocale from 'tools/get-locale';
import setNativeLanguage from 'tools/set-native-language';
import { vue as vueUtils } from 'utils';
import elementDoubleTap from 'utils/element-double-tap';
import { createApp } from 'vue';
// eslint-disable-next-line import/no-unresolved
import 'cssVars';

// Create application
export default class App {
	public vueRouter: InstanceType<typeof AppRouterView> | undefined;

	public standalone = false;

	private defaultLanguageNamespace = 'translation';

	constructor() {
		// Running as standalone app?
		this.standalone = window.navigator.userAgent.indexOf('FBAV') !== -1
			|| window.navigator.userAgent.indexOf('Instagram') !== -1
			|| (('standalone' in window.navigator) && window.navigator.standalone)
			|| window.matchMedia('(display-mode: standalone)').matches;

		// Set locale
		window.locale = getLocale();

		// Add translation strings for native interface
		if (window.glPlatform === 'native') {
			setNativeLanguage(window.locale);

			if (!window.webToNative) {
				throw new Error('Missing WebToNative on window');
			}

			// Store the native device details in a globally accessible window parameter
			// This way we don't have to call the promise everytime we need access to these
			// static details
			window.webToNative
				.getNativeDetails()
				.then((nativeDeviceDetails) => {
					window.nativeDeviceDetails = nativeDeviceDetails;
				})
				.catch((e) => {
					if (typeof window.glBugsnagClient !== 'undefined') {
						window.glBugsnagClient.notify(
							e,
							(event) => { event.severity = 'warning'; },
						);
					}
				});
		}
	}

	get router() {
		if (!this.vueRouter) {
			throw new Error('Router is not available yet. Did you call init on this class?');
		}

		return this.vueRouter;
	}

	init(): Promise<InstanceType<typeof AppRouterView>> {
		if (this.vueRouter) {
			return Promise.resolve(this.vueRouter);
		}

		i18next.on(
			'missingKey',
			(lngs, ns, key) => {
				if (ns === this.defaultLanguageNamespace) {
					const e = new Error(
						`Could not find translation value for key ${key}`,
					);

					if (typeof window.glBugsnagClient !== 'undefined') {
						window.glBugsnagClient.notify(
							e,
							(event) => { event.severity = 'warning'; },
						);
					} else {
						throw e;
					}
				}
			},
		);

		return i18next
			.use(i18nextIntervalPlural)
			.init({
				defaultNS: this.defaultLanguageNamespace,
				debug: window.glStack === 'local',
				lng: window.locale,
				fallbackLng: 'en',
				supportedLngs: window.glLanguages,
				saveMissing: true,
			})
			.then(() => this.importLanguage('en'))
			.then(() => this.importLanguage('en-gb'))
			.then(() => this.importLanguage('nl'))
			.then(() => this.importLanguage('fr'))
			.then(() => this.importLanguage('it'))
			.then(() => this.importLanguage('es'))
			.then(() => {
				const app = createApp(AppRouterView);
				configureBugsnag(app);
				configureStore(app);
				configureRouter(app);
				vueUtils.configureCurrentInstance(app);
				vueUtils.configureDevTools(app);
				vueUtils.configureDynamicParent(app);
				vueUtils.configureChildren(app);
				vueUtils.configureUnmount(app);
				vueUtils.configureRenderComponent(app);
				vueUtils.configureFlagPrototype(app);
				vueUtils.configureGetSlot(app);
				vueUtils.configureForceCompute(app);
				vueUtils.configureDialogService(app);
				vueUtils.configureTheme(app);
				vueUtils.configureToolbarService(app);
				vueUtils.configureTooltipService(app);
				elementDoubleTap.setup();
				viewportTools.configureAppHeight();
				app.use(
					I18NextVue,
					{
						i18next,
					},
				);

				this.vueRouter = app.mount('#webapp') as InstanceType<typeof AppRouterView>;

				return this.vueRouter;
			});
	}

	start() {
		// Add event handlers for document events
		document.addEventListener(
			'mousemove',
			(e) => {
				EventBus.emit(
					'mouse:move',
					e,
				);
			},
		);
		document.addEventListener(
			'touchmove',
			(e) => {
				EventBus.emit(
					'mouse:move',
					e,
				);
			},
			{ passive: false },
		);

		document.addEventListener(
			'mousedown',
			(e) => {
				EventBus.emit(
					'mouse:down',
					e,
				);
			},
		);
		document.addEventListener(
			'touchstart',
			(e) => {
				EventBus.emit(
					'mouse:down',
					e,
				);
			},
		);

		document.addEventListener(
			'mouseup',
			(e) => {
				EventBus.emit(
					'mouse:up',
					e,
				);
			},
		);
		document.addEventListener(
			'touchend',
			(e) => {
				EventBus.emit(
					'mouse:up',
					e,
				);
			},
		);

		if (window.glPlatform === 'native') {
			if (!window.nativeToWeb) {
				throw new Error('Missing native bridge');
			}

			window.nativeToWeb.onApplicationResume = (payload) => {
				if (payload && payload.hasOwnProperty('unreadMessagesCount')) {
					AppStateModule.setUnreadMessageCount(
						payload.unreadMessagesCount || 0,
					);
				}

				EventBus.emit('app:resume');
			};
		} else if (window.glPlatform === 'web') {
			document.addEventListener(
				'visibilitychange',
				() => {
					if (document.visibilityState !== 'hidden') {
						EventBus.emit('app:resume');
					}
				},
			);

			window.addEventListener(
				'beforeinstallprompt',
				(e) => {
					// Prevent Chrome 67 and earlier from automatically showing the prompt
					e.preventDefault();

					// Stash the event so it can be triggered later.
					window.deferredPrompt = e;

					// @ts-ignore
					e.userChoice.then((choiceResult) => {
						analytics.trackEvent(
							'Install prompt',
							{
								category: 'Banner',
								label: 'Action',
								value: choiceResult.outcome,
							},
						);
					});
				},
			);

			window.addEventListener(
				'beforeunload',
				(e) => {
					if (!ProductStateModule.getSaved) {
						(e || window.event).returnValue = 'Not all changes have been saved yet.';
						return 'Not all changes have been saved yet.';
					}
					return null;
				},
			);
		}

		this
			.init()
			.then(() => {
				// Launch app
				launch();
			});
	}

	importLanguage(ln: string): Promise<void> {
		// Import English translation files
		return import(`./i18n/${ln}/default.json`)
			.then((defaultTranslation) => {
				i18next.addResourceBundle(
					ln,
					this.defaultLanguageNamespace,
					defaultTranslation,
				);

				return import(`./i18n/${ln}/${LABEL}/custom.json`);
			})
			.then((labelTranslation) => {
				i18next.addResourceBundle(
					ln,
					this.defaultLanguageNamespace,
					labelTranslation,
					true,
					true,
				);
			})
			.catch((e) => {
				// No translation file available
				if (typeof window.glBugsnagClient !== 'undefined') {
					window.glBugsnagClient.notify(
						e,
						(event) => { event.severity = 'warning'; },
					);
				}
			});
	}
}
