<template>
	<li
		v-observe-visibility="{
			callback: visibilityChanged,
			once: true,
		}"
		@click.stop="selectLayout"
	>
		<div
			v-if="isVisible"
			class="imageNode"
		>
			<img
				:src="layoutModel.icon"
				@load="imageLoaded"
			>
		</div>
		<div class="textNode">
			{{ $t(`layouts:${layoutModel.id}.name`, '') }}
		</div>
		<div
			v-if="selectedLayout && layoutModel.id == selectedLayout"
			class="selectedIcon"
		>
			<i class="material-icons">
				check
			</i>
		</div>
	</li>
</template>

<script src='./picker-layout-item.ts'></script>

<style lang="less" scoped>
@import '../../css/components/picker.less';

.pickerItem {
	text-align: center;

    .imageNode {
		display: inline-block;

        img {
			max-width: 100%;
        }
	}

	.textNode {
		display: inline-block;
		width: 100%;
	}
}
</style>
