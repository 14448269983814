<template>
	<div class="addressPostcardView">
		<div class="introText">
			{{ $t('confirmPostcardAddress') }}
		</div>
		<div class="address">
			<span
				v-for="(addressLine, index) in addressLines"
				:key="index"
			>
				{{ addressLine }}<br>
			</span>
		</div>
	</div>
</template>

<style lang="less" scoped>
@import '~lessVars';

.introText,
.address {
	float: left;
	width: 100%;

	& when(@brand="Hema") {
		text-transform: none;
	}
}
.address {
	padding: 20px 0 0;
	font-family: @fontFamilyDemiBold;
	font-weight: @fontWeightDemiBold;
}
</style>
