import TooltipComponent from 'components/tooltip';
import { ServiceEvent } from 'services/service-event';
import {
	object as objectUtils,
	service as serviceUtils,
	vue as vueUtils,
} from 'utils';
import { type ComponentPublicInstance } from 'vue';
import { type Cons } from 'vue-facing-decorator/dist/component';

export { ServiceEvent } from 'services/service-event';

/* eslint-disable @typescript-eslint/indent */

export function openTooltip<BodyComponent extends Cons>(
	options: ServiceOptions<
		InstanceType<typeof TooltipComponent<BodyComponent>>
	>,
): ServiceOpenReturn<
	InstanceType<typeof TooltipComponent<BodyComponent>>
> {
	type TooltipComponentType = InstanceType<typeof TooltipComponent<BodyComponent>>;
	const defaultOptions: Omit<
		Partial<ServiceOptions<TooltipComponentType>>,
		'body'
	> & { body: Omit<ServiceOptions<TooltipComponentType>['body'], 'component'> } = {
		body: {
			classes: [],
			listeners: {},
			styles: {},
		},
	};
	let instance!: ComponentPublicInstance<TooltipComponentType>;
	let fromCloseListener = false;
	const tooltipAPI: ServiceOpenReturn<TooltipComponentType> = {
		api: {} as any,
		close(originalEvent?: Event) {
			const event = new ServiceEvent({
				type: 'close',
			});

			if (!fromCloseListener) {
				instance.onCloseClick(
					event,
					originalEvent,
				);
			} else {
				fromCloseListener = false;
			}

			if (!event.defaultPrevented) {
				tooltipAPI.destroy();
			}
		},
		destroy() {
			instance.$currentInstance.appContext.app.unmount();
			instance.$currentInstance.appContext.app._container?.remove();
		},
		isOpen: false,
	};
	Object.defineProperty(
		tooltipAPI,
		'isOpen',
		{
			enumerable: true,
			get() {
				return instance.$currentInstance.isMounted;
			},
		},
	);

	const finalOptions: typeof options = objectUtils.deepAssign(
		{},
		defaultOptions,
		options,
	);
	let { listeners } = finalOptions;

	const closeListener = () => {
		fromCloseListener = true;
		tooltipAPI.close();
	};
	listeners = serviceUtils.addListener(
		'close',
		closeListener,
		listeners,
	);
	finalOptions.listeners = listeners;

	const parent = (
		options.parent
		|| window.App.router
	);
	const dialogsElement = document.getElementById('dialogs');
	const webAppElement = document.getElementById('webapp');

	const renderComponentOptions = vueUtils.defineRenderComponentOptions({
		component: TooltipComponent,
		element: (
			dialogsElement?.parentElement
			|| webAppElement
		),
		insertBefore: (
			dialogsElement?.parentElement
				? dialogsElement
				: undefined
		),
		props: finalOptions,
		listeners,
	});

	if (
		options.body
		&& 'component' in options.body
		&& options.body.component
	) {
		if (!renderComponentOptions.slots) {
			renderComponentOptions.slots = {};
		}

		renderComponentOptions.slots.default = {
			component: options.body.component,
			props: options.body.props,
			listeners: options.body.listeners,
		};
	}

	instance = parent.$renderComponent(renderComponentOptions);
	tooltipAPI.api = vueUtils.getInstanceAPI(instance);

	requestAnimationFrame(() => {
		if (!finalOptions.isModal) {
			const onWindowClick = (event: Event) => {
				const target = event.target as HTMLElement;

				if (
					target !== instance.$el
					&& !instance.$el.contains(target)
					&& !event.defaultPrevented
				) {
					if (instance.anchor) {
						let { anchor } = instance;

						if ('$el' in anchor) {
							anchor = anchor.$el as HTMLElement;
						}

						if (
							anchor !== target
							&& !anchor.contains(target)
						) {
							tooltipAPI.close(event);
						}
					} else {
						tooltipAPI.close(event);
					}
				}
			};
			window.document.body.addEventListener(
				'click',
				onWindowClick,
			);
			instance.onUnmount(() => {
				window.document.body.removeEventListener(
					'click',
					onWindowClick,
				);
			});
		}
	});

	return tooltipAPI;
}

/* eslint-enable @typescript-eslint/indent */

const TooltipService = {
	openTooltip,
};

export default TooltipService;
