import { AxiosRequestConfig } from 'axios';
import ajax from 'controllers/ajax';
import merge from 'deepmerge';
import { AjaxOptions, RemoteUserModel } from 'interfaces/app';
import { ERRORS_INVALID_REQUEST_DATA } from 'settings/errors';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'remoteuser' })
export default class RemoteUser extends VuexModule implements RemoteUserModel {
	// We need a lowercase copy of the user id (to align with other channels)
	id: string | null = null;

	Id: string | null = null;

	FirstName: string | null = null;

	Infix: string | null = null;

	LastName: string | null = null;

	DOB: string | null = null;

	Company: string | null = null;

	Street: string | null = null;

	HouseNumber: string | null = null;

	HouseNumberAddition: string | null = null;

	AddressLine2: string | null = null;

	AddressLine3: string | null = null;

	PostalCode: string | null = null;

	CountryCode: string | null = null;

	EmailAddress: string | null = null;

	TelNumber: string | null = null;

	public get getState() {
		return JSON.parse(JSON.stringify(this.context.state));
	}

	private get _urlRoot() {
		return `/api/${this.context.rootState.config['userAccount.route']}`;
	}

	@Mutation
	set(payload: RemoteUserModel) {
		Object.assign(
			this,
			payload,
		);
	}

	@Action({ rawError: true })
	public fetch(options: {
		methodOptions?: AjaxOptions;
		requestOptions?: AxiosRequestConfig;
	} = {}): Promise<RemoteUserModel> {
		return new Promise((resolve, reject) => {
			const { getters, commit } = this.context;
			const defaultRequestOptions: AxiosRequestConfig = {
				method: 'get',
				url: getters._urlRoot,
			};
			const defaultMethodOptions: AjaxOptions = {
				auth: true,
				debug: {
					offline: true,
					dialog: true,
					abort: false,
				},
			};

			const requestOptions = options && options.requestOptions
				? merge(
					defaultRequestOptions,
					options.requestOptions,
				)
				: defaultRequestOptions;
			const methodOptions = options && options.methodOptions
				? merge(
					defaultMethodOptions,
					options.methodOptions,
				)
				: defaultMethodOptions;

			ajax
				.request(
					requestOptions,
					methodOptions,
				)
				.then((response) => {
					if (response.data.Id) {
						response.data.id = response.data.Id;
					}

					commit(
						'set',
						response.data,
					);
					resolve(getters.getState);
				})
				.catch(reject);
		});
	}

	@Action({ rawError: true })
	patch({
		data,
		methodOptions,
		requestOptions,
	}: {
		data: Record<string, any> | null;
		methodOptions?: AjaxOptions;
		requestOptions?: AxiosRequestConfig;
	} = { data: null }): Promise<void> {
		return new Promise((resolve, reject) => {
			const { getters, commit } = this.context;

			if (!this.Id) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else {
				if (data) {
					commit(
						'set',
						data,
					);
				}

				const defaultRequestOptions: AxiosRequestConfig = {
					method: 'patch',
					url: `${getters._urlRoot}/${this.Id}`,
					headers: {
						'content-type': 'application/json; charset=utf-8',
					},
					data,
				};
				const defaultMethodOptions: AjaxOptions = {
					auth: true,
					retry: 1,
					debug: {
						offline: true,
						dialog: true,
						abort: false,
					},
				};

				requestOptions = requestOptions
					? merge(
						defaultRequestOptions,
						requestOptions,
					)
					: defaultRequestOptions;
				methodOptions = methodOptions
					? merge(
						defaultMethodOptions,
						methodOptions,
					)
					: defaultMethodOptions;

				ajax
					.request(
						requestOptions,
						methodOptions,
					)
					.then((response) => {
						commit(
							'set',
							response.data,
						);
						resolve();
					})
					.catch(reject);
			}
		});
	}
}
