import BetterQueue from 'better-queue';
import MemoryStore from 'better-queue-memory';
import analytics from 'controllers/analytics';
import * as PI from 'interfaces/project';
import {
	AppStateModule,
	PhotosModule,
	ProductStateModule,
} from 'store';

const queueOptions: BetterQueue.QueueOptions<OptionalExceptFor<PI.PhotoModel, 'id'>, PI.PhotoModel> = {
	concurrent: 2,
	filter(
		input,
		cb,
	) {
		if (!input.full_url) {
			return cb(
				'Missing photo url',
				input,
			);
		}
		return cb(
			null,
			input,
		);
	},
	id: 'id',
	maxRetries: 3,
	// Task processing cannot take longer than 60 seconds
	maxTimeout: 60 * 1000,
	precondition(cb) {
		if (AppStateModule.online) {
			cb(
				null,
				true,
			);
		} else {
			cb(
				null,
				false,
			);
		}
	},
	preconditionRetryTimeout: 5 * 1000, // If we go offline, retry every 5s
	process(input: OptionalExceptFor<PI.PhotoModel, 'id'>, cb) {
		if (typeof input.id === 'number') {
			// For unknown reason, sometimes an already saved photo ends up here
			// Since we don't want to save it again, we can just return the existing model
			const photoModel = PhotosModule.getById(input.id);
			if (!photoModel) {
				throw new Error(`Photo with id ${input.id} not found`);
			}

			cb(
				null,
				photoModel,
			);
		} else {
			const oldId = input.id;
			const startTime = new Date().getTime();

			PhotosModule
				.createModel({
					data: input,
				})
				.then((photoModel) => {
					// We apply 10% sampling to avoid going over the Amplitude/Moengage Event Usage
					const sample = Math.random() < 0.1;
					analytics.trackEvent(
						'Photo processed',
						{
							fileSizeKb: photoModel.kbyte,
							processTime: (new Date().getTime() - startTime) / 1000,
							source: photoModel.source,
						},
						{
							moengage: sample,
							amplitude: sample,
						},
					);

					const newId = photoModel.id;

					// Check if product is still in product photo selection
					// User can have deselected the photo while processing on server
					if (ProductStateModule.hasSavedPhoto(oldId)) {
						return ProductStateModule.changePhotoId([oldId, newId])
							.then(() => photoModel);
					}

					ProductStateModule.removePhoto(oldId);
					return photoModel;
				})
				.then((photoModel) => {
					cb(
						null,
						photoModel,
					);
				})
				.catch((err: Error) => {
					cb(err);
				});
		}
	},
	// @ts-ignore: This fixes an issue with Edge (see https://github.com/diamondio/better-queue/issues/27)
	setImmediate: window.setTimeout.bind(null),
	retryDelay: 1000,
	store: new MemoryStore(),
};

const queue: BetterQueue<OptionalExceptFor<PI.PhotoModel, 'id'>, PI.PhotoModel> = new BetterQueue(queueOptions);
queue.on(
	'task_accepted',
	(taskId: string) => {
		PhotosModule.updateModel({
			id: taskId,
			_processing: true,
		});
	},
);
queue.on(
	'task_finish',
	(taskId: string) => {
		PhotosModule.updateModel({
			id: taskId,
			_processing: false,
		});
	},
);
queue.on(
	'task_failed',
	(taskId: string, err: Error) => {
		PhotosModule.updateModel({
			id: taskId,
			_error: err,
			_processing: false,
		});
	},
);

export default queue;
