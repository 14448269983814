import { AxiosRequestConfig } from 'axios';
import ajax from 'controllers/ajax';
import merge from 'deepmerge';
import { AjaxOptions } from 'interfaces/app';
import * as DB from 'interfaces/database';
import { ERRORS_INVALID_REQUEST_DATA } from 'settings/errors';
import { AppDataModule } from 'store';
import _ from 'underscore';
import { reactive } from 'vue';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'cartItems' })
export default class CartItems extends VuexModule {
	collection = reactive<DB.ShoppingCartItemModel[]>([]);

	collectionUrl = '/api/shoppingcartitem';

	fetched = false;

	modelUrl = '/api/shoppingcartitem';

	offset = 0;

	totalRecords: number | null = null;

	public get findWhere() {
		return (properties: Partial<DB.ShoppingCartItemModel>) => _.findWhere(
			this.collection,
			properties,
		);
	}

	public get getById() {
		return (id: number) => _.findWhere(
			this.collection,
			{ id },
		);
	}

	public get quantityCount() {
		return _.reduce(
			this.collection,
			(memo, num) => memo + num.quantity,
			0,
		);
	}

	public get getByCountryId() {
		return (countryId: number | null) => {
			const models: DB.ShoppingCartItemModel[] = [];

			if (countryId) {
				const regionId = AppDataModule.getCountry(countryId)?.regionid;

				if (regionId) {
					// eslint-disable-next-line no-restricted-syntax
					for (const cartItemModel of this.collection) {
						const regionOfferingModelFound = AppDataModule.findRegionOfferingLinkWhere({
							regionid: regionId,
							offeringid: cartItemModel.offeringid,
						});

						if (regionOfferingModelFound) {
							models.push(cartItemModel);
						}
					}
				}
			}

			return models;
		};
	}

	public get where() {
		return (properties: Partial<DB.ShoppingCartItemModel>) => _.where(
			this.collection,
			properties,
		);
	}

	@Mutation
	private _addModel(data: DB.ShoppingCartItemModel): void {
		this.collection.push(data);
	}

	@Mutation
	private _removeModel(id: number): void {
		const i = _.findIndex(
			this.collection,
			(m) => m.id == id,
		);
		if (i >= 0) {
			this.collection.splice(
				i,
				1,
			);
			if (this.offset > 0) this.offset -= 1;
			if (this.totalRecords && this.totalRecords > 0) this.totalRecords -= 1;
		}
	}

	@Mutation
	private _resetCollection(data: DB.ShoppingCartItemModel[]): void {
		this.collection = data || [];
	}

	@Mutation
	private _resetMetaData(): void {
		this.fetched = false;
		this.offset = 0;
		this.totalRecords = null;
	}

	@Mutation
	private _setModel(data: DB.ShoppingCartItemModel): void {
		const i = _.findIndex(
			this.collection,
			{ id: data.id },
		);
		this.collection[i] = data;
	}

	@Mutation
	private _updateModel(data: OptionalExceptFor<DB.ShoppingCartItemModel, 'id'>): void {
		const i = _.findIndex(
			this.collection,
			{ id: data.id },
		);
		const model = this.collection[i];
		this.collection[i] = {
			...model,
			...data,
		};
	}

	@Action
	public addModel(data: DB.ShoppingCartItemModel): Promise<DB.ShoppingCartItemModel | undefined> {
		if (!data.id) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		if (this.getById(data.id)) {
			this._updateModel(data);
		} else {
			this._addModel(data);
		}

		return Promise.resolve(this.getById(data.id));
	}

	@Action
	public addModels(arrData: DB.ShoppingCartItemModel[]): Promise<DB.ShoppingCartItemModel[]> {
		const arrPromises: Promise<DB.ShoppingCartItemModel | undefined>[] = [];
		arrData.forEach((data) => {
			arrPromises.push(this.addModel(data));
		});

		return Promise.all(arrPromises)
			.then((arrModels) => arrModels.filter((model): model is DB.ShoppingCartItemModel => !!model));
	}

	@Action
	public cleanByCountryId(countryId: number): void {
		const validModels: DB.ShoppingCartItemModel[] = this.getByCountryId(countryId);
		const removeIds: number[] = [];

		this.collection.forEach(
			(cartItemModel) => {
				let found = false;
				validModels.forEach(
					(validModel) => {
						if (validModel.id == cartItemModel.id) {
							found = true;
						}
					},
				);
				if (!found && cartItemModel.id) {
					removeIds.push(cartItemModel.id);
				}
			},
		);

		while (removeIds.length) {
			const id = removeIds.shift();
			if (id) {
				this.destroyModel({ id });
			}
		}
	}

	@Action({ rawError: true })
	public createModels({
		data,
		requestOptions,
		methodOptions,
	}: {
		data: Partial<DB.ShoppingCartItemModel>[];
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<DB.ShoppingCartItemModel[]> {
		if (!data || !data.length) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'post',
			url: this.modelUrl,
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
			data,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 1,
			debug: {
				offline: true,
				dialog: true,
				abort: false,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => this.addModels(response.data));
	}

	@Action({ rawError: true })
	public destroyModel({
		id,
		requestOptions,
		methodOptions,
	}: {
		id: number;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<void> {
		if (!id) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		// Check for cart item that is related to the one being destroyed
		const relatedModels: DB.ShoppingCartItemModel[] = [];
		const models: DB.ShoppingCartItemModel[] = this.collection;
		models.forEach(
			(cartItemModel) => {
				if (cartItemModel.shoppingcartitemid && cartItemModel.shoppingcartitemid == id) {
					relatedModels.push(cartItemModel);
				}
			},
		);
		while (relatedModels.length) {
			const relatedModel = relatedModels.shift();
			if (relatedModel?.id) {
				// Remove related shopping cart item from data
				// Note: items already removed from database by foreign key (so no destroy method here)
				try {
					this.removeModel(relatedModel.id);
				} catch (err) {
					// Swallow error
				}
			}
		}

		const model = this.getById(id);
		if (!model) {
			return Promise.resolve();
		}

		try {
			this.removeModel(id);
		} catch (err) {
			// Swallow error
		}

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'delete',
			url: `${this.modelUrl}/${id}`,
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 1,
			debug: {
				offline: true,
				dialog: true,
				abort: false,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then(() => undefined)
			.catch((err: Error) => {
				this.addModel(model);
				throw err;
			});
	}

	@Action({ rawError: true })
	public fetchAddress(cartItemModel: DB.ShoppingCartItemModel): Promise<DB.AddressModel> {
		const productId = cartItemModel.productid;
		return this.context.dispatch(
			'productstate/fetch',
			{ productId, parse: false },
			{ root: true },
		).then((productData) => productData.address);
	}

	@Action({ rawError: true })
	public putModel({
		id,
		data,
		requestOptions,
		methodOptions,
	}: {
		id: number;
		data: Partial<DB.ShoppingCartItemModel>;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<DB.ShoppingCartItemModel | undefined> {
		if (!id) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		if (!data) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		const model = this.getById(id);
		if (!model) {
			throw new Error('Model does not exist');
		}

		const currentModelData = JSON.parse(JSON.stringify(model));
		const newModelData = {
			...data,
			id,
		};
		this._updateModel(newModelData);

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'put',
			url: `${this.modelUrl}/${id}`,
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
			data: newModelData,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 1,
			debug: {
				offline: true,
				dialog: true,
				abort: false,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.catch((err) => {
				this._setModel(currentModelData);
				throw err;
			})
			.then((response) => {
				this._setModel(response.data);
				return this.getById(id);
			});
	}

	@Action({ rawError: true })
	public putModels({
		data,
		requestOptions,
		methodOptions,
	}: {
		data: OptionalExceptFor<DB.ShoppingCartItemModel, 'id'>[];
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<DB.ShoppingCartItemModel[]> {
		if (!data) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		data.forEach((modelData) => {
			if (modelData.cartid
				&& modelData.cartid !== this.context.rootState.cart.id
			) {
				throw new Error('Invalid cartid');
			}
		});

		const arrCurrentData: DB.ShoppingCartItemModel[] = [];
		const arrData: DB.ShoppingCartItemModel[] = [];
		data.forEach(
			(newModelData) => {
				const model = this.getById(newModelData.id);
				if (!model) {
					throw new Error('One or more of the models do not exist');
				}

				arrCurrentData.push(
					JSON.parse(JSON.stringify(model)),
				);

				this._updateModel(newModelData);
				const updatedModel = this.getById(newModelData.id);
				if (updatedModel) {
					arrData.push(updatedModel);
				}
			},
		);

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'put',
			url: this.collectionUrl,
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
			data: arrData,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 1,
			debug: {
				offline: true,
				dialog: true,
				abort: true,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.catch((err: Error) => {
				// Reset models in case of server error
				arrCurrentData.forEach(
					(modelData) => {
						this._setModel(modelData);
					},
				);

				throw err;
			})
			.then((response) => response.data.map(
				(modelData: DB.ShoppingCartItemModel) => {
					this._setModel(modelData);
					return this.getById(modelData.id);
				},
			).filter((model: DB.ShoppingCartItemModel | undefined): model is DB.ShoppingCartItemModel => !!model));
	}

	@Action({ rawError: true })
	public removeModel(id: number): void {
		if (!id) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		if (!this.getById(id)) {
			throw new Error('Model not found');
		}

		return this._removeModel(id);
	}

	@Action
	public reset({
		data,
	}: {
		data: DB.ShoppingCartItemModel[];
	} = {
		data: [],
	}): void {
		this._resetMetaData();
		this._resetCollection(data);
	}

	@Action
	public updateModel(
		data: OptionalExceptFor<DB.ShoppingCartItemModel, 'id'>,
	): void {
		const updatingModel = this.getById(data.id);

		if (updatingModel
			&& data.hasOwnProperty('quantity')
		) {
			// Check for cart item that is related to the one being destroyed
			this.collection.forEach(
				(cartItemModel) => {
					if (cartItemModel.shoppingcartitemid === data.id) {
						const upsellModel = this.context.rootGetters['appdata/findWhereUpsell']({
							offeringid: updatingModel.offeringid,
							upsellid: cartItemModel.offeringid,
						});

						if (upsellModel && upsellModel.peritem) {
							this._updateModel({
								id: cartItemModel.id,
								quantity: data.quantity,
							});
						}
					}
				},
			);
		}

		this._updateModel(data);
	}
}
