import {
	nextTick,
	type ObjectPlugin,
} from 'vue';
import {
	Position,
	Router,
} from 'vue-router';

export type ScrollBehaviorHistory = {
	path: string;
	position: Position;
}

export type ScrollBehavior = {
	delay: number;
	history: ScrollBehaviorHistory[];
	maxHistory: number;
	install: ObjectPlugin<ScrollBehaviorOptions>['install'];
}

export type ScrollBehaviorOptions = {
	delay?: ScrollBehavior['delay'];
	maxHistory?: ScrollBehavior['maxHistory'];
	router: Router;
}

const ScrollBehavior: ScrollBehavior = {
	delay: 0,
	history: [],
	maxHistory: 50,
	install(
		app,
		options?: ScrollBehaviorOptions,
	) {
		if (
			typeof options?.router === 'object'
			&& typeof options?.router.beforeEach === 'function'
		) {
			const {
				router,
				delay,
				maxHistory,
			} = options;

			if (delay) {
				ScrollBehavior.delay = delay;
			}
			if (maxHistory) {
				ScrollBehavior.maxHistory = maxHistory;
			}

			router.beforeEach((to, from, next) => {
				if (!from.meta?.keepScrollPosition) {
					next();
				} else {
					if (ScrollBehavior.history.length >= ScrollBehavior.maxHistory) {
						ScrollBehavior.history.shift();
					}

					const prevHistory = ScrollBehavior.history.find((history) => history.path === from.fullPath);
					const prevHistoriesAlsoKnownAs = ScrollBehavior.history.filter((history) => from.meta?.alsoKnownAs?.includes(history.path));

					if (prevHistory) {
						prevHistory.position = {
							x: 0,
							y: window.scrollY,
						};
					} else {
						ScrollBehavior.history.push({
							path: from.fullPath,
							position: {
								x: 0,
								y: window.scrollY,
							},
						});
					}
					if (prevHistoriesAlsoKnownAs.length > 0) {
						// eslint-disable-next-line no-restricted-syntax
						for (const prevHistoryAlsoKnownAs of prevHistoriesAlsoKnownAs) {
							prevHistoryAlsoKnownAs.position = {
								x: 0,
								y: window.scrollY,
							};
						}
					} else if (
						to.meta?.alsoKnownAs
						&& to.meta.alsoKnownAs.length > 0
					) {
						// eslint-disable-next-line no-restricted-syntax
						for (const currAlsoKnownAs of to.meta.alsoKnownAs) {
							ScrollBehavior.history.push({
								path: currAlsoKnownAs,
								position: {
									x: 0,
									y: window.scrollY,
								},
							});
						}
					}

					next();
				}
			});
			router.afterEach((to) => {
				if (to.meta?.keepScrollPosition) {
					const prevHistory = ScrollBehavior.history.find((history) => history.path === to.fullPath);
					const prevHistoryAlsoKnownAs = ScrollBehavior.history.find((history) => to.meta?.alsoKnownAs?.includes(history.path));

					if (
						prevHistory
						|| prevHistoryAlsoKnownAs
					) {
						const scrollTo = () => nextTick(() => {
							const { position } = (prevHistory || prevHistoryAlsoKnownAs) as ScrollBehaviorHistory;
							window.scrollTo(
								position.x,
								position.y,
							);
						});

						if (ScrollBehavior.delay > 0) {
							scrollTo();
							setTimeout(
								scrollTo,
								ScrollBehavior.delay,
							);
							setTimeout(
								scrollTo,
								ScrollBehavior.delay + 50,
							);
							setTimeout(
								scrollTo,
								ScrollBehavior.delay + 100,
							);
						} else {
							scrollTo();
						}
					} else {
						window.scrollTo(
							0,
							0,
						);
					}
				}
			});
		} else {
			console.warn('Scroll behavior depends on vue-router');
		}
	},
};

export default ScrollBehavior;
