import './defines';
import * as DB from 'interfaces/database';
import { ProductStateModule } from 'store';
import formatAddress from 'tools/format-address';
import { toNative } from 'utils/vue-facing-decorator';
import {
	Component,
	Prop,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	mixins: [Template],
})
class PostcardAddressView extends Vue {
	@Prop({
		required: true,
		type: Object,
	})
	public readonly productModel!: DB.ProductModel;

	protected get addressLines() {
		if (this.addressModel) {
			return formatAddress(this.addressModel);
		}

		return [];
	}

	private get addressModel() {
		return ProductStateModule.getAddress;
	}
}

export default toNative(PostcardAddressView);
