import './defines';
import PriceCalculator from 'classes/price-calculator';
import ProductState from 'classes/productstate';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import { ServiceEvent } from 'services/service-event';
import {
	AppDataModule,
	AppStateModule,
	ProductStateModule,
	UserModule,
} from 'store';
import _ from 'underscore';
import { toNative } from 'utils/vue-facing-decorator';
import TwoDView from 'views/2d/template.vue';
import PriceView from 'views/price';
import {
	Component,
	Prop,
	Ref,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	components: {
		PriceView,
		TwoDView,
	},
	emits: [
		'closeDialog',
		'select',
	],
	mixins: [Template],
})
class FramePickerView extends Vue {
	@Prop({
		default: undefined,
		type: Object,
	})
	public readonly pageModel!: PI.PageModel;

	protected boxHeight = 500;

	protected boxWidth = 500;

	private selectedFrameValueModel: DB.OfferingOptionValueModel | null = null;

	private selectedOfferingModel: DB.OfferingModel | null = null;

	@Ref('twodViewWrapper')
	private readonly twodViewWrapper!: HTMLDivElement;

	private get countryModel() {
		return UserModule.countryid
			? AppDataModule.getCountry(UserModule.countryid)
			: undefined;
	}

	private get currencyModel() {
		return UserModule.currency
			? AppDataModule.getCurrency(UserModule.currency)
			: undefined;
	}

	private get isAvailableInRegion() {
		if (this.countryModel && this.selectedOfferingModel) {
			return !!AppDataModule.findRegionOfferingLinkWhere({
				regionid: this.countryModel.regionid,
				offeringid: this.selectedOfferingModel.id,
			});
		}

		return false;
	}

	protected get model2D() {
		return this.selectedOfferingModel && this.selectedOfferingModel.model2did
			? _.findWhere(
				AppDataModule.models2d,
				{ id: this.selectedOfferingModel.model2did },
			)
			: undefined;
	}

	private get priceData() {
		if (this.isAvailableInRegion
			&& this.selectedOfferingModel
			&& this.productModel
		) {
			return PriceCalculator.projectPrice({
				productid: this.productModel.id,
				offeringid: this.selectedOfferingModel.id,
			});
		}

		return false;
	}

	private get productModel() {
		return ProductStateModule.getProduct;
	}

	protected get selectedOfferingPrice() {
		if (this.pageModel) {
			if (!this.currencyModel) {
				throw new Error('Missing required currency model');
			}
			if (!this.selectedOfferingModel) {
				throw new Error('Missing required offering model');
			}

			const pricingModel = AppDataModule.findPricingWhere({
				offeringid: this.selectedOfferingModel.id,
				currency: this.currencyModel.id,
			});

			if (!pricingModel) {
				return undefined;
			}

			return pricingModel.price_base;
		}

		let selectedOfferingPrice = this.priceData
			? this.priceData.subTotal
			: 0;

		if (this.priceData && this.priceData.offeringModel) {
			const upsellModels = AppDataModule.whereUpsell({
				offeringid: this.priceData.offeringModel.id,
				autoinclude: 1,
				optional: 0,
			});

			upsellModels.forEach((upsellModel) => {
				if (this.productModel) {
					const price = PriceCalculator.projectPrice({
						offeringid: upsellModel.upsellid,
						productid: this.productModel.id,
					});
					if (price) {
						selectedOfferingPrice += price.subTotal;
					}
				}
			});
		}

		return selectedOfferingPrice;
	}

	private get offeringOptionFrameValueModels() {
		return this.offeringOptionValueModels.filter(
			(model) => model.offeringoptionid === this.offeringOptionFrameModel?.id,
		);
	}

	private get offeringOptionValueModels() {
		return this.selectedOfferingModel
			? AppDataModule.getOfferingOptionValueModels(
				this.selectedOfferingModel.id,
				this.countryModel?.regionid,
			)
			: [];
	}

	private get offeringOptionFrameModel() {
		return this.offeringOptionModels.find(
			(model) => model.tag === 'frame',
		);
	}

	private get offeringOptionModels() {
		return this.selectedOfferingModel
			? AppDataModule.getOfferingOptionModels(
				this.selectedOfferingModel.id,
				this.countryModel?.regionid,
			)
			: [];
	}

	protected created() {
		this.selectedOfferingModel = this.pageModel && this.pageModel.offeringId
			? AppDataModule.getOffering(this.pageModel.offeringId) || null
			: ProductStateModule.getOffering;

		if (this.selectedOfferingModel) {
			const linkedValueModels = AppDataModule.offeringoptionvalueofferinglinks.filter(
				(linkModel) => linkModel.offeringid === this.selectedOfferingModel?.id,
			);

			// Find the orientation value model that is linked to the selected offering model
			const linkedFrameValueModel = linkedValueModels.find(
				(linkModel) => _.pluck(
					this.offeringOptionFrameValueModels,
					'id',
				).indexOf(linkModel.offeringoptionvalueid) >= 0,
			);
			if (linkedFrameValueModel) {
				this.selectedFrameValueModel = this.offeringOptionFrameValueModels.find(
					(valueModel) => valueModel.id === linkedFrameValueModel.offeringoptionvalueid,
				) || null;
			}
		}
	}

	protected mounted() {
		this.setBoxSize();
		window.addEventListener(
			'resize',
			this.setBoxSize,
		);
	}

	protected beforeUnmount() {
		window.removeEventListener(
			'resize',
			this.setBoxSize,
		);
	}

	private changeOffering() {
		if (!this.selectedOfferingModel) {
			return Promise.reject(
				new Error('Missing selected offering model'),
			);
		}
		if (!this.productModel) {
			return Promise.reject(
				new Error('Missing product model'),
			);
		}

		if (this.selectedOfferingModel.groupid == this.productModel.group
			&& this.selectedOfferingModel.typeid == this.productModel.typeid
			&& this.selectedOfferingModel.variantid == this.productModel.variantid
		) {
			return Promise.resolve();
		}

		AppStateModule.setHeavyLoad();
		const closeLoader = this.$openLoaderDialog();

		return ProductState
			.changeOffering(
				this.selectedOfferingModel.id,
			)
			.then(() => {
				ProductStateModule.pushHistory();
			})
			.catch((e: Error) => {
				// Swallow error: no action required
				if (typeof window.glBugsnagClient !== 'undefined') {
					window.glBugsnagClient.notify(
						e,
						(event) => { event.severity = 'warning'; },
					);
				}
			})
			.finally(() => {
				closeLoader();
				AppStateModule.unsetHeavyLoad();
			});
	}

	private closePicker() {
		this.$emit(
			'select',
			new ServiceEvent({
				type: 'select',
				payload: this.selectedFrameValueModel,
			}),
		);

		this.$emit('closeDialog');
	}

	protected saveChanges() {
		this.changeOffering()?.finally(() => {
			this.closePicker();
		});
	}

	protected getValueModelPreviewImage(valueModel: DB.OfferingOptionValueModel) {
		const linkModels = AppDataModule.offeringoptionvalueofferinglinks.filter(
			(m) => m.offeringoptionvalueid == valueModel.id,
		);

		if (linkModels.length === 0) {
			return undefined;
		}

		const linkModel = linkModels.find((m) => {
			const offeringModel = AppDataModule.getOffering(m.offeringid);
			return offeringModel?.typeid == this.productModel?.typeid;
		});
		if (!linkModel) {
			return undefined;
		}

		const offeringModel = AppDataModule.getOffering(linkModel.offeringid);
		if (!offeringModel || !offeringModel.variantthumbnail || offeringModel.variantthumbnail.length === 0) {
			return undefined;
		}

		return offeringModel.variantthumbnail;
	}

	private selectOffering() {
		let linkModels = AppDataModule.offeringoptionvalueofferinglinks.filter(
			(linkModel) => linkModel.offeringoptionvalueid == this.selectedFrameValueModel?.id,
		);

		linkModels = _.sortBy(
			linkModels,
			(linkModel) => {
				const offeringModel = AppDataModule.getOffering(linkModel.offeringid);
				return offeringModel
					&& this.productModel
					&& Boolean(offeringModel.typeid == this.productModel.typeid)
					? -1
					: 1;
			},
		);

		let offeringModel: DB.OfferingModel | undefined;
		if (linkModels.length) {
			offeringModel = _.findWhere(
				AppDataModule.offerings,
				{
					id: linkModels[0].offeringid,
				},
			);
		}

		if (offeringModel) {
			this.selectedOfferingModel = offeringModel;
		}
	}

	protected selectFrameValueModel(
		valueModel: DB.OfferingOptionValueModel,
	) {
		this.selectedFrameValueModel = valueModel;
		this.selectOffering();
	}

	private setBoxSize() {
		this.boxWidth = this.twodViewWrapper.clientWidth;
		this.boxHeight = this.twodViewWrapper.clientHeight;
	}
}

export default toNative(FramePickerView);
