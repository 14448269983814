import type {
	createApp,
	Directive,
	DirectiveBinding,
} from 'vue';

const HANDLERS_PROPERTY = '__v-click-outside';
const HAS_WINDOWS = typeof window !== 'undefined';
const HAS_NAVIGATOR = typeof navigator !== 'undefined';
const IS_TOUCH = (
	HAS_WINDOWS
	&& (
		'ontouchstart' in window
		|| (
			HAS_NAVIGATOR
			&& 'msMaxTouchPoints' in navigator
			&& (navigator.msMaxTouchPoints as number) > 0
		)
	)
);
const EVENTS = (
	IS_TOUCH
		? ['touchstart']
		: ['click']
);

type HTMLElementWithVClickOutside = HTMLElement & { [HANDLERS_PROPERTY]?: Array<{ event: string; srcTarget: HTMLElement; handler: (event: Event) => void }> };
type VClickOutsideBeforeMountDirective = (el: HTMLElementWithVClickOutside, binding: Pick<DirectiveBinding<((...args: any[]) => any)>, 'value'>) => void;
type VClickOutsideUnmountedDirective = (el: HTMLElementWithVClickOutside) => void;
type VClickOutsideUpdatedDirective = (el: HTMLElementWithVClickOutside, binding: Pick<DirectiveBinding<((...args: any[]) => any)>, 'oldValue' | 'value'>) => void;

interface OnEventParams {
	el: HTMLElementWithVClickOutside;
	event: Event;
	handler: ((...args: any[]) => any);
}

function validate(bindingValue: ((...args: any[]) => any)): boolean {
	if (typeof bindingValue !== 'function') {
		console.warn('v-click-outside: Binding value must be a function');
		return false;
	}

	return true;
}

function onEvent(params: OnEventParams): void {
	const {
		el,
		event,
		handler,
	} = params;
	const path = (
		(
			'path' in event
			&& event.path
		)
		|| event.composedPath?.()
	) as EventTarget[] | undefined;
	const eventTarget = event.target as HTMLElement | null;

	if (eventTarget) {
		if (
			path
			&& path.length > 0
		) {
			path.unshift(eventTarget);
		}

		if (
			el.contains(eventTarget)
			|| el.isSameNode(eventTarget)
		) {
			return;
		}

		handler(event);
	}
}

const beforeMount: VClickOutsideBeforeMountDirective = (
	el,
	{
		value,
	},
) => {
	if (!validate(value)) {
		return;
	}

	el[HANDLERS_PROPERTY] = EVENTS.map((eventName) => ({
		event: eventName,
		srcTarget: document.documentElement,
		handler: (event) => onEvent({
			el,
			event,
			handler: value,
		}),
	}));
	el[HANDLERS_PROPERTY].forEach(({ event, srcTarget, handler }) => setTimeout(
		() => {
			if (!el[HANDLERS_PROPERTY]) {
				return;
			}

			srcTarget.addEventListener(
				event,
				handler,
			);
		},
		0,
	));
};
const unmounted: VClickOutsideUnmountedDirective = (el) => {
	const handlers = el[HANDLERS_PROPERTY] || [];
	handlers.forEach(({
		event, srcTarget, handler,
	}) => srcTarget.removeEventListener(
		event,
		handler,
	));
	delete el[HANDLERS_PROPERTY];
};
const updated: VClickOutsideUpdatedDirective = (
	el,
	{
		value,
		oldValue,
	},
) => {
	if (JSON.stringify(value) === JSON.stringify(oldValue)) {
		return;
	}

	if (!validate(value)) {
		return;
	}

	unmounted(el);
	beforeMount(
		el,
		{
			value,
		},
	);
};
const directive: Directive = {
	beforeMount,
	updated,
	unmounted,
};

const plugin = {
	install(app: ReturnType<typeof createApp>): void {
		app.directive(
			'click-outside',
			directive,
		);
	},
	directive,
};

export default plugin;
