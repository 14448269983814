import './defines';
import { toNative } from 'utils/vue-facing-decorator';
import {
	Component,
	Prop,
	Vue,
} from 'vue-facing-decorator';

import Template from './template.vue';

@Component({
	name: 'ProgressBar',
	mixins: [Template],
})
class ProgressBar extends Vue {
	@Prop({
		required: true,
		type: Number,
	})
	public readonly progressPercentage!: number;

	@Prop({
		required: true,
		type: String,
	})
	public readonly text!: string;
}

export default toNative(ProgressBar);
