import { OfferingFrameModel } from 'interfaces/app';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import {
	AppDataModule,
	ProductStateModule,
} from 'store';
import getCanvasSize from 'tools/get-canvas-size';
import { toNative } from 'utils/vue-facing-decorator';
import FlatPageView from 'views/page-flat.vue';
import {
	Component,
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-facing-decorator';

@Component({
	components: {
		FlatPageView,
	},
})
class TwoDView extends Vue {
	@Ref('canvas2d') readonly canvas2d!: HTMLCanvasElement;

	@Ref('fullCanvas') readonly fullCanvas!: HTMLDivElement;

	@Prop({ default: 0, type: Number })
	readonly boxHeight!: number;

	@Prop({ required: true, type: Number })
	readonly boxWidth!: number;

	@Prop({ required: true, type: Object })
	readonly model2dModel!: DB.Model2DModel;

	@Prop({ required: true, type: Object })
	readonly offeringModel!: DB.OfferingModel;

	@Prop({ required: true, type: Object })
	readonly pageModel!: PI.PageModel;

	private model2dImg: HTMLImageElement | null = null;

	private offeringFrameImg: HTMLImageElement | null = null;

	private isPainted = false;

	get deltaX() {
		return (this.boxWidth - (this.model2dModel.imageWidth / this.scale)) / 2;
	}

	get deltaY() {
		return (this.boxHeight - (this.model2dModel.imageHeight / this.scale)) / 2;
	}

	get drawHeight() {
		if (this.model2dModel.ppi) {
			const heightInInch = this.offeringModel.height / this.offeringModel.configdpi;
			const heightInPixels = heightInInch * this.model2dModel.ppi;
			return heightInPixels / this.scale;
		}

		if (this.model2dModel.frameHeight) {
			return this.model2dModel.frameHeight / this.scale;
		}

		throw new Error('No ppi or frameHeight');
	}

	get drawWidth() {
		if (this.model2dModel.ppi) {
			const widthInInch = this.offeringModel.width / this.offeringModel.configdpi;
			const widthInPixels = widthInInch * this.model2dModel.ppi;
			return widthInPixels / this.scale;
		}

		if (this.model2dModel.frameWidth) {
			return this.model2dModel.frameWidth / this.scale;
		}

		throw new Error('No ppi or frameWidth');
	}

	get drawX() {
		if (this.model2dModel.centerHorizontal) {
			return this.model2dModel.centerHorizontal / this.scale - (this.drawWidth / 2) + this.deltaX;
		}

		if (this.model2dModel.frameLeft) {
			return (this.model2dModel.frameLeft / this.scale) + this.deltaX;
		}

		throw new Error('No centerHorizontal or frameLeft');
	}

	get drawY() {
		if (this.model2dModel.centerVertical) {
			return this.model2dModel.centerVertical / this.scale - (this.drawHeight / 2) + this.deltaY;
		}

		if (this.model2dModel.frameTop) {
			return (this.model2dModel.frameTop / this.scale) + this.deltaY;
		}

		throw new Error('No centerVertical or frameTop');
	}

	get fullScale() {
		const showBleed = false;
		const bleedMargin = showBleed && this.pageModel.offset
			? this.pageModel.offset
			: 0;
		const canvasHeight = Math.round(
			this.pageModel.height + 2 * bleedMargin,
		);
		const canvasWidth = Math.round(
			this.pageModel.width + 2 * bleedMargin,
		);

		const canvasSize = getCanvasSize(
			canvasWidth,
			canvasHeight,
		);

		return canvasSize.width / canvasWidth;
	}

	/* Since we are cropping the depth pixels in the 2D view, we need to compensate for this loss */
	get offeringFrameImageDepthCompensation() {
		if (!this.offeringModel) {
			return 0;
		}

		return this.offeringModel.depth * (this.drawWidth / this.offeringWidthFlat);
	}

	get offeringFrameImageHeight() {
		if (!this.offeringFrame) {
			return 1;
		}

		return this.offeringFrame.imageModel.height * this.offeringFrameScale;
	}

	get offeringFrameImageLeft() {
		if (!this.offeringFrame || !this.offeringModel) {
			return 0;
		}

		return this.offeringFrame.templateModel.x * this.offeringFrameScale + this.offeringFrameImageDepthCompensation;
	}

	get offeringFrameImageTop() {
		if (!this.offeringFrame || !this.offeringModel) {
			return 1;
		}

		return this.offeringFrame.templateModel.y * this.offeringFrameScale + this.offeringFrameImageDepthCompensation;
	}

	get offeringFrameImageWidth() {
		if (!this.offeringFrame) {
			return 1;
		}

		return this.offeringFrame.imageModel.width * this.offeringFrameScale;
	}

	get offeringFrameScale() {
		if (!this.offeringFrame || !this.offeringModel) {
			return 1;
		}

		return this.drawWidth / this.offeringFrame.templateModel.width * (this.offeringModel.width / this.offeringWidthFlat);
	}

	get offeringFrameStyle() {
		return {
			top: `${this.drawY - this.offeringFrameImageTop}px`,
			left: `${this.drawX - this.offeringFrameImageLeft}px`,
		};
	}

	get imageHeight() {
		return Math.round(this.model2dModel.imageHeight / this.scale);
	}

	get imageWidth() {
		return Math.round(this.model2dModel.imageWidth / this.scale);
	}

	get offeringFrame(): OfferingFrameModel | undefined {
		if (this.offeringModel) {
			return AppDataModule.getOfferingFrame(
				this.offeringModel.id,
			);
		}

		return undefined;
	}

	get offeringMaskImage() {
		return ProductStateModule.getMaskImage;
	}

	/* Get the width of the offering without the depth */
	get offeringWidthFlat() {
		if (!this.offeringModel) {
			return 0;
		}

		return this.offeringModel.width - 2 * this.offeringModel.depth;
	}

	get scale() {
		return Math.min(
			this.model2dModel.imageWidth / this.boxWidth,
			this.model2dModel.imageHeight / this.boxHeight,
		);
	}

	get showLoader() {
		return !this.isPainted || !this.model2dImg;
	}

	created() {
		this.load2DImage();
		this.loadOfferingFrameImage();
	}

	@Watch('model2dModel')
	changedModel() {
		this.model2dImg = null;
		this.load2DImage();
	}

	@Watch('offeringFrame')
	changedOfferingFrame() {
		this.offeringFrameImg = null;
		this.loadOfferingFrameImage();
	}

	load2DImage() {
		const newImg = new Image();
		newImg.onload = () => {
			this.model2dImg = newImg;
		};
		newImg.onerror = () => {
			// Something went wrong, handle situation
		};
		newImg.crossOrigin = 'anonymous';
		newImg.src = this.model2dModel.imageUrl;
	}

	loadOfferingFrameImage() {
		if (this.offeringFrame) {
			const newImg = new Image();
			newImg.onload = () => {
				this.offeringFrameImg = newImg;
			};
			newImg.onerror = () => {
				// Something went wrong, handle situation
			};
			newImg.crossOrigin = 'anonymous';
			newImg.src = this.offeringFrame.imageModel.url;
		}
	}

	@Watch('isPainted')
	@Watch('model2dImg')
	@Watch('offeringFrameImg')
	@Watch('offeringMaskImage')
	@Watch('offeringModel')
	paintPreview() {
		if (!this.model2dImg) {
			// Waiting for the 2D model image to load
			return;
		}
		if (this.offeringFrame && !this.offeringFrameImg) {
			// Waiting for the offering frame image to load
			return;
		}
		if (!this.isPainted) {
			// Waiting for the user's project preview to be rendered
			return;
		}

		if (this.model2dModel.stackOnTop) {
			this.paintModel2d();
			this.paintProject();
		} else {
			this.paintProject();
			this.paintModel2d();
		}
	}

	paintModel2d() {
		if (this.model2dImg) {
			const context = this.canvas2d.getContext('2d');
			context?.drawImage(
				this.model2dImg,
				this.deltaX,
				this.deltaY,
				this.imageWidth,
				this.imageHeight,
			);
		}
	}

	paintOfferingFrame() {
		if (this.offeringFrameImg) {
			const context = this.canvas2d.getContext('2d');
			context?.drawImage(
				this.offeringFrameImg,
				this.drawX - this.offeringFrameImageLeft,
				this.drawY - this.offeringFrameImageTop,
				this.offeringFrameImageWidth,
				this.offeringFrameImageHeight,
			);
		}
	}

	paintProject() {
		// Get the canvas that holds the painting of the pageModel (the page in the user's project)
		const fullCanvas = this.fullCanvas.getElementsByTagName('canvas')[0];

		// Calculate the original cropping of the canvas that we want to use
		let canvasCropWidth = fullCanvas.width;
		let canvasCropHeight = fullCanvas.height;
		const canvasScale = fullCanvas.width / this.offeringModel.width;
		let cropX = 0;
		let cropY = 0;

		if (this.offeringModel && this.offeringModel.depth) {
			canvasCropWidth -= 2 * this.offeringModel.depth * canvasScale;
			canvasCropHeight -= 2 * this.offeringModel.depth * canvasScale;
			cropX += this.offeringModel.depth * canvasScale;
			cropY += this.offeringModel.depth * canvasScale;
		}

		// In case where we're showing a preview of the user's project projected on a different offering,
		// we will need to crop the painting on the canvas
		let cropWidth = canvasCropWidth;
		let cropHeight = canvasCropHeight;

		// In case the user is currently working on a 20x20 wall decoration, but we're showing a preview
		// of a 30x20 wall decoration, we are dealing with different ratios and thus we'll need to crop the user's project
		const offeringRatio = this.offeringModel.width / this.offeringModel.height;
		const projectRatio = canvasCropWidth / canvasCropHeight;

		// We compare ratios with rounding precision of one digit
		if (Math.round(offeringRatio * 10) / 10 != Math.round(projectRatio * 10) / 10) {
			if (offeringRatio > projectRatio) {
				cropHeight = (projectRatio / offeringRatio) * canvasCropHeight;
				cropY += (canvasCropHeight - cropHeight) / 2;
			} else {
				cropWidth = (offeringRatio / projectRatio) * canvasCropWidth;
				cropX += (canvasCropWidth - cropWidth) / 2;
			}
		}

		if (fullCanvas) {
			const context = this.canvas2d.getContext('2d');
			if (context) {
				if (this.offeringFrame && !this.offeringFrame.overpage) {
					this.paintOfferingFrame();
				}

				context.save();

				if (!this.offeringFrame
					|| (this.offeringFrame.templateModel.x === 0
						&& this.offeringFrame.templateModel.y === 0
					)
				) {
					// Draw shadow to show some depth
					// Note: only for offerings that do not have a frame drawn around them
					context.shadowColor = 'rgba(0,0,0,0.5)';
					context.shadowBlur = 5;
				}

				if (!this.offeringModel.mask) {
					context.fillStyle = this.pageModel.bgcolor || 'transparent';
					context.fillRect(
						this.drawX,
						this.drawY,
						this.drawWidth,
						this.drawHeight,
					);
				} else if (this.offeringMaskImage) {
					context.drawImage(
						this.offeringMaskImage,
						this.drawX,
						this.drawY,
						this.drawWidth,
						this.drawHeight,
					);
				}
				context.restore();

				context.drawImage(
					fullCanvas,
					cropX,
					cropY,
					cropWidth,
					cropHeight,
					this.drawX,
					this.drawY,
					this.drawWidth,
					this.drawHeight,
				);

				if (this.offeringFrame && this.offeringFrame.overpage) {
					this.paintOfferingFrame();
				}
			}
		}
	}

	painted() {
		this.isPainted = true;
	}

	@Watch('imageWidth')
	@Watch('imageHeight')
	sizeChanged() {
		// We need to use a small timeout here, otherwise the actual canvas
		// resize event may happen after this, which in its turn will clear
		// the drawing on the canvas
		window.setTimeout(
			() => {
				this.paintPreview();
			},
			1,
		);
	}
}

export default toNative(TwoDView);
