<template>
	<div class="viewWrapper">
		<div
			v-for="(field, index) in formData"
			:key="index"
			class="itemWrapper"
		>
			<span class="inputLabel">
				{{ field.inputLabel }}
			</span>
			<input
				:id="field.id"
				class="inputField"
				:value="field.value"
				:disabled="loading"
				@input="field.value = $event.target.value"
			>
		</div>
		<div
			v-show="loading"
			class="loadingBar"
		/>
		<button
			v-show="!loading"
			class="actionbutton full"
			@click="save"
		>
			{{ $t('dialogButtonProductAttributesOk') }}
		</button>
	</div>
</template>

<style lang="less" scoped>
@import '~lessVars';
@import '~root/css/components/button.less';
@import '~root/css/components/loadbar.less';

.viewWrapper {
    float: left;
    width: 100%;
    text-align: left;
}
.itemWrapper {
    float: left;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 15px;
    }

    .inputLabel {
        float: left;
        width: 100%;
    }
    .inputField {
        float: left;
        width: 100%;
        padding: 5px;
        border: 1px solid #ccc;
        margin: 2px 0;
        text-align: inherit;
        border-radius: 0;
        box-sizing: border-box;
    }
}
.errorBox {
    float: left;
    width: 100%;
    color: red;
    overflow: hidden;
    overflow-wrap: break-word;
    padding-bottom: 5px;
}
</style>
