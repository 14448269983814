<template>
	<div class="TwoDView">
		<div
			ref="fullCanvas"
			class="fullCanvas"
		>
			<FlatPageView
				:page-model="pageModel"
				:scaling="fullScale"
				:show-mask="true"
				:show-overlay="true"
				@painted="painted"
			/>
		</div>
		<div class="space2d">
			<canvas
				v-show="!showLoader"
				ref="canvas2d"
				:width="imageWidth"
				:height="imageHeight"
			/>
			<div
				v-show="showLoader"
				class="loaderBox"
				:style="{
					height: imageHeight + 'px'
				}"
			>
				<svg
					class="loadIconSvg"
					viewBox="0 0 32 32"
					width="64"
					height="64"
				>
					<circle
						class="loadIcon"
						cx="16"
						cy="16"
						r="14"
						fill="none"
					/>
				</svg>
			</div>
		</div>
	</div>
</template>

<script src='./index.ts'></script>

<style lang="less" scoped>
@import '../../../css/components/animation.less';

.TwoDView {
	overflow: hidden;
	width: 100%;
}
.fullCanvas {
    display: none;
}
.space2d {
	width: 100%;

	canvas {
		outline: none;
	}
}
.loaderBox {
	position: relative;
	width: 100%;

	.loadIconSvg {
		position: absolute;
		top: ~"calc(50% - 32px)";
		left: ~"calc(50% - 32px)";
	}
	.loadIcon {
		.animation-spinner;

		box-sizing: border-box;
		stroke: #A9A9A9;
		stroke-width: 3px;
		transform-origin: 50%;
	}
}
.offeringFrame {
	position: absolute;
}
</style>
