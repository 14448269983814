import './defines';
import { Model } from 'utils/decorators';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'InputSliderComponent',
	emits: [
		'change',
		'mousepress',
		'slider-mousedown',
		'slider-touchstart',
	],
	mixins: [Template],
})
class InputSliderComponent extends Vue {
	@Model({
		description: 'Defines the value of the slider',
		type: Number,
	})
	public readonly value!: number;

	@Prop({
		default: true,
		description: 'Indicates if the slider input value is editable or just informative',
		type: Boolean,
	})
	public readonly editable!: boolean;

	@Prop({
		default: 8,
		description: 'Defines the minimum value for the slider',
		type: Number,
	})
	public readonly min!: number;

	@Prop({
		default: 300,
		description: 'Defines the maximum value for the slider',
		type: Number,
	})
	public readonly max!: number;

	@Prop({
		default: false,
		description: 'Indicates if the increase/decrease buttons should be shown',
		type: Boolean,
	})
	public readonly showIncreaseDecreaseButtons!: boolean;

	@Prop({
		default: false,
		description: 'Indicates if the slider should show the steps dots',
		type: Boolean,
	})
	public readonly showSteps!: boolean;

	@Prop({
		default: true,
		description: 'Indicates if the value and input (for manual inputting) should be shown',
		type: Boolean,
	})
	public readonly showValue!: boolean;

	@Prop({
		default: '--primary1',
		description: 'Defines the color of the slider thumb',
		type: String,
	})
	public readonly thumbColor!: string;

	@Prop({
		default: 24,
		type: Number,
	})
	public readonly trackHeight!: number | string;

	@Prop({
		default: 200,
		description: 'Defines the width of the slider track',
		type: Number,
	})
	public readonly trackWidth!: number;

	protected get sliderStyle(): Partial<CSSStyleDeclaration> & Record<string, string> {
		const styles: Partial<CSSStyleDeclaration> & Record<string, string> = {};
		styles.width = `${this.trackWidth}px`;
		styles['--track-height'] = `${this.trackHeight}px`;

		return styles;
	}

	protected get sliderHandleStyle(): Partial<CSSStyleDeclaration> {
		const styles: Partial<CSSStyleDeclaration> = {};
		styles.transform = `translateX(${this.sliderHandleTranslate}px)`;

		if (
			this.inputSliderElement
			&& this.inputSliderHandleElement
		) {
			const translateY = ((this.inputSliderHandleElement.offsetHeight / 2) - (this.inputSliderElement.offsetHeight / 2));

			if (translateY) {
				styles.transform = `${styles.transform} translateY(-${translateY}px)`;
			}
		}
		if (
			this.thumbColor.substring(
				0,
				2,
			) === '--'
		) {
			styles.backgroundColor = `var(${this.thumbColor})`;
		} else {
			styles.backgroundColor = this.thumbColor;
		}

		return styles;
	}

	protected get sliderHandleTranslate(): number {
		const translate = this.trackInnerWidth * (this.internalValuePercentage / 100);

		if (translate <= 0) {
			return 0;
		}
		if (translate >= this.trackInnerWidth) {
			return this.trackInnerWidth;
		}

		return translate;
	}

	private get internalValuePercentage(): number {
		return (this.internalValue - this.min) / (this.max - this.min) * 100;
	}

	@Ref('inputSlider')
	private readonly inputSliderElement!: HTMLElement;

	@Ref('inputSliderHandle')
	private readonly inputSliderHandleElement!: HTMLElement;

	@Ref('inputSliderTrack')
	private readonly inputSliderTrackElement?: HTMLElement;

	private internalValue = 0;

	private isMouseDown?: boolean;

	private mouseDownInterval?: NodeJS.Timeout;

	private mouseDownTimeout?: NodeJS.Timeout;

	private resizeObserver?: ResizeObserver;

	private trackInnerWidth = 0;

	protected beforeUnmount(): void {
		window.removeEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onWindowMouseUp,
		);

		if (this.mouseDownInterval) {
			clearInterval(this.mouseDownInterval);
			this.mouseDownInterval = undefined;
		}

		if (this.mouseDownTimeout) {
			clearTimeout(this.mouseDownTimeout);
			this.mouseDownTimeout = undefined;
		}

		this.resizeObserver?.disconnect();
	}

	protected mounted(): void {
		this.$nextTick(() => {
			if (this.inputSliderTrackElement) {
				this.trackInnerWidth = this.inputSliderTrackElement.offsetWidth;
			}
		});
		this.resizeObserver = new ResizeObserver(this.onTrackWidthChange);
		this.resizeObserver.observe(this.inputSliderElement);
		this.$forceCompute('sliderHandleStyle');
	}

	@Watch('trackHeight')
	protected onTrackHeightChange(): void {
		requestAnimationFrame(() => {
			this.$forceCompute('sliderHandleStyle');
		});
	}

	@Watch('trackWidth')
	protected onTrackWidthChange(): void {
		requestAnimationFrame(() => {
			if (this.inputSliderTrackElement) {
				this.trackInnerWidth = this.inputSliderTrackElement.offsetWidth;
			}
		});
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange() {
		this.internalValue = this.value ?? this.min;
	}

	private decreaseValue(): void {
		const newValue = this.internalValue - 1;

		if (
			newValue >= this.min
			&& newValue !== this.internalValue
		) {
			this.internalValue = newValue;
			this.triggerInputEvent();
			this.triggerChangeEvent();
		}
	}

	private increaseValue(): void {
		const newValue = this.internalValue + 1;

		if (
			newValue <= this.max
			&& newValue !== this.internalValue
		) {
			this.internalValue = newValue;
			this.triggerInputEvent();
			this.triggerChangeEvent();
		}
	}

	protected onDecreaseClick(): void {
		this.decreaseValue();
	}

	protected onDecreaseMouseDown(): void {
		this.isMouseDown = true;
		window.addEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.addEventListener(
			'touchend',
			this.onWindowMouseUp,
		);

		if (this.mouseDownTimeout) {
			clearTimeout(this.mouseDownTimeout);
		}

		this.mouseDownTimeout = setTimeout(
			() => {
				if (this.mouseDownInterval) {
					clearInterval(this.mouseDownInterval);
				}

				this.mouseDownInterval = setInterval(
					() => {
						if (!this.isMouseDown) {
							clearInterval(this.mouseDownInterval);
							this.mouseDownInterval = undefined;
							return;
						}

						this.$emit('mousepress');
						const newValue = this.internalValue - 1;

						if (
							newValue >= this.min
							&& newValue !== this.internalValue
						) {
							this.internalValue = newValue;
							this.triggerInputEvent();
							this.triggerChangeEvent();
						}
					},
					100,
				);
			},
			500,
		);
	}

	protected onIncreaseClick(): void {
		this.increaseValue();
	}

	protected onIncreaseMouseDown(): void {
		this.isMouseDown = true;
		window.addEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.addEventListener(
			'touchend',
			this.onWindowMouseUp,
		);

		if (this.mouseDownTimeout) {
			clearTimeout(this.mouseDownTimeout);
		}

		this.mouseDownTimeout = setTimeout(
			() => {
				if (this.mouseDownInterval) {
					clearInterval(this.mouseDownInterval);
				}

				this.mouseDownInterval = setInterval(
					() => {
						if (!this.isMouseDown) {
							clearInterval(this.mouseDownInterval);
							this.mouseDownInterval = undefined;
							return;
						}

						this.$emit('mousepress');
						const newValue = this.internalValue + 1;

						if (
							newValue <= this.max
							&& newValue !== this.internalValue
						) {
							this.internalValue = newValue;
							this.triggerInputEvent();
							this.triggerChangeEvent();
						}
					},
					100,
				);
			},
			500,
		);
	}

	protected onInputChange(event: InputEvent): void {
		event.stopPropagation();
		const value = parseInt(
			(event.target as HTMLInputElement).value,
			10,
		) || 0;

		if (value < this.min) {
			/**
			 * If the value is lower than the min value, set the value to the min value
			 * We are using the trick of setting the internal value to `this.min - 1` before setting it to the min value
			 * to force the component to update the value of the input
			 */
			this.internalValue = this.min - 1;
			this.internalValue = this.min;
		} else if (value > this.max) {
			/**
			 * If the value is greater than the max value, set the value to the max value
			 * We are using the trick of setting the internal value to `this.max - 1` before setting it to the max value
			 * to force the component to update the value of the input
			 */
			this.internalValue = this.max - 1;
			this.internalValue = this.max;
		}

		this.triggerChangeEvent();
	}

	protected onInputInput(event: InputEvent): void {
		const value = parseInt(
			(event.target as HTMLInputElement).value,
			10,
		);

		if (
			value >= this.min
			&& value <= this.max
		) {
			this.internalValue = value;
		} else if (value > this.max) {
			/**
			 * If the value is greater than the max value, set the value to the max value
			 * We are using the trick of setting the internal value to `this.max - 1` before setting it to the max value
			 * to force the component to update the value of the input
			 */
			this.internalValue = this.max - 1;
			this.internalValue = this.max;
		}

		this.triggerInputEvent();
	}

	protected onSliderChange(event: InputEvent) {
		event.stopPropagation();
		this.triggerChangeEvent();
	}

	protected onSliderInput(event: InputEvent) {
		this.internalValue = parseInt(
			(event.target as HTMLInputElement).value,
			10,
		);
		this.triggerInputEvent();
	}

	protected onSliderMouseDown(event: MouseEvent): void {
		this.$emit(
			'slider-mousedown',
			event,
		);
	}

	protected onSliderTouchMove(event: TouchEvent) {
		event.stopPropagation();
	}

	protected onSliderTouchStart(event: TouchEvent): void {
		this.$emit(
			'slider-touchstart',
			event,
		);
	}

	private onWindowMouseUp(): void {
		window.removeEventListener(
			'mouseup',
			this.onWindowMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onWindowMouseUp,
		);
		this.isMouseDown = false;

		if (this.mouseDownInterval) {
			clearInterval(this.mouseDownInterval);
			this.mouseDownInterval = undefined;
		}

		if (this.mouseDownTimeout) {
			clearTimeout(this.mouseDownTimeout);
			this.mouseDownTimeout = undefined;
		}
	}

	private triggerChangeEvent(): void {
		this.$emit(
			'change',
			this.internalValue,
		);
	}

	private triggerInputEvent(): void {
		this.$emit(
			'update:value',
			this.internalValue,
		);
	}
}

export default toNative(InputSliderComponent);
