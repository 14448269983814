<template>
	<div class="signup__dialog">
		<div class="first line">
			<div class="title pull__up">
				<h2 class="new__to__text">
					<span v-html="$t('views.authDialog.newTo', { label: labelName })" />
				</h2>
			</div>
			<div class="dialogTopLinks">
				<div>
					<span class="text subHeader">
						{{ $t('views.signup.noAccountYet', { label: labelName }) }}
					</span>
				</div>
			</div>

			<div
				v-if="showForm"
				class="loginForm"
			>
				<div
					v-if="showFirstName"
					class="input-group"
				>
					<label for="fname">
						{{ $t('userProfileFields.first_name') }}
					</label>
					<input
						v-model.trim="inputData.firstName"
						type="text"
						name="fname"
						size="15"
						data-private="lipsum"
						:class="[
							'privateData',
							{ invalid: invalidFirstName }
						]"
						required
					>
				</div>
				<div
					v-if="showLastName"
					class="input-group"
				>
					<label for="lname">
						{{ $t('userProfileFields.last_name') }}
					</label>
					<input
						v-model.trim="inputData.lastName"
						type="text"
						name="lname"
						size="15"
						data-private="lipsum"
						:class="[
							'privateData',
							{ invalid: invalidLastName }
						]"
						required
					>
				</div>
				<div class="input-group">
					<label for="email">
						{{ $t('userProfileFields.email') }}
					</label>
					<input
						v-model.trim="inputData.username"
						type="text"
						name="email"
						:placeholder="$t('views.authDialog.emailPlaceholder')"
						size="15"
						data-private="lipsum"
						:class="[
							'privateData',
							{ invalid: invalidUserName }
						]"
						required
					>
				</div>
				<div class="input-group">
					<label for="password">
						{{ $t('password') }}
					</label>
					<input
						v-model.trim="inputData.password"
						:type="showPassword ? 'text' : 'password'"
						name="password"
						:placeholder="$t('password')"
						size="15"
						:class="{ invalid: invalidPassword }"
						required
						@keyup="checkKey"
					>
					<button
						type="button"
						:class="[
							'togglePassword',
							{ show: showPassword }
						]"
						@click="togglePasswordVisiblity"
					>
						{{ showPassword ? $t('passwordHide') : $t('passwordShow') }}
					</button>
				</div>
				<div class="input-group">
					<input
						v-model.trim="inputData.cpassword"
						:type="showCPassword ? 'text' : 'password'"
						name="cpassword"
						:placeholder="$t('password')"
						size="15"
						:class="{ invalid: invalidCPassword }"
						required
						@keyup="checkKey"
					>
					<button
						type="button"
						:class="[
							'togglePassword',
							{ show: showCPassword }
						]"
						@click="toggleCPasswordVisiblity"
					>
						{{ showCPassword ? $t('passwordHide') : $t('passwordShow') }}
					</button>
				</div>
				<div
					class="instructions"
				>
					{{ $t('views.signup.instructionsText') }}
				</div>
				<div class="buttonContainer">
					<button
						class="actionbutton alt3Color"
						@click="submit"
					>
						{{ $t('buttonSignup') }}
					</button>
				</div>
			</div>
		</div>
		<div class="second">
			<div class="check__text__wrapper">
				<div class="check__text">
					<span class="icon">
						<i class="material-icons check">check</i>
					</span>
					<span class="text">
						{{ $t('views.authDialog.benefit_1') }}
					</span>
				</div>
				<div class="check__text">
					<span class="icon">
						<i class="material-icons check">check</i>
					</span>
					<span class="text">
						{{ $t('views.authDialog.benefit_2') }}

					</span>
				</div>
				<div class="check__text">
					<span class="icon">
						<i class="material-icons check">check</i>
					</span>
					<span class="text">
						{{ $t('views.authDialog.benefit_3') }}
					</span>
				</div>
			</div>

			<div>
				<p class="existingUserLink">
					{{ $t('views.signup.existingUser', { label: labelName }) }}
				</p>
				<div class="buttonContainer">
					<button
						class="actionbutton alt2Color"
						@click="login"
					>
						{{ $t('login') }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="less" scoped>
@import '~lessVars';
@import '~root/css/components/button.less';
@import '~root/css/components/login.less';

.instructions {
	width: 100%;
	float: left;
	text-align: left;
	font-size: 0.8em;
}
.buttonContainer {
	width: 100%;
	float: left;
	padding-top: 15px;
}
.existingUserLink {
	& when(@brand="Hema") {
		text-transform: none;
	}
}
</style>
