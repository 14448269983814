import './defines';
import moment from 'moment';
import Pikaday from 'pikaday';
import { ServiceEvent } from 'services/service-event';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'PikadayView',
	emits: ['onSelect'],
	mixins: [Template],
})
class PikadayView extends Vue {
	@Prop({
		default: true,
		type: Boolean,
	})
	public readonly calendarIcon!: boolean;

	@Prop({
		default: undefined,
		type: Date,
	})
	public readonly defaultDate!: Date | undefined;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly disableWeekends!: boolean;

	@Prop({
		default: 1,
		type: Number,
	})
	public readonly firstDay!: number;

	@Prop({
		default: 'MMMM Do YYYY',
		type: String,
	})
	public readonly format!: string;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly inputDisabled!: boolean;

	@Prop({
		default: null,
		type: String,
	})
	public readonly inputField!: string | null;

	@Prop({
		default: undefined,
		type: Date,
	})
	public readonly maxDate!: Date | undefined;

	@Prop({
		default: undefined,
		type: Date,
	})
	public readonly minDate!: Date | undefined;

	@Prop({
		default: 1,
		type: Number,
	})
	public readonly numberOfMonths!: number;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly setDefaultDate!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly showMonthAfterYear!: boolean;

	@Prop({
		default: () => [1900, 2020],
		type: Array,
	})
	public readonly yearRange!: number[];

	@Ref('input')
	private readonly input!: HTMLInputElement;

	@Ref('datePicker')
	private readonly datePicker!: HTMLElement;

	private currentDate: Date = {} as any;

	private picker!: Pikaday;

	protected mounted() {
		this.picker = new Pikaday({
			defaultDate: this.defaultDate,
			disableWeekends: this.disableWeekends,
			field: this.input,
			firstDay: this.firstDay,
			format: this.format,
			maxDate: this.maxDate,
			minDate: this.minDate,
			numberOfMonths: this.numberOfMonths,
			setDefaultDate: this.setDefaultDate,
			showMonthAfterYear: this.showMonthAfterYear,
			yearRange: this.yearRange,
			i18n: {
				months: [
					this.$t('calendar.months.january'),
					this.$t('calendar.months.february'),
					this.$t('calendar.months.march'),
					this.$t('calendar.months.april'),
					this.$t('calendar.months.may'),
					this.$t('calendar.months.june'),
					this.$t('calendar.months.july'),
					this.$t('calendar.months.august'),
					this.$t('calendar.months.september'),
					this.$t('calendar.months.october'),
					this.$t('calendar.months.november'),
					this.$t('calendar.months.december'),
				],
				previousMonth: this.$t('calendar.months.previous'),
				nextMonth: this.$t('calendar.months.next'),
				weekdays: [
					this.$t('calendar.days.sunday'),
					this.$t('calendar.days.monday'),
					this.$t('calendar.days.tuesday'),
					this.$t('calendar.days.wednesday'),
					this.$t('calendar.days.thursday'),
					this.$t('calendar.days.friday'),
					this.$t('calendar.days.saturday'),
				],
				weekdaysShort: [
					this.$t('sundayAbbreviation'),
					this.$t('mondayAbbreviation'),
					this.$t('tuesdayAbbreviation'),
					this.$t('wednesdayAbbreviation'),
					this.$t('thursdayAbbreviation'),
					this.$t('fridayAbbreviation'),
					this.$t('saturdayAbbreviation'),
				],
			},
			onSelect: () => {
				const mo = this.picker.getMoment();
				const date = (
					mo
						? mo.toDate()
						: this.picker.getDate() || new Date()
				);

				this.currentDate = date;
				this.$emit(
					'onSelect',
					new ServiceEvent({
						type: 'onSelect',
						payload: this.currentDate,
					}),
				);
			},
			toString: (date, format) => {
				moment.locale(window.locale);

				return moment(date).format(format);
			},
		});

		if (this.defaultDate) {
			this.currentDate = this.defaultDate;
		}

		if (!this.inputField) {
			if (this.datePicker && this.datePicker.parentNode && this.picker) {
				this.datePicker.parentNode.insertBefore(
					this.picker.el,
					this.datePicker.nextSibling,
				);
			}
		}
	}

	protected beforeUnmount() {
		if (this.picker) {
			this.picker.destroy();
		}
	}

	@Watch('minDate')
	protected onPikadayMinDateChange(newMinDate: Date) {
		if (this.picker) {
			this.picker.setMinDate(newMinDate);

			if (this.currentDate < newMinDate) {
				this.picker.setDate(
					'1970-01-01',
					true,
				);
			}
		}
	}

	@Watch('maxDate')
	protected onPikadayMaxDateChange(newMaxDate: Date) {
		if (this.picker) {
			this.picker.setMaxDate(newMaxDate);

			if (this.currentDate > newMaxDate) {
				this.picker.setDate(newMaxDate);
			}
		}
	}
}

export default toNative(PikadayView);
