import connector from 'controllers/connector';
import merge from 'deepmerge';
import {
	ChannelModel,
	ChannelProfileModel,
} from 'interfaces/app';
import _ from 'underscore';
import { reactive } from 'vue';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';
import {
	channelModelAttributes,
	profileModelAttributes,
} from './defaults';

@Module({ namespaced: true, name: 'channels' })
export default class Channels extends VuexModule {
	public collection = reactive<ChannelModel[]>([]);

	public get getById() {
		return (id: ChannelModel['id']) => _.findWhere(
			this.collection,
			{ id },
		);
	}

	public get findWhere() {
		return (properties: Partial<ChannelModel>) => _.findWhere(
			this.collection,
			properties,
		);
	}

	public get photoImportChannels(): ChannelModel[] {
		if (window.glPlatform == 'native') {
			return [];
		}

		return this.collection.filter(
			(channelModel) => (channelModel.id === 'app' || connector.isSupported(channelModel.id))
				&& Boolean(channelModel.albums || channelModel.photos || channelModel.folders),
		);
	}

	@Mutation
	private _addModel(data: ChannelModel) {
		this.collection.push(data);
	}

	@Mutation
	public removeModel(id: string) {
		const i = _.findIndex(
			this.collection,
			(m) => m.id == id,
		);
		this.collection.splice(
			i,
			1,
		);
	}

	@Mutation
	private _setModel(data: ChannelModel) {
		const i = _.findIndex(
			this.collection,
			{ id: data.id },
		);
		this.collection[i] = data;
	}

	@Mutation
	public setProfile({
		id,
		data,
	}: {
		id: ChannelModel['id'];
		data: Partial<ChannelProfileModel>;
	}) {
		const i = _.findIndex(
			this.collection,
			{ id },
		);
		const model = this.collection[i].profile;
		const profileData = (
			model
				? merge(
					model,
					data,
				)
				: data
		) as ChannelProfileModel;
		this.collection[i].profile = profileData;
	}

	@Mutation
	private _updateModel(data: ChannelModel) {
		const i = _.findIndex(
			this.collection,
			{ id: data.id },
		);
		const model = this.collection[i];
		this.collection[i] = merge(
			model,
			data,
		);
	}

	@Action
	public addModel(data: ChannelModel) {
		const { getters, commit } = this.context;

		data = _.extend(
			JSON.parse(JSON.stringify(channelModelAttributes)),
			data,
		);

		if (data.id == 'upload') {
			data.icon.push('far fa-laptop fa-3x fa-fw desktopUploadIcon');
			data.icon.push('far fa-mobile-alt fa-3x fa-fw mobileUploadIcon');
		} else if (data.id == 'app') {
			data.icon.push('far fa-history fa-3x fa-fw');
		} else if (data.id == 'dropbox') {
			data.icon.push('fab fa-dropbox fa-3x fa-fw');
		} else if (data.id == 'facebook') {
			data.icon.push('fab fa-facebook-f fa-3x fa-fw');
		} else if (data.id == 'gplus') {
			data.icon.push('fab fa-google fa-3x fa-fw');
		} else if (data.id == 'instagram') {
			data.icon.push('fab fa-instagram fa-3x fa-fw');
		} else if (data.id == 'microsoft') {
			data.icon.push('fab fa-microsoft fa-3x fa-fw');
		} else {
			data.icon.push('far fa-cloud fa-3x fa-fw');
		}

		if (getters.getById(data.id)) {
			commit(
				'_setModel',
				data,
			);
		} else {
			commit(
				'_addModel',
				data,
			);
		}
	}

	@Action
	public addModels(arrData: ChannelModel[]) {
		const { dispatch } = this.context;

		_.each(
			_.sortBy(
				arrData,
				'place',
			),
			(data) => {
				dispatch(
					'addModel',
					data,
				);
			},
		);
	}

	@Action
	public resetModel(id: string) {
		const { commit } = this.context;

		commit(
			'_updateModel',
			{
				id,
				profile: JSON.parse(JSON.stringify(profileModelAttributes)),
			},
		);
	}
}
