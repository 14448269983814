import './defines';
import { ServiceEvent } from 'services/service-event';
import {
	dom as domUtils,
	vue as vueUtils,
} from 'utils';
import { Public } from 'utils/decorators';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import { type ComponentPublicInstance } from 'vue';
import {
	Prop,
	Ref,
	Vue,
	Watch,
} from 'vue-facing-decorator';
import { type Cons } from 'vue-facing-decorator/dist/component';
import Template from './template.vue';

@Component({
	name: 'ToolbarComponent',
	emits: ['close'],
	mixins: [Template],
})
class ToolbarComponent<BodyComponent extends Cons> extends Vue {
	@Prop({
		default: undefined,
		type: Function,
	})
	public readonly beforeClose?: ServiceEventHandler<any>;

	@Prop({
		required: true,
		type: Object,
	})
	public readonly body!: ToolbarServiceOptionsBody<BodyComponent>;

	@Prop({
		default: () => ({}),
		type: [Array, Object],
	})
	public readonly bodyClasses!: string[] | Record<string, boolean>;

	@Prop({
		default: () => ({}),
		type: Object,
	})
	public readonly bodyStyles!: Partial<CSSStyleDeclaration> & Record<string, string>;

	@Prop({
		default: true,
		description: 'Indicates if the tooltip should shown as a modal',
		type: Boolean,
	})
	public readonly isModal!: boolean;

	@Prop({
		default: false,
		type: Boolean,
	})
	public readonly isOpen!: boolean;

	@Prop({
		default: () => ({}),
		type: Object,
	})
	public readonly listeners!: Record<string, ServiceEventHandler<any>[] | ServiceEventHandler<any>>;

	@Prop({
		default: undefined,
		type: String,
	})
	public readonly title?: string;

	@Prop({
		default: () => ({}),
		type: [Array, Object],
	})
	public readonly toolbarClasses!: string[] | Record<string, boolean>;

	@Prop({
		default: () => ({}),
		type: Object,
	})
	public readonly toolbarStyles!: Partial<CSSStyleDeclaration> & Record<string, string>;

	protected get computedBodyClasses(): Record<string, boolean> {
		const classes: Record<string, boolean> = {};

		if (Array.isArray(this.bodyClasses)) {
			// eslint-disable-next-line no-restricted-syntax
			for (const className of this.bodyClasses) {
				classes[className] = true;
			}
		} else {
			Object.assign(
				classes,
				this.bodyClasses,
			);
		}

		if (this.title) {
			classes['toolbar-body-with-title'] = true;
		}

		return classes;
	}

	protected get computedToolbarOverlayClasses(): Record<string, boolean> {
		return {
			'toolbar-modal': this.isModal,
			[this.internalTheme]: true,
		};
	}

	protected get bodyComponentInstanceAPI(): NonVuePublicProps<ComponentPublicInstance<InstanceType<BodyComponent>>> | undefined {
		const defaultSlotComponentInstance = this.$getSlot<InstanceType<BodyComponent>>('default');

		if (defaultSlotComponentInstance) {
			return vueUtils.getInstanceAPI(defaultSlotComponentInstance);
		}

		return undefined;
	}

	@Ref('toolbar')
	protected readonly toolbarElement!: HTMLDivElement;

	private currentDragPosition!: number;

	private isDragging!: boolean;

	private startDragPosition!: number;

	private toolbarHeight!: number;

	private translateY!: number;

	protected beforeUnmount(): void {
		window.removeEventListener(
			'mousemove',
			this.onToolbarComponentMouseMove,
		);
		window.removeEventListener(
			'touchmove',
			this.onToolbarComponentMouseMove,
		);
		window.removeEventListener(
			'mouseup',
			this.onToolbarComponentMouseUp,
		);
		window.removeEventListener(
			'touchend',
			this.onToolbarComponentMouseUp,
		);
	}

	@Watch(
		'isOpen',
		{
			immediate: true,
		},
	)
	protected onIsOpenChange(): void {
		if (this.isOpen) {
			this.$nextTick(() => {
				document.body.style.overflow = 'hidden';
				this.$el.classList.add('toolbar-open');
			});
		} else {
			this.$nextTick(() => {
				document.body.style.overflow = '';
				this.$el.classList.remove('toolbar-open');
			});
		}
	}

	@Public()
	public bodyComponent(): Decorated<NonVuePublicProps<ComponentPublicInstance<InstanceType<BodyComponent>>>> | undefined {
		return this.bodyComponentInstanceAPI as Decorated<NonVuePublicProps<ComponentPublicInstance<InstanceType<BodyComponent>>>> | undefined;
	}

	@Public()
	public close(
		event?: ServiceEvent,
		immediate?: boolean,
	): Decorated<void> {
		if (!event) {
			event = new ServiceEvent({
				type: 'close',
			});
		}

		if (typeof immediate !== 'undefined') {
			event.payload = immediate;
		}

		if (this.beforeClose) {
			this.beforeClose(event);
		}

		if (!event.defaultPrevented) {
			this.$emit(
				'close',
				event,
			);
		}
	}

	protected onToolbarComponentMouseDown(event: MouseEvent | TouchEvent): void {
		const target = event.target as HTMLElement;

		if (
			!domUtils.isRectScrollable(
				target,
				this.$el as HTMLElement,
			)
		) {
			let finalEvent: MouseEvent | Touch;

			if ('touches' in event) {
				// eslint-disable-next-line prefer-destructuring
				finalEvent = event.touches[0];
			} else {
				finalEvent = event;
			}

			this.currentDragPosition = finalEvent.clientY;
			this.startDragPosition = finalEvent.clientY;
			this.toolbarHeight = this.toolbarElement.offsetHeight;
			this.translateY = 0;
			this.isDragging = true;
			window.addEventListener(
				'mousemove',
				this.onToolbarComponentMouseMove,
			);
			window.addEventListener(
				'touchmove',
				this.onToolbarComponentMouseMove,
			);
			window.addEventListener(
				'mouseup',
				this.onToolbarComponentMouseUp,
			);
			window.addEventListener(
				'touchend',
				this.onToolbarComponentMouseUp,
			);
		}
	}

	protected onToolbarComponentMouseMove(event: MouseEvent | TouchEvent): void {
		let finalEvent: MouseEvent | Touch;

		if ('touches' in event) {
			// eslint-disable-next-line prefer-destructuring
			finalEvent = event.touches[0];
		} else {
			finalEvent = event;
		}

		const { clientY } = finalEvent;

		if (
			this.isDragging
			&& clientY >= this.startDragPosition
		) {
			event.preventDefault();
			const delta = clientY - this.currentDragPosition;
			this.translateY = Math.max(
				0,
				this.translateY + delta,
			);
			this.toolbarElement.style.transition = 'unset';
			this.toolbarElement.style.transform = `translate3d(0, ${this.translateY}px, 0)`;
			this.currentDragPosition = clientY;
		}
	}

	protected onToolbarComponentMouseUp(): void {
		if (this.isDragging) {
			this.isDragging = false;
			const heightPercent = ((this.toolbarHeight - this.translateY) * 100) / this.toolbarHeight;
			this.toolbarElement.style.transition = '';
			const computedStyles = getComputedStyle(this.toolbarElement);
			const halfTransitionDuration = (parseFloat(computedStyles.transitionDuration) * 1000) / 2;
			this.toolbarElement.style.transition = `transform ${halfTransitionDuration / 1000}s ${computedStyles.transitionTimingFunction}`;

			if (heightPercent < 60) {
				this.$el.addEventListener(
					'transitionend',
					() => {
						this.toolbarElement.style.transition = '';
						this.close(
							undefined,
							true,
						);
					},
					{
						once: true,
					},
				);
				this.toolbarElement.style.transform = `translate3d(0, ${this.toolbarHeight}px, 0)`;
			} else {
				if (heightPercent < 100) {
					this.$el.addEventListener(
						'transitionend',
						() => {
							this.toolbarElement.style.transition = '';
						},
						{
							once: true,
						},
					);
				}

				this.toolbarElement.style.transform = '';

				if (heightPercent >= 100) {
					this.toolbarElement.style.transition = '';
				}
			}

			window.removeEventListener(
				'mousemove',
				this.onToolbarComponentMouseMove,
			);
			window.removeEventListener(
				'touchmove',
				this.onToolbarComponentMouseMove,
			);
			window.removeEventListener(
				'mouseup',
				this.onToolbarComponentMouseUp,
			);
			window.removeEventListener(
				'touchend',
				this.onToolbarComponentMouseUp,
			);
		}
	}

	protected onToolbarModalClick(event: Event | TouchEvent): void {
		let target: HTMLElement;

		if ('touches' in event) {
			target = event.touches[0].target as HTMLElement;
		} else {
			target = event.target as HTMLElement;
		}

		if (
			!this.$currentInstance.isUnmounted
			&& target.isConnected
			&& target !== this.toolbarElement
			&& !this.toolbarElement.contains(target)
			&& !event.defaultPrevented
		) {
			const closeEvent = new ServiceEvent({
				type: 'close',
				payload: false,
			});
			this.$emit(
				'close',
				closeEvent,
			);
		}
	}
}

export default toNative(ToolbarComponent);
