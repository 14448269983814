import { AxiosRequestConfig } from 'axios';
import ajax from 'controllers/ajax';
import merge from 'deepmerge';
import { AjaxOptions } from 'interfaces/app';
import * as DB from 'interfaces/database';
import { ERRORS_INVALID_REQUEST_DATA } from 'settings/errors';
import _ from 'underscore';
import { reactive } from 'vue';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'orders' })
export default class Orders extends VuexModule {
	collection = reactive<DB.OrderModel[]>([]);

	fetched = false;

	modelUrl = '/api/order';

	offset = 0;

	totalRecords: number | null = null;

	private get collectionUrl() {
		return `/api/user/${this.context.rootState.user.id}/orders`;
	}

	public get getById() {
		return (id: number) => _.findWhere(
			this.collection,
			{ id },
		);
	}

	public get sortedCollection() {
		return _.sortBy(
			this.collection,
			'id',
		);
	}

	@Mutation
	private _addModel(data: DB.OrderModel) {
		this.collection.push(data);
	}

	@Mutation
	private _addOffset(number: number) {
		this.offset += number;
	}

	@Mutation
	private _setFetched(flag: boolean) {
		this.fetched = flag;
	}

	@Mutation
	private _setTotalRecords(number: number) {
		this.totalRecords = number;
	}

	@Mutation
	public updateModel(data: DB.OrderModel) {
		const i = _.findIndex(
			this.collection,
			{ id: data.id },
		);
		const model = this.collection[i];
		this.collection[i] = _.extend(
			model,
			data,
		);
	}

	@Action({ rawError: true })
	public addModel(data: DB.OrderModel): Promise<DB.OrderModel> {
		const { getters, commit } = this.context;

		return new Promise((resolve, reject) => {
			if (!data.id) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else {
				if (getters.getById(data.id)) {
					commit(
						'updateModel',
						data,
					);
				} else {
					commit(
						'_addModel',
						data,
					);
				}

				resolve(getters.getById(data.id));
			}
		});
	}

	@Action({ rawError: true })
	public fetch({
		requestOptions,
		methodOptions,
	}: {
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<void> {
		const { getters, commit, dispatch } = this.context;

		return new Promise((resolve, reject) => {
			const defaultRequestOptions: AxiosRequestConfig = {
				method: 'get',
				url: getters.collectionUrl,
				params: {
					offset: this.offset,
					limit: 20,
					orderby: 'id DESC',
				},
			};
			const defaultMethodOptions: AjaxOptions = {
				auth: true,
				debug: {
					offline: true,
					dialog: true,
					abort: true,
				},
			};

			requestOptions = requestOptions
				? merge(
					defaultRequestOptions,
					requestOptions,
				)
				: defaultRequestOptions;
			methodOptions = methodOptions
				? merge(
					defaultMethodOptions,
					methodOptions,
				)
				: defaultMethodOptions;

			const { limit } = requestOptions.params;

			ajax
				.request(
					requestOptions,
					methodOptions,
				)
				.then((response) => {
					_.each(
						response.data,
						(model) => {
							dispatch(
								'addModel',
								model,
							);
						},
					);

					commit(
						'_addOffset',
						limit,
					);
					commit(
						'_setFetched',
						true,
					);

					// Set total records and limit to collection properties
					if (response.headers.hasOwnProperty('x-total-records')) {
						commit(
							'_setTotalRecords',
							Math.max(
								0,
								parseInt(
									response.headers['x-total-records'],
									10,
								),
							),
						);
					}

					resolve();
				})
				.catch(reject);
		});
	}

	@Action({ rawError: true })
	public fetchModel({
		id,
		requestOptions,
		methodOptions,
	}: {
		id: number;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<DB.OrderModel> {
		const { getters, dispatch } = this.context;

		return new Promise((resolve, reject) => {
			const defaultRequestOptions: AxiosRequestConfig = {
				method: 'get',
				url: `${this.modelUrl}/${id}`,
			};
			const defaultMethodOptions: AjaxOptions = {
				auth: true,
				debug: {
					offline: true,
					dialog: true,
					abort: true,
				},
			};

			requestOptions = requestOptions
				? merge(
					defaultRequestOptions,
					requestOptions,
				)
				: defaultRequestOptions;
			methodOptions = methodOptions
				? merge(
					defaultMethodOptions,
					methodOptions,
				)
				: defaultMethodOptions;

			ajax
				.request(
					requestOptions,
					methodOptions,
				)
				.then((response) => {
					dispatch(
						'addModel',
						response.data,
					);
					resolve(getters.getById(response.data.id));
				})
				.catch(reject);
		});
	}
}
