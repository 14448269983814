import { UploadModel } from 'interfaces/app';
import { MIME_TYPES_REQUIRE_CONVERSION } from 'settings/filetypes';
import _ from 'underscore';
import { reactive } from 'vue';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'upload' })
export default class Upload extends VuexModule {
	completed = reactive<UploadModel[]>([]);

	error = reactive<UploadModel[]>([]);

	uploading = reactive<UploadModel[]>([]);

	waiting = reactive<UploadModel[]>([]);

	get find() {
		return (id: string) => _.findWhere(
			this.error,
			{ id },
		)
			|| _.findWhere(
				this.uploading,
				{ id },
			)
			|| _.findWhere(
				this.waiting,
				{ id },
			)
			|| _.findWhere(
				this.completed,
				{ id },
			);
	}

	get findWhere() {
		return (props: Partial<UploadModel>) => _.findWhere(
			this.error,
			props,
		)
			|| _.findWhere(
				this.uploading,
				props,
			)
			|| _.findWhere(
				this.waiting,
				props,
			)
			|| _.findWhere(
				this.completed,
				props,
			);
	}

	get findCompleted() {
		return (id: string) => _.findWhere(
			this.completed,
			{ id },
		);
	}

	get findError() {
		return (id: string) => _.findWhere(
			this.error,
			{ id },
		);
	}

	get findUploading() {
		return (id: string) => _.findWhere(
			this.uploading,
			{ id },
		);
	}

	get findWaiting() {
		return (id: string) => _.findWhere(
			this.waiting,
			{ id },
		);
	}

	get getInProgress() {
		return _.union(
			this.uploading,
			this.waiting,
		);
	}

	get models() {
		return [
			...this.error,
			...this.uploading,
			...this.waiting,
			...this.completed,
		];
	}

	public get totalBytes() {
		let totalBytes = 0;
		this.getInProgress.forEach((uploadModel) => {
			totalBytes += uploadModel.totalBytes;
		});
		this.completed.forEach((uploadModel) => {
			totalBytes += uploadModel.totalBytes;
		});

		return totalBytes;
	}

	public get totalBytesSpecial() {
		let totalBytes = 0;
		this.getInProgress.forEach((uploadModel) => {
			if (uploadModel._type
				&& MIME_TYPES_REQUIRE_CONVERSION.includes(uploadModel._type)
			) {
				totalBytes += uploadModel.totalBytes;
			}
		});
		this.completed.forEach((uploadModel) => {
			if (uploadModel._type
				&& MIME_TYPES_REQUIRE_CONVERSION.includes(uploadModel._type)
			) {
				totalBytes += uploadModel.totalBytes;
			}
		});

		return totalBytes;
	}

	public get totalUploadBytes() {
		let totalUploadedBytes = 0;
		this.getInProgress.forEach((uploadModel) => {
			totalUploadedBytes += uploadModel.totalBytes * uploadModel.queue;
		});
		this.completed.forEach((uploadModel) => {
			totalUploadedBytes += uploadModel.totalBytes;
		});

		return totalUploadedBytes;
	}

	public get totalUploadBytesSpecial() {
		let totalUploadedBytes = 0;
		this.getInProgress.forEach((uploadModel) => {
			if (uploadModel._type
				&& MIME_TYPES_REQUIRE_CONVERSION.includes(uploadModel._type)
			) {
				totalUploadedBytes += uploadModel.totalBytes * uploadModel.queue;
			}
		});
		this.completed.forEach((uploadModel) => {
			if (uploadModel._type
				&& MIME_TYPES_REQUIRE_CONVERSION.includes(uploadModel._type)
			) {
				totalUploadedBytes += uploadModel.totalBytes;
			}
		});

		return totalUploadedBytes;
	}

	@Mutation
	private _removeError(id: string) {
		const i = _.findIndex(
			this.error,
			(m) => m.id == id,
		);
		if (i !== -1) {
			this.error.splice(
				i,
				1,
			);
		}
	}

	@Mutation
	private _removeUploading(id: string) {
		const i = _.findIndex(
			this.uploading,
			(m) => m.id == id,
		);
		if (i !== -1) {
			this.uploading.splice(
				i,
				1,
			);
		}
	}

	@Mutation
	private _removeWaiting(id: string) {
		const i = _.findIndex(
			this.waiting,
			(m) => m.id == id,
		);
		if (i !== -1) {
			this.waiting.splice(
				i,
				1,
			);
		}
	}

	@Mutation
	private _resetCompleted() {
		this.completed = [];
	}

	@Mutation
	private _resetUploading() {
		this.uploading = [];
	}

	@Mutation
	private _resetWaiting() {
		this.waiting = [];
	}

	@Mutation
	public addCompleted(data: UploadModel) {
		this.completed.push(data);
	}

	@Mutation
	public addError(data: UploadModel) {
		this.error.push(data);
	}

	@Mutation
	public addUploading(data: UploadModel) {
		this.uploading.push(data);
	}

	@Mutation
	public addWaiting(data: UploadModel) {
		this.waiting.push(data);
	}

	@Mutation
	public changeModel(data: OptionalExceptFor<UploadModel, 'id'>) {
		// Check if the model can be found in the Waiting queue
		// and update the model if so
		const w = _.findIndex(
			this.waiting,
			(m) => m.id == data.id,
		);
		if (w >= 0) {
			const waitingModel = this.waiting[w];
			this.waiting[w] = _.extend(
				waitingModel,
				data,
			);
		} else {
			// Check if the model can be found in the Uploading queue
			// and update the model if so
			const u = _.findIndex(
				this.uploading,
				(m) => m.id == data.id,
			);
			if (u >= 0) {
				const uploadModel = this.uploading[u];
				this.uploading[u] = _.extend(
					uploadModel,
					data,
				);
			} else {
				// Check if the model can be found in the Error queue
				// and update the model if so
				const e = _.findIndex(
					this.error,
					(m) => m.id == data.id,
				);
				if (e >= 0) {
					const errorModel = this.error[e];
					this.error[e] = _.extend(
						errorModel,
						data,
					);
				} else {
					// Check if the model can be found in the Completed queue
					// and update the model if so
					const c = _.findIndex(
						this.completed,
						(m) => m.id == data.id,
					);
					if (c >= 0) {
						const completeModel = this.completed[c];
						this.completed[c] = _.extend(
							completeModel,
							data,
						);
					}
				}
			}
		}
	}

	@Mutation
	public resetError() {
		this.error = [];
	}

	@Mutation
	public updateCompleted(newData: {
		id: string;
		photoModelId: number;
	}) {
		const currentData = _.findWhere(
			this.completed,
			{ id: newData.id },
		);
		if (currentData) {
			const i = _.findIndex(
				this.completed,
				(m) => m.id == newData.id,
			);
			const data = _.extend(
				currentData,
				newData,
			);
			this.completed[i] = data;
		}
	}

	@Mutation
	public updateUploading(newData: OptionalExceptFor<UploadModel, 'id' | 'queue'>) {
		const currentData = _.findWhere(
			this.uploading,
			{ id: newData.id },
		);
		if (currentData) {
			const i = _.findIndex(
				this.uploading,
				(m) => m.id == newData.id,
			);
			const data = _.extend(
				currentData,
				newData,
			);
			this.uploading[i] = data;
		}
	}

	@Action
	public moveToError(id: string) {
		const { getters, commit } = this.context;

		if (getters.findWaiting(id)) {
			const uploadModel = getters.findWaiting(id);
			const uploadData = JSON.parse(JSON.stringify(uploadModel));
			uploadData.errorCount += 1;
			commit(
				'addError',
				uploadData,
			);
			commit(
				'_removeWaiting',
				uploadModel.id,
			);
		} else if (getters.findUploading(id)) {
			const uploadModel = getters.findUploading(id);
			const uploadData = JSON.parse(JSON.stringify(uploadModel));
			uploadData.errorCount += 1;
			commit(
				'addError',
				uploadData,
			);
			commit(
				'_removeUploading',
				uploadModel.id,
			);
		}
	}

	@Action
	public moveErrorToWaiting(id: string) {
		const { getters, commit } = this.context;

		if (getters.findError(id)) {
			const uploadModel = getters.findError(id);
			const uploadData = JSON.parse(JSON.stringify(uploadModel));
			commit(
				'addWaiting',
				uploadData,
			);
			commit(
				'_removeError',
				uploadModel.id,
			);
		}
	}

	@Action
	public moveWaitingToUploading(id: string) {
		const { getters, commit } = this.context;

		// Set timestamp to upload model for measuring upload time
		const uploadModel = getters.findWaiting(id);
		if (uploadModel) {
			const uploadData = JSON.parse(JSON.stringify(uploadModel));
			commit(
				'addUploading',
				uploadData,
			);
			commit(
				'_removeWaiting',
				id,
			);
		}
	}

	@Action
	public remove(id: string) {
		const { getters, commit } = this.context;

		if (getters.findWaiting(id)) {
			commit(
				'_removeWaiting',
				id,
			);
		} else if (getters.findUploading(id)) {
			commit(
				'_removeUploading',
				id,
			);
		} else if (getters.findError(id)) {
			commit(
				'_removeError',
				id,
			);
		}
	}

	@Action
	public reset() {
		const { commit } = this.context;

		commit('resetError');
		commit('_resetCompleted');
		commit('_resetUploading');
		commit('_resetWaiting');
	}
}
