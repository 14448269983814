import './defines';
import User from 'classes/user';
import analytics from 'controllers/analytics';
import storage from 'controllers/storage';
import {
	AppDataModule,
	UserModule,
} from 'store';
import setNativeLanguage from 'tools/set-native-language';
import _ from 'underscore';
import { toNative } from 'utils/vue-facing-decorator';
import {
	Component,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

interface CountryData {
	id: number;
	name: string;
}

@Component({
	mixins: [Template],
})
class UserSettingsView extends Vue {
	protected get countries() {
		const countries: CountryData[] = [];
		AppDataModule.countries.forEach((countryModel) => {
			countries.push({
				id: countryModel.id,
				name: AppDataModule.getCountryName(countryModel.id),
			});
		});

		return _.sortBy(
			countries,
			'name',
		);
	}

	protected get currencies() {
		return this.countryid
			? AppDataModule.getCurrencyByCountryId(this.countryid)
			: AppDataModule.currencies;
	}

	private get countryid() {
		return UserModule.countryid;
	}

	private set countryid(value) {
		UserModule.set({
			countryid: value,
		});
	}

	protected get languages() {
		return AppDataModule.languages;
	}

	private get userLanguage() {
		return UserModule.language || window.locale;
	}

	private set userLanguage(value) {
		UserModule.set({
			language: value,
		});
	}

	protected get userCurrency() {
		return UserModule.currency;
	}

	protected set userCurrency(value) {
		UserModule.set({
			currency: value,
		});
	}

	protected onChange() {
		// Check if all values are valid
		User.validate().then(() => {
			UserModule.put().finally(() => {
				if (this.userLanguage && this.userLanguage != window.locale) {
					storage.set(
						'locale',
						this.userLanguage,
					);
					window.locale = this.userLanguage;

					if (window.glPlatform === 'native') {
						// Add translation strings for native interface
						setNativeLanguage(this.userLanguage);
					}
				}
				if (!UserModule.id) {
					storage.set(
						'countryid',
						UserModule.countryid,
					);
				}

				return this.$i18next.changeLanguage(this.userLanguage);
			}).then(() => {
				// Send updated properties to analytics providers
				analytics.setUserProperties();

				// @ts-ignore: reload is our custom method and does exist
				window.App.router.reload();
			}).catch(() => {
				// Swallow error: no action required
			});
		});
	}
}

export default toNative(UserSettingsView);
