import { AxiosRequestConfig } from 'axios';
import ajax from 'controllers/ajax';
import merge from 'deepmerge';
import { AjaxOptions } from 'interfaces/app';
import * as DB from 'interfaces/database';
import { ERRORS_INVALID_REQUEST_DATA } from 'settings/errors';
import _ from 'underscore';
import { reactive } from 'vue';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'orderitems' })
export default class OrderItems extends VuexModule {
	collection = reactive<DB.OrderItemModel[]>([]);

	fetched = false;

	modelUrl = '/api/orderitem';

	offset = 0;

	totalRecords: number | null = null;

	public get getById() {
		return (id: number) => _.findWhere(
			this.collection,
			{ id },
		);
	}

	public get sortedCollection() {
		return _.sortBy(
			this.collection,
			'id',
		);
	}

	public get where() {
		return (properties: Partial<DB.OrderItemModel>) => _.where(
			this.collection,
			properties,
		);
	}

	@Mutation
	private _addModel(data: DB.OrderItemModel) {
		this.collection.push(data);
	}

	@Mutation
	private _addOffset(number: number) {
		this.offset += number;
	}

	@Mutation
	private _setFetched(val: boolean) {
		this.fetched = val;
	}

	@Mutation
	private _setTotalRecords(val: number) {
		this.totalRecords = val;
	}

	@Mutation
	public updateModel(data: DB.OrderItemModel) {
		const i = _.findIndex(
			this.collection,
			{ id: data.id },
		);
		const model = this.collection[i];
		this.collection[i] = _.extend(
			model,
			data,
		);
	}

	@Action({ rawError: true })
	public addModel(data: DB.OrderItemModel): Promise<DB.OrderItemModel> {
		const { getters, commit } = this.context;

		return new Promise((resolve, reject) => {
			if (!data.id) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else {
				if (getters.getById(data.id)) {
					commit(
						'updateModel',
						data,
					);
				} else {
					commit(
						'_addModel',
						data,
					);
				}

				resolve(getters.getById(data.id));
			}
		});
	}

	@Action({ rawError: true })
	public fetch({
		orderId,
		requestOptions,
		methodOptions,
	}: {
		orderId: number;
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<void> {
		const { commit, dispatch } = this.context;

		return new Promise((resolve, reject) => {
			if (!orderId) {
				reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			} else {
				const defaultRequestOptions: AxiosRequestConfig = {
					method: 'get',
					url: `/api/order/${orderId}/items`,
					params: {
						offset: this.offset,
						limit: 20,
						orderby: 'id DESC',
					},
				};
				const defaultMethodOptions: AjaxOptions = {
					auth: true,
					debug: {
						offline: true,
						dialog: true,
						abort: true,
					},
				};

				requestOptions = requestOptions
					? merge(
						defaultRequestOptions,
						requestOptions,
					)
					: defaultRequestOptions;
				methodOptions = methodOptions
					? merge(
						defaultMethodOptions,
						methodOptions,
					)
					: defaultMethodOptions;

				const { limit } = requestOptions.params;

				ajax
					.request(
						requestOptions,
						methodOptions,
					)
					.then((response) => {
						_.each(
							response.data,
							(model) => {
								dispatch(
									'addModel',
									model,
								);
							},
						);

						commit(
							'_addOffset',
							limit,
						);
						commit(
							'_setFetched',
							true,
						);

						// Set total records and limit to collection properties
						if (response.headers.hasOwnProperty('x-total-records')) {
							commit(
								'_setTotalRecords',
								Math.max(
									0,
									parseInt(
										response.headers['x-total-records'],
										10,
									),
								),
							);
						}

						resolve();
					})
					.catch(reject);
			}
		});
	}
}
