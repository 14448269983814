import './defines';
import FacebookIcon from '@root/svg/facebook-icon.svg';
import connector from 'controllers/connector';
import { toNative } from 'utils/vue-facing-decorator';
import {
	Component,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	components: {
		FacebookIcon,
	},
	emits: ['buttonClicked'],
	mixins: [Template],
})
class FacebookButtonView extends Vue {
	private disabled = true;

	protected mounted() {
		connector
			.setup('facebook')
			.then(() => connector.init('facebook'))
			.then(() => {
				this.disabled = false;
			})
			.catch((error: Error) => {
				console.log(error);
			});
	}

	protected clickButton() {
		if (!this.disabled) {
			this.$emit('buttonClicked');
		}
	}
}

export default toNative(FacebookButtonView);
