import AppClass from 'classes/app';
import DropboxClass from 'classes/dropbox';
import FacebookClass from 'classes/facebook';
import GooglePlusClass from 'classes/gplus';
import InstagramClass from 'classes/instagram';
import MicrosoftClass from 'classes/microsoft';
import ProductState from 'classes/productstate';
import User from 'classes/user';
import EventBus from 'components/event-bus';
import merge from 'deepmerge';
import {
	ChannelModel,
	ChannelProfileModel,
	ConnectFolderData,
	ConnectorBridgeResponseData,
} from 'interfaces/app';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import * as DialogService from 'services/dialog';
import {
	ERRORS_OAUTH_FAILED,
	ERRORS_UNSUPPORTED_FEATURE,
} from 'settings/errors';
import {
	AppStateModule,
	ChannelsModule,
	ConfigModule,
	ExternalUsersModule,
	ProductStateModule,
	UserModule,
} from 'store';
import _ from 'underscore';
import ajax from './ajax';
import experiment from './experiment';

type Network = Exclude<ChannelModel['id'], 'upload'|'app'|'qr'>;
export type ConnectorScopeCategory = 'basic'|'login'|'photos'|'friends'|'folders'|'likes';

export interface ConnectorBridgeLoginOptions {
	display?: string;
	redirectUri?: string;
	username?: string;
	password?: string;
}
export interface ConnectorBridgeLoginResponse {
	accessToken?: string;
	userid?: string;
}
export interface ConnectorBridgeMeOptions {
	fields?: string;
}
export type ConvertedPhotoData = OptionalExceptFor<PI.PhotoModel, 'full_url'>;

export interface ConnectorBridge {
	isSupported: boolean;
	contentFilters: string[];
	grantedScopes: string[];
	setup: () => Promise<void>;
	init: (m: ChannelModel) => Promise<void>;
	login: (
		scope: string[],
		options: ConnectorBridgeLoginOptions,
	) => Promise<ConnectorBridgeLoginResponse>;
	logout: (options?: ConnectorOptions) => Promise<void>;
	me: (
		scope: string[],
		options?: ConnectorBridgeMeOptions
	) => Promise<ChannelProfileModel>;
	photos: (options: any) => Promise<ConnectorBridgeResponseData>;
	albums: (options: any) => Promise<ConnectorBridgeResponseData>;
	albumPhotos: (albumid: string, options: any) => Promise<ConnectorBridgeResponseData>;
	folders: (folderid: string | null, options: any) => Promise<ConnectorBridgeResponseData>;
	convertPhotoData: (
		photoData: any,
	) => ConvertedPhotoData|undefined;
	convertAlbumData: (data: any) => any;
	convertFolderData: (data: any) => any;
	getFileType: (data: any) => any;
	getFullUrl?: (id: string) => Promise<string>;
	share: (options: any) => Promise<void>;
}

export interface ConnectorOptions {
	category?: string | DB.PhotoModel['type'];
	display?: string;
	first_name?: string;
	folderid?: string;
	force?: boolean;
	fromDate?: string;
	isRetry?: boolean;
	last_name?: string;
	nextOptions?: string;
	nextPage?: string;
	password?: string;
	redirectUri?: string;
	routeOptions?: Record<string, any>;
	scopeCategories?: ConnectorScopeCategory[];
	toDate?: string;
	username?: string;
}
interface BridgeOptions extends ConnectorOptions {
	scope: string[];
}

export interface ConnectorLoginOptions {
	display?: string;
	force?: false;
	password?: string;
	redirectUri?: string;
	scopeCategories: ConnectorScopeCategory[];
	username?: string;
}
export interface ConnectorSignupOptions {
	display?: string;
	first_name?: string;
	last_name?: string;
	password?: string;
	redirectUri?: string;
	scopeCategories?: ConnectorScopeCategory[];
	username?: string;
}

interface ConnectorShareOptions {
	link?: string;
	text?: string;
	image?: string;
}

export interface CloudAlbumModel {
	id: string;
	name: string;
	type: string;
	thumbnail?: string;
}

interface ResponseData {
	paging: {
		nextOptions?: any;
		nextPage?: string;
	};
}
export interface ConnectorPhotosResponseData extends ResponseData {
	data: Partial<PI.PhotoModel>[];
}
interface AlbumsResponseData extends ResponseData {
	data: CloudAlbumModel[];
}
interface FolderResponseData extends ResponseData {
	photoCollection: Partial<PI.PhotoModel>[];
	folderCollection: ConnectFolderData[];
}

class Connector {
	public networks: {
		[K in Network]: {
			accessToken: string|null;
			bridge: ConnectorBridge;
			initialized: boolean;
		}
	};

	private scope: {
		[K in Network]: {
			[L in ConnectorScopeCategory]: string[];
		}
	} = {
			dropbox: {
				basic: [],
				login: [],
				photos: [],
				friends: [],
				folders: [],
				likes: [],
			},
			facebook: {
				basic: ['public_profile'],
				login: ['email'],
				photos: ['user_photos'],
				friends: ['user_friends'],
				folders: [],
				likes: [],
			},
			gplus: {
				basic: [],
				login: [],
				photos: [],
				friends: [],
				folders: [],
				likes: [],
			},
			instagram: {
				basic: ['user_profile'],
				login: [],
				photos: ['user_profile', 'user_media'],
				friends: [],
				folders: [],
				likes: [],
			},
			microsoft: {
				basic: [],
				login: [],
				photos: [],
				friends: [],
				folders: [],
				likes: [],
			},
		};

	private fields: {
		[K in Network]: {
			me: string;
		}
	} = {
			dropbox: {
				me: '',
			},
			facebook: {
				me: 'email,first_name,last_name,name,timezone,verified',
			},
			gplus: {
				me: '',
			},
			instagram: {
				me: 'id,username',
			},
			microsoft: {
				me: '',
			},
		};

	constructor() {
		this.networks = {
			dropbox: {
				accessToken: null,
				bridge: new DropboxClass(),
				initialized: false,
			},
			facebook: {
				accessToken: null,
				bridge: new FacebookClass(),
				initialized: false,
			},
			gplus: {
				accessToken: null,
				bridge: new GooglePlusClass(),
				initialized: false,
			},
			instagram: {
				accessToken: null,
				bridge: new InstagramClass(),
				initialized: false,
			},
			microsoft: {
				accessToken: null,
				bridge: new MicrosoftClass(),
				initialized: false,
			},
		};
	}

	private getScopes(
		network: Network,
		arrMapIds?: ConnectorScopeCategory[],
	): string[] {
		const mappedScopes: string[] = [];
		if (arrMapIds) {
			arrMapIds.forEach((mapId) => {
				this.scope[network][mapId].forEach((mappedScope) => {
					mappedScopes.push(mappedScope);
				});
			});
		}

		return mappedScopes;
	}

	public isSupported(source: ChannelModel['id']): boolean {
		if (source === 'qr') {
			if (ConfigModule['features.qrUpload']) {
				return true;
			}

			return false;
		}

		if (source === 'upload'
			|| source === 'app'
		) {
			return false;
		}

		if (source === 'facebook' || source === 'instagram') {
			if (!experiment.getFlagValue('flag_facebook_integration')) {
				return false;
			}
		}

		if (source === 'gplus') {
			if (!experiment.getFlagValue('flag_google_integration')) {
				return false;
			}
		}

		return Boolean(this.networks[source] && this.networks[source].bridge?.isSupported);
	}

	public setup(network: ChannelModel['id']): Promise<void> {
		if (network === 'upload'
			|| network === 'app'
			|| network === 'qr'
		) {
			throw new Error(`Channel ${network} should not be used in connector`);
		}

		// Setup SDK
		return this.networks[network].bridge.setup();
	}

	public init(source: ChannelModel['id']): Promise<void> {
		if (source === 'upload'
			|| source === 'app'
			|| source === 'qr'
		) {
			throw new Error(`Channel ${source} should not be used in connector`);
		}

		if (this.networks[source].initialized) {
			return Promise.resolve();
		}

		// Get network configuration from database model
		const channelModel = ChannelsModule.getById(source);
		if (channelModel && this.isSupported(source)) {
			// Initialize api
			this.networks[source].initialized = true;

			return this.networks[source].bridge.init(channelModel);
		}

		throw new Error(ERRORS_UNSUPPORTED_FEATURE);
	}

	public login(
		source: ChannelModel['id'],
		options: ConnectorLoginOptions,
		logErrors?: boolean,
	): Promise<void> {
		if (source === 'upload'
			|| source === 'app'
			|| source === 'qr'
		) {
			throw new Error(`Channel ${source} should not be used in connector`);
		}

		const network = source;

		options = _.extend(
			{
				display: 'popup',
				scopeCategories: ['login'],
			},
			options,
		);

		if (typeof logErrors === 'undefined') {
			logErrors = true;
		}

		if (
			options.display == 'page'
			&& !ProductStateModule.getSaved
		) {
			return ProductState.finalize().then(
				() => this.login(
					source,
					options,
					logErrors,
				),
			);
		}

		let redirectUri = `${window.location.protocol}//${window.location.host}`;
		redirectUri += options.display == 'page' ? `/app/oauth/${network}` : '/auth/hello.html';
		options.redirectUri = redirectUri;

		return this.init(source)
			.then(() => {
				// Block auto saving (as the userid can change in the meantime)
				AppStateModule.disableAutoSave();

				const scope = options.scopeCategories
					? this.getScopes(
						source,
						options.scopeCategories,
					)
					: [];

				return this.networks[network].bridge.login(
					scope,
					options,
				);
			})
			.then((objDetails: ConnectorBridgeLoginResponse) => {
				// open load dialog
				if (options.display !== 'none') {
					DialogService.openLoaderDialog();
				}

				// Refresh access token and permission set
				this.networks[network].accessToken = objDetails.accessToken || null;

				return this.status(network).then(() => objDetails);
			})
			.then((objDetails: ConnectorBridgeLoginResponse) => {
				const channelModel = ChannelsModule.getById(network);
				if (!channelModel) {
					throw new Error('Could not find channel model');
				}

				if (objDetails.userid && channelModel.profile && channelModel.profile.id != objDetails.userid) {
					ChannelsModule.setProfile({
						id: channelModel.id,
						data: {
							id: objDetails.userid,
						},
					});
				}

				// See if external user model is already known to this user
				const externalUserModel = channelModel.profile && channelModel.profile.id
					? ExternalUsersModule.findWhere({ source: network, externalId: String(channelModel.profile.id) })
					: null;
				const { accessToken } = this.networks[network];

				if (!externalUserModel || !UserModule.id) {
					return this.me(network)
						.then(() => {
							// Important: 'profile' property might be unkown before this point,
							// so do not declare shorthand variable before this promise resolve callback
							const profileModel = channelModel.profile;

							if (!profileModel || !profileModel.id) {
								throw new Error('Missing profile id');
							}

							const postData: {
								access_token: string | null; // eslint-disable-line camelcase
								countryid?: number | null;
								currency?: string | null;
								id: string;
								first_name: string | null; // eslint-disable-line camelcase
								last_name: string | null; // eslint-disable-line camelcase
								email: string | null;
							} = {
								access_token: accessToken,
								id: profileModel.id,
								first_name: profileModel && profileModel.first_name
									? profileModel.first_name
									: null,
								last_name: profileModel && profileModel.last_name
									? profileModel.last_name
									: null,
								email: profileModel && profileModel.email
									? profileModel.email
									: null,
							};

							if (UserModule.currency) {
								postData.currency = UserModule.currency;
							}
							if (UserModule.countryid) {
								postData.countryid = UserModule.countryid;
							}

							return ajax.request(
								{
									method: 'post',
									url: `/api/auth/${network}?affiliateid=${window.affiliateID}`,
									data: postData,
								},
								{
									auth: true,
									wait: true, // Wait for running ajax requests to complete, to avoid data integrity issues when userid changes
								},
							);
						})
						.then(() => User.setup(true))
						.then(() => User.validate());
				}

				if (
					externalUserModel
					&& accessToken
					&& externalUserModel.accesstoken != accessToken
				) {
					return ExternalUsersModule.putModel({
						id: externalUserModel.id,
						data: {
							accesstoken: accessToken,
						},
					});
				}

				return Promise.resolve();
			})
			.finally(() => {
				// close dialog
				DialogService.closeLoaderDialog();

				// Re-enable auto saving
				AppStateModule.enableAutoSave();
			})
			.catch((e: Error) => { // Login failed
				const errorMessage = e.message;

				if (logErrors && typeof window.glBugsnagClient !== 'undefined') {
					const err = new Error(ERRORS_OAUTH_FAILED);
					window.glBugsnagClient.notify(
						err,
						(event) => {
							event.severity = 'info';
							event.addMetadata(
								'component',
								{
									network: source,
									reason: errorMessage,
								},
							);
						},
					);
				}

				throw new Error(errorMessage);
			});
	}

	public status(
		network: ChannelModel['id'],
		scopeCategories: ConnectorScopeCategory[] = ['basic'],
	): Promise<void> {
		if (network === 'upload'
			|| network === 'app'
			|| network === 'qr'
		) {
			throw new Error(`Channel ${network} should not be used in connector`);
		}

		return this.init(network)
			.then(() => {
				if (this.networks[network].accessToken) {
					const requiredScopes = this.getScopes(
						network,
						scopeCategories,
					);
					const { grantedScopes } = this.networks[network].bridge;
					const missingScopes = requiredScopes.filter(
						(s) => grantedScopes.indexOf(s) < 0,
					);
					if (missingScopes.length > 0) {
						throw new Error(ERRORS_OAUTH_FAILED);
					}
				} else {
					throw new Error(ERRORS_OAUTH_FAILED);
				}
			});
	}

	public refresh(
		source: ChannelModel['id'],
		options?: ConnectorOptions,
	): Promise<void> {
		if (source == 'upload') {
			throw new Error('Channel upload should not be used in connector');
		}

		return this.login(
			source,
			{
				display: 'none',
				force: false,
				scopeCategories: options && options.scopeCategories ? options.scopeCategories : [],
			},
			false,
		);
	}

	public logout(
		source?: Network,
		options: ConnectorOptions = {},
	) {
		const defaults = {
			force: false,
		};
		options = _.extend(
			defaults,
			options,
		);

		if (typeof source !== 'undefined' && source) {
			this.logoutNetwork(
				source,
				options,
			);
		} else {
			const networks = Object.keys(this.networks) as Network[];
			networks.forEach((network) => {
				if (ChannelsModule.getById(network)) {
					this.logoutNetwork(
						network,
						options,
					);
				}
			});
		}
	}

	public logoutNetwork(
		source: ChannelModel['id'],
		options?: ConnectorOptions,
	) {
		if (source === 'upload'
			|| source === 'app'
			|| source === 'qr'
		) {
			throw new Error(`Channel ${source} should not be used in connector`);
		}

		this.networks[source].bridge.logout(options).then(() => {
			// Reset access token
			this.networks[source].accessToken = null;

			// Reset data in channel model
			ChannelsModule.resetModel(source);

			// Trigger logout event
			EventBus.emit(
				'auth.logout',
				source,
			);
		}).catch(() => {
			// Swallow error: no action required
		});
	}

	public me(source: ChannelModel['id']): Promise<ChannelProfileModel> {
		if (source === 'upload'
			|| source === 'app'
			|| source === 'qr'
		) {
			throw new Error(`Channel ${source} should not be used in connector`);
		}

		const network = source;
		const routeOptions: ConnectorBridgeMeOptions = {};
		if (this.fields[source].me.length) {
			routeOptions.fields = this.fields[source].me;
		}

		const scope = this.getScopes(
			source,
			['login'],
		);

		return this.status(network).then(
			() => this.networks[network].bridge.me(
				scope,
				routeOptions,
			),
		).then((data: ChannelProfileModel) => {
			ChannelsModule.setProfile({
				id: network,
				data,
			});

			return data;
		});
	}

	public photos(
		network: ChannelModel['id'],
		options: ConnectorOptions = {},
	): Promise<ConnectorPhotosResponseData> {
		if (network === 'upload'
			|| network === 'qr'
		) {
			throw new Error(`Channel ${network} should not be used in connector`);
		}

		if (network === 'app') {
			// @ts-ignore (to do: fix typescript error)
			return AppClass.photos(options);
		}

		const scope = this.getScopes(
			network,
			options.scopeCategories ? options.scopeCategories : ['photos'],
		);
		const bridgeOptions: BridgeOptions = merge(
			options,
			{
				scope,
			},
		);

		return this.networks[network].bridge.photos(bridgeOptions)
			.then((resp: ConnectorBridgeResponseData) => {
				const data: Partial<PI.PhotoModel>[] = [];
				resp.data.forEach((photoData: any) => {
					const objPhoto = this.networks[network].bridge.convertPhotoData(photoData);
					if (objPhoto) {
						data.push(objPhoto);
					}
				});

				return {
					data,
					paging: resp.paging,
				};
			});
	}

	public albums(
		network: ChannelModel['id'],
		options: ConnectorOptions = {},
	): Promise<AlbumsResponseData> {
		if (network === 'upload'
			|| network === 'app'
			|| network === 'qr'
		) {
			throw new Error(`Channel ${network} should not be used in connector`);
		}

		const scope = this.getScopes(
			network,
			options.scopeCategories ? options.scopeCategories : ['photos'],
		);
		const bridgeOptions: BridgeOptions = merge(
			options,
			{
				scope,
			},
		);

		return this.networks[network].bridge.albums(bridgeOptions)
			.then((resp: ConnectorBridgeResponseData) => {
				const { data, paging } = resp;
				const convertedData: CloudAlbumModel[] = [];
				data.forEach((albumData: any) => {
					const objAlbum: CloudAlbumModel = this.networks[network].bridge.convertAlbumData(albumData);
					if (objAlbum) {
						convertedData.push(objAlbum);
					}
				});

				const response: AlbumsResponseData = {
					data: convertedData,
					paging,
				};

				return response;
			});
	}

	public albumPhotos(
		network: ChannelModel['id'],
		albumid: string,
		options: ConnectorOptions = {},
	): Promise<ConnectorPhotosResponseData> {
		if (network === 'upload'
			|| network === 'app'
			|| network === 'qr'
		) {
			throw new Error(`Channel ${network} should not be used in connector`);
		}

		const scope = this.getScopes(
			network,
			options.scopeCategories ? options.scopeCategories : ['photos'],
		);
		const bridgeOptions: BridgeOptions = merge(
			options,
			{
				scope,
			},
		);

		return this.networks[network].bridge.albumPhotos(
			albumid,
			bridgeOptions,
		)
			.then((resp: ConnectorBridgeResponseData) => {
				const data: Partial<PI.PhotoModel>[] = [];
				resp.data.forEach((photoData: any) => {
					const objPhoto = this.networks[network].bridge.convertPhotoData(photoData);
					if (objPhoto) {
						data.push(objPhoto);
					}
				});

				const response: ConnectorPhotosResponseData = {
					data,
					paging: resp.paging,
				};

				return response;
			});
	}

	public folders(
		network: ChannelModel['id'],
		options: ConnectorOptions = {},
	): Promise<FolderResponseData> {
		if (network === 'upload'
			|| network === 'app'
			|| network === 'qr'
		) {
			throw new Error(`Channel ${network} should not be used in connector`);
		}

		const scope = this.getScopes(
			network,
			options.scopeCategories ? options.scopeCategories : ['folders'],
		);
		const bridgeOptions: BridgeOptions = merge(
			options,
			{
				scope,
			},
		);

		return this.networks[network].bridge.folders(
			options.folderid || null,
			bridgeOptions,
		).then((resp: ConnectorBridgeResponseData) => {
			const photoCollection: Partial<PI.PhotoModel>[] = [];
			const folderCollection: ConnectFolderData[] = [];
			resp.data.forEach((fileData: any) => {
				const fileType = this.networks[network].bridge.getFileType(fileData);

				if (fileType == 'folder') {
					const objFolder = this.networks[network].bridge.convertFolderData(fileData);
					if (objFolder) {
						folderCollection.push(objFolder);
					}
				} else if (fileType == 'photo' || fileType == 'image/jpeg' || fileType == 'image/jpg' || fileType == 'image/png') {
					const objPhoto = this.networks[network].bridge.convertPhotoData(fileData);
					if (objPhoto) {
						photoCollection.push(objPhoto);
					}
				}
			});

			const response: FolderResponseData = {
				paging: resp.paging,
				photoCollection,
				folderCollection,
			};

			return response;
		});
	}

	public share(
		network: Network,
		options?: ConnectorShareOptions,
	): Promise<void> {
		return this.init(network).then(
			() => this.networks[network].bridge.share(options),
		);
	}

	public getContentFilters(network: ChannelModel['id']): string[] {
		if (network === 'upload'
			|| network === 'app'
			|| network === 'qr'
		) {
			throw new Error(`Channel ${network} should not be used in connector`);
		}

		return this.networks[network].bridge.contentFilters;
	}
}

export default new Connector();
