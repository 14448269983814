<template>
	<div class="loginView">
		<div class="login__dialog">
			<div class="first line">
				<div class="title pull__up">
					<h2 class="description__text">
						{{ $t('views.login.haveAccount') }}
					</h2>
				</div>
				<div class="dialogTopLinks">
					<div>
						<span
							class="text subHeader"
							v-html="$t('views.login.description_title', { label: labelName })"
						/>
					</div>
				</div>

				<div
					v-if="showForm"
					class="loginForm"
				>
					<div class="input-group">
						<label for="username">
							{{ $t('userProfileFields.email') }}
						</label>
						<input
							v-model.trim="inputData.username"
							type="text"
							name="username"
							size="15"
							:placeholder="$t('views.authDialog.emailPlaceholder')"
							data-private="lipsum"
							:class="[
								'privateData',
								{ invalid: invalidUserName }
							]"
							required
						>
					</div>
					<div class="input-group">
						<label>
							{{ $t('password') }}
						</label>
						<input
							v-model.trim="inputData.password"
							:type="showPassword ? 'text' : 'password'"
							name="password"
							:placeholder="$t('password')"
							size="15"
							:class="{ invalid: invalidPassword }"
							@keyup="checkKey"
						>
						<button
							type="button"
							:class="[
								'togglePassword',
								{ show: showPassword }
							]"
							@click="togglePasswordVisiblity"
						>
							{{ showPassword ? $t('passwordHide') : $t('passwordShow') }}
						</button>
					</div>
					<div class="loginFormFooter">
						<button
							class="actionbutton"
							@click="checkLogin"
						>
							{{ $t('login') }}
						</button>
						<span
							class="forgotPasswordLink"
							@click="forgotPassword"
						>
							{{ $t('forgotPassword') }}
						</span>
					</div>
				</div>
			</div>
			<div class="second">
				<div>
					<div class="title pull__up">
						<h2 class="new__to__text">
							<span v-html="$t('views.authDialog.newTo', { label: labelName })" />
						</h2>
					</div>
					<div class="pull__up">
						<span class="text subHeader">{{ $t('views.login.noAccountYet', { label: labelName }) }} </span>
					</div>
					<div class="check__text__wrapper">
						<div class="check__text">
							<span class="icon">
								<i class="material-icons check">check</i>
							</span>
							<span class="text">
								{{ $t('views.authDialog.benefit_1') }}
							</span>
						</div>
						<div class="check__text">
							<span class="icon">
								<i class="material-icons check">check</i>
							</span>
							<span class="text">
								{{ $t('views.authDialog.benefit_2') }}

							</span>
						</div>
						<div class="check__text">
							<span class="icon">
								<i class="material-icons check">check</i>
							</span>
							<span class="text">
								{{ $t('views.authDialog.benefit_3') }}
							</span>
						</div>
					</div>
				</div>

				<div>
					<div class="buttonContainer">
						<button
							class="actionbutton"
							@click="signup"
						>
							{{ $t('dialogButtonRegister') }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="less" scoped>
@import '~lessVars';
@import '~root/css/components/button.less';
@import '~root/css/components/login.less';

.loginFormFooter {
	padding-top: 10px;
	font-family: @fontFamilyDemiBold;
	font-weight: @fontWeightDemiBold;

	.forgotPasswordLink {
		margin-left: 15px;
		cursor: pointer;
	}
}
</style>
