import { AxiosRequestConfig } from 'axios';
import ajax from 'controllers/ajax';
import merge from 'deepmerge';
import { AjaxOptions } from 'interfaces/app';
import * as DB from 'interfaces/database';
import { ERRORS_INVALID_REQUEST_DATA } from 'settings/errors';
import _ from 'underscore';
import { reactive } from 'vue';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'shipping' })
export default class Shipping extends VuexModule {
	private collection = reactive<DB.ShippingModel[]>([]);

	public fetched = false;

	private modelUrl = '/api/shipping';

	private offset = 0;

	private totalRecords: number | null = null;

	public get collectionUrl(): string {
		return `/api/shoppingcart/${this.context.rootState.cart.id}/shipping`;
	}

	public get getById() {
		return (id: number) => _.findWhere(
			this.collection,
			{ id },
		);
	}

	public get where() {
		return (properties: Partial<DB.ShippingModel>) => _.where(
			this.collection,
			properties,
		);
	}

	@Mutation
	private _addModel(data: DB.ShippingModel): void {
		this.collection.push(data);
	}

	@Mutation
	private _addOffset(number: number): void {
		this.offset += number;
	}

	@Mutation
	private _resetCollection(data: DB.ShippingModel[]): void {
		this.collection = data || [];
	}

	@Mutation
	public resetMetaData(): void {
		this.fetched = false;
		this.offset = 0;
		this.totalRecords = null;
	}

	@Mutation
	private _setFetched(flag: boolean): void {
		this.fetched = flag;
	}

	@Mutation
	private _setTotalRecords(number: number): void {
		this.totalRecords = number;
	}

	@Mutation
	public updateModel(data: DB.ShippingModel): void {
		const i = _.findIndex(
			this.collection,
			{ id: data.id },
		);
		const model = this.collection[i];
		this.collection[i] = {
			...model,
			...data,
		};
	}

	@Action({ rawError: true })
	public addModel(data: DB.ShippingModel): Promise<DB.ShippingModel | undefined> {
		if (!data.id) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		if (this.getById(data.id)) {
			this.updateModel(data);
		} else {
			this._addModel(data);
		}

		return Promise.resolve(
			this.getById(data.id),
		);
	}

	@Action({ rawError: true })
	public fetchCartShippingData({
		requestOptions,
		methodOptions,
	}: {
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<DB.ShippingModel[]> {
		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'get',
			url: this.collectionUrl,
			params: {
				offset: this.offset,
				limit: 20,
				orderby: 'id DESC',
			},
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			debug: {
				offline: true,
				dialog: true,
				abort: true,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		const { limit } = requestOptions.params;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				const data = response.data as DB.ShippingModel[];
				data.forEach(
					(model) => {
						this.addModel(model);
					},
				);

				this._addOffset(limit);
				this._setFetched(true);

				// Set total records and limit to collection properties
				if (response.headers.hasOwnProperty('x-total-records')) {
					this._setTotalRecords(
						Math.max(
							0,
							parseInt(
								response.headers['x-total-records'],
								10,
							),
						),
					);
				}

				const newIds = data.map(
					(model) => model.id,
				);
				return this.collection.filter(
					(model) => newIds.includes(model.id),
				);
			});
	}

	@Action({ rawError: true })
	public fetchOfferingShippingData({
		offeringId,
		requestOptions,
		methodOptions,
	}: {
		offeringId: DB.OfferingModel['id'];
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<DB.ShippingModel[]> {
		if (!offeringId) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'get',
			url: `/api/offering/${offeringId}/shipping`,
			params: {
				limit: 0,
			},
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: false,
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => _.toArray(response.data));
	}

	@Action({ rawError: true })
	public fetchModel({
		id,
		requestOptions,
		methodOptions,
	}: {
		id: DB.ShippingModel['id'];
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	}): Promise<DB.ShippingModel | undefined> {
		if (!id) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'get',
			url: `${this.modelUrl}/${id}`,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			debug: {
				offline: true,
				dialog: true,
				abort: true,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				this.addModel(
					response.data,
				);

				return this.getById(response.data.id);
			});
	}
}
