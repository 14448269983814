<template>
	<div
		id="dialogs"
		@mousedown.stop
		@touchstart.stop
	>
		<DefaultDialogView
			v-if="main.show"
			:dialog-model="main"
			@close-dialog="closeDialog"
		/>
		<LoaderDialogView
			v-if="loader.show"
			:dialog-model="loader"
		/>
		<AuthDialogView
			v-if="auth.show"
			:dialog-model="auth"
			@close-dialog="closeDialog"
		/>
		<DefaultDialogView
			v-if="error.show"
			:dialog-model="error"
			@close-dialog="closeDialog"
		/>
	</div>
</template>

<style lang="less" scoped>
</style>
