<template>
	<div class="editbox relative">
		<div class="content">
			<Form
				ref="observer"
				tag="div"
				class="table responsive"
			>
				<Field
					v-slot="{ errorMessage, classes, field }"
					v-model.trim="addressName"
					:name="$t('name')"
					class="tr"
					mode="eager"
					rules="required"
					tag="div"
				>
					<div class="td td3">
						{{ $t('name') }}
					</div>
					<div class="td td3x2">
						<input
							v-bind="field"
							ref="nameInput"
							:class="classes"
							:placeholder="$t('name')"
							autocomplete="off"
							data-private="lipsum"
							maxlength="40"
							name="name"
							type="text"
						>
						<span class="inputError">
							{{ errorMessage }}
						</span>
					</div>
				</Field>

				<div class="tr">
					<div class="td td3">
						{{ $t('address') }}
					</div>
					<div class="td td3x2">
						<Field
							v-slot="{ classes }"
							v-model.trim="addressStreet1"
							mode="eager"
							rules="required"
							:name="$t('address')"
						>
							<input
								type="text"
								maxlength="40"
								name="address1"
								:placeholder="$t('address')"
								autocomplete="off"
								data-private="lipsum"
								:class="classes"
							>
						</Field>
						<br>
						<Field
							v-slot="{ errorMessage, field }"
							v-model.trim="addressStreet2"
							mode="eager"
							:name="$t('address2')"
						>
							<input
								v-bind="field"
								type="text"
								maxlength="40"
								name="address2"
								autocomplete="off"
								data-private="lipsum"
							>
							<span class="inputError">
								{{ errorMessage }}
							</span>
						</Field>
					</div>
				</div>

				<Field
					v-slot="{ errorMessage, classes, field }"
					v-model.trim="addressZipCode"
					:name="$t('zipcode')"
					class="tr"
					mode="eager"
					rules="zipcode:countryIso|required"
					tag="div"
				>
					<div class="td td3">
						{{ $t('zipcode') }}
					</div>
					<div class="td td3x2">
						<input
							v-bind="field"
							:class="classes"
							:placeholder="$t('zipcode')"
							autocomplete="off"
							data-private="lipsum"
							maxlength="25"
							name="zipcode"
							type="text"
						>
						<span class="inputError">
							{{ errorMessage }}
						</span>
					</div>
				</Field>

				<Field
					v-slot="{ errorMessage, classes, field }"
					v-model.trim="addressCity"
					:name="$t('city')"
					class="tr"
					rules="required"
					tag="div"
				>
					<div class="td td3">
						{{ $t('city') }}
					</div>
					<div class="td td3x2">
						<input
							v-bind="field"
							:class="classes"
							:placeholder="$t('city')"
							autocomplete="off"
							data-private="lipsum"
							maxlength="25"
							name="city"
							type="text"
						>
						<span class="inputError">
							{{ errorMessage }}
						</span>
					</div>
				</Field>

				<Field
					v-if="countryStateprovs.length"
					v-slot="{ errorMessage, classes, field }"
					v-model.trim="addressState"
					class="tr"
					name="state"
					rules="required"
					tag="div"
				>
					<div class="td td3">
						{{ $t('state') }}&nbsp;<span class="asteriks">
							<i class="far fa-asterisk" />
						</span>
					</div>
					<div class="td td3x2">
						<select
							v-bind="field"
							ref="countrySelect"
							:class="[classes, 'selectBox']"
							:title="$t('state')"
							name="state"
						>
							<option
								v-for="stateprovmodel in countryStateprovs"
								:key="stateprovmodel.iso"
								:value="stateprovmodel.iso"
							>
								{{ stateprovmodel.name }}
							</option>
						</select>
						<span class="inputError">
							{{ errorMessage }}
						</span>
					</div>
				</Field>

				<Field
					v-slot="{ classes, field }"
					v-model.trim="addressCountry"
					class="tr"
					name="country"
					rules="required"
					tag="div"
					vid="countryIso"
				>
					<div class="td td3">
						{{ $t('country') }}
					</div>
					<div class="td td3x2">
						<select
							v-bind="field"
							:class="[classes, 'selectBox']"
							name="country"
						>
							<option
								v-for="objCountry in countries"
								:key="objCountry.iso"
								:value="objCountry.iso"
							>
								{{ objCountry.name }}
							</option>
						</select>
					</div>
				</Field>
			</Form>
		</div>
		<button
			class="actionbutton full"
			@click="save"
		>
			{{ $t('buttonSave') }}
		</button>
	</div>
</template>

<style lang="less" scoped>
@import '~lessVars';
@import '~root/css/components/button.less';
@import '~root/css/components/input.less';

.actionbutton {
    margin-top: 15px;
}
.editbox,
.content {
    float: left;
    width: 100%;
}
.editbox .td:first-child {
	@media @mobile {
		display: none;
	}
}
.editbox .td:nth-child(2) {
    @media @desktop {
        padding-left: 5px;
    }
	@media @mobile {
		width: 100%;
	}
}
input {
	box-sizing: border-box;
    text-align: left;
	padding: 0 14px;
	height: 40px;
    border: 1px solid #ccc;
    width: 100%;
    margin: 2px 0;
	border-radius: 0;
	font-family: @fontFamilyRegular;
	font-weight: @fontFamilyRegular;
	font-size: @fontSize_XS1;
}
.selectBox {
	width: 100%;
	margin: 2px 0;
	text-transform: capitalize;
}
.inputError {
	display: inline-block;
	box-sizing: border-box;
    width: 100%;
    text-align: left;
    font-size: 0.8em;
	color: red;

    @media @desktop {
        padding: 0 5px;
    }
}
</style>
