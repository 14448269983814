<template>
	<div :class="{ loadIconBox: !instant && !isReady }">
		<canvas
			v-show="instant || isReady"
			:ref="useDownSampling ? 'scaledCanvas' : 'canvas'"
			class="mainCanvas"
			:width="canvasWidth"
			:height="canvasHeight"
		/>
		<canvas
			v-if="useDownSampling"
			v-show="false"
			ref="canvas"
			:width="maxCanvasWidth"
			:height="maxCanvasHeight"
		/>
		<DrawView
			v-if="instant || isReady"
			:address-model="addressModel"
			:bleed-margin="bleedMargin"
			:color="inverseColor"
			:draw-mask="showMask"
			:draw-objects="objectModels"
			:draw-overlay="showOverlay"
			:draw-page="true"
			:mirror-overlay="flippedOverlay"
			:page-model="pageModel"
			:scaling="useDownSampling ? maxScale : scaling"
			:show-loaders="showLoaders"
		/>
		<svg
			v-if="!instant && !isReady"
			viewBox="0 0 32 32"
			width="64"
			height="64"
		>
			<circle
				class="loadIcon"
				cx="16"
				cy="16"
				r="14"
				fill="none"
			/>
		</svg>
	</div>
</template>

<script src='./page-flat.ts'></script>

<style lang="less" scoped>
@import '~lessVars';
@import '../../css/components/animation.less';

.loadIconBox {
    position: relative !important;
    top: 50%;
    transform: translateY(-50%);
}

.loadIcon {
    .animation-spinner;

    box-sizing: border-box;
    stroke: #ccc;
    stroke-width: 3px;
    transform-origin: 50%;
}
</style>
