import './defines';
import { toNative } from 'utils/vue-facing-decorator';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'DialogProgress',
	emits: ['complete'],
	mixins: [Template],
})
class DialogProgress extends Vue {
	@Prop({
		required: true,
		type: String,
	})
	public readonly label!: string;

	@Prop({
		required: true,
		type: Number,
	})
	public readonly total!: number;

	@Prop({
		required: true,
		type: Number,
	})
	public readonly value!: number;

	protected get valuePercentage(): number {
		let { total } = this;

		if (this.value >= total) {
			// Completed cannot be higher than total
			total = this.value;
		}

		return Math.floor((1 - (total - this.value) / total) * 100);
	}

	@Watch('value')
	protected onValueChange() {
		if (this.value >= this.total) {
			this.$emit('complete');
		}
	}
}

export default toNative(DialogProgress);
