import { Plugin } from 'vue';

const VueVisible: Plugin = {
	install(app) {
		app.directive(
			'visible',
			(el, binding) => {
				const { value } = binding;

				if (value) {
					el.style.visibility = 'visible';
				} else {
					el.style.visibility = 'hidden';
				}
			},
		);
	},
};

export default VueVisible;
