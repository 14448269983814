<template>
	<div
		class="modal"
		@click="clickModal"
	>
		<div
			:id="dialogModel.cid"
			ref="dialog"
			class="dialog chrome"
			:style="styleObject"
			@click="stopDelegation"
		>
			<div
				ref="dialogHeader"
				class="header"
			>
				<div
					:class="[
						{ closer: dialogModel.hasclose }
					]"
				>
					<i
						v-if="dialogModel.hasclose"
						class="material-icons"
						@click="checkClose"
					>
						close
					</i>
				</div>
				<div class="title">
					<span v-html="$t('views.authDialog.welcomeUser', { label: labelName })" />
				</div>
			</div>
			<div
				ref="dialogBody"
				class="body"
				:style="{ 'max-height': maxBodyHeight + 'px', ...bodyStyle }"
			>
				<SignupView
					v-if="dialogModel.showSignup"
					v-bind="dialogModel.options"
					v-on="{ ...componentListeners }"
				/>
				<LoginView
					v-else-if="dialogModel.showLogin"
					v-bind="dialogModel.options"
					v-on="{ ...componentListeners }"
				/>
				<LoginView
					v-else
					v-bind="dialogModel.options"
					v-on="{ ...componentListeners }"
				/>
			</div>
		</div>
	</div>
</template>

<script src='./dialog-auth.ts'></script>

<style lang="less" scoped>
@import '~lessVars';
@import '../../css/components/dialog.less';

.dialog {
	&.chrome {
		width: 75%;
		max-width: 800px;
		margin: auto;

		@media @desktop {
			padding-left: 40px;
			padding-right: 40px;
		}
		@media @mobile {
			width: 90%;
		}
	}

	.title {
		@media @desktop {
			& when(@brand="Hema") {
				font-size: @fontSize_L4;
			}
		}
	}
}
</style>
