import getPixelUrl from 'tools/get-pixel-url';
import { toNative } from 'utils/vue-facing-decorator';
import CountryFlag from 'vue-country-flag-next';
import {
	Component,
	Prop,
	Vue,
} from 'vue-facing-decorator';

@Component({
	components: {
		CountryFlag,
	},
	emits: ['select'],
})
class CountrySelectorItemView extends Vue {
	@Prop({ required: true, type: String })
	readonly iso!: string;

	@Prop({ required: true, type: String })
	readonly name!: string;

	@Prop({ default: false, type: Boolean })
	readonly suggested!: boolean;

	get pixel() {
		return getPixelUrl();
	}

	get itemClassName() {
		let className = 'listItem';
		if (this.suggested) {
			className += ' suggested';
		}
		return className;
	}

	get flagClassName() {
		return `flag flag-${this.iso}`;
	}

	onClick() {
		this.$emit(
			'select',
			this.$.vnode.key,
		);
	}
}

export default toNative(CountrySelectorItemView);
