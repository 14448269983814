import { AxiosRequestConfig } from 'axios';
import Page from 'classes/page';
import Product from 'classes/product';
import Template, { PhotoData } from 'classes/template';
import PriceCalculator from 'classes/price-calculator';
import TemplatePosition from 'classes/templateposition';
import Theme from 'classes/theme';
import User from 'classes/user';
import EventBus from 'components/event-bus';
import analytics from 'controllers/analytics';
import experiment from 'controllers/experiment';
import navigate from 'controllers/navigate';
import storage from 'controllers/storage';
import template from 'controllers/template';
import upload from 'controllers/upload';
import {
	DialogClosePayloadVirtalOfferingSelect,
	EditorBookFillImagePageOrientation,
	EditorBookFillMargins,
	EditorBookFillPage,
	EditorBookFillPages,
	EditorBookFillPagesMarginsModel,
	TemplatePhotoPosition,
	TemplateTextPosition,
} from 'interfaces/app';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import moment from 'moment';
import deleteProduct from 'mutations/product/delete';
import checkConnection from 'services/check-connection';
import * as DialogService from 'services/dialog';
import {
	ERRORS_LOAD_FONT,
	ERRORS_OFFLINE,
} from 'settings/errors';
import {
	OfferingConversions,
	OfferingGroups,
	OfferingLaunchers,
} from 'settings/offerings';
import {
	AppDataModule,
	AppStateModule,
	CartItemsModule,
	ConfigModule,
	FontModule,
	PhotosModule,
	ProductsModule,
	ProductStateModule,
	ThemeDataModule,
	ThemeStateModule,
	UploadModule,
	UserModule,
} from 'store';
import { mobile as mobileTools } from 'tools';
import breakText from 'tools/break-text';
import getRandomString from 'tools/get-random-string';
import _ from 'underscore';
import CloneView from 'views/clone';
import EditorBookFillView from 'views/editor-book-fill';
import LayoutPickerView from 'views/layout-picker';
import ProductAttributesView from 'views/product-attributes';
import SmartEnhancementView from 'views/smart-enhancement';
import UploadErrorsView from 'views/upload-errors';
import VirtualOfferingSelectView from 'views/virtual-offering-select';
import SavingProjectView from 'views/saving-project';

export default class ProductState {
	private static savePromise: Promise<void> | null = null;

	static autoFill = (
		pageCount?: number,
	) => {
		// Temporarily disable auto saving
		AppStateModule.disableAutoSave();

		// fill template with photos not yet included
		return template
			.autoFill(
				pageCount,
			)
			.catch((e) => {
				if (typeof window.glBugsnagClient !== 'undefined') {
					const err = new Error(`error in auto filling: ${e}`);
					window.glBugsnagClient.notify(
						err,
						(event) => { event.severity = 'error'; },
					);
				}
			})
			.finally(() => {
				// Enable auto saving again
				AppStateModule.enableAutoSave();

				// Save result
				ProductState.save();

				// Move forward in process
				navigate.goForward('fill');
			});
	};

	static projectContentInsights = () => {
		const groupId = ProductStateModule.getProduct?.group;
		if (!groupId) {
			throw new Error('Missing group id of project');
		}

		const isBook = OfferingGroups(
			groupId,
			['BookTypes'],
		);

		// Get all editable pages in the project
		const editablePages = ProductStateModule.getEditablePages;

		// Apply filter for books to only include inner pages
		const filteredEditablePages = isBook
			? _.filter(
				editablePages,
				(pageModel) => ProductStateModule.getPageIndex(pageModel) > 1,
			)
			: editablePages;

		const emptyPages = _.filter(
			filteredEditablePages,
			(pageModel) => {
				const pageObjects = ProductStateModule.getPageObjects(pageModel);
				return pageObjects.length == 0;
			},
		);

		return {
			totalEditablePages: filteredEditablePages.length,
			totalEmptyPages: emptyPages.length,
		};
	};

	static create(
		data: Partial<DB.ProductModel>,
		projectSettings?: Partial<PI.ProductSettings>,
		customAttributes?: PI.ProductDataModel['customAttributes'],
	): Promise<DB.ProductModel> {
		let affiliateid = 0;
		if (window.affiliateID >= 0) {
			affiliateid = window.affiliateID;
		} else if (UserModule.affiliateid) {
			({ affiliateid } = UserModule);
		}

		data = _.extend(
			{
				core: 2,
				userid: UserModule.id,
				read_token: getRandomString(20),
				affiliateid,
			},
			data,
		);

		if (typeof data.layoutid === 'undefined' && data.themeid) {
			const themeModel = ThemeDataModule.getTheme(data.themeid);
			if (themeModel && themeModel.layoutid) {
				data.layoutid = themeModel.layoutid;
			}
		}

		const searchProps: Partial<DB.OfferingModel> = {
			groupid: data.group,
			typeid: data.typeid,
		};
		if (data.variantid) {
			searchProps.variantid = data.variantid;
		}
		const offeringModel = AppDataModule.findOfferingWhere(searchProps);

		if (!offeringModel) {
			return Promise.reject(new Error('Could not find offering model'));
		}
		if (!offeringModel.instock) {
			return Promise.reject(new Error(window.App.router.$i18next.t('offeringNotInStock')));
		}

		// Reset the productstate data and cleanup listeners
		return ProductState.reset()
			.then(() => ProductsModule.createModel({
				data,
			}))
			.then((productModel) => {
				analytics.trackCreateProject(productModel);

				return ProductState.select(productModel.id);
			})
			.then((productModel) => {
				ProductStateModule.resetHistory();

				if (projectSettings) {
					ProductStateModule.changeProductSettings(
						projectSettings,
					);
				}

				if (customAttributes) {
					ProductStateModule.setCustomAttributes(
						customAttributes,
					);
				}

				return productModel;
			});
	}

	static askEnhancementConfirmation(): Promise<void> {
		// If the offering supports neither enhancement and upscaling,
		// there's no need to ask the user for this setting
		const offeringModel = ProductStateModule.getOffering;

		if (
			offeringModel
			&& (
				offeringModel.applyEnhancement
				|| offeringModel.applyUpscaling
			)
		) {
			return new Promise((resolve) => {
				const settings = ProductStateModule.getProductSettings;
				let smartEnhancement = true;

				if (typeof settings.applyEnhancement !== 'undefined') {
					smartEnhancement = settings.applyEnhancement;
				}

				const { close: closeDialog } = DialogService.openDialogNew({
					header: {
						hasCloseButton: false,
						title: window.App.router.$t('views.smartEnhancement.title'),
						styles: {
							fontSize: (
								mobileTools.isMobile
									? 'var(--font-size-m)'
									: 'var(--font-size-l)'
							),
						},
					},
					body: {
						component: SmartEnhancementView,
						props: {
							showSwitchBox: true,
							value: smartEnhancement,
						},
						listeners: {
							'update:value': (value: boolean) => {
								smartEnhancement = value;
							},
						},
					},
					footer: {
						buttons: [
							{
								id: 'save',
								text: window.App.router.$t('views.smartEnhancement.saveButton'),
								click: () => {
									const enhancedData: Partial<PI.ProductSettings> = {
										applyEnhancement: smartEnhancement,
									};
									ProductStateModule.changeProductSettings(enhancedData);

									analytics.trackEvent(
										'Save enhancement setting',
										{
											enabled: smartEnhancement,
										},
									);

									closeDialog();
								},
							},
						],
					},
					listeners: {
						close: () => {
							resolve();
						},
					},
					padding: (
						mobileTools.isMobile
							? [16, 20]
							: [24, 32]
					),
					styles: {
						rowGap: '24px',
					},
					theme: 'light',
					width: (
						mobileTools.isMobile
							? 350
							: 460
					),
				});
			});
		}

		return Promise.resolve();
	}

	static autoConvertProjectOffering(
		productid: number,
	): Promise<void> {
		// This feature is currently only available using an experiment flag
		if (!experiment.getFlagValue('flag_offering_conversion')) {
			return Promise.resolve();
		}

		// Make sure the currently opened project is the same as the one we are trying to convert
		const productModel = ProductStateModule.getProduct;
		if (productModel?.id != productid) {
			return Promise.reject(
				new Error('ProjectId did not match with current active project'),
			);
		}

		// We need the offering model to know from which offering we are converting
		const offeringModel = ProductStateModule.getOffering;
		if (!offeringModel) {
			return Promise.reject(
				new Error('Could not find offering model'),
			);
		}

		// Check the offering mapping
		const newOfferingId = OfferingConversions(
			offeringModel.id,
		);

		// If no mapping is found, we can exit without the need to convert
		if (!newOfferingId) {
			return Promise.resolve();
		}

		// Show dialog to user to notify about the conversion we will perform
		return new Promise((resolve, reject) => {
			const closeAlert = DialogService.openAlertDialog({
				header: {
					title: window.App.router.$t('dialogs.autoConvertProjectOffering.title'),
				},
				body: {
					content: window.App.router.$t('dialogs.autoConvertProjectOffering.message'),
				},
				footer: {
					buttons: [
						{
							id: 'accept',
							text: window.App.router.$t('dialogs.autoConvertProjectOffering.buttons.accept'),
							click: () => {
								// Close the alert dialog
								closeAlert();

								// Change the offering to the mapped one
								if (newOfferingId) {
									ProductState.changeOffering(
										newOfferingId,
									).then(
										resolve,
										reject,
									);
								} else {
									reject(new Error('Could not find new offering id'));
								}
							},
						},
					],
				},
			});
		});
	}

	static setup(
		productid: number,
		options?: {
			ajaxOptions?: AxiosRequestConfig;
			customAttributes?: PI.ProductDataModel['customAttributes'];
			extendProjectLifetime?: boolean;
		},
	): Promise<void> {
		// check if product model is already loaded
		if (ProductStateModule.productId == productid) {
			// Productstate already setup for this product, continue
			return Promise.resolve();
		}

		if (!AppStateModule.online) {
			return Promise.reject(
				new Error(ERRORS_OFFLINE),
			);
		}

		// Online and saved product: get data from server
		const closeLoader = DialogService.openLoaderDialog();

		// Reset product state data
		return ProductState.reset()
			// Clear local user data to avoid memory overload
			.then(() => User.clearMemory())
			.then(() => ProductStateModule.fetch({
				productId: productid,
				requestOptions: options && options.ajaxOptions ? options.ajaxOptions : undefined,
				parse: true,
			}))
			.then(() => ProductState.autoConvertProjectOffering(
				productid,
			))
			.then(() => {
				if (options?.customAttributes) {
					ProductStateModule.mergeCustomAttributes(
						options.customAttributes,
					);
				} else if (options?.extendProjectLifetime) {
					// Force save the snapshot with an updated 'lastupdate' property value
					// This will extend the lifetime of the project before automatically
					// cleaning the data from the storage
					return ProductStateModule.save();
				}

				return Promise.resolve();
			})
			.then(() => {
				closeLoader();

				return undefined;
			})
			.catch((err) => {
				closeLoader();

				if (err.message == ERRORS_LOAD_FONT) {
					return new Promise((resolve, reject) => {
						const closeError = DialogService.openErrorDialog({
							body: {
								content: window.App.router.$t('dialogTextLoadError'),
							},
							footer: {
								buttons: [
									{
										id: 'accept',
										text: window.App.router.$t('dialogButtonOk'),
										click: () => {
											ProductState
												.setup(
													productid,
													options,
												).then(
													resolve,
													reject,
												);
											closeError();
										},
									},
								],
							},
						});
					});
				}

				throw err;
			});
	}

	static async select(productid: number): Promise<DB.ProductModel> {
		if (ProductStateModule.productId != productid) {
			// We are switching products, execute reset for memory management
			return ProductState.reset()
				.then(() => ProductStateModule.fetch({
					productId: productid,
					parse: true,
				}))
				.then(() => ProductState.select(productid))
				.catch((err) => {
					if (err.message === ERRORS_LOAD_FONT) {
						return new Promise((resolve, reject) => {
							const closeError = DialogService.openErrorDialog({
								body: {
									content: window.App.router.$t('dialogTextLoadError'),
								},
								footer: {
									buttons: [
										{
											id: 'accept',
											text: window.App.router.$t('dialogButtonOk'),
											click: () => {
												ProductState
													.select(productid)
													.then(
														resolve,
														reject,
													);
												closeError();
											},
										},
									],
								},
							});
						});
					}

					return Promise.reject(err);
				});
		}

		const productModel = ProductStateModule.getProduct;
		const offeringModel = ProductStateModule.getOffering;

		if (!productModel) {
			return Promise.reject(
				new Error('Could not find product model'),
			);
		}
		if (window.glPlatform == 'server') {
			// On the server platform there is no need to setup the theme
			return Promise.resolve(productModel);
		}

		if (!offeringModel) {
			return Promise.reject(
				new Error('Could not find offering model'),
			);
		}

		// Set showing of bleed margin to correct setting
		if (offeringModel && offeringModel.showbleed > 1) {
			AppStateModule.enableBleed();
		} else {
			AppStateModule.hideBleed();
		}

		// If themestate is undefined, fetch theme data from server
		return Theme
			.setup(productModel.themeid)
			.catch((err) => {
				// Theme fetch failed
				// Check if we are still online, or that offline status has been detected during the fetch
				if (!AppStateModule.online) {
					// No longer online, so reject the fetch
					return Promise.reject(
						new Error(ERRORS_OFFLINE),
					);
				}

				if (typeof window.glBugsnagClient !== 'undefined') {
					window.glBugsnagClient.notify(
						new Error(`Could not fetch theme data for theme ${productModel.themeid}`),
						(event) => { event.severity = 'error'; },
					);
				}

				if (err.response?.status === 410) {
					// Theme no longer exists, try to find an automatic replacement
					if (offeringModel?.themeid) {
						return ProductState.changeTheme(
							offeringModel.themeid,
							false,
							true,
						);
					}

					const themeModels = ThemeDataModule.getThemesByOfferingId(
						offeringModel.id,
					);
					if (themeModels.length) {
						const themeModel = themeModels[0];
						return ProductState.changeTheme(
							themeModel.id,
							false,
							true,
						);
					}
				}

				// Still online, so there must be something wrong with the data fetch
				// Offer error dialog to let user try again
				return new Promise((resolve, reject) => {
					let dialogErrorText = window.App.router.$t('dialogTextError');
					if (err.message) {
						dialogErrorText += `\n\nError: ${err.message}`;
					}

					const closeError = DialogService.openErrorDialog({
						body: {
							content: dialogErrorText,
						},
						footer: {
							buttons: [
								{
									id: 'abort',
									text: window.App.router.$t('dialogButtonErrorAbort'),
									click: () => {
										closeError();
										reject(err);
									},
								},
								{
									id: 'accept',
									text: window.App.router.$t('dialogButtonErrorRetry'),
									click: () => {
										closeError();

										ProductState.select(
											productid,
										).then(
											resolve,
											reject,
										);
									},
								},
							],
						},
					});
				});
			})
			.then(() => {
				const extraPageQuantity = offeringModel.setuppages - (ProductStateModule.getPages.length || 0);

				if (extraPageQuantity <= 0) {
					try {
						// Automatically update project content from its offering options
						this.updateContentFromOfferingOptions();
					} catch (error) {
						// Do nothing
					}

					return productModel;
				}

				let pagePromise = Promise.resolve();
				for (let y = 0; y < extraPageQuantity; y += 1) {
					pagePromise = pagePromise.then(
						() => ProductState.addPage(false).then(() => undefined),
					);
				}

				return pagePromise
					.then(() => ProductState.select(productid))
					.catch((err) => ProductState.reset()
						.then(() => Promise.reject(err)));
			});
	}

	static reset(): Promise<void> {
		// Remove upload items from queues in upload controller
		upload.reset();

		// Reset store
		return ProductStateModule.reset();
	}

	public static scalePage(
		pageModel: PI.PageModel,
		newOfferingModel: DB.OfferingModel,
	) {
		const scaleFactorWidth = (newOfferingModel.width + 2 * newOfferingModel.bleedmargin)
			/ (pageModel.width + 2 * pageModel.offset);
		const scaleFactorHeight = (newOfferingModel.height + 2 * newOfferingModel.bleedmargin)
			/ (pageModel.height + 2 * pageModel.offset);

		const pageProps: OptionalExceptFor<PI.PageModel, 'id'> = {
			id: pageModel.id,
			width: newOfferingModel.width,
			height: newOfferingModel.height,
			offset: newOfferingModel.bleedmargin,
		};

		if (ProductStateModule.getOffering?.virtual) {
			pageProps.offeringId = newOfferingModel.id;
		}

		ProductStateModule.changePage(pageProps);

		ProductStateModule
			.getPageObjects(pageModel)
			.forEach((objectModel) => {
				const props: OptionalExceptFor<PI.PageObjectModel, 'id' | 'x_axis' | 'y_axis' | 'width' | 'height'> = {
					id: objectModel.id,
					x_axis: objectModel.x_axis * scaleFactorWidth,
					y_axis: objectModel.y_axis * scaleFactorHeight,
					width: objectModel.width * scaleFactorWidth,
					height: objectModel.height * scaleFactorHeight,
				};

				// Auto compensate for small rounding differences
				if (props.x_axis && props.x_axis <= 2 && props.x_axis >= -2) {
					props.x_axis = 0;
				}
				if (props.y_axis && props.y_axis <= 2 && props.y_axis >= -2) {
					props.y_axis = 0;
				}
				if (props.width && props.width <= newOfferingModel.width + 2 && props.width >= newOfferingModel.width - 2) {
					props.width = newOfferingModel.width;
				}
				if (props.height && props.height <= newOfferingModel.height + 2 && props.height >= newOfferingModel.height - 2) {
					props.height = newOfferingModel.height;
				}

				if (objectModel.borderwidth) {
					props.borderwidth = objectModel.borderwidth * Math.min(
						scaleFactorWidth,
						scaleFactorHeight,
					);
				}

				if (
					objectModel.type === 'photo'
					&& objectModel.photoid
				) {
					const photoModel = PhotosModule.getById(
						objectModel.photoid,
					);

					if (!photoModel) {
						return;
					}

					const facebox = (
						(
							typeof photoModel.fcx === 'number'
							&& typeof photoModel.fcy === 'number'
							&& typeof photoModel.fcw === 'number'
							&& typeof photoModel.fch === 'number'
						)
							? {
								x: photoModel.fcx,
								y: photoModel.fcy,
								width: photoModel.fcw,
								height: photoModel.fch,
							}
							: undefined
					);
					const calc = Template.fitPhotoInRectangle(
						{
							x: props.x_axis,
							y: props.y_axis,
							width: props.width,
							height: props.height,
							angle: objectModel.rotate,
							borderwidth: objectModel.borderwidth,
							autoRotate: false,
						},
						{
							id: photoModel.id,
							width: photoModel.full_width,
							height: photoModel.full_height,
							facebox,
						},
						undefined,
						{
							resizing: {
								maxScale: newOfferingModel.configdpi / newOfferingModel.minimumdpi,
								recommendedMaxScale: newOfferingModel.configdpi / newOfferingModel.qualitydpi,
							},
						},
					);

					props.x_axis = calc.x;
					props.y_axis = calc.y;
					props.width = calc.width;
					props.height = calc.height;
					props.cropx = calc.cropX;
					props.cropy = calc.cropY;
					props.cropwidth = calc.cropWidth;
					props.cropheight = calc.cropHeight;
				}

				if (
					objectModel.type == 'text'
					&& objectModel.pointsize
				) {
					if (!objectModel.fontface) {
						throw new Error('Missing required fontface');
					}

					const fontModel = FontModule.getById(objectModel.fontface);
					const subset = (
						fontModel
							? fontModel.subset.split(',')
							: ['latin']
					);

					const calc = breakText({
						phrase: objectModel.text ?? '',
						maxPxLength: props.width,
						maxPxHeight: props.height,
						fontface: objectModel.fontface,
						bold: Boolean(objectModel.fontbold),
						italic: Boolean(objectModel.fontitalic),
						pointsize: objectModel.pointsize,
						resize: {
							up: 80,
							down: (
								newOfferingModel
									? newOfferingModel.minfontsize
									: 10
							),
						},
						subset,
					});

					if (calc) {
						props.cropy = calc.textheight
							? Math.max(
								0,
								(props.height - calc.textheight) / 2,
							)
							: 0;
						props.pointsize = calc.pointsize;
						props.text_formatted = calc.text_formatted;
					}
				}

				ProductStateModule.changePageObject(props);
			});
	}

	/**
	 * Automatically makes updates to the content of the project based on the offering options
	 * Currently only in use for selecting a cover color, but can be extended to other offering options
	 *
	 * @returns void
	 */
	static updateContentFromOfferingOptions(): void {
		// This is currently not in use, but this function could be plugged in
		// for special type of offerings in the future
	}

	public static changeOffering(offeringid: number): Promise<void> {
		const { productId } = ProductStateModule;

		if (!productId) {
			return Promise.reject(new Error('Missing required productId'));
		}

		const currentOfferingModel = ProductStateModule.getOffering;

		if (!currentOfferingModel) {
			return Promise.reject(
				new Error('Missing required current offering model'),
			);
		}

		const newOfferingModel = AppDataModule.getOffering(offeringid);

		if (!newOfferingModel) {
			return Promise.reject(
				new Error('Missing required new offering model'),
			);
		}

		// Proportinal scaling so we keep the current content
		const pageModels = ProductStateModule.getPages;

		// eslint-disable-next-line no-restricted-syntax
		for (const pageModel of pageModels) {
			this.scalePage(
				pageModel,
				newOfferingModel,
			);
		}

		return ProductsModule
			.putModel({
				id: productId,
				data: {
					group: newOfferingModel.groupid,
					typeid: newOfferingModel.typeid,
					variantid: newOfferingModel.variantid,
				},
			})
			.then(() => {
				ProductStateModule.resetMaskImage();
				AppStateModule.setMask(null);

				if (currentOfferingModel.previewColorSpectrum !== newOfferingModel.previewColorSpectrum) {
					const objectModels = ProductStateModule.getObjects;

					// eslint-disable-next-line no-restricted-syntax
					for (const objectModel of objectModels) {
						if (objectModel._image) {
							ProductStateModule.changePageObject({
								id: objectModel.id,
								_resetImage: true,
							});
						}
					}
				}

				return Theme.fetchData(false);
			})
			.then(() => {
				let themeModel: DB.ThemeModel | undefined;

				if (newOfferingModel.themeid) {
					// The offering has a default theme defined, so we will use that theme
					themeModel = ThemeDataModule.getTheme(newOfferingModel.themeid);
				}

				if (!themeModel) {
					// There is no default theme defined, so we will just use the first one available
					const themeModels = ThemeDataModule.getThemesByOfferingId(offeringid);

					if (themeModels.length) {
						[themeModel] = themeModels;
					}
				}

				if (!themeModel) {
					// There are no theme models available for this offering
					throw new Error('Missing required theme model');
				}

				const isBasicProduct = OfferingGroups(
					newOfferingModel.groupid,
					['BasicProducts'],
				);

				return this.changeTheme(
					themeModel.id,
					false,
					!isBasicProduct,
				);
			})
			.then(() => {
				try {
					// Automatically update project content from its offering options
					this.updateContentFromOfferingOptions();
				} catch (error) {
					// Do nothing
				}

				// If there is an item in the cart for this project and the old offering id,
				// we update this cart item with the new offering id
				const cartItem = CartItemsModule.findWhere({
					productid: productId,
					offeringid: currentOfferingModel.id,
				});

				if (cartItem) {
					return CartItemsModule
						.putModel({
							id: cartItem.id,
							data: {
								offeringid: newOfferingModel.id,
								groupid: newOfferingModel.groupid,
								typeid: newOfferingModel.typeid,
								variantid: newOfferingModel.variantid,
							},
						})
						.then(() => undefined);
				}

				return undefined;
			});
	}

	static changeOfferingVariant(
		variantid: number,
	): Promise<void> {
		const { productId } = ProductStateModule;
		if (!productId) {
			return Promise.reject(
				new Error('Missing required productId'),
			);
		}

		const currentOfferingModel = ProductStateModule.getOffering;
		if (!currentOfferingModel) {
			return Promise.reject(
				new Error('Missing required current offering model'),
			);
		}

		return ProductsModule.putModel({
			id: productId,
			data: {
				variantid,
			},
		}).then(() => {
			ProductStateModule.resetMaskImage();
			AppStateModule.setMask(null);

			ProductStateModule.flagChange();
		}).then(() => {
			const newOfferingModel = ProductStateModule.getOffering;
			if (!newOfferingModel) {
				return Promise.reject(
					new Error('Missing required new offering model'),
				);
			}

			try {
				// Automatically update project content from its offering options
				this.updateContentFromOfferingOptions();
			} catch (error) {
				// Do nothing
			}

			// If there is an item in the cart for this project and the old offering id,
			// we update this cart item with the new offering id
			const cartItem = CartItemsModule.findWhere({
				productid: productId,
				offeringid: currentOfferingModel.id,
			});

			if (cartItem) {
				return CartItemsModule.putModel({
					id: cartItem.id,
					data: {
						offeringid: newOfferingModel.id,
						variantid: newOfferingModel.variantid,
					},
				}).then(() => undefined);
			}

			return undefined;
		});
	}

	static changePagesOfferingTypeId(
		typeId: DB.OfferingModel['typeid'] | 'bestFit',
	) {
		ProductStateModule.getPages.forEach((pageModel) => {
			this.changePageOfferingTypeId(
				pageModel,
				typeId,
			);
		});

		ProductStateModule.pushHistory();
	}

	public static changePageOfferingTypeId(
		pageModel: PI.PageModel,
		typeId: DB.OfferingModel['typeid'] | 'bestFit',
	) {
		const mainOfferingModel = ProductStateModule.getOffering;

		if (!mainOfferingModel) {
			throw new Error('Could not find required offering model');
		}

		const currentOfferingModel = (
			pageModel.offeringId
				? AppDataModule.getOffering(pageModel.offeringId)
				: undefined
		);

		let newOfferingModel: DB.OfferingModel | undefined;

		if (typeId === 'bestFit') {
			const pagePhotoModels = ProductStateModule.getPagePhotos(pageModel);

			if (pagePhotoModels.length) {
				newOfferingModel = ProductState.findBestVirtualOfferingChild(
					pagePhotoModels[0].full_width,
					pagePhotoModels[0].full_height,
					currentOfferingModel?.variantid,
				);
			}
		} else {
			newOfferingModel = AppDataModule.findOfferingWhere({
				groupid: mainOfferingModel.groupid,
				typeid: typeId,
				variantid: currentOfferingModel?.variantid || 1,
			});
		}

		if (newOfferingModel) {
			this.changePageOfferingId(
				pageModel,
				newOfferingModel.id,
				true,
			);
		}
	}

	public static changePagesOfferingId(
		offeringId: DB.OfferingModel['id'],
	) {
		ProductStateModule.getPages.forEach((pageModel) => {
			this.changePageOfferingId(
				pageModel,
				offeringId,
				true,
			);
		});

		ProductStateModule.pushHistory();
	}

	public static changePageOfferingId(
		pageModel: PI.PageModel,
		offeringId: DB.OfferingModel['id'],
		skipHistory?: boolean,
	) {
		if (pageModel.offeringId !== offeringId) {
			const selectedOfferingModel = AppDataModule.getOffering(
				offeringId,
			);

			if (!selectedOfferingModel) {
				throw new Error('Could not find selected offering model');
			}
			if (!pageModel.offeringId) {
				throw new Error('No current offeringId set to page model');
			}

			const currentOfferingModel = AppDataModule.getOffering(
				pageModel.offeringId,
			);

			if (!currentOfferingModel) {
				throw new Error('Could not find current offering model');
			}

			if (
				selectedOfferingModel.groupid === currentOfferingModel.groupid
				&& selectedOfferingModel.typeid === currentOfferingModel.typeid
			) {
				// We are only changing to a different variant of the offering, this will have the same
				// size and bleedmargin, so there's no need to change the template and dimensions
				ProductStateModule.changePage({
					id: pageModel.id,
					offeringId: selectedOfferingModel.id,
				});
			} else {
				const pageStateModel = ThemeStateModule.getVirtualPageStateData(
					selectedOfferingModel.id,
				);

				if (!pageStateModel) {
					throw new Error('Could not find virtual page state data');
				}

				const pageIndex = ProductStateModule.getPageIndex(pageModel);

				let layoutId: DB.LayoutTemplateLinkModel['layoutid'] | undefined;

				if (pageModel.template) {
					// The page has a template assigned, we try to locate the layout this template belongs to
					const linkModel = ThemeDataModule.layouttemplatelinks.find(
						(model) => model.templateid === pageModel.template,
					);
					if (linkModel?.layoutid) {
						layoutId = linkModel.layoutid;
					}
				}
				if (!layoutId) {
					// We can't find the layout from the page properties
					// Let's try to use the project's setting
					const productModel = ProductStateModule.getProduct;
					if (productModel?.layoutid) {
						layoutId = productModel.layoutid;
					}
				}

				const themePageStateModel = ThemeStateModule.getPageStateData(
					pageIndex,
					0,
					{
						layoutId,
						photoOrientation: pageModel.orientation,
						offeringId: selectedOfferingModel.id,
					},
				);

				if (!themePageStateModel) {
					throw new Error('Could not find page state model');
				}

				const themePageModel = themePageStateModel.pageModel;
				ProductStateModule.changePage({
					id: pageModel.id,
					offeringId: selectedOfferingModel.id,
					template: themePageModel.template,
					width: themePageModel.width,
					height: themePageModel.height,
					offset: themePageModel.offset,
					customLayout: false,
				});
				ProductStateModule.resetMaskImage();
				AppStateModule.setMask(null);

				ProductState.changeTemplate(
					pageModel,
				);
			}

			if (!skipHistory) {
				// Save marker in project history (for undo/redo)
				ProductStateModule.pushHistory();
			}
		}
	}

	static changeLayout(
		layoutId: number,
		isSwitch: boolean,
	): Promise<void> {
		const productModel = ProductStateModule.getProduct;
		if (!productModel) {
			return Promise.reject(new Error('Could not find product model'));
		}
		if (productModel.layoutid == layoutId) {
			if (isSwitch) {
				return Promise.resolve();
			}

			return ProductState.autoFill();
		}

		return ProductsModule
			.putModel({
				id: productModel.id,
				data: {
					layoutid: layoutId,
				},
			})
			.then(() => {
				const offeringModel = ProductStateModule.getOffering;
				if (offeringModel?.virtual) {
					// Set heavy load flag, to avoid browser freeze with following operation
					AppStateModule.setHeavyLoad();

					const closeLoader = DialogService.openLoaderDialog();

					const arrPromises: Promise<void>[] = [];
					ProductStateModule.getPages.forEach((pageModel) => {
						arrPromises.push(
							this.changeLayoutPage(
								pageModel,
								layoutId,
							),
						);
					});

					return Promise
						.allSettled(arrPromises)
						.then(() => {
							ProductStateModule.pushHistory();
						})
						.finally(() => {
							AppStateModule.unsetHeavyLoad();
							closeLoader();
						});
				}

				return ProductState.changeProjectPages(
					true,
					undefined,
					true,
				);
			});
	}

	static changeLayoutPage(
		pageModel: PI.PageModel,
		layoutId: DB.LayoutModel['id'],
	): Promise<void> {
		const pageIndex = ProductStateModule.getPageIndex(pageModel);
		const themePageStateModel = ThemeStateModule.getPageStateData(
			pageIndex,
			0,
			{
				layoutId,
				offeringId: pageModel.offeringId,
				photoOrientation: pageModel.orientation,
			},
		);
		if (themePageStateModel) {
			ProductStateModule.changePage({
				id: pageModel.id,
				template: themePageStateModel.pageModel.template,
				customLayout: false,
			});
		}

		return ProductState.changeTemplate(
			pageModel,
		);
	}

	static changeTheme(
		themeid: DB.ThemeModel['id'],
		replaceContent: boolean,
		fill: boolean,
	): Promise<void> {
		const productModel = ProductStateModule.getProduct;
		if (!productModel) {
			return Promise.reject(
				new Error('Could not find product model'),
			);
		}
		if (productModel.themeid == themeid) {
			return Promise.resolve();
		}

		return Theme.setup(themeid)
			.then(() => {
				const data: Partial<DB.ProductModel> = {
					themeid,
				};

				const themeModel = ThemeDataModule.getTheme(themeid);
				if (themeModel && themeModel.layoutid) {
					data.layoutid = themeModel.layoutid;
				} else if (!productModel.layoutid || !_.findWhere(
					ThemeStateModule.layoutCollection,
					{
						id: productModel.layoutid,
					},
				)) {
					data.layoutid = null;
				}

				return ProductsModule.putModel({
					id: productModel.id,
					data,
				});
			})
			.then(() => ProductState.changeProjectPages(
				replaceContent,
				undefined,
				fill,
			));
	}

	static findBestVirtualOfferingChild(
		idealWidth: PI.PageModel['width'],
		idealHeight: PI.PageModel['height'],
		variantid?: DB.OfferingModel['variantid'],
	): DB.OfferingModel | undefined {
		// Find the best fitting child offering model
		const virtualOfferingModel = ProductStateModule.getOffering;
		if (!virtualOfferingModel) {
			throw new Error('Could not find required offering model');
		}

		const idealRatio = idealWidth / idealHeight;
		let groupOfferingModels = AppDataModule.findOffering({
			flexgroupid: virtualOfferingModel.flexgroupid,
			variantid: variantid || ProductStateModule.productSettings.childOfferingVariantId || 1,
			instock: 1,
			virtual: 0,
		});

		if (UserModule.countryid) {
			const countryModel = AppDataModule.getCountry(
				UserModule.countryid,
			);
			if (countryModel) {
				groupOfferingModels = groupOfferingModels.filter(
					(offeringModel) => !!AppDataModule.findRegionOfferingLinkWhere({
						regionid: countryModel.regionid,
						offeringid: offeringModel.id,
					}),
				);
			}
		}

		const sortedChildOfferingModels = _.sortBy(
			groupOfferingModels,
			(m) => {
				const pageWidth = idealWidth >= idealHeight
					? Math.max(
						m.width,
						m.height,
					)
					: Math.min(
						m.width,
						m.height,
					);
				const pageHeight = idealHeight > idealWidth
					? Math.max(
						m.width,
						m.height,
					)
					: Math.min(
						m.width,
						m.height,
					);

				// First sorting is based on the ideal ratio of the print
				const precisionScore = Math.abs(idealRatio - (pageWidth / pageHeight));
				// We use the precision score setting to group almost similar scores together
				// (small croppings are almost invisible to the user)
				const mainScore = Math.max(
					0,
					precisionScore - (1 - ConfigModule['pageSize.bestFit.precision']),
				);

				// In case the mainScore is equal, the subScore will be used for ranking
				// We give preference to the smallest print
				const subScore = m.width * m.height;
				const score = Math.round(mainScore * 1000) + (1 - (1 / subScore));

				return score;
			},
		);

		if (sortedChildOfferingModels.length) {
			return sortedChildOfferingModels[0];
		}

		return undefined;
	}

	static changeProjectPages(
		replaceContent: boolean,
		pageCount: number | undefined,
		fill: boolean,
	): Promise<void> {
		const productModel = ProductStateModule.getProduct;
		const offeringModel = ProductStateModule.getOffering;

		if (!productModel) {
			throw new Error('Could not find product model');
		}
		if (!offeringModel) {
			throw new Error('Could not find offering model');
		}

		if (ProductStateModule.pageList.length) {
			if (replaceContent) {
				const serialNumbers: number[] = [];
				if (OfferingGroups(
					offeringModel.groupid,
					['BasicProducts', 'PhotoFrameBox'],
				)) {
					serialNumbers.push(0);
				}
				if (OfferingGroups(
					offeringModel.groupid,
					['Cards', 'PhotoFrameBox'],
				)) {
					serialNumbers.push(1);
				}

				if (serialNumbers.length) {
					serialNumbers.forEach((serialNumber) => {
						const pageModel = ProductStateModule.getPageByNumber(serialNumber);
						if (!pageModel) {
							throw new Error('Missing required pageModel');
						}

						const themePageStateModel = ThemeStateModule.getPageStateData(
							serialNumber,
							0,
							{
								photoOrientation: pageModel.orientation,
								offeringId: pageModel.offeringId,
							},
						);
						if (!themePageStateModel) {
							throw new Error('Could not find page state model');
						}

						// Replace smartTags
						const themePageModel = themePageStateModel.pageModel;
						const pageData: OptionalExceptFor<PI.PageModel, 'id'> = {
							id: pageModel.id,
							bgcolor: themePageModel.bgcolor,
							bgimage: themePageModel.bgimage,
							bgpattern: themePageModel.bgpattern,
							editable: themePageModel.editable,
							movable: themePageModel.movable,
							scaling: themePageModel.scaling,
							template: themePageModel.template,
							customLayout: false,
						};
						Theme.replaceTags(pageData);
						ProductStateModule.changePage(pageData);

						ProductState.changeTemplate(pageModel);
					});

					return Promise.resolve();
				}
			} else {
				// Switch templates to those of new theme
				ProductStateModule.pageList.forEach((pageId, pageIndex) => {
					const pageModel = ProductStateModule.getPageByNumber(pageIndex);
					if (!pageModel) {
						throw new Error('Missing required pageModel');
					}

					const themePageStateModel = ThemeStateModule.getPageStateData(
						pageIndex,
						0,
						{
							offeringId: offeringModel.id,
						},
					);
					if (!themePageStateModel) {
						throw new Error('Could not find page state model');
					}

					const themePageModel = themePageStateModel.pageModel;
					const pageData: OptionalExceptFor<PI.PageModel, 'id'> = {
						id: pageModel.id,
						template: themePageModel.template,
						templateSetId: null,
					};
					ProductStateModule.changePage(pageData);
				});
			}
		}

		if (fill) {
			return Product.fill({
				navigate: false,
				showBuildOptions: false,
				replaceContent,
				pageCount,
			});
		}

		return Promise.resolve();
	}

	static changePageObjectFill(
		objectModel: PI.PageObjectModel,
		newFillMethod: PI.PageObjectModel['fillMethod'],
		angle?: number,
	): void {
		if (!objectModel.photoid) {
			throw new Error('Missing required property photoid');
		}
		if (!objectModel.templatestateid) {
			throw new Error('Missing required property templatestateid');
		}

		const pageModel = ProductStateModule.findPageFromObject(objectModel.id);

		if (!pageModel) {
			throw new Error('Could not find required page model');
		}

		const offeringModel = ProductStateModule.getOffering;
		const photoModel = PhotosModule.getById(objectModel.photoid);

		if (!photoModel) {
			throw new Error('Could not find required photo model');
		}

		const templatePositions = ProductStateModule.getPageTemplatePositions(pageModel);
		const templatePositionModel = templatePositions.find((position) => (
			position.id === objectModel.templatestateid
		)) as TemplatePhotoPosition;

		if (!templatePositionModel) {
			throw new Error('Missing required template position model');
		}

		analytics.trackEvent(
			'Change Object Fill',
			{
				fillMethod: newFillMethod,
			},
		);

		const photoData: Omit<PhotoData, 'url'> = {
			id: photoModel.id,
			width: photoModel.full_width,
			height: photoModel.full_height,
			caption: photoModel.title || undefined,
		};

		if (
			typeof photoModel.fcx !== 'undefined'
			&& photoModel.fcx !== null
			&& typeof photoModel.fcy !== 'undefined'
			&& photoModel.fcy !== null
			&& typeof photoModel.fcw !== 'undefined'
			&& photoModel.fcw !== null
			&& typeof photoModel.fch !== 'undefined'
			&& photoModel.fch !== null
		) {
			photoData.facebox = {
				x: photoModel.fcx,
				y: photoModel.fcy,
				width: photoModel.fcw,
				height: photoModel.fch,
			};
		}

		let finalAngle = templatePositionModel.angle;

		if (typeof angle !== 'undefined') {
			finalAngle = angle;
		}

		const props = Template.fitPhotoInRectangle(
			{
				x: templatePositionModel.x,
				y: templatePositionModel.y,
				width: templatePositionModel.width,
				height: templatePositionModel.height,
				angle: finalAngle,
				borderwidth: templatePositionModel.borderwidth,
				autoRotate: Boolean(templatePositionModel.autorotate),
			},
			photoData,
			undefined,
			{
				fit: newFillMethod === 'contain',
				resizing: {
					maxScale: offeringModel
						? offeringModel.configdpi / offeringModel.minimumdpi
						: 1000,
					recommendedMaxScale: offeringModel
						? offeringModel.configdpi / offeringModel.qualitydpi
						: 1000,
				},
			},
		);

		ProductStateModule.changePageObject({
			id: objectModel.id,
			// TODO: commented for now, seems to be unncecessary at the moment
			// photoid: photoModel.id,
			// TODO: commented for now, but confirm if should be removed or added back
			// effect: null,
			x_axis: props.x,
			y_axis: props.y,
			width: props.width,
			height: props.height,
			cropwidth: props.cropWidth,
			cropheight: props.cropHeight,
			cropx: props.cropX,
			cropy: props.cropY,
			maxwidth: photoModel.full_width,
			maxheight: photoModel.full_height,
			fillMethod: newFillMethod,
		});

		ProductStateModule.pushHistory();
	}

	static changePageOrientation(
		pageModel: PI.PageModel,
	): void {
		const offeringModel = ProductStateModule.getOffering;
		if (!offeringModel) {
			throw new Error('Missing required offeringModel');
		}

		if (offeringModel.virtual && pageModel.offeringId) {
			analytics.trackEvent(
				'Change Page Orientation',
				{},
			);

			const newOrientation = pageModel.orientation === 'p'
				? 'l'
				: 'p';
			const pageStateModel = ThemeStateModule.getVirtualPageStateData(
				pageModel.offeringId,
				newOrientation,
			);
			if (!pageStateModel) {
				throw new Error('Could not find virtual page state data');
			}

			const pageIndex = ProductStateModule.getPageIndex(pageModel);
			const productModel = ProductStateModule.getProduct;
			const themePageStateModel = ThemeStateModule.getPageStateData(
				pageIndex,
				0,
				{
					layoutId: productModel?.layoutid || undefined,
					offeringId: pageModel.offeringId,
					photoOrientation: newOrientation,
				},
			);
			if (!themePageStateModel) {
				throw new Error('Could not find page state model');
			}

			const themePageModel = themePageStateModel.pageModel;
			ProductStateModule.changePage({
				id: pageModel.id,
				template: themePageModel.template,
				width: pageModel.height,
				height: pageModel.width,
				orientation: newOrientation,
			});

			ProductState.changeTemplate(
				pageModel,
			);

			// Save history snapshot (for undo/redo)
			ProductStateModule.pushHistory();
		} else {
			const rotatedOfferingModel = AppDataModule.findOfferingWhere({
				flexgroupid: offeringModel.flexgroupid,
				variantid: offeringModel.variantid,
				width: offeringModel.height,
				height: offeringModel.width,
			});

			if (rotatedOfferingModel) {
				analytics.trackEvent(
					'Change Offering Orientation',
					{},
				);

				AppStateModule.setHeavyLoad();
				const closeLoader = DialogService.openLoaderDialog();

				ProductState.changeOffering(rotatedOfferingModel.id)
					.then(() => {
						// Save history snapshot (for undo/redo)
						ProductStateModule.pushHistory();
					})
					.catch((e: Error) => {
						// Swallow error: no action required
						if (typeof window.glBugsnagClient !== 'undefined') {
							window.glBugsnagClient.notify(
								e,
								(event) => { event.severity = 'warning'; },
							);
						}
					})
					.finally(() => {
						closeLoader();
						AppStateModule.unsetHeavyLoad();
					});
			}
		}
	}

	/**
	 * Removes the current content of the page in the project, applies the new template,
	 * and fills the page with previous content
	 *
	 * @param pageModel The page model you want to apply the new template to
	 * @param templateSetId The id of this combination of template positions (calculated as a hash of position properties)
	 * @param opts Configuration options to use
	 * @param opts.fit Use the "fit" method (instead of "fill") to make sure photos will be fully visible (no cropping)
	 * @param opts.objectTypes The type of objects to remove from the page before applying the new template
	 * @param opts.photoModels An array of photo models that should be added additionally to the page
	 */
	static changeTemplate(
		pageModel: PI.PageModel,
		templateSetId?: string,
		opts?: {
			fit?: boolean;
			objectTypes?: PI.PageObjectModel['type'][];
			photoModels?: PI.PhotoModel[];
		},
	): Promise<void> {
		const fit = !!opts?.fit;
		const objectTypes: PI.PageObjectModel['type'][] = opts?.objectTypes || ['photo', 'text'];

		// Store all photos that are currently displayed on the page in an array for recyling
		let photoModels: PI.PhotoModel[] = [
			...opts?.photoModels || [],
		];
		const pageTextObjectModels: PI.PageObjectModel[] = [];

		ProductStateModule
			.getPageObjects(pageModel)
			.forEach((objectModel) => {
				if (objectModel.type === 'text') {
					pageTextObjectModels.push(
						JSON.parse(JSON.stringify(objectModel)),
					);
				} else if (objectModel.photoid) {
					const photoModel = PhotosModule.getById(objectModel.photoid);

					if (photoModel) {
						photoModels.push(photoModel);
					}
				}
			});

		const offeringModel = ProductStateModule.getOffering;

		if (
			offeringModel
			&& offeringModel.maxpages <= 2
		) {
			photoModels = ProductStateModule.getPhotosSelected;
		}

		// Remove all existing page objects
		return Page.deleteObjects(
			pageModel,
			{
				objectTypes,
			},
		).finally(() => {
			// Add theme objects to the page
			const pageIndex = ProductStateModule.getPageIndex(pageModel);
			const themePageStateData = ThemeStateModule.getPageStateData(
				pageIndex,
				pageIndex,
				{
					offeringId: pageModel.offeringId,
					photoOrientation: pageModel.orientation,
				},
			);
			if (themePageStateData) {
				Page.copyObjects(
					themePageStateData,
					pageModel,
				);
			}

			if (pageModel.template
				&& photoModels.length
			) {
				// Get the template positions we need to fill
				const templateSet = templateSetId
					? ThemeStateModule.getTemplateSetById(
						pageModel.template,
						templateSetId,
						{
							marginAroundEdge: pageModel.templateMarginAroundEdge,
							marginBetweenPositions: pageModel.templateMarginBetweenPositions,
						},
					)
					: ThemeStateModule.getTemplateSet(
						pageModel.template,
						photoModels,
					).templateSet;

				if (templateSet) {
					// Fill template positions
					return template
						.fillTemplateSet(
							pageModel,
							templateSet,
							photoModels,
							{
								fit,
							},
						)
						.catch(() => {
							// Swallow error: no action required
							// For instance: the promise is rejected when there are no photo positions
							// in the new template set, but there are photoModels available
						})
						.then(() => {
							ProductStateModule.changePage({
								id: pageModel.id,
								templateSetId,
							});
						});
				}
			}

			if (templateSetId) {
				ProductStateModule.changePage({
					id: pageModel.id,
					templateSetId,
				});
			}

			return Promise.resolve();
		}).then(() => {
			// Try to re-add text object to page
			while (ProductStateModule.getPageTemplatePositionsAvailable(pageModel).filter(
				(templatePosition) => templatePosition.type == 'text',
			).length && pageTextObjectModels.length) {
				const textPosition = ProductStateModule.getPageTemplatePositionsAvailable(pageModel).filter(
					(templatePosition) => templatePosition.type == 'text',
				)[0] as TemplateTextPosition;
				const textObjectModel = pageTextObjectModels.shift();

				TemplatePosition.fillTextPosition(
					pageModel,
					textPosition,
					textObjectModel?.text_formatted || '',
					{
						force: true,
					},
				);
			}
		});
	}

	// Finalize is called when the product needs to be saved to the server before the user can continue
	public static finalize(
		showCancel?: boolean,
		showRemove?: boolean,
	): Promise<boolean> {
		if (!ProductStateModule.productId) {
			// There is no product to save, continue
			return Promise.resolve(true);
		}

		if (ProductStateModule.getSaved) {
			const productModel = ProductStateModule.getProduct;

			if (!productModel?.read_token) {
				return Promise.resolve(true);
			}

			// Remove from local storage, we have a full copy saved to the server
			return ProductState
				.removeFromStorage(productModel.read_token)
				.catch(() => {
					// Swallow error: no action required
				})
				.then(() => true);
		}

		if (!AppStateModule.online) {
			// We are offline, so wait for user to come back online
			// Show offline message to user
			return new Promise<boolean>((resolve, reject) => {
				const closeError = DialogService.openErrorDialog({
					header: {
						hasCloseButton: false,
						title: window.App.router.$t('dialogHeaderOffline'),
					},
					body: {
						content: window.App.router.$t('dialogTextOffline'),
					},
					footer: {
						buttons: [
							{
								id: 'cancel',
								text: window.App.router.$t('dialogButtonCancel'),
								click: () => {
									reject();
									closeError();
								},
							},
							{
								id: 'accept',
								text: window.App.router.$t('dialogButtonOfflineOk'),
								click: () => {
									const closeLoader = DialogService.openLoaderDialog();

									// Try ping to check if we are back online
									checkConnection()
										.catch(() => {
											// Swallow error: no action required
										})
										.then(() => {
											closeLoader();

											ProductState
												.finalize(
													showCancel,
													showRemove,
												).then(
													resolve,
													reject,
												);
										});

									closeError();
								},
							},
						],
					},
				});
			});
		}

		// Temporarily disable auto saving
		AppStateModule.disableAutoSave();

		// Save product to database
		return ProductState
			.finalizeUploads({
				showCancel,
				showRemove,
				dialogHeader: window.App.router.$t('dialogTextSave'),
			})
			.then((saved) => {
				if (!saved) {
					return false;
				}

				return new Promise<boolean>((resolve, reject) => {
					const { close: closeDialog } = DialogService.openDialog({
						header: {
							hasCloseButton: false,
						},
						body: {
							component: SavingProjectView,
							props: {
								hasCancel: showCancel,
							},
							listeners: {
								cancel: () => {
									closeDialog();
									resolve(false);
								},
							},
						},
						width: 250,
					});

					// Start saving product to server
					ProductState
						.save()
						.then(() => {
							const productModel = ProductStateModule.getProduct;
							if (!productModel) {
								return true;
							}

							// Remove from local storage, we have a full copy saved to the server
							return ProductState
								.removeFromStorage(productModel.read_token)
								.catch(() => {
									// Swallow error: no action required
								})
								.then(() => true);
						})
						.then(() => {
							closeDialog();

							// Successfully saved to database
							resolve(true);
						})
						.catch((error) => {
							closeDialog();

							// Failed saving to database
							reject(error);
						});
				});
			})
			.then((saved) => {
				// Enable auto saving again
				AppStateModule.enableAutoSave();

				return saved;
			})
			.catch((error) => {
				// Enable auto saving again
				AppStateModule.enableAutoSave();

				throw error;
			});
	}

	/**
	 * Save the product state to database.
	 *
	 * Saves only happen one at a time, if a save is already in progress,
	 * the new save will be queued and executed after the current save is finished.
	 * @param bypassQueue Flag that if true the save logic will be executed immediately
	 * even if there is a save in progress, this is intended to be used when the same
	 * function is being called recursively, for example when a save fails and we need
	 * to retry the save.
	 * @returns Promise that resolves when the save is finished or rejects if an error occurs
	 */
	public static save(bypassQueue = false): Promise<void> {
		/**
		 * If we are already saving, we wait for the current save to finish
		 * before starting a new one, unless we are bypassing the queue
		 * which is happening when the same function is being called
		 * recursively.
		 */
		if (
			ProductState.savePromise
			&& !bypassQueue
		) {
			ProductState.savePromise = ProductState.savePromise.finally(() => ProductState.save());

			return ProductState.savePromise;
		}

		ProductState.savePromise = new Promise<void>((saveResolve, saveReject) => {
			// Check if we can save to database
			if (
				AppStateModule.online
				&& !AppStateModule.sync
			) {
				// Online and no save in progress, so start saving to database
				ProductState
					.saveToDatabase(
						500,
						true,
					)
					.then(() => {
						if (ProductStateModule.getPhotosFailed.length) {
							if (AppStateModule.showUploadErrorDialog) {
								return new Promise<void>((resolve, reject) => {
									const closeError = DialogService.openErrorDialog({
										header: {
											hasCloseButton: false,
										},
										body: {
											component: UploadErrorsView,
										},
										footer: {
											buttons: [
												{
													id: 'abort',
													text: window.App.router.$t('dialogButtonUploadErrorsCancel'),
													click: () => {
														closeError();

														if (ProductStateModule.getPhotosFailed.length) {
															return ProductStateModule
																.retryPhotoErrors()
																.catch(() => {
																	// Swallow error: no action required
																})
																.finally(() => {
																	ProductState
																		.save(true) // Set the bypassQueue flag to true to bypass the queue
																		.then(resolve)
																		.catch(reject);
																});
														}

														return ProductState
															.save(true) // Set the bypassQueue flag to true to bypass the queue
															.then(resolve)
															.catch(reject);
													},
												},
												{
													id: 'accept',
													text: window.App.router.$t('dialogButtonUploadErrorsOk'),
													click: () => {
														closeError();

														const errorModels = ProductStateModule.getPhotosFailed;
														errorModels
															.slice(0)
															.forEach((photoModel) => ProductStateModule.removePhoto(photoModel.id));

														ProductState
															.save(true) // Set the bypassQueue flag to true to bypass the queue
															.then(resolve)
															.catch(reject);
													},
												},
											],
										},
									});
								});
							}

							throw new Error('Unsaved photo models');
						}
						if (
							!AppStateModule.sync
							&& ProductStateModule.getSaved
						) {
							EventBus.emit('product:save:finished');
							return undefined;
						}

						return new Promise<void>((resolve, reject) => {
							window.setTimeout(
								() => {
									ProductState
										.save(true) // Set the bypassQueue flag to true to bypass the queue
										.then(resolve)
										.catch(reject);
								},
								500,
							);
						});
					})
					.catch((error) => {
						EventBus.emit(
							'product:save:finished',
							error,
						);
						throw error;
					})
					.then(saveResolve)
					.catch(saveReject);
				return;
			}
			if (AppStateModule.online) {
				// Save in progress, so delay process
				window.setTimeout(
					() => {
						ProductState
							.save(true) // Set the bypassQueue flag to true to bypass the queue
							.then(saveResolve)
							.catch(saveReject);
					},
					500,
				);
				return;
			}

			const error = new Error(ERRORS_OFFLINE);
			EventBus.emit(
				'product:save:finished',
				error,
			);

			saveReject(error);
		}).finally(() => {
			/**
			 * Reset the savePromise property back to null to allow new saves to run
			 * without putting them in a queue.
			 */
			ProductState.savePromise = null;
		});

		return ProductState.savePromise;
	}

	static saveToDatabase(
		delay = 500,
		retry = false,
	): Promise<void> {
		if (AppStateModule.sync) {
			throw new Error('Save already in progress');
		}

		if (!ProductStateModule.productId) {
			return Promise.resolve();
		}

		if (!AppStateModule.online) {
			throw new Error(ERRORS_OFFLINE);
		}

		if (!retry
			&& UploadModule.error.length
			&& UploadModule.error.filter((uploadModel) => uploadModel.errorCount >= 3).length
		) {
			throw new Error('Upload failure');
		}

		if (UploadModule.error.length) {
			upload.retryAll();

			return ProductState.saveToDatabase(delay);
		}

		if (UploadModule.getInProgress.length) {
			// Wait for running processes to finish
			return new Promise((resolve, reject) => {
				window.setTimeout(
					() => {
						ProductState.saveToDatabase(delay).then(
							resolve,
							reject,
						);
					},
					delay,
				);
			});
		}

		if (ProductStateModule.getPhotosQueued.length) {
			const stuckModels = ProductStateModule.getPhotosQueued.filter(
				(photoModel) => !photoModel._processing,
			);
			if (stuckModels.length) {
				stuckModels.filter(
					(stuckModel) => stuckModel.full_url,
				).forEach((stuckModel) => {
					PhotosModule.retryModel(stuckModel.id);
				});
			}

			// Wait for running processes to finish
			return new Promise((resolve, reject) => {
				window.setTimeout(
					() => {
						ProductState.saveToDatabase(delay).then(
							resolve,
							reject,
						);
					},
					delay,
				);
			});
		}

		if (ProductStateModule.getSaved) {
			return Promise.resolve();
		}

		AppStateModule.setSync(true);

		return ProductStateModule.save()
			.then(() => {
				AppStateModule.setSync(false);
				return undefined;
			}).catch((err) => {
				AppStateModule.setSync(false);
				throw err;
			});
	}

	/**
	 * Get the margin options to place the photos in the book
	 * Used for the slider in the dynamic fill dialog
	 * @param offeringModel - The offering model of the product to fill
	 *
	 * @returns array with margin options
	 */
	public static getDynamicFillMarginOptions(
		offeringModel: DB.OfferingModel,
	): EditorBookFillMargins {
		const safeMargin = offeringModel.pagemargin || 40;
		return [
			{
				id: 'large',
				value: 2 * safeMargin,
			}, {
				id: 'small',
				value: safeMargin,
			}, {
				id: 'zero',
				value: -offeringModel.bleedmargin,
			},
		];
	}

	/**
	 * Get the orientation option to place the photos in the book
	 * Used for the slider in the dynamic fill dialog
	 * @param offeringModel - The offering model of the product to fill
	 * @returns orientation option
	 */
	public static getDynamicFillOrientation(
		offeringModel: DB.OfferingModel,
	): EditorBookFillImagePageOrientation {
		let orientation: EditorBookFillImagePageOrientation = 'square';
		if (offeringModel.width * 0.98 > offeringModel.height) {
			orientation = 'landscape';
		} else if (offeringModel.width < offeringModel.height * 0.98) {
			orientation = 'portrait';
		}

		return orientation;
	}

	/**
	 * Calculate the number of pages required to place the photos in the product
	 * Used for the slider in the dynamic fill dialog
	 * @param densityId - The density id of the product to fill
	 * @param numberOfPhotos - Number of photos to place in the book
	 * @param offeringModel - The offering model of the product to fill
	 * @returns Number of pages to fill
	 */
	public static getDynamicFillPageOption(
		densityId: EditorBookFillPage['id'],
		numberOfPhotos: number,
		offeringModel: DB.OfferingModel,
	): EditorBookFillPage {
		const isBook = OfferingGroups(
			offeringModel.groupid,
			['BookTypes'],
		);

		let averageNumberOfPhotosPerPage = 1;
		if (densityId === 'highest') {
			averageNumberOfPhotosPerPage = 4.5;
		}
		if (densityId === 'high') {
			averageNumberOfPhotosPerPage = 3;
		}
		if (densityId === 'moderate') {
			averageNumberOfPhotosPerPage = 2;
		}
		if (densityId === 'fewer') {
			averageNumberOfPhotosPerPage = 1.5;
		}
		if (densityId === 'single') {
			averageNumberOfPhotosPerPage = 1;
		}

		// Calculate the number of pages required to fill all photos
		const pagesToFill = Math.ceil(numberOfPhotos / averageNumberOfPhotosPerPage);

		let totalProjectPages = isBook
			? pagesToFill + 2 // Add cover pages to the total number of pages
			: pagesToFill;

		// Make sure the number of pages is a multiple of the page interval of the offering
		totalProjectPages += (totalProjectPages % offeringModel.pageinterval);

		if (totalProjectPages > offeringModel.maxpages) {
			// We cannot fill more pages than the maximum number of pages in the offering
			totalProjectPages = offeringModel.maxpages;
		}

		if (totalProjectPages < offeringModel.minprintpages) {
			// We cannot fill less pages than the minimum number of pages in the offering
			totalProjectPages = offeringModel.minprintpages;
		}

		// Calculate the price for this number of pages
		const price: number = PriceCalculator.productPrice(
			offeringModel,
			totalProjectPages,
			{
				quantity: 1,
			},
		).subTotal / 100;

		const innerPagesAfterAutoFill = isBook
			? totalProjectPages - 2 // Substract 2 for the cover pages
			: totalProjectPages;
		const autoFillPageCount = isBook
			? pagesToFill + 2 // Add 2 for the cover pages
			: pagesToFill;

		return {
			id: densityId,
			averageNumberOfPhotosPerPage,
			innerPagesAfterAutoFill,
			price,
			autoFillPageCount,
		};
	}

	/**
	 * Get the page options to place the photos in the book
	 * Used for the slider in the dynamic fill dialog
	 * @param numberOfPhotos - The number of photos to place in the project
	 * @param offeringModel - The offering model of the product to fill
	 * @returns array with page options
	 */
	public static getDynamicFillPageOptions(
		numberOfPhotos: number,
		offeringModel: DB.OfferingModel,
	): EditorBookFillPages {
		return [
			this.getDynamicFillPageOption(
				'highest',
				numberOfPhotos,
				offeringModel,
			),
			this.getDynamicFillPageOption(
				'high',
				numberOfPhotos,
				offeringModel,
			),
			this.getDynamicFillPageOption(
				'moderate',
				numberOfPhotos,
				offeringModel,
			),
			this.getDynamicFillPageOption(
				'fewer',
				numberOfPhotos,
				offeringModel,
			),
			this.getDynamicFillPageOption(
				'single',
				numberOfPhotos,
				offeringModel,
			),
		];
	}

	static saveBuildConfiguration(config: {
		autoFill: boolean;
	}) {
		const productModel = ProductStateModule.getProduct;

		if (!productModel) {
			throw new Error('Could not find required product model');
		} else {
			ProductStateModule.changeProductSettings({
				autoFill: config.autoFill,
			});

			if (
				OfferingGroups(
					productModel.group,
					['BuildOptions'],
				)
			) {
				const offeringModel = ProductStateModule.getOffering;
				if (!offeringModel) {
					throw new Error('Could not find required offeringModel');
				}

				const autoFill = () => {
					if (
						ThemeStateModule.themeHasDynamicLayout
						&& offeringModel.minpages < offeringModel.maxpages
					) {
						const pageOptions = this.getDynamicFillPageOptions(
							ProductStateModule.getPhotosSelected.length,
							offeringModel,
						);
						const marginOptions = this.getDynamicFillMarginOptions(
							offeringModel,
						);
						const orientationOption = this.getDynamicFillOrientation(
							offeringModel,
						);

						const { close: closeDialog } = DialogService.openDialog({
							header: {
								hasCloseButton: false,
							},
							body: {
								component: EditorBookFillView,
								props: {
									currencyModel: UserModule.currency
										? AppDataModule.getCurrency(UserModule.currency)
										: AppDataModule.defaultCurrency,
									pages: pageOptions,
									margins: marginOptions,
									orientation: orientationOption,
									productLabel: AppDataModule.productGroupName(offeringModel.groupid),
									value: {
										margins: ProductStateModule.getProductSettings.autoFillMarginId ?? marginOptions[1].id,
										pages: ProductStateModule.getProductSettings.autoFillPageDensityId ?? pageOptions[3].id,
									},
								},
								listeners: {
									'update:value': (data: EditorBookFillPagesMarginsModel) => {
										const selectedMarginOption = marginOptions.find(
											(option) => option.id == data.margins,
										);
										const selectedPageOption = pageOptions.find(
											(option) => option.id == data.pages,
										);

										ProductStateModule.changeProductSettings({
											autoFillPageDensityId: selectedPageOption?.id,
											autoFillMarginId: selectedMarginOption?.id,
											templateMarginAroundEdge: (selectedMarginOption?.value ?? 0),
											templateMarginBetweenPositions: Math.max(
												1,
												(selectedMarginOption?.value ?? 0) / 2,
											),
										});

										if (selectedMarginOption) {
											// Set margin style to pages that are already setup in project
											ProductStateModule.getPages.forEach((pageModel) => {
												ProductStateModule.changePage({
													id: pageModel.id,
													templateMarginAroundEdge: ProductStateModule.getProductSettings.templateMarginAroundEdge,
													templateMarginBetweenPositions: ProductStateModule.getProductSettings.templateMarginBetweenPositions,
												});
											});
										}

										// Close the dialog with the margin and pages selection
										closeDialog();

										// Open a loader dialog
										const closeLoader = DialogService.openLoaderDialog();

										// Debounce to prevent a screen freeze due to an occupied processor
										_.debounce(
											() => {
												AppStateModule.setHeavyLoad();

												ProductState.autoFill(
													selectedPageOption?.autoFillPageCount,
												).finally(() => {
													AppStateModule.unsetHeavyLoad();
													closeLoader();
												});
											},
											100,
										)();
									},
									close: () => {
										closeDialog();
									},
								},
								styles: {
									padding: '0',
									backgroundColor: 'transparent',
								},
							},
							styles: {
								padding: '0',
								backgroundColor: 'transparent',
							},
							maxScreenSize: true,
							theme: 'light',
							width: 1100,
						});
					} else if (ThemeStateModule.themeHasLayoutSelect) {
						const { close: closeDialog } = DialogService.openDialog({
							header: {
								classes: 'picker',
								title: window.App.router.$t('pickerLayout'),
							},
							body: {
								component: LayoutPickerView,
								props: {
									isSwitch: false,
								},
								listeners: {
									closeDialog: () => {
										closeDialog();
									},
								},
							},
							width: 500,
						});
					} else {
						ProductState.autoFill();
					}
				};

				const noAutoFill = () => {
					const pageMargin = offeringModel.pagemargin ?? 0;
					ProductStateModule.changeProductSettings({
						templateMarginAroundEdge: pageMargin,
						templateMarginBetweenPositions: Math.max(
							1,
							pageMargin / 2,
						),
					});

					// Set margin style to pages that are already setup in project
					ProductStateModule.getPages.forEach((pageModel) => {
						ProductStateModule.changePage({
							id: pageModel.id,
							templateMarginAroundEdge: pageMargin,
							templateMarginBetweenPositions: pageMargin / 2,
						});
					});

					template.fillProductAttributes();
					ProductState.save();
				};

				const extraPageQuantity = offeringModel.minpages - ProductStateModule.getPages.length;
				if (extraPageQuantity > 0) {
					const afterAdding = _.after(
						extraPageQuantity,
						() => {
							if (config.autoFill) {
								autoFill();
							} else {
								noAutoFill();
							}
						},
					);
					for (let y = 0; y < extraPageQuantity; y += 1) {
						ProductState.addPage(false).then(() => {
							afterAdding();
						});
					}
				} else if (config.autoFill) {
					autoFill();
				} else {
					noAutoFill();
				}

				navigate.toOverview(productModel.id);
			}
		}
	}

	static removeFromStorage(token: string) {
		if (!storage.enabled.data) {
			return Promise.reject(new Error('No storage enabled'));
		}

		return storage.clear(['productData', token]);
	}

	static finalizeUploads(options: {
		showCancel?: boolean;
		showRemove?: boolean;
		dialogHeader: string;
	}): Promise<boolean> {
		if (ProductStateModule.getPhotosQueued.length === 0) {
			return Promise.resolve(true);
		}

		return new Promise((resolve) => {
			let interval = 0;

			let closeProgress!: () => void;
			const progressDialogButtons: DialogServiceButton[] = [];

			if (options.showRemove) {
				progressDialogButtons.push({
					id: 'delete',
					text: window.App.router.$t('buttonAbortUploadDestroyProject'),
					click: () => {
						// Save analytics event
						analytics.trackEvent(
							'Delete product',
							{
								category: 'Save dialog',
							},
						);

						// Show confirmation dialog
						const closeConfirm = DialogService.openConfirmDialog({
							header: {
								title: window.App.router.$t('dialogHeaderRemoveProduct'),
							},
							body: {
								content: window.App.router.$t('dialogTextRemoveProduct'),
							},
							footer: {
								buttons: [
									{
										id: 'cancel',
										text: window.App.router.$t('dialogButtonCancel'),
										click: () => {
											if (interval) {
												window.clearInterval(interval);
											}

											resolve(false);
											closeConfirm();
										},
									},
									{
										id: 'accept',
										text: window.App.router.$t('dialogButtonRemoveProductOk'),
										click: () => {
											if (ProductStateModule.getProduct) {
												deleteProduct(ProductStateModule.getProduct.id)
													.then(() => {
														navigate.toStart();
														closeConfirm();
													})
													.catch(() => {
														window.App.router.reload();
													});
											} else {
												navigate.toStart();
												closeConfirm();
											}
										},
									},
								],
							},
						});
						closeProgress();
					},
				});
			}
			if (options.showCancel) {
				progressDialogButtons.push({
					id: 'cancel',
					text: window.App.router.$t('buttonHideUploadProgress'),
					click: () => {
						if (interval) {
							window.clearInterval(interval);
						}

						resolve(false);
						closeProgress();
					},
				});
			}

			// Show dialog to indicate saving progress to user
			const progressDialog = DialogService.openProgressDialog({
				header: {
					title: options.dialogHeader,
				},
				body: {
					props: {
						value: 0,
						total: 1,
					},
				},
				footer: {
					buttons: (
						progressDialogButtons.length
							? progressDialogButtons
							: undefined
					),
				},
			});
			const apiProgress = progressDialog.api;
			closeProgress = progressDialog.close;

			// Update progress dialog
			interval = window.setInterval(
				() => {
					// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
					const dialogProgressComponent = apiProgress.bodyComponent()!;
					dialogProgressComponent.value = upload.getUploadProgressPercentage / 100;

					if (ProductStateModule.getPhotosQueued.length === 0) {
						window.clearInterval(interval);
						closeProgress();

						resolve(true);
					} else if (
						ProductStateModule.getPhotosQueued.length
						&& AppStateModule.online
						&& !AppStateModule.sync
					) {
						// Save process seems to be stuck so give it a kickstart
						ProductState.save();
					}
				},
				100,
			);
		});
	}

	static async clone(
		origin: 'ShelfItem' | 'ProductReady' | 'CartItem' | 'ProductConfirm',
	): Promise<void> {
		// Get the data of the original product
		const productData = ProductStateModule.getData;
		const productModel = ProductStateModule.getProduct;

		if (!productModel) {
			throw new Error('Could not find product model');
		}

		const productVersion = moment(productModel.lastupdate).format('X');
		const productImage = `${ConfigModule.projectImageBaseUrl}/${productModel.id}/${productModel.read_token}/${productVersion}`;
		const startTime = new Date().getTime();

		// Open dialog showing clone animation
		const { close: closeDialog } = DialogService.openDialog({
			header: {
				hasCloseButton: false,
				title: window.App.router.$t('dialogHeaderLoad'),
			},
			body: {
				component: CloneView,
				props: {
					productImage,
				},
			},
			width: 500,
		});

		// Temporarily disable auto save
		AppStateModule.disableAutoSave();

		try {
			const data: Partial<DB.ProductModel> = {
				affiliateid: productModel.affiliateid,
				group: productModel.group,
				title: productModel.title,
				typeid: productModel.typeid,
				variantid: productModel.variantid,
				themeid: productModel.themeid,
				layoutid: productModel.layoutid,
				origin: `cloneFrom${origin}`,
			};

			// Create the project record in the database
			await this.create(data);

			// Remove all pages that are setup for product by default
			while (ProductStateModule.getPages.length) {
				const pageModel = _.first(ProductStateModule.getPages);
				if (pageModel) {
					// eslint-disable-next-line no-await-in-loop
					await ProductStateModule.removePage({ pageId: pageModel.id });
				}
			}

			// Add photos to new product
			productData.photoList.forEach((photoId) => {
				ProductStateModule.addPhoto({ photoId });
			});

			// Clone product settings
			// Note: important to do this before cloning the pages, otherwise the page background color will be overwritten
			ProductStateModule.changeProductSettings(productData.productSettings);

			// Clone product attributes
			productData.productAttributes.forEach((productAttribute) => {
				ProductStateModule.addAttribute({
					data: productAttribute,
				});
			});

			// Clone pages
			// eslint-disable-next-line no-restricted-syntax
			for (const [index, pageId] of productData.pageList.entries()) {
				// eslint-disable-next-line no-await-in-loop
				await this.clonePage(
					productData,
					pageId,
					index,
				);
			}

			// Clone address
			/* if (productData.address) {
				ProductStateModule.addAddress({
					data: productData.address,
				});
			} */
		} catch (e) {
			closeDialog();

			// Re-enable auto save
			AppStateModule.enableAutoSave();

			throw e;
		}

		// Clone ready
		// We want the dialog to be visible for at least 3 seconds, so user understands what's happening
		const endTime = new Date().getTime();
		const timeout = Math.max(
			0,
			3000 - (endTime - startTime),
		);

		return new Promise((resolve, reject) => {
			window.setTimeout(
				() => {
					this.save().then(
						() => {
							closeDialog();

							// Re-enable auto save
							AppStateModule.enableAutoSave();

							resolve();
						},
						reject,
					);
				},
				timeout,
			);
		});
	}

	static async clonePage(
		productData: PI.ProductDataModel,
		pageId: string,
		index: number,
	): Promise<void> {
		const pageData = productData.pages[pageId];
		const newPageModel = await ProductStateModule.addPage({
			data: _.omit(
				pageData,
				'id',
				'objectList',
			),
			at: index,
		});

		if (pageData.objectList) {
			// eslint-disable-next-line no-restricted-syntax
			for (const objectId of pageData.objectList) {
				const objectData = productData.objects[objectId];
				if (objectData && objectData.type) {
					// eslint-disable-next-line no-await-in-loop
					await ProductStateModule.addPageObject({
						pageId: newPageModel.id,
						data: _.omit(
							objectData,
							'id',
						),
					});
				}
			}
		}
	}

	static addProductProperties(
		options: {
			skipVirtualOfferingSelect?: boolean;
		} = {},
	): Promise<void> {
		return new Promise((resolve) => {
			const productModel = ProductStateModule.getProduct;
			if (!productModel) {
				throw new Error('Could not find product model');
			}

			const offeringModel = ProductStateModule.getOffering;
			if (!offeringModel) {
				throw new Error('Could not find offering model');
			}

			const { themeModel } = ThemeStateModule;

			if (
				offeringModel.virtual
				&& !options.skipVirtualOfferingSelect
				&& ProductStateModule.getUnusedPhotos.length
			) {
				const offeringModels = AppDataModule.findOffering({
					flexgroupid: offeringModel.flexgroupid,
				});

				if (offeringModels.length === 0) {
					throw new Error('There are no offeringModels linked to this virtual one');
				}

				const { close: closeDialog } = DialogService.openDialog({
					header: {
						hasCloseButton: false,
					},
					body: {
						component: VirtualOfferingSelectView,
						props: {
							count: ProductStateModule.getUnusedPhotos.length,
							modus: 'select',
							offeringModel,
							preSelectedOfferingId: ProductStateModule.getChildOfferingId,
							regionId: UserModule.countryid
								? AppDataModule.getCountry(UserModule.countryid)?.regionid
								: 0,
							selectSize: true,
							selectFinish: true,
							selectLayout: Boolean(themeModel?.layoutselect),
						},
						listeners: {
							closeDialog: () => {
								closeDialog();
							},
						},
						styles: {
							padding: '0',
						},
					},
					listeners: {
						close: () => {
							this
								.addProductProperties({
									...options,
									skipVirtualOfferingSelect: true,
								})
								.then(resolve);
						},
					},
					width: 800,
				});
			} else {
				const arrThemeVariants = ThemeDataModule.whereVariant({
					themeid: productModel.themeid,
					input: 1,
				});

				if (arrThemeVariants.length > ProductStateModule.getAttributes.length) {
					const launchSetting = OfferingLaunchers(offeringModel.id);
					if (
						launchSetting
						&& launchSetting.before
					) {
						navigate.go(
							`launch/${offeringModel.id}`,
							{ trigger: true, replace: true },
						);
					} else if (
						launchSetting
						&& productModel.themeid
						&& ProductStateModule.productId
					) {
						navigate.go(
							`${ProductStateModule.productId}/launch`,
							{ trigger: true, replace: true },
						);
					} else {
						const { close: closeDialog } = DialogService.openDialog({
							header: {
								hasCloseButton: false,
								title: window.App.router.$t('dialogHeaderProductAttributes'),
							},
							body: {
								component: ProductAttributesView,
								listeners: {
									closeDialog: () => {
										closeDialog();
									},
								},
							},
							listeners: {
								close: () => {
									resolve();
								},
							},
						});
					}
				} else {
					resolve();
				}
			}
		});
	}

	static addPage(
		autoCompensate: boolean,
		orientation?: PI.PageModel['orientation'],
		idealWidth?: PI.PageModel['width'],
		idealHeight?: PI.PageModel['height'],
	): Promise<PI.PageModel> {
		return new Promise((resolve, reject) => {
			const productModel = ProductStateModule.getProduct;
			const serialnumber = ProductStateModule.getPages.length;
			const offeringModel = ProductStateModule.getOffering;

			if (!productModel) {
				throw new Error('Could not find required product model');
			}
			if (!offeringModel) {
				throw new Error('Could not find required offering model');
			}

			let offeringId: DB.OfferingModel['id'] | undefined;
			if (offeringModel.virtual
				&& ProductStateModule.productSettings.childOfferingTypeId === 'bestFit'
			) {
				if (!idealHeight) {
					throw new Error('Missing required idealHeight');
				}
				if (!idealWidth) {
					throw new Error('Missing required idealWidth');
				}

				// Find the best fitting child offering model
				const childOfferingModel = this.findBestVirtualOfferingChild(
					idealWidth,
					idealHeight,
				);
				if (childOfferingModel) {
					offeringId = childOfferingModel.id;
				}
			}

			// create extra pages
			const themePageStateData = ThemeStateModule.getPageStateData(
				serialnumber,
				ProductStateModule.getPages.length,
				{
					layoutId: productModel.layoutid || undefined,
					offeringId,
					photoOrientation: orientation,
				},
			);
			if (!themePageStateData) {
				throw new Error('Could not setup required theme page state model');
			}

			const pageData: Partial<PI.PageModel> = themePageStateData.pageModel;
			delete pageData.id;
			delete pageData.name;
			delete pageData.productid;
			delete pageData.serialnumber;

			if (ProductStateModule.productId) {
				pageData.productid = ProductStateModule.productId;
			}
			if (offeringId) {
				pageData.offeringId = offeringId;
			}

			const productSettings = ProductStateModule.getProductSettings;

			if (typeof productSettings.templateMarginAroundEdge !== 'undefined'
				&& ThemeStateModule.themeHasDynamicLayout
			) {
				pageData.templateMarginAroundEdge = productSettings.templateMarginAroundEdge;
			}
			if (typeof productSettings.templateMarginBetweenPositions !== 'undefined'
				&& ThemeStateModule.themeHasDynamicLayout
			) {
				pageData.templateMarginBetweenPositions = productSettings.templateMarginBetweenPositions;
			}
			if (pageData.bgcolor != 'transparent' && (productSettings.bgcolor || productSettings.bgpattern)) {
				pageData.bgpattern = productSettings.bgpattern;
				pageData.bgcolor = productSettings.bgcolor;
			}

			// Replace smartTags
			Theme.replaceTags(pageData);

			ProductStateModule
				.addPage({
					data: pageData,
					at: serialnumber,
				})
				.then(
					(newPageModel) => {
						// Copy all the objects on the page
						Page.copyObjects(
							themePageStateData,
							newPageModel,
						);

						// Add template to pagestate
						if (newPageModel.template
							&& newPageModel.templateSetId
						) {
							const templateSet = ThemeStateModule.getTemplateSetById(
								newPageModel.template,
								newPageModel.templateSetId,
								{
									marginAroundEdge: newPageModel.templateMarginAroundEdge,
									marginBetweenPositions: newPageModel.templateMarginBetweenPositions,
								},
							);
							if (templateSet) {
								const templatepositions = ProductStateModule.getPageTemplatePositionsAvailable(
									newPageModel,
								).filter(
									(positionModel) => positionModel.type === 'text',
								) as TemplateTextPosition[];
								templatepositions.forEach((positionModel) => {
									// @ts-ignore: We know that this is a text position model, and thus that productattribute exists
									if (positionModel.productattribute) {
										const productAttributeModel = _.findWhere(
											ProductStateModule.getAttributes,
											// @ts-ignore: We know that this is a text position model, and thus that productattribute exists
											{ property: positionModel.productattribute },
										);
										if (productAttributeModel && productAttributeModel.value) {
											TemplatePosition
												.fillTextPosition(
													newPageModel,
													positionModel,
													productAttributeModel.value,
												)
												.catch(() => {
													// Swallow error: no action required
												});
										}
									}
								});
							}
						}

						if (!productModel) {
							reject(new Error('Could not find required product model'));
						} else if (!offeringModel) {
							reject(new Error('Could not find required offering model'));
						} else if (autoCompensate
							&& (
								(
									// For double sided prints and the memory game, add the backpage as well
									(OfferingGroups(
										productModel.group,
										['DoubleSidePrints'],
									) || offeringModel.hasback)
									&& serialnumber % 2 === 0
								)
								|| (
									// For books always create an even number of pages
									offeringModel.spread
									&& serialnumber > 1
									&& serialnumber % 2 === 0
								)
							)
						) {
							ProductState.addPage(
								true,
								orientation,
							).then(
								() => {
									resolve(newPageModel);
								},
								reject,
							);
						} else {
							resolve(newPageModel);
						}
					},
					reject,
				);
		});
	}

	static showLayoutSwitch(
		offeringModel: DB.OfferingModel,
	) {
		const countryModel = UserModule.countryid
			? AppDataModule.getCountry(UserModule.countryid)
			: undefined;

		if (ThemeStateModule.themeHasDynamicLayout) {
			const pageOptions = ProductState.getDynamicFillPageOptions(
				ProductStateModule.getPhotosSelected.length,
				offeringModel,
			);
			const marginOptions = ProductState.getDynamicFillMarginOptions(
				offeringModel,
			);
			const orientationOption = ProductState.getDynamicFillOrientation(
				offeringModel,
			);

			const { close: closeDialog } = DialogService.openDialog({
				header: {
					hasCloseButton: false,
				},
				body: {
					component: EditorBookFillView,
					props: {
						currencyModel: UserModule.currency
							? AppDataModule.getCurrency(UserModule.currency)
							: AppDataModule.defaultCurrency,
						pages: pageOptions,
						margins: marginOptions,
						orientation: orientationOption,
						productLabel: AppDataModule.productGroupName(offeringModel.groupid),
						value: {
							margins: ProductStateModule.getProductSettings.autoFillMarginId ?? marginOptions[1].id,
							pages: ProductStateModule.getProductSettings.autoFillPageDensityId ?? pageOptions[3].id,
						},
					},
					listeners: {
						'update:value': (data: EditorBookFillPagesMarginsModel) => {
							const selectedMarginOption = marginOptions.find(
								(option) => option.id == data.margins,
							);
							const selectedPageOption = pageOptions.find(
								(option) => option.id == data.pages,
							);

							ProductStateModule.changeProductSettings({
								autoFillPageDensityId: selectedPageOption?.id,
								autoFillMarginId: selectedMarginOption?.id,
								templateMarginAroundEdge: (selectedMarginOption?.value ?? 0),
								templateMarginBetweenPositions: Math.max(
									1,
									(selectedMarginOption?.value ?? 0) / 2,
								),
							});

							// Close the dialog with the margin and pages selection
							closeDialog();

							// Show a loader dialog
							const closeLoader = DialogService.openLoaderDialog();

							// Set heavy load state so parts of the UI are temporarily disabled
							AppStateModule.setHeavyLoad();

							// Debounce to prevent a screen freeze due to an occupied processor
							_.debounce(
								() => {
									ProductState.changeProjectPages(
										true,
										selectedPageOption?.autoFillPageCount,
										true,
									).finally(() => {
										AppStateModule.unsetHeavyLoad();
										ProductStateModule.pushHistory();
										closeLoader();
									});
								},
								100,
							)();
						},
						close: () => {
							closeDialog();
						},
					},
					styles: {
						padding: '0',
						backgroundColor: 'transparent',
					},
				},
				styles: {
					padding: '0',
					backgroundColor: 'transparent',
				},
				maxScreenSize: true,
				theme: 'light',
				width: 1100,
			});
		} else if (OfferingGroups(
			offeringModel.groupid,
			['PhotoPrints'],
		)) {
			const { close: closeDialog } = DialogService.openDialog({
				body: {
					component: VirtualOfferingSelectView,
					props: {
						count: ProductStateModule.getPages.length,
						modus: 'change',
						offeringModel,
						regionId: countryModel?.regionid || 0,
						selectSize: false,
						selectFinish: false,
						selectLayout: true,
					},
					listeners: {
						closeDialog: (event: ServiceEvent<DialogClosePayloadVirtalOfferingSelect>) => {
							if (event.payload?.layoutid) {
								analytics.trackEvent(
									'Change Layout',
									{
										category: 'Button',
									},
								);

								const closeLoader = DialogService.openLoaderDialog();

								ProductState
									.changeLayout(
										event.payload.layoutid,
										true,
									)
									.then(() => {
										ProductStateModule.pushHistory();
									})
									.finally(() => {
										closeLoader();
									});
							}

							closeDialog();
						},
					},
					styles: {
						padding: '0',
					},
				},
			});
		} else {
			const { close: closeDialog } = DialogService.openDialog({
				header: {
					classes: 'picker',
					title: window.App.router.$t('pickerLayout'),
				},
				body: {
					component: LayoutPickerView,
					listeners: {
						closeDialog: () => {
							closeDialog();
						},
					},
				},
				width: 500,
			});
		}
	}
}
