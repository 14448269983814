import _ from 'underscore';
import merge from 'deepmerge';
import moment from 'moment';
import filterText from 'tools/filter-text';
import {
	ChannelModel,
	ConnectorBridgeResponseData,
	ChannelProfileModel,
} from 'interfaces/app';
import store, { ConfigModule } from '../store/index';
import parseUrl from '../tools/parse-url';
import connector, {
	ConnectorBridge,
	ConnectorBridgeLoginOptions,
	ConnectorBridgeLoginResponse,
	ConnectorBridgeMeOptions,
	ConvertedPhotoData,
} from '../controllers/connector';
import { ERRORS_OAUTH_FAILED } from '../settings/errors';
import EventBus from '../components/event-bus';

class FacebookClass implements ConnectorBridge {
	public contentFilters: string[] = [];

	public grantedScopes: string[] = [];

	private version = 'v5.0';

	private locale = 'en_US';

	private libraryUrl: string | undefined;

	public isSupported = true;

	private network: ChannelModel['id'] = 'facebook';

	constructor() {
		this.libraryUrl = `//connect.facebook.net/${this.locale}/sdk.js`;

		window.fbAsyncInit = () => {
			EventBus.emit('fbAsyncInit');
		};
	}

	public setup(): Promise<void> {
		return new Promise((resolve) => {
			if (typeof window.FB === 'undefined') {
				// We use the EventBus so we're not overwriting fbAsyncInit
				// when the setup function is called in multiple components at the same time
				EventBus.on(
					'fbAsyncInit',
					resolve,
				);

				/* eslint-disable */
				const { libraryUrl } = this;
				(function (d, s, id) {
					var js, fjs = d.getElementsByTagName(s)[0];
					if (d.getElementById(id)) return;
					js = d.createElement(s); js.id = id;
					// @ts-ignore
					js.src = libraryUrl;
					// @ts-ignore
					fjs.parentNode.insertBefore(js, fjs);
				}(document, 'script', 'facebook-jssdk'));
				/* eslint-enable */
			} else {
				resolve();
			}
		});
	}

	public init(channelModel: ChannelModel) {
		if (channelModel.apikey) {
			window.FB.init({
				appId: channelModel.apikey,
				xfbml: false,
				version: this.version,
			});

			return Promise.resolve();
		}

		return Promise.reject(new Error('Missing api key'));
	}

	public login(
		scopes: string[],
		options: ConnectorBridgeLoginOptions,
	): Promise<ConnectorBridgeLoginResponse> {
		return new Promise((resolve, reject) => {
			if (options.display == 'none') {
				window.FB.getLoginStatus((response) => {
					if (response.status === 'connected' && response.authResponse) {
						const objSuccess = {
							accessToken: response.authResponse.accessToken,
							userid: response.authResponse.userID,
						};

						window.FB.api(
							'/me/permissions',
							(permissionResponse: any) => {
								if (permissionResponse.error) {
									reject(new Error(permissionResponse.error.message));
								} else {
									for (let i = 0; i < permissionResponse.data.length; i += 1) {
										if (!permissionResponse.data[i].status
										|| permissionResponse.data[i].status != 'declined'
										) {
											if (this.grantedScopes.indexOf(permissionResponse.data[i].permission) === -1) {
												this.grantedScopes.push(permissionResponse.data[i].permission);
											}
										}
									}

									const missingScopes = scopes.filter((s) => this.grantedScopes.indexOf(s) < 0);

									if (missingScopes.length) {
										reject(new Error(ERRORS_OAUTH_FAILED));
									} else {
										resolve(objSuccess);
									}
								}
							},
						);
					} else {
						reject(new Error(ERRORS_OAUTH_FAILED));
					}
				});
			} else {
				window.FB.login(
					(response) => {
						if (
							response.status === 'connected'
						&& response.authResponse
						// @ts-ignore: grantedScopes could exist in authResponse
						&& response.authResponse.grantedScopes
						) {
						// @ts-ignore: grantedScopes does exist in authResponse
							const grantedScopes = response.authResponse.grantedScopes.split(',');
							grantedScopes.forEach((scope) => {
								if (this.grantedScopes.indexOf(scope) === -1) {
									this.grantedScopes.push(scope);
								}
							});

							const missingScopes = _.filter(
								scopes,
								(s) => this.grantedScopes.indexOf(s) < 0,
							);
							if (missingScopes.length) {
								reject(new Error(ERRORS_OAUTH_FAILED));
							} else {
								resolve({
									accessToken: response.authResponse.accessToken,
									userid: response.authResponse.userID,
								});
							}
						} else {
							reject(new Error(ERRORS_OAUTH_FAILED));
						}
					},
					{
						scope: scopes.join(),
						return_scopes: true,
						auth_type: 'rerequest',
					},
				);
			}
		});
	}

	public logout(): Promise<void> {
		return new Promise((resolve) => {
			if (typeof FB !== 'undefined') {
				window.FB.logout(() => {
					resolve();
				});
			} else {
				resolve();
			}
		});
	}

	private api(
		route: string,
		scope: string[],
		routeOptions: any,
	): Promise<ConnectorBridgeResponseData> {
		return new Promise((resolve, reject) => {
			routeOptions.scope = scope.join();

			this.setup()
				.then(() => {
					window.FB.api(
						route,
						'get',
						routeOptions,
						(json: any) => {
							if (json.error) {
								reject(new Error(json.error.message));
							} else {
								const returnData: ConnectorBridgeResponseData = {
									data: json.data ? json.data : json,
									paging: {},
								};

								if (json.paging && json.paging.next) {
									if (json.paging.cursors && json.paging.cursors.after) {
										returnData.paging.nextOptions = {
											after: json.paging.cursors.after,
										};
									} else {
										const parsedNextUrl = parseUrl(json.paging.next);
										returnData.paging.nextPage = parsedNextUrl.path
										+ parsedNextUrl.query
										+ parsedNextUrl.hash;
									}
								}

								resolve(returnData);
							}
						},
					);
				})
				.catch(() => {
					// Swallow error: no action required
				});
		});
	}

	public me(
		scope: string[],
		options?: ConnectorBridgeMeOptions,
	): Promise<ChannelProfileModel> {
		return new Promise((resolve, reject) => {
			this.api(
				'me',
				scope,
				options,
			).then(
				({ data }) => {
					const profile: ChannelProfileModel = {
						id: data,
						picture: `https://graph.facebook.com/${data.id}/picture`,
					};
					if (data.first_name) {
						profile.first_name = data.first_name;
						profile.display_name = data.first_name;
					}
					if (data.last_name) {
						profile.last_name = data.last_name;
					}

					resolve(profile);
				},
				reject,
			);
		});
	}

	public folders() {
		return Promise.reject(
			new Error('Bridge function not implemented'),
		);
	}

	public albums(options: any) {
		const defaults = {
			scope: '',
		};
		options = _.extend(
			defaults,
			options,
		);

		const route = options.nextPage ? options.nextPage : 'me/albums';

		let routeOptions = {
			limit: 20,
			fields: 'cover_photo,name,count,type',
		};
		if (options.nextOptions) {
			routeOptions = merge(
				routeOptions,
				options.nextOptions,
			);
		}

		return this.api(
			route,
			options.scope,
			routeOptions,
		);
	}

	public albumPhotos(
		albumid: string | number,
		options: any,
	) {
		const defaults = {
			scope: '',
			limit: 20,
		};
		options = _.extend(
			defaults,
			options,
		);

		let route;
		if (options.nextPage) {
			route = options.nextPage;
		} else if (typeof albumid == 'string' && albumid.indexOf('?') >= 0) {
			const a = albumid.substring(
				0,
				albumid.indexOf('?'),
			);
			const q = albumid.substring(albumid.indexOf('?'));
			route = `${a}/photos${q}`;
		} else {
			route = `${albumid}/photos`;
		}

		let routeOptions = {
			limit: Math.min(
				20,
				options.limit,
			),
			fields: 'id,picture,name,images,source,width,height,likes,created_time',
		};
		if (options.nextOptions) {
			routeOptions = merge(
				routeOptions,
				options.nextOptions,
			);
		}

		return this.api(
			route,
			options.scope,
			routeOptions,
		);
	}

	public photos(options: any) {
		const defaults = {
			scope: '',
		};
		options = _.extend(
			defaults,
			options,
		);

		let route;
		if (options.nextPage) {
			route = options.nextPage;
		} else {
			route = 'me/photos';
		}

		let routeOptions: {
			limit: number;
			fields: string;
			type: string;
			since?: string;
			until?: string;
		} = {
			limit: 20,
			fields: 'id,picture,name,images,source,width,height,likes,created_time,tags',
			type: 'uploaded',
		};
		if (options.fromDate) {
			routeOptions.since = moment(options.fromDate).format('X');
		}
		if (options.toDate) {
			routeOptions.until = moment(options.toDate).format('X');
		}
		if (options.nextOptions) {
			routeOptions = merge(
				routeOptions,
				options.nextOptions,
			);
		}

		return this.api(
			route,
			options.scope,
			routeOptions,
		);
	}

	public convertFolderData() {
		throw new Error('Bridge function not implemented');
	}

	public convertAlbumData(albumData: {
		id: string;
		type: string;
		count: number;
		name?: string;
		cover_photo?: { // eslint-disable-line camelcase
			id: string;
			created_time: string; // eslint-disable-line camelcase
		};
	}) {
		const objAlbum = {
			id: albumData.id,
			name: albumData.name,
			type: albumData.type,
		};

		const thumbnail = `https://graph.facebook.com/${
			albumData.id
		}/picture?width=100&height=100&access_token=${
			connector.networks.facebook.accessToken
		}`;
		_.extend(
			objAlbum,
			{
				thumbnail,
				count: albumData.count,
			},
		);

		return objAlbum;
	}

	public convertPhotoData(photoData: {
		id: string;
		width: number;
		height: number;
		source: string;
		picture: string;
		thumbnail?: string;
		images: {
			height: number;
			width: number;
			source: string;
		}[];
		name?: string;
		created_time?: string; // eslint-disable-line camelcase
	}) {
		// Sort by size
		photoData.images = _.sortBy(
			photoData.images,
			(image) => -image.width * image.height,
		);

		let title: string|null = null;
		if (photoData.name) {
			// only add title if at least 50% of it has latin characters
			const filteredTitle = filterText(
				photoData.name,
				ConfigModule.textEncodingSupport,
			);
			if (filteredTitle.length >= 0.5 * photoData.name.length) {
				title = filteredTitle;
			}
		}

		const objPhoto: ConvertedPhotoData = {
			source: this.network,
			externalId: String(photoData.id),
			full_url: photoData.images[0].source,
			full_width: photoData.images[0].width,
			full_height: photoData.images[0].height,
			photodate: photoData.created_time ? moment(photoData.created_time).format('X') : null,
			title,
			thumb_url: photoData.picture || photoData.thumbnail,
		};

		// Find preview image that is closest to our preferred resolution
		_.find(
			photoData.images,
			(image) => {
				if (image.width < ConfigModule.photoPreviewSize && image.height < ConfigModule.photoPreviewSize) {
					_.extend(
						objPhoto,
						{
							url: image.source,
							width: image.width,
							height: image.height,
						},
					);
					return true;
				}
				return false;
			},
		);

		return objPhoto;
	}

	public getFileType() {
		throw new Error('Bridge function not implemented');
	}

	public share(options: {
		link?: string;
		hashtag?: string;
		text?: string;
	}): Promise<void> {
		const defaults = {
			hashtag: `#${store.state.config['app.name']}`,
			text: '',
		};
		options = _.extend(
			defaults,
			options,
		);

		return new Promise((resolve, reject) => {
			const params: fb.ShareDialogParams = {
				method: 'share',
				href: options.link || window.location.href,
			};
			/* if (options.text && options.text.length) {
				params.quote = options.text;
			}
			if (options.hashtag) {
				params.hashtag = options.hashtag;
			} */

			window.FB.ui(
				params,
				(response) => {
				// @ts-ignore: error_message is a valid property of response
					if (response && !response.error_message) {
						resolve();
					} else {
						reject(new Error('Failed sharing'));
					}
				},
			);
		});
	}
}

export default FacebookClass;
