<template>
	<div
		class="vectorizationView"
	>
		<div class="mainHeader">
			{{ $t('views.vectorization.mainHeader') }}
		</div>
		<div class="subHeader">
			{{ $t('views.vectorization.subHeader') }}
		</div>
		<SpinnerComponent :size="64" />
		<ProgressBar
			:progress-percentage="progressPercentage"
			:text="$t('views.vectorization.converting')"
		/>
	</div>
</template>

<style lang="less" scoped>
@import '~lessVars';
@import '~root/css/components/animation.less';

.vectorizationView {
	float: left;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center;
	padding: 25px;
	font-size: @fontSize_XS2;
}
.mainHeader {
	float: left;
	width: 100%;
	font-size: @fontSize_S;
	font-family: @fontFamilyBold;
	font-weight: @fontWeightBold;

	& when(@brand="Hema") {
		text-transform: lowercase;
	}
}
.subHeader {
	float: left;
	width: 100%;
	font-size: @fontSize_XS;
}
</style>
