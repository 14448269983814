import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';

const addressModel: DB.AddressModel = {
	address1: '',
	address2: null,
	city: '',
	country: null,
	email: null,
	id: null,
	name: '',
	newsletter: 0,
	phone: null,
	state: null,
	userid: null,
	zipcode: '',
};

const objectModel: RequiredExceptFor<PI.PageObjectModel, 'id'> = {
	// private properties (state management)
	_borderimage: null,
	_canvas: null,
	_caretLine: 0,
	_crop: false,
	_error: 0,
	_hovered: false,
	_image: null,
	_loading: false,
	_mask: null,
	_previousState: null,
	_processing: false,
	_resetImage: false,
	_rollback: false,
	_selected: false,
	_selectedForEdition: false,
	_selectionEnd: -1,
	_selectionStart: -1,
	_vectorColors: null,
	_vectorSVG: null,
	// shared object properties
	bordercolor: null,
	borderwidth: 0,
	cropheight: 0,
	cropx: 0,
	cropy: 0,
	cropwidth: 0,
	editable: 1,
	fillMethod: 'cover',
	flip: 0,
	flop: 0,
	height: 0,
	maxwidth: 0,
	maxheight: 0,
	opacity: 0,
	rotate: 0,
	templatestateid: null,
	type: 'photo',
	transformable: 1,
	width: 0,
	x_axis: 0,
	y_axis: 0,
	z_axis: 1,
	// photo properties
	colorReplacement: null,
	effect: null,
	ext: 'jpg',
	filename: null,
	mask: null,
	photoid: null,
	source: null,
	title: null,
	// text properties
	align: 'Center',
	bgcolor: null,
	fontbold: 0,
	fontcolor: null,
	fontface: null,
	fontitalic: 0,
	pointsize: 24,
	text: '',
	text_formatted: '',
	text_formatted_for_canvas: '',
	text_svg: '',
};

const pageModel: RequiredExceptFor<PI.PageModel, 'id'> = {
	_bgimage: null,
	_bgpattern: null,
	_cropped: false,
	bgcolor: '#FFFFFF',
	bgimage: null,
	bgpattern: null,
	customLayout: false,
	editable: 0,
	height: 0,
	movable: 0,
	objectList: [],
	offeringId: undefined,
	offset: 0,
	orientation: 'l',
	preview: null,
	quantity: 1,
	scaling: 0,
	template: null,
	templateSetId: null,
	thumbnail: null,
	width: 0,
};

const productStateModel: PI.ProductStateModel = {
	_activePage: null,
	_bookmarks: {
		left: 0,
		right: 1,
	},
	_historyIndex: -1,
	_loadingMask: false,
	_loadingOverlay: false,
	_mask: null,
	_overlay: null,
	_saved: false,
	_snapshots: [],
	address: null,
	customAttributes: undefined,
	objects: {},
	pageList: [],
	pages: {},
	photoList: [],
	productAttributes: [],
	productSettings: {
		autoFillMethod: 'photodate',
		bgcolor: null,
		bgpattern: null,
		faceDetect: true,
		childOfferingTypeId: null,
		childOfferingVariantId: null,
		startWithOfferingOptionUI: null,
	},
	productId: null,
	version: null,
};

const pointsize = 20;

export default {
	addressModel,
	objectModel,
	pageModel,
	productStateModel,
	pointsize,
};
