import * as urlTools from '@sosocio/frontend-utils/url';
import {
	AxiosRequestConfig,
	AxiosResponse,
} from 'axios';
import ProductStateClass from 'classes/productstate';
import { DEFAULT_DYNAMIC_TEMPLATE_ID } from 'classes/template';
import TemplatePosition from 'classes/templateposition';
import EventBus from 'components/event-bus';
import ajax from 'controllers/ajax';
import connector from 'controllers/connector';
import upload from 'controllers/upload';
import equal from 'deep-equal';
import merge from 'deepmerge';
import * as API from 'interfaces/api';
import {
	AddObjectModel,
	AddPageObjectModel,
	AddPageObjectModelWithNoStore,
	AddPageObjectModelWithoutNoStore,
	AjaxOptions,
	ChangePageObjectModel,
	ChangePageObjectModelWithIgnorePointerSizeResize,
	ChangePageObjectModelWithNoStore,
	DeselectePageObjectsModel,
	FontModel,
	PageObjectModelChangeResult,
	TemplateSet,
} from 'interfaces/app';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import removePhotoObjects from 'mutations/product/remove-photo-objects';
import { nanoid } from 'nanoid';
import loadImage from 'services/load-image';
import {
	ERRORS_INVALID_REQUEST_DATA,
	ERRORS_INVALID_RETURN_DATA,
} from 'settings/errors';
import {
	MIME_TYPES_SHOWN_AS_PNG,
	MIME_TYPES_SVG,
} from 'settings/filetypes';
import { OfferingGroups } from 'settings/offerings';
import {
	COVER_BACK,
	COVER_BACK_INSIDE,
	COVER_FRONT,
	COVER_FRONT_INSIDE,
} from 'settings/values';
import {
	AppDataModule,
	AppStateModule,
	ConfigModule,
	FontModule,
	PhotosModule,
	ProductsModule,
	ThemeDataModule,
	ThemeStateModule,
	UploadModule,
} from 'store';
import { project as projectTools } from 'tools';
import detectBrowser from 'tools/detect-browser';
import getRandomString from 'tools/get-random-string';
import isValidCSSColor from 'tools/is-valid-css-color';
import maxObjectSize from 'tools/max-object-size';
import objDiff from 'tools/object-diff';
import _ from 'underscore';
import { reactive } from 'vue';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';
import defaults from './defaults';
import resizeObjectText from './helpers/resize-text';
import * as svgTextHelper from './helpers/svg-text';

function getPhotoSize(data: Partial<PI.PhotoModel>): Promise<Partial<PI.PhotoModel>> {
	if (data.source == 'dropbox'
		&& data.full_url
		&& data.full_url.charAt(0) === '/'
	) {
		if (!connector.networks.dropbox.bridge.getFullUrl) {
			throw new Error('Missing required bridge function getFullUrl');
		}

		return connector.networks.dropbox.bridge.getFullUrl(data.full_url)
			.then((URL) => loadImage(URL))
			.catch(() => false)
			.then((returnData) => {
				if (typeof returnData === 'object') {
					data.full_width = returnData.image.width;
					data.full_height = returnData.image.height;
				}
				return data;
			});
	}

	if (data.full_width
		&& data.full_height
	) {
		return Promise.resolve(data);
	}

	if (data._localRef) {
		const localFile = upload.getLocalFile(
			data._localRef,
		);

		if (localFile) {
			return loadImage(localFile)
				.catch(() => false)
				.then((returnData) => {
					if (typeof returnData === 'object') {
						data.full_width = returnData.image.width;
						data.full_height = returnData.image.height;
					}
					return data;
				});
		}
	}

	if (typeof data.full_url === 'string'
		&& data.full_url.length
	) {
		return loadImage(data.full_url)
			.catch(() => false)
			.then((returnData) => {
				if (typeof returnData === 'object') {
					data.full_width = returnData.image.width;
					data.full_height = returnData.image.height;
				}
				return data;
			});
	}

	return Promise.resolve(data);
}

@Module({ namespaced: true, name: 'productstate' })
export default class ProductState extends VuexModule implements PI.ProductStateModel {
	// Reference to the page currently being edited
	_activePage: string | null = null;

	// Bookmarks for page indexes shown in the product preview
	_bookmarks = reactive<PI.BookmarksModel>({
		left: 0,
		right: 1,
	});

	// Marker indicating the current position in the _snapshots array
	_historyIndex = -1;

	// Maximum number of historic snapshots
	_historyLimit = 20;

	// Flag to indicate if the mask is loading
	_loadingMask = false;

	/* Flag to indicate if the offering frame image is loading */
	_loadingOfferingFrame = false;

	// Flag to indicate if the overlay is loading
	_loadingOverlay = false;

	// Store the loaded overlay image for the product
	_mask: HTMLImageElement | null = null;

	// Store the loaded overlay image for the product
	_overlay: HTMLImageElement | null = null;

	// Flag to indicate if the product has been updated since the last save
	_saved: boolean | null = false;

	_snapshots = reactive<string[]>([]);

	address: PI.AddressModel | null = null;

	customAttributes: PI.ProductDataModel['customAttributes'] = undefined;

	objects = reactive<Record<string, PI.PageObjectModel>>({});

	pageList = reactive<string[]>([]);

	pages = reactive<Record<string, PI.PageModel>>({});

	photoList = reactive<PI.PhotoModel['id'][]>([]);

	productAttributes = reactive<PI.ProductAttributeModel[]>([]);

	productSettings = reactive<PI.ProductSettings>({
		autoFillMethod: 'photodate',
		bgcolor: null,
		bgpattern: null,
		childOfferingTypeId: null,
		childOfferingVariantId: null,
		faceDetect: true,
		startWithOfferingOptionUI: null,
	});

	productTours = reactive<PI.ProductDataModel['productTours']>({});

	productId: number | null = null;

	version: number | null = null;

	public get findPageFromObject() {
		return (objectModelId: PI.PageObjectModel['id']): PI.PageModel | undefined => this.getPages.find(
			(model) => model.objectList && model.objectList.indexOf(objectModelId) >= 0,
		);
	}

	public get findPhoto() {
		return (modelData: Partial<PI.PhotoModel>): PI.PhotoModel | null => {
			if (modelData.id) {
				if (this.hasSavedPhoto(modelData.id)) {
					return this.context.rootGetters['photos/getById'](modelData.id);
				}
			} else if (modelData.source && modelData.externalId) {
				return this.context.rootGetters['photos/findWhere']({
					source: modelData.source,
					externalId: String(modelData.externalId),
				});
			}
			return null;
		};
	}

	public get getActivePage() {
		return this._activePage
			? this.pages[this._activePage] || undefined
			: undefined;
	}

	public get getAddress() {
		return this.address;
	}

	public get getAttribute() {
		return (propertyName: string) => _.findWhere(
			this.productAttributes,
			{ property: propertyName },
		);
	}

	public get getAttributes() {
		return this.productAttributes;
	}

	public get getBookmarks() {
		return this._bookmarks;
	}

	public get getChildOfferingId() {
		if (!this.getOffering) {
			return undefined;
		}

		if (this.productSettings.childOfferingTypeId === 'bestFit'
			|| !this.productSettings.childOfferingTypeId
			|| !this.productSettings.childOfferingVariantId
		) {
			return undefined;
		}

		const offeringId = parseInt(
			`${this.getOffering.groupid}${this.productSettings.childOfferingTypeId}${this.productSettings.childOfferingVariantId || 1}`,
			10,
		);
		return offeringId;
	}

	public get getCoverPage() {
		const productModel = this.getProduct;

		let pageIndex = 1;
		if (productModel) {
			if (OfferingGroups(
				productModel.group,
				['PhotoFrameBox'],
			)) {
				pageIndex = 0;
			} else if (OfferingGroups(
				productModel.group,
				['PrintTypes'],
			)) {
				const offeringModel = this.context.rootGetters['appdata/findOfferingWhere']({
					groupid: productModel.group,
					typeid: productModel.typeid,
				});
				pageIndex = offeringModel.hasback ? 1 : 0;
			} else if (OfferingGroups(
				productModel.group,
				['BasicProducts'],
			)) {
				pageIndex = 0;
			}
		}

		return this.getPageByNumber(pageIndex);
	}

	public get getCroppedPages() {
		const pageModels = _.where(
			this.getPages,
			{ editable: 1 },
		);
		const cropThreshold = this.context.rootState.config['cropModule.threshold'];

		return pageModels.filter(
			(pageModel) => {
				const croppedPhotoObjects = this.getPageObjects(pageModel).filter(
					(pageObjectModel) => pageObjectModel.type === 'photo'
						&& (
							pageObjectModel.cropwidth < cropThreshold * pageObjectModel.maxwidth
							|| pageObjectModel.cropheight < cropThreshold * pageObjectModel.maxheight
						),
				);

				return croppedPhotoObjects.length;
			},
		);
	}

	public get getData(): PI.ProductDataModel {
		const data: PI.ProductDataModel = JSON.parse(JSON.stringify(this.context.state));
		const productModel = this.getProduct;

		if (productModel) {
			const productData: DB.ProductModel = JSON.parse(JSON.stringify(productModel));

			// Set filtered product properties to data
			const unwantedProductProperties: Array<keyof DB.ProductModel> = [
				'deleted',
				'graphinstance',
				'height',
				'shared',
				'thumbnail',
				'userid',
				'width',
			];
			const dataProductKeys = Object.keys(productData) as (keyof PI.ProductDataModel['product'])[];

			data.product = dataProductKeys.reduce(
				(newProductData, key) => {
					if (unwantedProductProperties.includes(key)) {
						return newProductData;
					}

					return {
						...newProductData,
						[key]: productData[key],
					};
				},
				{} as DB.ProductModel,
			);
		}

		// Filter top level properties
		const topLevelKeys = Object.keys(data) as (keyof PI.ProductDataModel)[];

		// eslint-disable-next-line no-restricted-syntax
		for (const key of topLevelKeys) {
			if (key.charAt(0) === '_') {
				delete data[key];
			}
		}

		// Filter page properties
		const pages = Object.values(data.pages);

		// eslint-disable-next-line no-restricted-syntax
		for (const pageModel of pages) {
			const pageLevelKeys = Object.keys(pageModel) as (keyof PI.PageModel)[];

			// eslint-disable-next-line no-restricted-syntax
			for (const key of pageLevelKeys) {
				if (key.charAt(0) === '_') {
					delete pageModel[key];
				}
			}
		}

		// Filter object properties
		const objects = Object.values(data.objects);

		// eslint-disable-next-line no-restricted-syntax
		for (const objectModel of objects) {
			const objectLevelKeys = Object.keys(objectModel) as (keyof PI.PageObjectModel)[];

			// eslint-disable-next-line no-restricted-syntax
			for (const key of objectLevelKeys) {
				if (key.charAt(0) === '_') {
					delete objectModel[key];
				}
			}
		}

		return data;
	}

	public get getDataForSnapshot() {
		const data = this.getData;

		// Filter out unsaved photo models
		data.photoList = data.photoList.filter(
			(id) => typeof id !== 'string',
		);

		// Filter out object models that are linked to unsaved photo models
		const objects: Record<string, any> = {};
		Object.values(data.objects).forEach((objectModel) => {
			if (!objectModel.photoid
				|| data.photoList.indexOf(objectModel.photoid) >= 0
			) {
				objects[objectModel.id] = objectModel;
			}
		});
		data.objects = objects;

		// Filter out unsaved object models from the page models
		data.pageList.forEach((pageId) => {
			const pageData = data.pages[pageId];
			if (pageData.objectList) {
				data.pages[pageId].objectList = pageData.objectList.filter(
					(objectId) => data.objects.hasOwnProperty(objectId),
				);
			}
		});

		// Store used software versions and platforms in project meta data
		if (!data.metaData) {
			data.metaData = {
				softwareWebVersions: [],
				softwarePlatforms: [],
			};
		}
		if (!data.metaData.softwareWebVersions.includes(VERSION)) {
			data.metaData.softwareWebVersions.push(VERSION);
		}

		let softwarePlatform;
		if (window.glPlatform === 'web') {
			softwarePlatform = JSON.stringify(detectBrowser());
		} else if (window.glPlatform === 'native') {
			const deviceDetails = _.omit(
				window.nativeDeviceDetails,
				'deviceUUID',
			);
			softwarePlatform = JSON.stringify(deviceDetails);
		}
		if (softwarePlatform
			&& !data.metaData.softwarePlatforms.includes(softwarePlatform)
		) {
			data.metaData.softwarePlatforms.push(softwarePlatform);
		}

		// Strip html entities from text svg nodes - we can't use htmlentities in the PDF generator
		Object.keys(data.objects).forEach((objectKey) => {
			const svg = data.objects[objectKey].text_svg;
			if (svg) {
				data.objects[objectKey].text_svg = svgTextHelper.stripHtmlEntities(svg);
			}
		});

		return data;
	}

	public get getEditablePages(): PI.PageModel[] {
		return this.getPages.filter(
			(pageModel) => pageModel.editable,
		);
	}

	public get getOffering(): DB.OfferingModel | null {
		const productModel = this.getProduct;

		if (productModel) {
			const searchProps: Partial<DB.OfferingModel> = {
				groupid: productModel.group,
				typeid: productModel.typeid,
			};

			if (productModel.variantid) {
				searchProps.variantid = productModel.variantid;
			}

			const offeringModel = AppDataModule.findOfferingWhere(searchProps);

			return offeringModel || null;
		}

		return null;
	}

	public get getMaskImage() {
		return this._mask;
	}

	public get getObjects(): PI.PageObjectModel[] {
		const models: PI.PageObjectModel[] = [];

		if (typeof this.objects === 'object') {
			const objectModels = Object.values(this.objects);

			// eslint-disable-next-line no-restricted-syntax
			for (const objectModel of objectModels) {
				if (objectModel) {
					models.push(objectModel);
				}
			}
		}

		return models.sort(
			(a, b) => a.z_axis - b.z_axis,
		);
	}

	public get getOverlayImage() {
		return this._overlay;
	}

	public get getPageCount(): number | null {
		const offeringModel = this.getOffering;

		if (offeringModel) {
			if (
				OfferingGroups(
					offeringModel.groupid,
					['BookTypes'],
				)
			) {
				// We extract the front- and back cover pages
				return this.getPages.length - 2;
			}

			if (!OfferingGroups(
				offeringModel.groupid,
				['BasicProducts'],
			)) {
				return this.getEditablePages.length;
			}

			return this.getPages.length;
		}

		return null;
	}

	public get getPage() {
		return (id: PI.PageModel['id']) => this.pages[id];
	}

	public get getPageByNumber() {
		return (pageIndex: number): PI.PageModel | undefined => {
			const pageId = this.pageList[pageIndex];

			if (pageId) {
				return this.getPage(pageId);
			}

			return undefined;
		};
	}

	public get getPageIndex(): (pageModel: OptionalExceptFor<PI.PageModel, 'id'>) => number {
		return (pageModel) => this.pageList.indexOf(pageModel.id);
	}

	public get getPageLabel(): (
		pageModel: OptionalExceptFor<PI.PageModel, 'id'>,
		pageIndex?: number,
		offeringModel?: OptionalExceptFor<DB.OfferingModel, 'groupid'> | null,
		leadingZero?: boolean,
	) => number | string | null {
		return (
			pageModel,
			pageIndex = this.getPageIndex(pageModel),
			offeringModel = this.getOffering,
			leadingZero = true,
		) => {
			if (offeringModel) {
				if (this.isMultiSidedProduct) {
					if (pageIndex === 0) {
						return window.App.router.$i18next.t('pageFront') as string;
					}
					if (pageIndex === 1) {
						return window.App.router.$i18next.t('pageBack') as string;
					}
				}
				if (
					projectTools.isPageCoverFront(
						offeringModel,
						pageModel.id,
						pageIndex,
					)
				) {
					return window.App.router.$i18next.t('cover_front') as string;
				}

				if (
					projectTools.isPageCoverInside(
						offeringModel,
						pageModel.id,
					)
				) {
					return window.App.router.$i18next.t('coverInside') as string;
				}

				if (
					projectTools.isPageCoverBack(
						offeringModel,
						pageModel.id,
						pageIndex,
					)
				) {
					return window.App.router.$i18next.t('cover_back') as string;
				}

				if (
					OfferingGroups(
						offeringModel.groupid,
						'CardGame',
					)
				) {
					return pageIndex;
				}

				if (
					OfferingGroups(
						offeringModel.groupid,
						'BookTypes',
					)
				) {
					const pageLabel = pageIndex - 1;

					if (
						pageLabel < 10
						&& leadingZero
					) {
						return `0${pageLabel}`;
					}

					return pageLabel;
				}

				if (
					OfferingGroups(
						offeringModel.groupid,
						'Cards',
					)
				) {
					return pageIndex + 1;
				}

				if (
					OfferingGroups(
						offeringModel.groupid,
						'DoubleSidePrints',
					)
				) {
					const firstPage = this.getPages[0];

					return (pageIndex + 1) / (!firstPage.editable ? 2 : 1);
				}

				if (
					OfferingGroups(
						offeringModel.groupid,
						'PrintTypes',
					)
				) {
					const firstPage = this.getPages[0];
					const pageLabel = (pageIndex + 1) / (offeringModel.hasback && !firstPage.editable ? 2 : 1);

					if (
						pageLabel < 10
						&& leadingZero
					) {
						return `0${pageLabel}`;
					}

					return pageLabel;
				}
			}

			return null;
		};
	}

	public get getPageMaxZ() {
		return (pageModel: PI.PageModel) => {
			const objectModels = this.getPageObjects(pageModel);
			return _.reduce(
				objectModels,
				(memo, objectModel) => (objectModel.editable ? Math.max(
					objectModel.z_axis,
					memo,
				) : memo),
				1,
			);
		};
	}

	public get getPageObject() {
		return (id: PI.PageObjectModel['id']) => this.objects[id];
	}

	public get getPageObjects() {
		return (pageModel: PI.PageModel) => {
			const models: PI.PageObjectModel[] = [];

			if (
				pageModel.objectList
				&& Array.isArray(pageModel.objectList)
			) {
				// eslint-disable-next-line no-restricted-syntax
				for (const objectId of pageModel.objectList) {
					const objectModel = this.objects[objectId];

					if (objectModel) {
						models.push(objectModel);
					}
				}
			}

			return models.sort((a, b) => a.z_axis - b.z_axis);
		};
	}

	public get getPagePhotos(): (pageModel: PI.PageModel) => PI.PhotoModel[] {
		return (pageModel) => {
			const objectModels = this.getPageObjects(pageModel);
			const photoModels = objectModels
				.reduce(
					(photoModelsArray, objectModel) => {
						if (objectModel.photoid) {
							const photoModel = PhotosModule.getById(objectModel.photoid);

							if (
								photoModel
								&& !photoModelsArray.includes(photoModel)
							) {
								photoModelsArray.push(photoModel);
							}
						}

						return photoModelsArray;
					},
					[] as PI.PhotoModel[],
				);

			return photoModels;
		};
	}

	public get getPages() {
		return _.compact(
			this.pageList.map((pageId) => this.pages[pageId]),
		);
	}

	private get getLinkedOfferingOptionValueModel() {
		return (
			offeringOptionId: DB.OfferingOptionModel['id'],
			offeringId: DB.OfferingModel['id'],
		): DB.OfferingOptionValueModel | undefined => {
			// Get all the possible values for the coverColor offering option
			const valueModels = AppDataModule.offeringoptionvalues.filter(
				(m) => m.offeringoptionid === offeringOptionId,
			);

			// Get the ids of the possible values for the coverColor offering option
			const valueModelIds = valueModels.map(
				(valueModel) => valueModel.id,
			);

			// Find the link model that links the selected value for the coverColor to the current offering
			const linkModel = AppDataModule.offeringoptionvalueofferinglinks.find(
				(m) => m.offeringid === offeringId && valueModelIds.includes(m.offeringoptionvalueid),
			);

			// Find the value model that is linked to the current offering
			return valueModels.find(
				(m) => m.id === linkModel?.offeringoptionvalueid,
			);
		};
	}

	/**
	 * Getter to get the pages in the product for viewing
	 * This manipulates the original pages array in the project based on offering properties
	 */
	public get getPagesForViewing() {
		const offeringModel = this.getOffering;
		if (!offeringModel) {
			return [];
		}

		const pageModels = offeringModel.hasback
			? this.getPages.slice(0).filter(
				(pageModel) => pageModel.editable === 1,
			) : this.getPages.slice(0);

		let coverBackgroundColor = '#FFFFFF';
		let coverBackgroundPattern = null;
		let frontCoverImage = null;
		let insideFrontCoverImage = null;
		let backCoverImage = null;
		let insideBackCoverImage = null;

		// Check to see if there's a cover color and/or pattern defined for this offering
		const coverColorOptionModel = AppDataModule.getOfferingOptionModels(
			offeringModel.id,
		).find(
			(optionModel) => optionModel.tag === 'cover-color',
		);
		if (coverColorOptionModel) {
			const valueModel = this.getLinkedOfferingOptionValueModel(
				coverColorOptionModel.id,
				offeringModel.id,
			);
			// We can read the requested bgcolor from the tag property of the value model
			if (valueModel?.tag
				&& isValidCSSColor(valueModel.tag)
			) {
				coverBackgroundColor = valueModel.tag;
			}
			if (valueModel?.image
				&& valueModel.image.substring(
					0,
					4,
				) === 'http'
			) {
				coverBackgroundPattern = valueModel.image;
			}
		}

		// Check to see if there's a front cover image defined for this offering
		const frontCoverImageOptionModel = AppDataModule.getOfferingOptionModels(
			offeringModel.id,
		).find(
			(optionModel) => optionModel.tag === 'front-cover-image',
		);
		if (frontCoverImageOptionModel) {
			const valueModel = this.getLinkedOfferingOptionValueModel(
				frontCoverImageOptionModel.id,
				offeringModel.id,
			);
			if (valueModel?.image
				&& valueModel.image.substring(
					0,
					4,
				) === 'http'
			) {
				frontCoverImage = valueModel.image;
			}
		}

		// Check to see if there's a inside front cover image defined for this offering
		const insideFrontCoverImageOptionModel = AppDataModule.getOfferingOptionModels(
			offeringModel.id,
		).find(
			(optionModel) => optionModel.tag === 'inside-front-cover-image',
		);
		if (insideFrontCoverImageOptionModel) {
			const valueModel = this.getLinkedOfferingOptionValueModel(
				insideFrontCoverImageOptionModel.id,
				offeringModel.id,
			);
			if (valueModel?.image
				&& valueModel.image.substring(
					0,
					4,
				) === 'http'
			) {
				insideFrontCoverImage = valueModel.image;
			}
		}

		// Check to see if there's a back cover image defined for this offering
		const backCoverImageOptionModel = AppDataModule.getOfferingOptionModels(
			offeringModel.id,
		).find(
			(optionModel) => optionModel.tag === 'back-cover-image',
		);
		if (backCoverImageOptionModel) {
			const valueModel = this.getLinkedOfferingOptionValueModel(
				backCoverImageOptionModel.id,
				offeringModel.id,
			);
			if (valueModel?.image
				&& valueModel.image.substring(
					0,
					4,
				) === 'http'
			) {
				backCoverImage = valueModel.image;
			}
		}

		// Check to see if there's a inside back cover image defined for this offering
		const insideBackCoverImageOptionModel = AppDataModule.getOfferingOptionModels(
			offeringModel.id,
		).find(
			(optionModel) => optionModel.tag === 'inside-back-cover-image',
		);
		if (insideBackCoverImageOptionModel) {
			const valueModel = this.getLinkedOfferingOptionValueModel(
				insideBackCoverImageOptionModel.id,
				offeringModel.id,
			);
			if (valueModel?.image
				&& valueModel.image.substring(
					0,
					4,
				) === 'http'
			) {
				insideBackCoverImage = valueModel.image;
			}
		}

		if (offeringModel.fixedcover) {
			// Replace the back cover page with a virtual one that shows the fixed cover
			pageModels.splice(
				0,
				1,
				{
					id: COVER_BACK,
					width: offeringModel.width,
					height: offeringModel.height,
					offset: offeringModel.bleedmargin,
					bgcolor: coverBackgroundColor,
					bgimage: backCoverImage,
					bgpattern: coverBackgroundPattern,
					editable: 0,
					movable: 0,
					quantity: 1,
					scaling: 1,
					template: null,
					thumbnail: null,
					preview: null,
				},
			);

			// Replace the front cover page with a virtual one that shows the fixed cover
			pageModels.splice(
				1,
				1,
				{
					id: COVER_FRONT,
					width: offeringModel.width,
					height: offeringModel.height,
					offset: offeringModel.bleedmargin,
					bgcolor: coverBackgroundColor,
					bgimage: frontCoverImage,
					bgpattern: coverBackgroundPattern,
					editable: 0,
					movable: 0,
					quantity: 1,
					scaling: 1,
					template: null,
					thumbnail: null,
					preview: null,
				},
			);
		}

		if (offeringModel.startright
			&& pageModels.length
		) {
			// Add a virtual page for the inside of the front cover
			pageModels.splice(
				2,
				0,
				{
					id: COVER_FRONT_INSIDE,
					width: offeringModel.width,
					height: offeringModel.height,
					offset: offeringModel.bleedmargin,
					bgcolor: coverBackgroundColor,
					bgimage: insideFrontCoverImage,
					bgpattern: coverBackgroundPattern,
					editable: 0,
					movable: 0,
					quantity: 1,
					scaling: 1,
					template: null,
					thumbnail: null,
					preview: null,
				},
			);

			// Add a virtual page for the inside of the back cover
			pageModels.push({
				id: COVER_BACK_INSIDE,
				width: offeringModel.width,
				height: offeringModel.height,
				offset: offeringModel.bleedmargin,
				bgcolor: coverBackgroundColor,
				bgimage: insideBackCoverImage,
				bgpattern: coverBackgroundPattern,
				editable: 0,
				movable: 0,
				quantity: 1,
				scaling: 1,
				template: null,
				thumbnail: null,
				preview: null,
			});
		}

		if (offeringModel
			&& OfferingGroups(
				offeringModel.groupid,
				['PhotoFrameBox', 'CardGame'],
			)
		) {
			let x = 0;
			if (offeringModel
				&& OfferingGroups(
					offeringModel.groupid,
					['PhotoFrameBox'],
				)
			) {
				x = 1;
			}

			return pageModels.filter(
				(pageModel, i) => i > x,
			);
		}

		return pageModels;
	}

	/**
	 * Getter used to check if the user did not leave the edges of the canvas empty
	 * This is usually by accident (after moving an object) and can lead to returned printed products
	 * This check is used for one-pager products, mostly wall decoration
	 */
	public get getPageMarginCheck() {
		return (pageModel: PI.PageModel): boolean => {
			const pageObjects = this.getPageObjects(pageModel);
			const photoObjects = pageObjects.filter((pageObject) => pageObject.type === 'photo');

			if (photoObjects.length != 1) {
				return true;
			}

			const photoObjectModel = photoObjects[0];
			const objectSurfaceX1 = Math.max(
				0,
				photoObjectModel.x_axis,
			);
			const objectSurfaceX2 = Math.min(
				pageModel.width,
				photoObjectModel.x_axis + photoObjectModel.width,
			);
			const objectSurfaceY1 = Math.max(
				0,
				photoObjectModel.y_axis,
			);
			const objectSurfaceY2 = Math.min(
				pageModel.height,
				photoObjectModel.y_axis + photoObjectModel.height,
			);
			const objectSurfaceWidth = Math.abs(objectSurfaceX2 - objectSurfaceX1);
			const objectSurfaceHeight = Math.abs(objectSurfaceY2 - objectSurfaceY1);
			const objectSurfaceSize = objectSurfaceWidth * objectSurfaceHeight;
			const pageSurfaceSize = pageModel.width * pageModel.height;

			return (
				objectSurfaceSize < (ConfigModule['pageMargin.warningThreshold'] * pageSurfaceSize)
				|| objectSurfaceSize >= pageSurfaceSize
			);
		};
	}

	/**
	 * Getter used to check if one or more objects on the page or in the project will be
	 * printed bigger than the recommended solution
	 */
	public get getPageQuality() {
		return (pageModel?: PI.PageModel): 'high' | 'low' => {
			const objectModels = pageModel
				? this.getPageObjects(pageModel)
				: this.getObjects;
			const lowQualityObject = objectModels.find(
				(pageObjectModel) => {
					if (pageObjectModel.type != 'photo' || !pageObjectModel.photoid) {
						return false;
					}

					const photoModel = pageObjectModel.photoid
						? PhotosModule.getById(pageObjectModel.photoid)
						: undefined;
					const photoData = photoModel
						? {
							width: photoModel.full_width,
							height: photoModel.full_height,
						}
						: undefined;
					const maxsize = maxObjectSize(
						{
							maxwidth: pageObjectModel.maxwidth,
							maxheight: pageObjectModel.maxheight,
							cropwidth: pageObjectModel.cropwidth,
							cropheight: pageObjectModel.cropheight,
							type: pageObjectModel.type,
						},
						photoData,
					);
					if (Math.round(pageObjectModel.width) > Math.round(maxsize.croppedQualityWidth)
						|| Math.round(pageObjectModel.height) > Math.round(maxsize.croppedQualityHeight)
					) {
						return true;
					}

					return false;
				},
			);

			return lowQualityObject
				? 'low'
				: 'high';
		};
	}

	public get getPagesQuantity() {
		const pageModels = this.getPages;

		return _.reduce(
			pageModels,
			(memo, pageModel) => memo + pageModel.quantity,
			0,
		);
	}

	public get getPhotosSelected(): PI.PhotoModel[] {
		return this.photoList
			.map((photoId) => PhotosModule.getById(photoId))
			.filter((item): item is PI.PhotoModel => !!item);
	}

	public get getPhotosFailed() {
		return this.getPhotosSelected.filter((photoModel) => !!photoModel._error);
	}

	public get getPhotosQueued(): PI.PhotoModel[] {
		return this.getPhotosSelected.filter(
			(photoModel) => (
				typeof photoModel.id === 'string'
				&& !photoModel._error
			),
		);
	}

	public get getProduct(): DB.ProductModel | null {
		if (this.productId) {
			return ProductsModule.getById(this.productId) || null;
		}

		return null;
	}

	public get getProductId(): DB.ProductModel['id'] | null {
		return this.productId;
	}

	public get getProductSettings() {
		return this.productSettings;
	}

	public get getProductTour() {
		return (name: DB.ProductTours) => this.productTours.hasOwnProperty(name) && this.productTours[name];
	}

	public get getSelectedPageObject(): PI.PageObjectModel | undefined {
		const pageModel = this.getActivePage;

		if (pageModel) {
			const models = this.getPageObjects(pageModel);

			return models.find((model) => model._selected);
		}

		return undefined;
	}

	public get getSelectedPageObjectForEdition(): PI.PageObjectModel | undefined {
		const pageModel = this.getActivePage;

		if (pageModel) {
			const models = this.getPageObjects(pageModel);

			return models.find((model) => model._selectedForEdition);
		}

		return undefined;
	}

	public get getPageTemplate() {
		return (pageModel: PI.PageModel) => {
			const templateId = pageModel.template;
			return templateId
				? ThemeDataModule.getTemplate(templateId)
				: null;
		};
	}

	public get getPageTemplateSet() {
		return (
			pageModel: PI.PageModel,
			templateSetId?: string,
			photoModels?: PI.PhotoModel[],
		): {
			templateSet?: TemplateSet,
			photoModels?: PI.PhotoModel[],
		} => {
			if (!pageModel.template) {
				return {};
			}

			const templateModel = ThemeDataModule.getTemplate(
				pageModel.template,
			);
			if (!templateModel) {
				return {};
			}

			if (templateSetId) {
				const templateSet = ThemeStateModule.getTemplateSetById(
					templateModel.id,
					templateSetId,
					{
						marginAroundEdge: pageModel.templateMarginAroundEdge,
						marginBetweenPositions: pageModel.templateMarginBetweenPositions,
					},
				);

				if (templateSet) {
					return {
						templateSet,
					};
				}
			}

			return ThemeStateModule.getTemplateSet(
				pageModel.template,
				photoModels,
				{
					marginAroundEdge: pageModel.templateMarginAroundEdge,
					marginBetweenPositions: pageModel.templateMarginBetweenPositions,
				},
			);
		};
	}

	public get getPageTemplatePositions() {
		return (
			pageModel: PI.PageModel,
			photoModels?: PI.PhotoModel[],
		): TemplateSet['positions'] => {
			const { templateSet } = this.getPageTemplateSet(
				pageModel,
				pageModel.templateSetId ?? DEFAULT_DYNAMIC_TEMPLATE_ID,
				photoModels || this.getPagePhotos(pageModel),
			);
			if (templateSet) {
				return templateSet.positions;
			}

			return [];
		};
	}

	public get getPageTemplatePositionsAvailable() {
		return (
			pageModel: PI.PageModel,
			photoModels?: PI.PhotoModel[],
		): TemplateSet['positions'] => {
			const positions = this.getPageTemplatePositions(
				pageModel,
				photoModels,
			);
			const objectModels = this.getPageObjects(
				pageModel,
			);

			return positions.filter(
				(positionModel) => TemplatePosition.getAvailability(
					positionModel,
					objectModels,
				),
			);
		};
	}

	public get getQRCodeForExternalUpload(): Promise<{ image: string, url: string }> {
		if (!this.productId) {
			throw new Error('No product id found');
		}

		return ajax.request(
			{
				url: `/api/product/${this.productId}/qr-upload`,
			},
			{
				auth: true,
			},
		).then((response) => {
			if (!response.data
				|| !response.data.image
				|| !response.data.url
			) {
				throw new Error('Failed to get QR code data');
			}

			return response.data;
		});
	}

	public get getSaved(): boolean | null {
		if (!this.productId) {
			return true;
		}

		return this.getPhotosQueued.length + this.getPhotosFailed.length === 0
			? this._saved // _saved can also be 'null'
			: false;
	}

	public get getUnusedPhotos(): PI.PhotoModel[] {
		// Build array of ids from photos already included in product
		const photoIdsUsed: (number | string)[] = [];
		this.getObjects.forEach((objectModel) => {
			if (objectModel.photoid
				&& !photoIdsUsed.includes(objectModel.photoid)
			) {
				photoIdsUsed.push(objectModel.photoid);
			}
		});

		// Build a (cloned) photo collection of all photos linked to this product that have not been included in the product yet
		const newPhotoCollection: PI.PhotoModel[] = [];
		this.getPhotosSelected.forEach((photoModel) => {
			if (!photoIdsUsed.includes(photoModel.id)) {
				newPhotoCollection.push(photoModel);
			}
		});

		return newPhotoCollection;
	}

	public get getThemeId(): DB.ThemeModel['id'] | undefined {
		return this.getProduct?.themeid;
	}

	public get hasFuture(): boolean {
		return this._snapshots.length - 1 > this._historyIndex;
	}

	public get hasHistory(): boolean {
		return this._historyIndex > 0;
	}

	public get hasPhoto() {
		return (modelData: Partial<PI.PhotoModel>) => {
			const photoModel = this.findPhoto(modelData);
			return photoModel
				? this.photoList.indexOf(photoModel.id) >= 0
				: false;
		};
	}

	public get hasSavedPhoto() {
		return (id: PI.PhotoModel['id']) => this.photoList.indexOf(id) >= 0;
	}

	/**
	 * Getter to check if this is a basic product that has multiple sides/positions to be edited
	 * (for instance, front and back of a t-shirt)
	 *
	 * @returns {boolean} Returns true if the product is multi sided
	*/
	public get isMultiSidedProduct(): boolean {
		if (this.getOffering
			&& OfferingGroups(
				this.getOffering.groupid,
				['BasicProducts'],
			)
		) {
			return this.getOffering.minpages > 1;
		}

		return false;
	}

	@Mutation
	private _addAddress(data: DB.AddressModel): void {
		this.address = reactive(data);
		this._saved = false;
	}

	@Mutation
	private _addAttribute(data: PI.ProductAttributeModel): void {
		this.productAttributes.push(data);
		this._saved = false;
	}

	@Mutation
	private _addObject({
		data,
		pageId,
	}: {
		data: PI.PageObjectModel;
		pageId?: PI.PageModel['id'];
	}): void {
		if (data.id) {
			this.objects[data.id] = data;

			if (pageId) {
				this.pages[pageId]?.objectList?.push(data.id);
			}

			this._saved = false;
		}
	}

	@Mutation
	private _addPage({
		data,
		at,
	}: {
		data: PI.PageModel;
		at?: number;
	}): void {
		if (data.id && this.pageList.indexOf(data.id) < 0) {
			this.pages[data.id] = data;

			if (typeof at !== 'undefined') {
				this.pageList.splice(
					at,
					0,
					data.id,
				);
			} else {
				this.pageList.push(data.id);
			}

			this._saved = false;
		}
	}

	@Mutation
	public addPhoto({
		photoId,
		index,
	}: {
		photoId: PI.PhotoModel['id'];
		index?: number;
	}): void {
		if (this.photoList.indexOf(photoId) === -1) {
			if (typeof index !== 'undefined') {
				this.photoList.splice(
					index,
					0,
					photoId,
				);
			} else {
				this.photoList.push(photoId);
			}

			this._saved = false;
		}
	}

	@Mutation
	public changeAddress(
		data: Partial<PI.AddressModel>,
	): void {
		if (this.address) {
			if (
				data.country
				&& window.App.router.$i18next.exists(`countries.${data.country}`)
			) {
				data.countryFormatted = window.App.router.$i18next.t(`countries.${data.country}`);
			}

			Object.assign(
				this.address,
				data,
			);
		}

		this._saved = false;
	}

	@Mutation
	private _changeHistoryIndex(val: number): void {
		this._historyIndex = val;
	}

	@Mutation
	private _changeLoadingMask(val: boolean): void {
		this._loadingMask = val;
	}

	@Mutation
	private _changeLoadingOfferingFrame(val: boolean): void {
		this._loadingOfferingFrame = val;
	}

	@Mutation
	private _changeLoadingOverlay(val: boolean): void {
		this._loadingOverlay = val;
	}

	@Mutation
	private _changePageObject(data: OptionalExceptFor<PI.PageObjectModel, 'id'>): void {
		if (data.id) {
			this.objects[data.id] = _.extend(
				this.objects[data.id],
				data,
			);

			if (
				_.find(
					_.keys(data),
					(key) => (
						key.charAt(0) !== '_'
						&& key !== 'id'
					),
				)
			) {
				this._saved = false;
			}
		}
	}

	@Mutation
	public changePage(data: OptionalExceptFor<PI.PageModel, 'id'>): void {
		if (data.id) {
			const currentData = this.pages[data.id];

			// Reset loaded asset when underlying property changes
			if (
				data.hasOwnProperty('bgimage')
				&& data.bgimage !== currentData.bgimage
			) {
				data._bgimage = null;
			}
			if (
				data.hasOwnProperty('bgpattern')
				&& data.bgpattern !== currentData.bgpattern
			) {
				data._bgpattern = null;
			}

			this.pages[data.id] = _.extend(
				currentData,
				data,
			);

			if (
				_.find(
					_.keys(data),
					(key) => (
						key.charAt(0) !== '_'
						&& key !== 'id'
					),
				)
			) {
				this._saved = false;
			}
		}
	}

	@Mutation
	public _changePhotoId([oldId, newId]: [PI.PhotoModel['id'], PI.PhotoModel['id']]): void {
		// Replace in history
		this._snapshots.forEach((snapshot, si) => {
			const historicData: PI.ProductDataModel = JSON.parse(
				snapshot,
			);

			// Replace entry in photoList
			const pi = _.indexOf(
				historicData.photoList,
				oldId,
			);
			if (pi !== -1) {
				historicData.photoList[pi] = newId;
			}

			// Replace entry in objects
			if (historicData.objects) {
				const objectModels = _.toArray(historicData.objects);
				objectModels.forEach((objectModel) => {
					if (objectModel.photoid && objectModel.photoid == oldId) {
						objectModel.photoid = newId;
					}
				});
			}

			// Replace snapshot entry
			this._snapshots[si] = JSON.stringify(historicData);
		});

		// Replace in current data
		const i = this.photoList.findIndex(
			(id) => id === oldId,
		);
		if (i !== -1) {
			this.photoList[i] = newId;
			this._saved = false;
		}
	}

	@Mutation
	public changeProductAttribute({
		data,
	}: {
		data: RequiredExceptFor<PI.ProductAttributeModel, 'id'>;
	}): void {
		const i = _.findIndex(
			this.productAttributes,
			{ property: data.property },
		);
		if (i != -1) {
			this.productAttributes[i].value = data.value;
		}
	}

	@Mutation
	private _changeProductSettings(data: Partial<PI.ProductSettings>): void {
		Object.assign(
			this.productSettings,
			data,
		);
		this._saved = false;
	}

	@Mutation
	public disableCropping(pageId: PI.PageModel['id']): void {
		const pageModel = this.pages[pageId];
		if (pageModel && pageModel.objectList) {
			const models = _.compact(pageModel.objectList.map((objectId) => this.objects[objectId]));
			models.forEach(
				(objectModel) => {
					objectModel._crop = false;
				},
			);
		}
	}

	@Mutation
	public mergeCustomAttributes(data: Record<string, string>): void {
		this.customAttributes = reactive({
			...this.customAttributes,
			...data,
		});
		this._saved = false;
	}

	@Mutation
	public movePage({
		from,
		to,
	}: {
		from: number;
		to: number;
	}): void {
		this.pageList.splice(
			to,
			0,
			this.pageList.splice(
				from,
				1,
			)[0],
		);
		this._saved = false;
	}

	@Mutation
	private _popHistory(): void {
		this._snapshots.pop();
		this._historyIndex -= 1;
	}

	@Mutation
	private _pushHistory(data: object): void {
		// We are starting a new timeline
		// Remove any forward history from the snapshots
		while (this._snapshots.length - 1 > this._historyIndex) {
			this._snapshots.pop();
		}

		this._snapshots.push(JSON.stringify(data));
		this._historyIndex += 1;
	}

	@Mutation
	private _removeAddress(): void {
		this.address = null;
		this._saved = false;
	}

	@Mutation
	private _removeAttribute(attributeId: string): void {
		const i = _.findIndex(
			this.productAttributes,
			(attributeModel) => attributeModel.id == attributeId,
		);
		if (i !== -1) {
			this.productAttributes.splice(
				i,
				1,
			);
		}
		this._saved = false;
	}

	@Mutation
	private _removePage(id: PI.PageModel['id']): void {
		this.pageList = _.without(
			this.pageList,
			id,
		);
		delete this.pages[id];

		this._saved = false;
	}

	@Mutation
	public removePageObject(id: PI.PageObjectModel['id']): void {
		const pages = this.pageList.map(
			(pageId) => this.pages[pageId],
		);

		pages.forEach((pageModel) => {
			if (pageModel.objectList) {
				const objectIndex = pageModel.objectList.indexOf(id);

				if (objectIndex !== -1) {
					pageModel.objectList.splice(
						objectIndex,
						1,
					);
				}
			}
		});
		delete this.objects[id];

		this._saved = false;
	}

	@Mutation
	private _removePhoto(photoid: PI.PhotoModel['id']): void {
		const i = _.indexOf(
			this.photoList,
			photoid,
		);
		if (i !== -1) {
			this.photoList.splice(
				i,
				1,
			);
		}
		this._saved = false;
	}

	@Mutation
	private _reset(): void {
		// Reset data to defaults
		Object.assign(
			this,
			JSON.parse(JSON.stringify(defaults.productStateModel)),
		);
		this._saved = true;
	}

	@Mutation
	public resetActivePage(): void {
		this._activePage = null;
	}

	@Mutation
	private _resetHistory(): void {
		this._snapshots = [];
		this._historyIndex = -1;
	}

	@Mutation
	public resetPages(pageIds: PI.PageModel['id'][]): void {
		this.pageList = pageIds || [];
		this._saved = false;
	}

	@Mutation
	public resetPhotos(photoIds: (PI.PhotoModel['id'])[]): void {
		this.photoList = photoIds || [];
		this._saved = false;
	}

	@Mutation
	public setActivePage(pageId: PI.PageModel['id']): void {
		if (this.pages[pageId]) {
			this._activePage = pageId;
		} else {
			this._activePage = null;
		}
	}

	@Mutation
	public setBookmarks(data: PI.BookmarksModel): void {
		Object.assign(
			this._bookmarks,
			data,
		);
	}

	@Mutation
	public setCustomAttributes(data: Record<string, string>): void {
		this.customAttributes = reactive(data);
		this._saved = false;
	}

	@Mutation
	private _setMaskImage(img: HTMLImageElement | null): void {
		if (img !== null) {
			this._mask = reactive(img);
		} else {
			this._mask = img;
		}
	}

	@Mutation
	private _setOverlayImage(img: HTMLImageElement | null): void {
		if (img !== null) {
			this._overlay = reactive(img);
		} else {
			this._overlay = img;
		}
	}

	@Mutation
	private _setProductId(id: number): void {
		this.productId = id;
	}

	@Mutation
	private _setProductTour(data: PI.ProductDataModel['productTours']): void {
		Object.assign(
			this.productTours,
			data,
		);
		this._saved = false;
	}

	@Mutation
	private _setSaved(isSaved: boolean | null): void {
		this._saved = isSaved;
	}

	@Mutation
	private _setVersion(timestamp?: number): void {
		this.version = timestamp || Math.floor(Date.now() / 1000);
	}

	@Mutation
	private _shiftHistory(): void {
		this._snapshots.shift();
		this._historyIndex -= 1;
	}

	@Action({ rawError: true })
	private async _parseData({
		data,
	}: {
		data: API.ProjectDataModel;
	}): Promise<DB.ProductModel> {
		// Disable auto saving while data is being replaced (to avoid sending incomplete data model)
		AppStateModule.disableAutoSave();
		this._reset();

		if (!data) {
			return Promise.reject(
				new Error(ERRORS_INVALID_REQUEST_DATA),
			);
		}

		ProductsModule.addModel(data.product);
		this._setProductId(
			data.product.id,
		);

		if (data.version) {
			this._setVersion(
				data.version,
			);
		}

		if (data.photos) {
			await PhotosModule.addModels(_.toArray(data.photos) as PI.PhotoModel[]);
		}

		const arrObjects = _.toArray(
			data.objects,
		);

		let objectsPromise: Promise<void> = Promise.resolve();

		if (arrObjects.length > 0) {
			arrObjects.forEach(
				(objectData) => {
					objectsPromise = objectsPromise.then(
						() => this.addPageObject({
							data: objectData,
						}).then(() => undefined),
					);
				},
			);
		}

		return objectsPromise
			.then(() => {
				if (data.pages) {
					data.pageList.map(
						(pageId) => data.pages[pageId],
					).forEach(
						(pageData, i) => {
							this.addPage(
								{
									data: pageData,
									at: i,
								},
							);
						},
					);
				}

				if (data.address) {
					this.addAddress(
						{ data: data.address },
					);
				}

				if (data.photoList) {
					this.addPhotos(
						data.photoList,
					);
				}

				if (data.customAttributes) {
					this.setCustomAttributes(
						data.customAttributes,
					);
				}

				if (data.productAttributes) {
					data.productAttributes.forEach(
						(attributeData) => {
							this.addAttribute(
								{ data: attributeData },
							);
						},
					);
				}

				if (data.productSettings) {
					this._changeProductSettings(
						data.productSettings,
					);
				}

				if (data.productTours) {
					this._setProductTour(
						data.productTours,
					);
				}

				this._setSaved(
					true,
				);

				AppStateModule.enableAutoSave();

				this.pushHistory();

				return data.product;
			})
			.then(async (product) => {
				if (product) {
					const searchProps: Partial<DB.OfferingModel> = {
						groupid: product.group,
						typeid: product.typeid,
					};

					if (product.variantid) {
						searchProps.variantid = product.variantid;
					}

					const offeringModelFound = AppDataModule.findOfferingWhere(searchProps);

					if (!offeringModelFound) {
						await AppDataModule.fetchOfferingsData({
							searchProps: {
								...searchProps,
								/**
								 * Indicates to base the fetch of the offering data on the `flexgroupid`
								 * from the offering model found from the result of the `searchProps`
								 */
								get_from_flexgroupid: true,
							},
						});
					}
				}

				return ProductStateClass.select(
					data.product.id,
				);
			})
			.catch((err) => {
				this._reset();
				AppStateModule.enableAutoSave();

				return Promise.reject(err);
			});
	}

	@Action({ rawError: true })
	public acceptPageQuality(
		pageId: PI.PageModel['id'],
	): void {
		if (!pageId) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		this.changePage(
			{
				id: pageId,
				_acceptedQualityWarning: true,
			},
		);
	}

	@Action({ rawError: true })
	public addAddress({
		data,
	}: {
		data: DB.AddressModel;
	} = {
		data: JSON.parse(JSON.stringify(defaults.addressModel)),
	}): Promise<DB.AddressModel> {
		const commitData: DB.AddressModel = {
			...JSON.parse(JSON.stringify(defaults.addressModel)),
			...data,
		};
		if (!commitData.country) {
			// Get default shipping country
			const countryId = this.context.rootState.user.countryid;
			const countryModel = this.context.rootGetters['appdata/getCountry'](countryId);
			commitData.country = countryModel.iso;
		}

		this._addAddress(
			commitData,
		);

		const addressModel = this.getAddress;
		if (!addressModel) {
			return Promise.reject(
				new Error('Something went wrong creating the address model'),
			);
		}

		return Promise.resolve(addressModel);
	}

	@Action({ rawError: true })
	public addAttribute({
		data,
	}: {
		data: RequiredExceptFor<PI.ProductAttributeModel, 'id'>;
	}): PI.ProductAttributeModel | undefined {
		if (data) {
			this._addAttribute({
				...data,
				id: data.id || nanoid(),
			});

			return _.findWhere(
				this.productAttributes,
				{ id: data.id },
			);
		}

		return undefined;
	}

	@Action({ rawError: true })
	public addPage({
		data,
		at,
	}: {
		data: Partial<PI.PageModel>;
		at: number;
	}): Promise<PI.PageModel> {
		if (!data.offeringId && this.getOffering?.virtual) {
			data.offeringId = this.getChildOfferingId;
		}

		const pageData = {
			...JSON.parse(JSON.stringify(defaults.pageModel)),
			...data,
			id: data.id || nanoid(),
		};

		this._addPage({
			data: pageData,
			at,
		});

		const pageModel = this.getPage(pageData.id);
		if (!pageModel) {
			throw new Error('Something went wrong creating the page model');
		}

		return Promise.resolve(pageModel);
	}

	@Action({ rawError: true })
	public addObject({
		data,
		pageId,
	}: AddObjectModel): Promise<PI.PageObjectModel> {
		this._addObject({
			data,
			pageId,
		});
		const objectModel = this.getPageObject(data.id);

		if (objectModel) {
			return Promise.resolve(objectModel);
		}

		return Promise.reject(new Error('Something went wrong adding the model'));
	}

	public async addPageObject(options: AddPageObjectModelWithoutNoStore): Promise<PI.PageObjectModel>;

	public async addPageObject(options: AddPageObjectModelWithNoStore): Promise<PI.PageObjectModel>;

	@Action({ rawError: true })
	public async addPageObject(options: AddPageObjectModel): Promise<PI.PageObjectModel> {
		const { data } = options;

		if (
			!data
			|| !data.type
		) {
			return Promise.reject(new Error(ERRORS_INVALID_REQUEST_DATA));
		}

		const noStore = !!options.noStore;
		const newObjectData: PI.PageObjectModel = {
			...JSON.parse(JSON.stringify(defaults.objectModel)),
			...data,
		};

		if (newObjectData.fontface) {
			const fontModel = FontModule.getById(newObjectData.fontface);

			if (
				fontModel
				&& !fontModel._loaded
			) {
				return FontModule
					.loadModel(fontModel.id)
					.then(() => this.addPageObject(options as AddPageObjectModelWithoutNoStore & AddPageObjectModelWithNoStore));
			}

			if (
				fontModel
				&& newObjectData.text
				&& newObjectData.text.length > 0
				&& (
					!newObjectData.text_formatted
					|| newObjectData.text_formatted.length === 0
				)
			) {
				const updatedProperties = resizeObjectText(
					newObjectData,
					fontModel,
				);
				Object.assign(
					newObjectData,
					updatedProperties,
				);
			}
		}

		if (
			newObjectData.hasOwnProperty('effect')
			&& newObjectData.effect === 'noeffect'
		) {
			// Convert old default property value
			newObjectData.effect = null;
		}

		if (newObjectData.photoid) {
			const photoModel = PhotosModule.getById(newObjectData.photoid);

			if (
				/**
				 * The `window.glPlatform` check is needed because the `render-page` lambda function is
				 * sending the entire object list of the project but only the photo models for the page
				 * being rendered, so when adding the page objects to the store there are objects with
				 * no photo model available, so we need to skip the check in this case.
				 * In the future, the `render-page` lambda function should only send the objects for the
				 * page being rendered and this check can be removed.
				 */
				window.glPlatform !== 'server'
				&& !photoModel
			) {
				return Promise.reject(new Error(ERRORS_INVALID_REQUEST_DATA));
			}

			if (
				(
					photoModel?._type
					&& MIME_TYPES_SHOWN_AS_PNG.includes(photoModel._type)
				)
				|| (
					photoModel?.url
					&& urlTools.parse(photoModel.url).extension === 'png'
				)
			) {
				newObjectData.ext = 'png';
			}

			if (
				(
					photoModel?._type
					&& MIME_TYPES_SVG.includes(photoModel._type)
				)
				|| (
					photoModel?.url
					&& photoModel.url.indexOf('.svg') > 0
				)
				|| photoModel?._vectorize
			) {
				newObjectData.ext = 'svg';
			}
		}

		if (newObjectData.mask === 'square') {
			newObjectData.mask = null;
		}

		const newObjectId = (
			newObjectData.id
			|| nanoid()
		);
		let objectModel: PI.PageObjectModel | undefined;

		if (!noStore) {
			const pageId = (
				(
					'pageId' in options
					&& options.pageId
				)
				|| undefined
			);
			this._addObject({
				data: {
					...newObjectData,
					id: newObjectId,
				},
				pageId,
			});
			objectModel = this.getPageObject(newObjectId);
		} else if ('pageModel' in options) {
			objectModel = {
				...newObjectData,
				id: newObjectId,
			};
			options.pageObjects.push(objectModel);
			options.pageModel.objectList?.push(newObjectId);
		}

		if (objectModel) {
			return Promise.resolve(objectModel);
		}

		return Promise.reject(new Error('Something went wrong creating the model'));
	}

	@Action({ rawError: true })
	public addPhotos(arrData: (PI.PhotoModel['id'])[]): void {
		arrData.forEach(
			(photoId) => {
				this.addPhoto({
					photoId,
				});
			},
		);
	}

	@Action({ rawError: true })
	private _autoFixData(): Promise<void> {
		// Get product data
		const data: PI.ProductDataModel = this.getData;

		Object.keys(data.objects).forEach((objectId) => {
			const objectModel = data.objects[objectId];
			if (objectModel.type == 'photo') {
				if (objectModel.photoid) {
					if (data.photoList.indexOf(objectModel.photoid) === -1) {
						// Photo used in photo object, so should be linked to product
						this.addPhoto({
							photoId: objectModel.photoid,
						});
					}
				} else if (!objectModel.source) {
					// Corrupt photo object, remove from data
					this.removePageObject(objectModel.id);
				}
			}
		});

		return Promise.resolve();
	}

	public async changePageObject(data: ChangePageObjectModelWithNoStore): Promise<PageObjectModelChangeResult | undefined>;

	public async changePageObject(data: ChangePageObjectModelWithIgnorePointerSizeResize): Promise<PageObjectModelChangeResult | undefined>;

	@Action({ rawError: true })
	public async changePageObject(data: ChangePageObjectModel): Promise<PageObjectModelChangeResult | undefined> {
		const noStore = (
			'noStore' in data
			&& !!data.noStore
		);

		// Reset loaded asset when underlying property changes
		if (data.hasOwnProperty('borderimage')) {
			data._borderimage = null;
		}
		if (
			data.hasOwnProperty('source')
			|| data.hasOwnProperty('photoid')
			|| data.hasOwnProperty('effect')
		) {
			data._image = null;
			data._canvas = null;
		}
		if (data.hasOwnProperty('mask')) {
			data._mask = null;
			data._canvas = null;
		}
		if (data.hasOwnProperty('fontface')) {
			data.text_formatted = '';
			data.text_formatted_for_canvas = '';
			data.text_svg = '';
		}

		let objectModel: PI.PageObjectModel | null = null;

		if (
			data.id
			&& !noStore
		) {
			objectModel = this.getPageObject(data.id);
		} else if (
			data.id
			&& 'noStore' in data
			&& 'originalPageObject' in data
			&& noStore
			&& data.originalPageObject
		) {
			objectModel = data.originalPageObject;
		}

		if (!objectModel) {
			// Object no longer exists, ignore request
			return undefined;
		}

		if (
			(
				data.hasOwnProperty('cropx')
				|| data.hasOwnProperty('cropy')
			)
			&& objectModel.mask
		) {
			// The canvas needs to be repainted after changing the photo cropping
			data._canvas = null;
		}

		// If text is changed, rewrite it for linebreaks
		if (
			data.hasOwnProperty('text')
			|| data.hasOwnProperty('text_formatted')
		) {
			const fontface = (data.fontface || objectModel.fontface) as string;
			const fontModel = FontModule.getById(fontface) as FontModel;

			if (objectModel.transformable) {
				const updatedProperties = resizeObjectText(
					_.extend(
						JSON.parse(JSON.stringify(objectModel)),
						data,
					),
					fontModel,
					{
						resizeObject: {
							up: true,
							down: true,
						},
					},
				);
				data = _.extend(
					data,
					updatedProperties,
				);
			} else {
				const offeringModel = this.getOffering;
				const updatedProperties = resizeObjectText(
					_.extend(
						JSON.parse(JSON.stringify(objectModel)),
						data,
					),
					fontModel,
					{
						resizeFont: {
							up: objectModel.pointsize || 100,
							down: offeringModel ? offeringModel.minfontsize : 12,
						},
					},
				);
				data = _.extend(
					data,
					updatedProperties,
				);
			}
		} else if (
			data.hasOwnProperty('pointsize')
			&& !data.ignorePointerSizeResize
		) {
			// If pointsize is changed, calculate new line breaks
			const fontface = (data.fontface || objectModel.fontface) as string;
			const fontModel = FontModule.getById(fontface) as FontModel;
			const updatedProperties = resizeObjectText(
				_.extend(
					JSON.parse(JSON.stringify(objectModel)),
					data,
				),
				fontModel,
				{
					resizeObject: {
						up: Boolean(objectModel.transformable),
						down: Boolean(objectModel.transformable),
					},
				},
			);
			data = _.extend(
				data,
				updatedProperties,
			);
		}
		// For text objects, we might need to rewrite the text when the size of the object changes
		if (
			(
				data.hasOwnProperty('width')
				|| data.hasOwnProperty('height')
			)
			&& (
				objectModel.transformable
				&& objectModel.type == 'text'
			)
		) {
			const fontface = (data.fontface || objectModel.fontface) as string;
			const fontModel = FontModule.getById(fontface) as FontModel;
			const updatedProperties = resizeObjectText(
				_.extend(
					JSON.parse(JSON.stringify(objectModel)),
					data,
				),
				fontModel,
			);
			data = _.extend(
				data,
				updatedProperties,
			);
		}

		const partialPageObject: OptionalExceptFor<PI.PageObjectModel, 'id'> = {
			id: objectModel.id,
		};
		const finalUpdatedProperties = Object.keys(data) as Array<keyof ChangePageObjectModelWithNoStore>;
		const pageObject: PI.PageObjectModel = {
			...objectModel,
		};

		if (finalUpdatedProperties.includes('id')) {
			finalUpdatedProperties.splice(
				finalUpdatedProperties.indexOf('id'),
				1,
			);
		}

		// eslint-disable-next-line no-restricted-syntax
		for (const finalUpdatedProperty of finalUpdatedProperties) {
			if (
				finalUpdatedProperty !== 'ignorePointerSizeResize'
				&& finalUpdatedProperty !== 'noStore'
				&& finalUpdatedProperty !== 'originalPageObject'
			) {
				(pageObject as any)[finalUpdatedProperty] = data[finalUpdatedProperty];
				partialPageObject[finalUpdatedProperty] = data[finalUpdatedProperty];
			}
		}

		if (
			data.hasOwnProperty('_selected')
			&& !data._selected
			&& objectModel.text_svg
		) {
			partialPageObject._caretLine = 0;
			partialPageObject._selectionStart = -1;
			partialPageObject._selectionEnd = -1;
		}

		/**
		 * In case the text object has svg content defined, we need to recreate the svg text
		 * and also signal the image to be reset but ONLY anything related to the rendering
		 * of the text object changed.
		 * If either the x or y axis is changed, then this means that the text object is only
		 * changing its position, so we don't need to recreate the svg text neither reset the image.
		 */
		if (
			objectModel.text_svg
			&& (
				'_selectionEnd' in partialPageObject
				|| '_selectionStart' in partialPageObject
				|| 'fontbold' in partialPageObject
				|| 'fontface' in partialPageObject
				|| 'fontitalic' in partialPageObject
				|| 'fontunderline' in partialPageObject
				|| 'fontcolor' in partialPageObject
				|| 'pointsize' in partialPageObject
				|| 'text' in partialPageObject
				|| 'bgcolor' in partialPageObject
				|| 'align' in partialPageObject
				|| 'width' in partialPageObject
				|| 'height' in partialPageObject
				|| 'maxwidth' in partialPageObject
				|| 'maxheight' in partialPageObject
				|| 'cropx' in partialPageObject
				|| 'cropy' in partialPageObject
				|| 'cropwidth' in partialPageObject
				|| 'cropheight' in partialPageObject
			)
		) {
			partialPageObject.text_svg = svgTextHelper.createText({
				...objectModel,
				...partialPageObject,
			});
			partialPageObject._image = null;
		}

		if (!noStore) {
			this._changePageObject(partialPageObject);
		}

		return {
			partialPageObject,
			updatedPageObject: pageObject,
		};
	}

	@Action({ rawError: true })
	public async changePhotoId([oldId, newId]: [PI.PhotoModel['id'], PI.PhotoModel['id']]): Promise<void> {
		this._changePhotoId([
			oldId,
			newId,
		]);

		// Change the photoid reference for all related pageobject models
		/* eslint-disable no-restricted-syntax, no-await-in-loop */
		for (const pageObjectModel of this.getObjects) {
			if (pageObjectModel.photoid && pageObjectModel.photoid == oldId) {
				await this.changePageObject({
					id: pageObjectModel.id,
					photoid: newId,
				});
			}
		}
		/* eslint-enable no-restricted-syntax, no-await-in-loop */
	}

	@Action({ rawError: true })
	public changeProductSettings(data: Partial<PI.ProductSettings>): void {
		this._changeProductSettings(data);

		if (data.bgcolor || data.bgpattern) {
			const pageData: Partial<PI.PageModel> = {};
			if (typeof data.bgcolor !== 'undefined') {
				pageData.bgcolor = data.bgcolor;
			}
			if (typeof data.bgpattern !== 'undefined') {
				pageData.bgpattern = data.bgpattern;
				pageData._bgpattern = null;
			}

			this.getPages.forEach((pageModel: PI.PageModel) => {
				// We can only change the page background color for pages that are not set with a transparent image
				// i.e. the "hallo herinnering" books from the Hema
				if (!(pageModel.bgcolor === 'transparent' && pageModel.bgimage)) {
					this.changePage(
						_.extend(
							pageData,
							{
								id: pageModel.id,
							},
						),
					);
				}
			});

			try {
				// Automatically update project content from its offering options
				ProductStateClass.updateContentFromOfferingOptions();
			} catch (error) {
				// Do nothing
			}
		}
	}

	@Action({ rawError: true })
	public deselectPageObjects(options?: DeselectePageObjectsModel): void {
		const skipEmptyTextObjectsDeletion = options?.skipEmptyTextObjectsDeletion || false;
		const objectModels = [
			this.getSelectedPageObject,
			this.getSelectedPageObjectForEdition,
		];

		// eslint-disable-next-line no-restricted-syntax
		for (const objectModel of objectModels) {
			if (objectModel) {
				if (
					objectModel.type == 'text'
					&& !skipEmptyTextObjectsDeletion
					&& (
						!objectModel.text
						|| objectModel.text.length === 0
					)
				) {
					// Remove the object from the page
					this.removePageObject(objectModel.id);
				} else {
					this._changePageObject({
						id: objectModel.id,
						_crop: false,
						_selected: false,
						_selectedForEdition: false,
					});
				}
			}
		}

		EventBus.emit('deselect');
	}

	@Action({ rawError: true })
	public fetch({
		productId,
		requestOptions,
		parse,
	}: {
		productId: DB.ProductModel['id'];
		requestOptions?: AxiosRequestConfig;
		parse: boolean;
	}): Promise<DB.ProductModel | API.ProjectDataModel> {
		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'get',
			url: `/api/product/${productId}/all`,
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;

		// Perform ajax request to get data from server
		return ajax
			.request(
				requestOptions,
				{
					auth: true,
				},
			)
			.then((response: AxiosResponse<API.ProjectDataModel>): Promise<DB.ProductModel | API.ProjectDataModel> => {
				const responseData = response.data;

				if (!responseData.hasOwnProperty('product')) {
					// Corrupt data
					throw new Error(ERRORS_INVALID_RETURN_DATA);
				}

				if (!parse) {
					if (responseData.objects) {
						const objectsIds = Object.keys(responseData.objects);

						// eslint-disable-next-line no-restricted-syntax
						for (const objectId of objectsIds) {
							const currentObject = responseData.objects[objectId];

							if (currentObject.mask === 'square') {
								currentObject.mask = null;
							}
						}
					}

					return Promise.resolve(responseData);
				}

				return this._parseData({
					data: responseData,
				});
			});
	}

	@Action({ rawError: true })
	public flagChange(): void {
		this._setSaved(false);
	}

	@Action({ rawError: true })
	public flagProductTour(tourName: DB.ProductTours): void {
		const props: PI.ProductDataModel['productTours'] = {};
		props[tourName] = true;

		this._setProductTour(props);
	}

	@Action({ rawError: true })
	public pushHistory(replace = false): void {
		if (this._snapshots.length > this._historyLimit) {
			// Limit size of history to 10 steps back
			this._shiftHistory();
		}

		const newData: PI.ProductDataModel = this.getData;
		if (this._historyIndex >= 0) {
			const oldData = JSON.parse(
				this._snapshots[this._historyIndex],
			);

			if (!equal(
				newData,
				oldData,
			)) {
				if (replace) {
					this._popHistory();
				}
				this._pushHistory(
					newData,
				);
			}
		} else {
			this._pushHistory(
				newData,
			);
		}
	}

	@Action({ rawError: true })
	public redoHistory(): Promise<void> {
		// Get the previous state and parse the data
		this._changeHistoryIndex(this._historyIndex + 1);

		const historyData = JSON.parse(
			this._snapshots[this._historyIndex],
		);
		return this.replaceData(
			{ data: historyData },
		).then(() => {
			this._setSaved(false);
		}).catch(() => {
			// Swallow error: no action required
		});
	}

	@Action({ rawError: true })
	public resetHistory(): void {
		this._resetHistory();
		this.pushHistory();
	}

	@Action({ rawError: true })
	public undoHistory(): Promise<void> {
		// Get the previous state and parse the data
		this._changeHistoryIndex(
			this._historyIndex - 1,
		);

		const historyData = JSON.parse(
			this._snapshots[this._historyIndex],
		);

		return this.replaceData(
			{ data: historyData },
		).then(() => {
			this._setSaved(false);
		}).catch(() => {
			// Swallow error: no action required
		});
	}

	@Action({ rawError: true })
	public loadMask(): void {
		if (!this._loadingMask) {
			const offeringModel = this.getOffering;

			if (!offeringModel) {
				throw new Error('Could not find offering model');
			}

			if (offeringModel.mask) {
				this._changeLoadingMask(true);

				const image = new Image();

				// This enumerated attribute indicates if the fetching of the related image must be done using CORS or not.
				// CORS-enabled images can be reused in the <canvas> element without being tainted.
				image.crossOrigin = 'Anonymous';

				image.onload = () => {
					this._setMaskImage(image);
					this._changeLoadingMask(false);
				};
				image.src = offeringModel.mask;
			}
		}
	}

	@Action({ rawError: true })
	public loadOverlay(): void {
		if (!this._loadingOverlay) {
			const offeringModel = this.getOffering;

			if (!offeringModel) {
				throw new Error('Could not find offering model');
			}

			if (offeringModel.overlay) {
				this._changeLoadingOverlay(true);

				const image = new Image();

				// This enumerated attribute indicates if the fetching of the related image must be done using CORS or not.
				// CORS-enabled images can be reused in the <canvas> element without being tainted.
				image.crossOrigin = 'Anonymous';

				image.onload = () => {
					this._setOverlayImage(image);
					this._changeLoadingOverlay(false);
				};
				image.src = offeringModel.overlay;
			}
		}
	}

	@Action({ rawError: true })
	public async removePages({
		keepPhotos,
	}: {
		keepPhotos?: boolean;
	}): Promise<void> {
		/* eslint-disable no-restricted-syntax, no-await-in-loop */
		for (const pageModel of this.getPages) {
			if (pageModel) {
				await this.removePage({
					pageId: pageModel.id,
					keepPhotos,
				});
			}
		}
		/* eslint-enable no-restricted-syntax, no-await-in-loop */
	}

	@Action({ rawError: true })
	public async removePage({
		pageId,
		keepPhotos,
	}: {
		pageId: PI.PageModel['id'];
		keepPhotos?: boolean;
	}): Promise<void> {
		const pageModel = this.getPage(pageId);
		if (pageModel) { // check if still exists
			const pageIndex = this.getPageIndex(pageModel);
			let backPageModel = null;
			const offeringModel = this.getOffering;
			const pageModels = this.getPages;
			let backPageIndex: number | null = null;

			if (offeringModel) {
				// For prints with back sides, we also need to destroy the back of the page
				if (OfferingGroups(
					offeringModel.groupid,
					['DoubleSidePrints'],
				)
					|| offeringModel.spread
					|| offeringModel.hasback
				) {
					// Get page number of backside (either the next or the previous page)
					if (OfferingGroups(
						offeringModel.groupid,
						['BookTypes'],
					)) {
						// Check if the theme for this product will have extra pages added after completion
						const extraPages = ThemeStateModule.getFixedPages;
						if (
							offeringModel.fixedcover + offeringModel.startright > 0
							// Book starts with a right page
							&& extraPages.length % 2 === 0
							// Book should end with a left page (would not be the case if one extra page is added to the end of the book after completion)
							&& (
								// This is the first (right) page inside the book (which has no left spread partner)
								pageIndex - offeringModel.fixedcover == 2
								// This is the last (left) page inside the book (which has no right spread partner)
								|| pageIndex == pageModels.length - 1
							)
						) {
							// This page is not part of a spread, so remove just the single page
						} else {
							backPageIndex = (pageIndex + offeringModel.startright) % 2 > 0
								? pageIndex - 1
								: pageIndex + 1;
						}
					} else {
						backPageIndex = pageIndex % 2 > 0 ? pageIndex - 1 : pageIndex + 1;
					}

					if (backPageIndex !== null) {
						// Get backpagestate
						backPageModel = this.getPageByNumber(backPageIndex);
					}
				}

				const objectModels: PI.PageObjectModel[] = this.getPageObjects(pageModel);
				/* eslint-disable no-restricted-syntax, no-await-in-loop */
				for (const objectModel of objectModels) {
					// referenced object in objectModels array could now be undefined, so check for existince
					if (objectModel) {
						// For Prints, also remove the connection between the product and the photo on this page
						if (!keepPhotos && OfferingGroups(
							offeringModel.groupid,
							['PrintTypes'],
						)) {
							if (objectModel.photoid) {
								const photoId = objectModel.photoid;
								// Make sure there are no other prints in this set that use the same photo (duplicated prints)
								const duplicate = _.find(
									this.objects,
									(oM) => oM.id !== objectModel.id && oM.photoid === photoId,
								);

								if (!duplicate) {
									await this.removePhoto(photoId);
								}
							}
						}

						this.removePageObject(objectModel.id);
					}
				}
				/* eslint-enable no-restricted-syntax, no-await-in-loop */

				if (backPageModel) {
					const backPageObjectModels: PI.PageObjectModel[] = this.getPageObjects(backPageModel);
					/* eslint-disable no-restricted-syntax, no-await-in-loop */
					for (const objectModel of backPageObjectModels) {
						// referenced object in objectModels array could now be undefined, so check for existince
						if (objectModel) {
							// For Prints, also removed the connection between the product and the photo on this page
							if (!keepPhotos && OfferingGroups(
								offeringModel.groupid,
								['PrintTypes'],
							)) {
								if (objectModel.photoid) {
									const photoId = objectModel.photoid;
									// Make sure there are no other prints in this set that use the same photo (duplicated prints)
									const duplicate = _.find(
										this.objects,
										(oM) => oM.id !== objectModel.id && oM.photoid === photoId,
									);

									if (!duplicate) {
										await this.removePhoto(photoId);
									}
								}
							}

							this.removePageObject(objectModel.id);
						}
					}
					/* eslint-enable no-restricted-syntax, no-await-in-loop */
				}
			}

			this._removePage(pageId);

			if (backPageModel) {
				this._removePage(backPageModel.id);
			}
		}
	}

	@Action({ rawError: true })
	public removePhoto(
		photoId: PI.PhotoModel['id'],
	): void {
		const photoModel = this.findPhoto({ id: photoId });
		if (photoModel && photoModel.id) {
			removePhotoObjects(photoModel.id);

			if (typeof photoModel.id == 'string'
				&& photoModel.externalId
				&& (
					photoModel.source == 'upload'
					|| photoModel.source == 'iOS'
					|| photoModel.source == 'Android'
				)
			) {
				upload.cancelUpload({
					id: photoModel.externalId,
				});
			}

			this._removePhoto(
				photoModel.id,
			);
		} else if (photoId) {
			this._removePhoto(
				photoId,
			);
		}
	}

	@Action({ rawError: true })
	public replaceData({
		data,
	}: {
		data: PI.ProductDataModel;
	}): Promise<void> {
		// Disable auto saving while data is being replaced (to avoid sending incomplete data model)
		this.context.commit(
			'appstate/disableAutoSave',
			undefined,
			{ root: true },
		);

		return new Promise((resolve, reject) => {
			const currentData: PI.ProductDataModel = this.getData;
			const newData = data;

			if (!currentData.product) {
				reject(new Error('Unknown product model'));
			} else if (!equal(
				currentData.product,
				newData.product,
			)) {
				const diff = objDiff(
					currentData.product,
					newData.product,
				);
				this.context.commit(
					'products/updateModel',
					_.extend(
						diff,
						{ id: newData.product.id },
					),
					{ root: true },
				);
			}

			if (newData.photoList) {
				// If the newData contains photos that are not known to this user session,
				// we add the models to the productstate store
				newData.photoList.forEach((photoId, i) => {
					if (typeof photoId === 'number') {
						if (!PhotosModule.getById(photoId)) {
							PhotosModule.fetchModel({
								id: photoId,
								methodOptions: {
									debug: {
										dialog: false,
									},
								},
							}).then(() => {
								if (!currentData.photoList.includes(photoId)) {
									this.addPhoto(
										{
											photoId,
											index: i,
										},
									);
								}
							});
						} else if (!currentData.photoList.includes(photoId)) {
							this.addPhoto(
								{
									photoId,
									index: i,
								},
							);
						}
					}
				});
			}

			if (currentData.photoList) {
				// If the currentData has photos that are no longer listed in the newData,
				// we remove the models from the productstate store
				currentData.photoList.forEach((photoId) => {
					if (!newData.photoList.includes(photoId)) {
						this._removePhoto(
							photoId,
						);
					}
				});
			}

			if (newData.customAttributes) {
				this.setCustomAttributes(newData.customAttributes);
			}

			newData.productAttributes.forEach(
				(attributeData) => {
					const currentAttributeData = _.findWhere(
						currentData.productAttributes,
						{ id: attributeData.id },
					);
					if (!currentAttributeData) {
						this.addAttribute({
							data: attributeData,
						});
					} else if (!equal(
						attributeData,
						currentAttributeData,
					)) {
						const diff = objDiff(
							currentAttributeData,
							attributeData,
						);
						this.changeProductAttribute({
							data: _.extend(
								diff,
								{ id: attributeData.id },
							),
						});
					}
				},
			);
			currentData.productAttributes.forEach(
				(attributeData: PI.ProductAttributeModel) => {
					if (!_.findWhere(
						newData.productAttributes,
						{ id: attributeData.id },
					)) {
						// The attribute is no longer present in the newData,
						// so we remove the model from the productstate store
						this._removeAttribute(attributeData.id);
					}
				},
			);

			if (!currentData.productSettings || !equal(
				newData.productSettings,
				currentData.productSettings,
			)) {
				const diff = objDiff(
					currentData.productSettings,
					newData.productSettings,
				);
				this._changeProductSettings(diff);
			}

			const afterObjects = () => {
				if (newData.pages) {
					newData.pageList.map((pageId) => newData.pages[pageId]).forEach(
						(pageData, i) => {
							if (pageData.id && !currentData.pages.hasOwnProperty(pageData.id)) {
								// If the currentData is missing a page listed in the newData,
								// we add the model to the productstate store
								this.addPage({
									data: pageData,
									at: i,
								});
							} else if (pageData.id && !equal(
								currentData.pages[pageData.id],
								newData.pages[pageData.id],
							)) {
								// If the properties of the page have changed, we update the model
								const diff = objDiff(
									currentData.pages[pageData.id],
									newData.pages[pageData.id],
								);
								this.changePage(
									_.extend(
										diff,
										{ id: pageData.id },
									),
								);
							}
						},
					);
				}
				if (currentData.pages) {
					currentData.pageList.map((pageId) => currentData.pages[pageId]).forEach(
						(pageData) => {
							if (pageData.id && !newData.pages.hasOwnProperty(pageData.id)) {
								// If the newData does not contain a page listed in the currentData,
								// we remove the model from the productstate store
								this._removePage(
									pageData.id,
								);
							}
						},
					);
				}
				if (newData.pageList) {
					// Order of pages could be different
					this.resetPages(newData.pageList);
				}

				if (newData.address || currentData.address) {
					if (newData.address && !currentData.address) {
						// The newData contains an address model not present in the currentData,
						// we add the model to the productstate store
						this.addAddress({
							data: data.address,
						});
					} else if (!newData.address && currentData.address) {
						// The currentData contains an address model that is no longer present in the newData,
						// we remove the model from the productstate store
						this._removeAddress();
					} else if (!equal(
						newData.address,
						currentData.address,
					)) {
						// The properties in the address model have changed,
						// update the model in the productstate store
						const diff = objDiff(
							currentData.address,
							newData.address,
						);
						this.changeAddress(diff);
					}
				}

				this._setSaved(true);
				resolve(undefined);
			};

			const newDataObjects: PI.PageObjectModel[] = _.toArray(newData.objects);
			const currentDataObjects: PI.PageObjectModel[] = _.toArray(currentData.objects);
			const objectCount = newDataObjects.length + currentDataObjects.length;

			if (objectCount > 0) {
				const after = _.after(
					objectCount,
					afterObjects,
				);
				const onFail = _.once((err: Error) => {
					this._reset();
					reject(err);
				});

				// Compare the objects in the newData to the ones in the currentData
				newDataObjects.forEach(
					(objectData) => {
						if (objectData.id && !currentData.objects.hasOwnProperty(objectData.id)) {
							this.addPageObject({
								data: objectData,
							}).then(
								() => {
									after();
								},
								onFail,
							);
						} else if (objectData.id && !equal(
							currentData.objects[objectData.id],
							newData.objects[objectData.id],
						)) {
							const diff = objDiff(
								currentData.objects[objectData.id],
								newData.objects[objectData.id],
							);
							this.changePageObject(
								_.extend(
									diff,
									{ id: objectData.id },
								),
							);
							after();
						} else {
							after();
						}
					},
				);

				// Compare the objects in the currentData to the ones in the newData
				currentDataObjects.forEach(
					(objectData) => {
						if (objectData.id && !newData.objects.hasOwnProperty(objectData.id)) {
							this.removePageObject(objectData.id);
						}

						after();
					},
				);
			} else {
				afterObjects();
			}
		}).catch((err) => {
			// Re-enable auto saving while data is being replaced
			this.context.commit(
				'appstate/enableAutoSave',
				undefined,
				{ root: true },
			);

			throw err;
		}).then(() => {
			// Re-enable auto saving while data is being replaced
			this.context.commit(
				'appstate/enableAutoSave',
				undefined,
				{ root: true },
			);

			return undefined;
		});
	}

	@Action
	public resetMaskImage(): void {
		this._setMaskImage(null);
		this.loadMask();
	}

	@Action
	public resetOverlayImage(): void {
		this._setOverlayImage(null);
		this.loadOverlay();
	}

	@Action
	public reset(): Promise<void> {
		this._reset();
		return Promise.resolve();
	}

	@Action({ rawError: true })
	public async retryPhotoErrors(): Promise<void> {
		const photoModels: PI.PhotoModel[] = this.getPhotosFailed;
		/* eslint-disable no-restricted-syntax, no-await-in-loop */
		for (const photoModel of photoModels) {
			if (photoModel.full_url) {
				try {
					await PhotosModule.retryModel(photoModel.id);
				} catch (e) {
					// Swallow error: no action required
				}
			} else if (photoModel.externalId) {
				const uploadModel = UploadModule.find(photoModel.externalId);
				if (uploadModel?.url) {
					await PhotosModule.setTemporaryUploadUrl({
						id: photoModel.id,
						url: uploadModel.url,
					});
				} else if (uploadModel) {
					upload.retry(uploadModel);
				} else {
					await this.removePhoto(photoModel.id);
				}
			} else {
				await this.removePhoto(photoModel.id);
			}
		}
		/* eslint-enable no-restricted-syntax, no-await-in-loop */
	}

	@Action
	public save({
		requestOptions,
		methodOptions,
	}: {
		requestOptions?: AxiosRequestConfig;
		methodOptions?: AjaxOptions;
	} = {}): Promise<void> {
		return this._autoFixData().then(() => {
			// Set timestamp to version
			this._setVersion();

			// Get product data
			const data: PI.ProductDataModel = this.getDataForSnapshot;

			const defaultRequestOptions: AxiosRequestConfig = {
				method: 'put',
				url: `/api/product/${this.productId}/snapshot`,
				headers: {
					'content-type': 'application/json; charset=utf-8',
				},
				data,
			};
			const defaultMethodOptions: AjaxOptions = {
				auth: true,
				retry: 1,
				debug: {
					offline: true,
					dialog: true,
					abort: false,
				},
			};

			requestOptions = requestOptions
				? merge(
					defaultRequestOptions,
					requestOptions,
				)
				: defaultRequestOptions;
			methodOptions = methodOptions
				? merge(
					defaultMethodOptions,
					methodOptions,
				)
				: defaultMethodOptions;

			// Set special status 'null', so we can check after saving if there haven't
			// been any changes since saving started
			this._setSaved(null);

			return ajax.request(
				requestOptions,
				methodOptions,
			);
		}).then((response) => {
			this.context.commit(
				'products/updateModel',
				response.data.product,
				{ root: true },
			);

			if (this.getSaved === null) {
				// If saved status is 'false' instead of 'null', we know there's been
				// changes since starting the save process, so we won't flag it to true
				this._setSaved(true);
			}
		});
	}

	@Action
	public selectPageObject({
		pageModel,
		objectModelId,
	}: {
		pageModel: PI.PageModel;
		objectModelId: PI.PageObjectModel['id'];
	}): void {
		// Activate this page for editing
		this.setActivePage(pageModel.id);

		// Disable cropping modus on all items
		this.disableCropping(pageModel.id);

		const objectModel = this.getPageObject(objectModelId);

		if (objectModel) {
			const selectedObjectModel = this.getSelectedPageObject;

			if (
				!selectedObjectModel
				|| objectModel.id != selectedObjectModel.id
			) {
				// Deselect all items
				this.deselectPageObjects({
					skipEmptyTextObjectsDeletion: true,
				});

				// Select this object
				this._changePageObject({
					id: objectModel.id,
					_selected: true,
				});

				if (!objectModel.transformable) {
					// Object is not transformable, thus can only be moved inside frame
					// Lock button in object toolbar will therefore not be shown,
					// so lock position here automatically
					AppStateModule.setObjectLock();
				} else if (
					objectModel.type == 'photo'
					&& objectModel.x_axis <= 0
					&& objectModel.width >= pageModel.width
				) {
					// Photo covers entire page width,
					// so it is likely that the user wants to move it inside the frame
					AppStateModule.setObjectLock();
				} else if (
					objectModel.type == 'photo'
					&& objectModel.y_axis <= 0
					&& objectModel.height >= pageModel.height
				) {
					// Photo covers entire page height,
					// so it is likely that the user wants to move it inside the frame
					AppStateModule.setObjectLock();
				}
			}
		}
	}

	@Action({ rawError: true })
	public selectPhoto(
		inputData: Partial<PI.PhotoModel>,
	): Promise<PI.PhotoModel> {
		const { productSettings } = this;

		let photoModel: PI.PhotoModel | undefined;
		if (inputData.id
			&& typeof inputData.id === 'string'
			&& inputData._localRef
		) {
			// This fixes a bug where the photo model would have been saved to the server already
			// but the local reference was still the old temporary photo model
			// this is caused by the changing of the photo id in the project's photoList
			// and thus the productstate getPhotosSelected would be changed
			photoModel = PhotosModule.findWhere({
				_localRef: inputData._localRef,
			});
		} else if (inputData.id) {
			photoModel = PhotosModule.getById(inputData.id);
		} else {
			photoModel = PhotosModule.findWhere({
				source: inputData.source,
				externalId: String(inputData.externalId),
			});
		}

		if (photoModel?.id
			&& typeof photoModel.id == 'number'
		) {
			// This photo is already saved to server, so we just update the existing model
			if (inputData.title != photoModel.title) {
				PhotosModule.putModel({
					id: photoModel.id,
					data: {
						title: inputData.title,
					},
				});
			}

			if (photoModel.photodate
				&& productSettings.autoFillMethod == 'photodate'
			) {
				const photoModels = this.getPhotosSelected;
				const index = _.sortedIndex(
					photoModels,
					photoModel,
					'photodate',
				);

				// Add photo model to this product
				this.addPhoto({
					photoId: photoModel.id,
					index,
				});
			} else {
				// Add photo model to this product
				this.addPhoto({
					photoId: photoModel.id,
				});
			}

			return Promise.resolve(photoModel);
		}

		// This is an unsaved model
		return getPhotoSize(inputData)
			.then((photoData) => {
				const localId = photoData.id && typeof photoData.id == 'string'
					? photoData.id
					: photoModel?.id || nanoid();

				const mergedData: Partial<PI.PhotoModel> = {
					...(photoModel || {}),
					...photoData,
				};

				return PhotosModule.addModel({
					_localRef: mergedData._localRef,
					_error: undefined,
					_orientation: mergedData._orientation || undefined,
					_type: mergedData._type || undefined,
					_vectorize: mergedData._vectorize || undefined,
					id: localId,
					userid: this.context.rootState.user.id,
					token: getRandomString(20),
					source: mergedData.source || null,
					externalId: String(mergedData.externalId) || null,
					thumb_url: mergedData.thumb_url || null,
					thumb_width: mergedData.thumb_width || null,
					thumb_height: mergedData.thumb_height || null,
					url: mergedData.url || null,
					width: mergedData.width || null,
					height: mergedData.height || null,
					full_url: mergedData.full_url || null,
					full_width: mergedData.full_width || 0,
					full_height: mergedData.full_height || 0,
					photodate: mergedData.photodate || null,
					title: mergedData.title || null,
					fch: mergedData.fch || undefined,
					fcw: mergedData.fcw || undefined,
					fcx: mergedData.fcx || undefined,
					fcy: mergedData.fcy || undefined,
					type: mergedData.type || 'photo',
				});
			})
			.then((newPhotoModel) => {
				if (newPhotoModel.photodate && productSettings.autoFillMethod == 'photodate') {
					const photoModels = this.getPhotosSelected;
					const index = _.sortedIndex(
						photoModels,
						newPhotoModel,
						'photodate',
					);
					this.addPhoto({
						photoId: newPhotoModel.id,
						index,
					});
				} else {
					this.addPhoto({
						photoId: newPhotoModel.id,
					});
				}

				return newPhotoModel;
			});
	}

	@Action
	public shufflePhotos(): void {
		this.resetPhotos(
			_.shuffle(this.photoList),
		);
	}
}
