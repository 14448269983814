import { AxiosResponse } from 'axios';
import ajax from 'controllers/ajax';
import analytics from 'controllers/analytics';
import experiment from 'controllers/experiment';
import storage from 'controllers/storage';
import { ExternalCartItemModel } from 'interfaces/app';
import * as API from 'interfaces/api';
import * as DB from 'interfaces/database';
import checkConnection from 'services/check-connection';
import detectCountry from 'services/detect-country';
import * as DialogService from 'services/dialog';
import { ERRORS_OFFLINE } from 'settings/errors';
import {
	AddressesModule,
	AppDataModule,
	AppStateModule,
	CartItemsModule,
	CartModule,
	ConfigModule,
	DiscountModule,
	ExternalUsersModule,
	PhotosModule,
	ProductsModule,
	SubscriptionsModule,
	UserDataModule,
	UserModule,
} from 'store';
import _ from 'underscore';
import CountrySelectorItemsView from 'views/selector-country-items';
import SubscriptionsView from 'views/subscriptions';

export default class User {
	static setup(
		forceServer?: boolean,
		forceCreate?: boolean,
	): Promise<void> {
		const currentUserId = UserModule.id || undefined;

		function errorHandler(err: Error): Promise<void> {
			if (err instanceof Error && err.message == ERRORS_OFFLINE) {
				DialogService.closeLoaderDialog();

				// We are offline, so wait for user to come back online
				// Show offline message to user
				return new Promise((resolve, reject) => {
					const closeError = DialogService.openErrorDialog({
						header: {
							hasCloseButton: false,
							title: window.App.router.$t('dialogHeaderOffline'),
						},
						body: {
							content: window.App.router.$t('dialogTextOffline'),
						},
						footer: {
							buttons: [
								{
									id: 'accept',
									text: window.App.router.$t('dialogButtonOfflineOk'),
									click: () => {
										const closeLoader = DialogService.openLoaderDialog();

										// Try ping to check if we are back online
										checkConnection()
											.catch(() => {
												// Swallow error: no action required
											})
											.finally(() => {
												closeLoader();

												User.setup(
													forceServer,
													forceCreate,
												)
													.then(
														resolve,
														reject,
													);
											});
										closeError();
									},
								},
							],
						},
					});
				});
			}

			return Promise.reject(err);
		}

		if (forceServer || AppStateModule.online) {
			return this.fetchDatabase()
				.then(
					() => {
						if (forceCreate && UserModule.id === null) {
							return User.create({ temporary: 1 });
						}

						return undefined;
					},
					errorHandler,
				)
				.then(() => User.setupData(currentUserId))
				.then(() => User.setupSubscriptions());
		}

		return errorHandler(new Error(ERRORS_OFFLINE));
	}

	static setupData(
		currentUserId?: number,
	) {
		if (currentUserId && UserModule.id && currentUserId != UserModule.id) {
			// Update userid setting for all models with such a reference
			// Note: no need to push to server, it's already updated there
			const objChange = { userid: UserModule.id };
			AddressesModule.updateModels(objChange);
			ExternalUsersModule.updateModels(objChange);
			ProductsModule.updateModels(objChange);
			CartModule.set(objChange);
			PhotosModule.updateModels(objChange);
		} else if (UserModule.id) {
			// Track user in analytics integration(s)
			analytics.identifyUser();
		} else if (storage.get('countryid')) {
			UserModule.set({
				countryid: storage.get('countryid'),
			});
		}

		if (!CartModule.id) {
			CartModule.set({
				userid: UserModule.id,
			});
		}

		analytics.setUserProperties();

		// Share user traits with feature flag integration(s)
		experiment.setUserTraits();

		return Promise.resolve();
	}

	static setupSubscriptions(): Promise<void> {
		return new Promise((resolve) => {
			if (
				UserModule.email
				&& UserModule.email.length > 0
				&& (
					UserModule.session_nr === 1
					|| UserModule.session_nr % 5 === 0
				)
				&& (
					SubscriptionsModule.collection.length === 0
					|| !SubscriptionsModule.findWhere({ active: 1 })
				)
				&& ConfigModule.hasWebshopModule
			) {
				DialogService.closeLoaderDialog();

				const { close: closeDialog } = DialogService.openDialog({
					header: {
						hasCloseButton: false,
						title: window.App.router.$t('dialogHeaderSubscriptions'),
					},
					body: {
						component: SubscriptionsView,
						props: {
							fetch: false,
							origin: 'dialog',
						},
					},
					footer: {
						buttons: [
							{
								id: 'save',
								text: window.App.router.$t('buttonSave'),
								click: () => {
									DialogService.openLoaderDialog();
									closeDialog();
								},
							},
						],
					},
					listeners: {
						close: () => {
							resolve();
						},
					},
				});
			} else {
				resolve();
			}
		});
	}

	static syncExternalCart(): Promise<void> {
		if (window.glStack === 'local') {
			return Promise.resolve();
		}

		const hyperlinkModel = AppDataModule.findHyperlink({ tag: 'cartApi' });
		if (!hyperlinkModel || !hyperlinkModel.url) {
			return Promise.resolve();
		}

		if (window.glPlatform === 'native') {
			return Promise.resolve();
		}

		return ajax
			.request(
				{
					method: 'get',
					withCredentials: true,
					url: `${hyperlinkModel.url}?app-version=${VERSION}&rt=${new Date().getTime()}`,
				},
				{
					auth: false,
				},
			)
			.then((response) => {
				let toRemove: DB.ShoppingCartItemModel[] = [];

				if (response.status < 200 || response.status >= 300) {
					throw new Error(`Received response code ${response.status} from Hema Cart API`);
				}

				if (
					response.status == 204
					|| !response.data.ShoppingCartRequestProductList
					|| response.data.ShoppingCartRequestProductList.length === 0
				) {
					toRemove = _.filter(
						CartItemsModule.collection,
						(cartItemModel) => cartItemModel.synced === 1,
					);
				} else {
					const { ShoppingCartRequestProductList }: {
						ShoppingCartRequestProductList: {
							Key: string;
							ListPrice: number;
							Name: string;
							Quantity: number;
							Source: string;
						}[];
					} = response.data;

					_.where(
						CartItemsModule.collection,
						{ synced: 1 },
					).forEach((cartItemModel) => {
						const externalItem = _.findWhere(
							ShoppingCartRequestProductList,
							{
								Source: 'sosocio',
								Key: cartItemModel.id.toString(),
							},
						);
						if (!externalItem) {
							toRemove.push(cartItemModel);
						} else if (externalItem.Quantity != cartItemModel.quantity && cartItemModel.groupid != 139) {
							CartItemsModule.putModel({
								id: cartItemModel.id,
								data: {
									quantity: externalItem.Quantity,
								},
							});
						}
					});

					const externalItems: ExternalCartItemModel[] = [];
					ShoppingCartRequestProductList.forEach((product) => {
						if (product.Source !== 'sosocio') {
							externalItems.push({
								id: product.Key,
								name: product.Name,
								price: product.ListPrice * 100,
								quantity: product.Quantity,
							});
						}
					});
					AppStateModule.setExternalCartItems(externalItems);
				}

				const arrPromises: Promise<AxiosResponse<any> | void>[] = [];

				toRemove.forEach((cartItemModel) => {
					arrPromises.push(
						CartItemsModule.destroyModel({ id: cartItemModel.id }),
					);
				});

				return Promise.allSettled(arrPromises);
			})
			.then(() => undefined).catch((err) => {
				// Swallow error, we run resolve in any case
				// Send to error log for tracking
				if (typeof window.glBugsnagClient !== 'undefined') {
					window.glBugsnagClient.notify(
						err,
						(event) => { event.severity = 'warning'; },
					);
				}

				// This function should always resolve, so we don't throw the error
				return undefined;
			});
	}

	static fetchDatabase() {
		const requestData: {
			aid: number;
			countryid: number | null;
			locale: string | null;
		} = {
			aid: window.affiliateID,
			countryid: UserModule.countryid ? UserModule.countryid : null,
			locale: window.locale ? window.locale : null,
		};

		// Fetch user data from server
		return ajax
			.request(
				{
					method: 'get',
					url: '/api/app/user',
					params: requestData,
				},
				{
					auth: true,
				},
			)
			.then((response: AxiosResponse<API.UserDataModel>) => {
				const responseData = response.data;

				if (responseData.user) {
					/* eslint-disable @typescript-eslint/no-non-null-assertion */
					// Update user model properties
					UserModule.set(responseData.user);

					// Add user data
					ExternalUsersModule.addModels(_.toArray(responseData.user_external!));
					AddressesModule.addModels(_.toArray(responseData.addresses!));
					SubscriptionsModule.addModels(responseData.subscriptions!);
					ProductsModule.addModels(_.toArray(responseData.products!));

					// Add discount data (if user has valid discount)
					if (responseData.voucher) {
						DiscountModule.setVoucher(responseData.voucher);

						if (responseData.discount) {
							DiscountModule.setDiscount(responseData.discount);
							DiscountModule.addProductTypes(responseData.discount_producttypes!);
							DiscountModule.addShippings(responseData.discount_country!);
						}
					}

					// Add shopping cart data
					if (responseData.cart) {
						CartModule.set(responseData.cart);
					}

					// Setup shopping cart items data
					CartItemsModule.reset({
						data: _.toArray(responseData.cartitems!),
					});

					// Add loyalty group data
					if (responseData.loyaltygroup) {
						UserDataModule.setLoyalty(responseData.loyaltygroup);
					}

					// Add saved data about user completing product tours
					if (responseData.tours) {
						UserDataModule.setToursModels(responseData.tours);
					}
					/* eslint-enable @typescript-eslint/no-non-null-assertion */
				}
			});
	}

	static getUserCountry(): Promise<DB.CountryModel['id']> {
		if (UserModule.countryid) {
			return Promise.resolve(UserModule.countryid);
		}

		return detectCountry()
			.then((countryModel) => {
				const data: {
					countryid: number;
					currency?: string;
				} = {
					countryid: countryModel.id,
				};

				if (!UserModule.currency || !AppDataModule.findRegionCurrencyLinkWhere({
					currencyid: UserModule.currency,
					regionid: countryModel.regionid,
				})) {
					const currency = AppDataModule.getCurrencyByCountryId(countryModel.id)[0].id;
					data.currency = currency;
				}

				if (UserModule.id) {
					return UserModule.put({
						data,
					}).then((userModel) => userModel.countryid);
				}

				UserModule.set(data);

				return countryModel.id;
			})
			// Swallow error: no action required
			.catch((): Promise<DB.CountryModel['id']> => new Promise((resolve) => {
				const { close: closeDialog } = DialogService.openDialog({
					header: {
						hasCloseButton: false,
					},
					body: {
						component: CountrySelectorItemsView,
						listeners: {
							closeDialog: (event: ServiceEvent<DB.CountryModel['id']>) => {
								closeDialog();
								resolve(event.payload);
							},
						},
					},
					classes: {
						chrome: false,
					},
					width: 500,
				});
			}))
			.then((countryId) => {
				if (countryId) {
					return countryId;
				}

				throw new Error('Missing country');
			});
	}

	/**
	 * Validate user data
	 * @returns {Promise<void>}
	 */
	static validate(): Promise<void> {
		// check if user country is supported
		if (UserModule.countryid
			&& !AppDataModule.getCountry(UserModule.countryid)
		) {
			// reset user country
			UserModule.set({
				countryid: null,
			});
		}

		return this.getUserCountry()
			.then(async (countryId) => {
				if (UserModule.countryid != countryId) {
					UserModule.set({
						countryid: countryId,
					});
				}

				/**
				 * Search for possible missing offerings data
				 * linked to shopping cart items
				 */
				const offeringIdsToFetch: number[] = [];

				// eslint-disable-next-line no-restricted-syntax
				for (const cartItemModel of CartItemsModule.collection) {
					const offeringModelFound = AppDataModule.findOfferingWhere({
						id: cartItemModel.offeringid,
					});

					if (!offeringModelFound) {
						offeringIdsToFetch.push(cartItemModel.offeringid);
					}
				}

				/**
				 * If there are missing offerings data, fetch it
				 * from the server
				 */
				if (offeringIdsToFetch.length > 0) {
					// eslint-disable-next-line no-restricted-syntax
					for (const offeringId of offeringIdsToFetch) {
						// eslint-disable-next-line no-await-in-loop
						await AppDataModule.fetchOfferingsData({
							searchProps: {
								id: offeringId,
								get_from_flexgroupid: true,
							},
						});
					}
				}

				// Destroy shopping cart items not available in this region
				CartItemsModule.cleanByCountryId(countryId);

				// Check if user currency is supported
				const userCurrency = UserModule.currency;
				const countryModel = AppDataModule.getCountry(countryId);

				if (
					countryModel
					&& (
						!userCurrency
						|| !AppDataModule.findRegionCurrencyLinkWhere({
							currencyid: userCurrency,
							regionid: countryModel.regionid,
						})
					)
				) {
					const currency = AppDataModule.getCurrencyByCountryId(countryId)[0].id;
					UserModule.set({ currency });

					return UserModule.put()
						.then(() => undefined)
						.catch(() => undefined);
				}

				return undefined;
			});
	}

	static create(attributes: Partial<DB.UserModel>): Promise<void> {
		const defaults = {
			affiliateid: window.affiliateID,
		};
		attributes = _.extend(
			defaults,
			attributes,
		);

		const currentUserId = UserModule.id;

		return UserModule
			.save({
				data: attributes,
			})
			.then(() => {
				if (UserModule.id) {
					if (!currentUserId) {
						// Register user in analytics tracking
						analytics.registerUser();
					} else {
						analytics.aliasUser(
							currentUserId,
							UserModule.id,
						);
					}
				}

				// Setup user data
				return this.setupData(currentUserId || undefined);
			});
	}

	static save(): Promise<void> {
		if (!AppStateModule.online) {
			throw new Error(ERRORS_OFFLINE);
		}

		return User.validate()
			.then(() => User.saveToDatabase());
	}

	static saveToDatabase(): Promise<void> {
		if (UserModule.id) {
			return this.saveCart()
				.then(() => this.saveCartAddressModels());
		}

		if (UserModule.id === null
			&& UserModule.temporary !== null
		) {
			return this.create({})
				.then(() => this.saveToDatabase());
		}

		throw new Error('No user configured');
	}

	static saveCart(): Promise<DB.ShoppingCartModel> {
		if (!CartModule.id) {
			// Save model to database
			return CartModule.save({
				data: {
					userid: UserModule.id,
				},
			});
		}

		return Promise.resolve(CartModule.getState);
	}

	static saveCartAddressModels(): Promise<void> {
		if (!ConfigModule.hasWebshopModule) {
			// External webshop, so no need for shipping- and billing addresses, continue
			return Promise.resolve();
		}

		let billingaddress;
		let shippingaddress;

		// Get shipping and billing address models
		if (CartModule.billingaddressid) {
			billingaddress = AddressesModule.getById(CartModule.billingaddressid);
		}
		if (CartModule.shippingaddressid) {
			shippingaddress = AddressesModule.getById(CartModule.shippingaddressid);
		}

		const countryId = UserModule.countryid;
		const countryModel = countryId ? AppDataModule.getCountry(countryId) : undefined;
		if (!countryModel) {
			throw new Error('No valid country set for user');
		}

		if (!billingaddress) {
			// No billing address exists, create one
			return AddressesModule
				.createModel({
					data: {
						userid: UserModule.id,
						country: countryModel.iso,
					},
				})
				.then((addressModel) => CartModule.save({
					data: {
						billingaddressid: addressModel.id,
					},
				}))
				.then(() => this.saveCartAddressModels());
		}

		if (!shippingaddress) {
			// No shipping address exists, create one
			return AddressesModule
				.createModel({
					data: {
						userid: UserModule.id,
						country: countryModel.iso,
					},
				})
				.then((addressModel) => CartModule.save({
					data: {
						shippingaddressid: addressModel.id,
					},
				}))
				.then(() => this.saveCartAddressModels());
		}

		// Shipping- and billing address saved, continue
		return Promise.resolve();
	}

	// Remove all data references for improved memory management
	static clearMemory(): Promise<void> {
		return PhotosModule.reset();
	}
}
