import './defines';
import * as DB from 'interfaces/database';
import { AppDataModule } from 'store';
import { toNative } from 'utils/vue-facing-decorator';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'UpsellSelectItemView',
	emits: [
		'findCartItemUpsell',
		'handleCartItemUpsell',
	],
	mixins: [Template],
})
class UpsellSelectItemView extends Vue {
	@Prop({
		required: true,
		type: Number,
		description: 'The cart item id associated with this upsell',
	})
	public readonly cartItemId!: number;

	@Prop({
		required: true,
		type: Number,
		description: 'The id associated with this upsell',
	})
	public readonly productId!: number;

	@Prop({
		required: true,
		type: Object,
		description: 'The upsell model associated with each upsell',
	})
	public readonly upsellModel!: DB.UpsellModel;

	@Prop({
		required: true,
		type: Object,
		description: 'The cart item model associated with this upsell',
	})
	public readonly cartItemModel!: DB.ShoppingCartItemModel;

	@Prop({
		required: true,
		type: String,
		description: 'The price label of the upsell',
	})
	public readonly priceLabel!: string;

	@Prop({
		required: true,
		type: Number,
		description: 'The quantity of the upsell',
	})
	public readonly quantity!: number;

	private internalQuantity = 0;

	@Watch(
		'quantity',
		{
			immediate: true,
		},
	)
	protected onValueChange() {
		this.$nextTick(() => {
			this.internalQuantity = this.quantity;
		});
	}

	protected get maxQuantity(): number {
		if (this.upsellModel.peritem) {
			return this.cartItemModel?.quantity;
		}

		if (this.upsellModel.quantity === 0) {
			return 1;
		}

		return 100;
	}

	protected get offeringDescriptionLineOne(): string {
		return this.$t(
			`offerings:${this.upsellModel.upsellid}.lines.0`,
			'',
		);
	}

	protected get offeringDescriptionLineTwo(): string {
		return this.$t(
			`offerings:${this.upsellModel.upsellid}.lines.1`,
			'',
		);
	}

	protected get upsellDescriptionText(): string {
		return this.$t(
			`upsells:${this.upsellModel.upsellid}.description`,
			'',
		);
	}

	protected get offeringImage(): string {
		const offeringModel = AppDataModule.getOffering(this.upsellModel.upsellid);
		return offeringModel
			? offeringModel.thumbnail
			: '';
	}

	protected get offeringName(): string {
		return AppDataModule.getOfferingName(this.upsellModel.upsellid);
	}

	protected mounted(): void {
		this.findCartItemUpsell();
	}

	private findCartItemUpsell(): void {
		this.$emit(
			'findCartItemUpsell',
			{
				cartItemId: this.cartItemId,
				upsellId: this.upsellModel.upsellid,
			},
		);
	}

	protected beforeUnmount(): void {
		this.handleCartItemUpsell();
	}

	private handleCartItemUpsell(): void {
		if (!this.cartItemModel) {
			throw new Error('Could not find associated cart item model');
		}

		// Note: Id could have been changed from component property (by save to server), so recompile
		this.$emit(
			'handleCartItemUpsell',
			{
				cartItemModel: this.cartItemModel,
				upsellModel: this.upsellModel,
				quantity: this.internalQuantity,
			},
		);
	}
}

export default toNative(UpsellSelectItemView);
