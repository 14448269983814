import Bugsnag, { BreadcrumbType } from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import User from 'classes/user';
import EventBus from 'components/event-bus';
import { enableRouting } from 'components/router';
import ajax from 'controllers/ajax';
import analytics from 'controllers/analytics';
import auth from 'controllers/auth';
import experiment from 'controllers/experiment';
import storage from 'controllers/storage';
import Support from 'controllers/support';
import upload from 'controllers/upload';
import * as DB from 'interfaces/database';
import LogRocket from 'logrocket';
import CheckConnection from 'services/check-connection';
import * as DialogService from 'services/dialog';
import { ERRORS_OFFLINE } from 'settings/errors';
import store, {
	AppDataModule,
	AppStateModule,
	ChannelsModule,
	ConfigModule,
	DiscountModule,
	UserDataModule,
	UserModule,
} from 'store';
import detectUpload from 'tools/detect-upload';
import getCookie from 'tools/get-cookie';
import parseUrl from 'tools/parse-url';
import setCookie from 'tools/set-cookie';
import _ from 'underscore';
import URL_PARAMETERS from 'settings/url-parameters';
import type { createApp } from 'vue';

function fetch(): Promise<void> {
	// Fetch data
	if (!AppStateModule.online) {
		// Not online and no data locally available, so reject with 'offline' reason
		return Promise.reject(new Error(ERRORS_OFFLINE));
	}

	return AppDataModule
		.fetch({
			dataToExclude: [
				'flexgroups',
				'model2ds',
				'model3ds',
				'pricing',
				'region_offerings',
				'offeringframes',
				'offeringframeimages',
				'offeringframetemplates',
				'offerings',
				'offeringoptions',
				'offeringoptionvalues',
				'offeringoptionvalue_offerings',
				'productcategories',
				'translations_offerings',
			],
		})
		.catch((err) => {
			if (!AppStateModule.online) {
				// Not online and no data locally available, so reject with 'offline' reason
				throw new Error(ERRORS_OFFLINE);
			}

			// App is online, but fetching from database failed, so reject
			throw err;
		});
}

function setup(performFetch = true): Promise<void> {
	const parsedUrl = parseUrl(window.location.href);
	const urlVars = parsedUrl.parameters;

	if (urlVars.hasOwnProperty(URL_PARAMETERS.voucherCode)) {
		storage.set(
			'vc',
			urlVars[URL_PARAMETERS.voucherCode],
		);
	}

	const fetchPromise = (
		performFetch
			? fetch()
			: Promise.resolve()
	);

	return fetchPromise
		.then(() => {
			if (ConfigModule['LogRocket.id']
				&& ConfigModule['LogRocket.enabled']
			) {
				// We accept the incoming URL for LogRocket recording if:
				// * there is no filter defined (accept all incoming requests)
				// * OR
				// * the incoming URL matches the defined regex filter
				const acceptUrl = !ConfigModule['LogRocket.filter.url']
					|| new RegExp(
						ConfigModule['LogRocket.filter.url'],
						'g',
					).exec(window.location.href);
				if (acceptUrl) {
					LogRocket.init(ConfigModule['LogRocket.id']);
				}
			}

			if (ConfigModule['debug.remotejs']) {
				(function () { // eslint-disable-line func-names
					const s = document.createElement('script');
					s.src = 'https://remotejs.com/agent/agent.js';
					s.setAttribute(
						'data-consolejs-channel',
						ConfigModule['debug.remotejs'],
					);
					document.head.appendChild(s);
				}());
			}
		})
		.then(() => {
			// Disable uploads for devices that have no such functionality
			if (!detectUpload()) {
				ChannelsModule.removeModel('upload');
			}

			// Get user country
			let countryid = null;
			if (urlVars.hasOwnProperty(URL_PARAMETERS.country)) {
				const countryModel = AppDataModule.findCountryWhere({
					iso: urlVars[URL_PARAMETERS.country],
				});
				countryid = countryModel ? countryModel.id : null;
			} else if (urlVars.hasOwnProperty(URL_PARAMETERS.countryId) && parseInt(
				urlVars[URL_PARAMETERS.countryId],
				10,
			) > 0) {
				// Backwards compatability - countryid parameter has been replaced with country
				countryid = parseInt(
					urlVars[URL_PARAMETERS.countryId],
					10,
				);
			}
			if (!countryid
				&& AppDataModule.countries.length == 1
			) {
				countryid = AppDataModule.countries[0].id;
			}

			const countryModel = countryid
				? AppDataModule.getCountry(countryid)
				: undefined;
			const regionid = countryModel
				? countryModel.regionid
				: null;
			let currency = null;

			if (regionid
				&& AppDataModule.findRegionCurrencyLink({ regionid }).length == 1
			) {
				const regionCurrencyModel = AppDataModule.findRegionCurrencyLinkWhere({ regionid });
				if (regionCurrencyModel) {
					currency = regionCurrencyModel.currencyid;
				}
			} else if (countryid
				&& AppDataModule.getCountry(countryid)) {
				const cModel = AppDataModule.getCountry(countryid);
				if (cModel) {
					({ currency } = cModel);
				}
			} else if (urlVars.hasOwnProperty(URL_PARAMETERS.currency)
				&& urlVars[URL_PARAMETERS.currency]
			) {
				const currencyModel = AppDataModule.getCurrency(urlVars[URL_PARAMETERS.currency])
					?? AppDataModule.getCurrencyByISO(urlVars[URL_PARAMETERS.currency]);
				if (currencyModel) {
					currency = currencyModel.id;
				}
			} else if (AppDataModule.defaultCurrency) {
				const currencyModel = AppDataModule.defaultCurrency;
				if (currencyModel) {
					currency = currencyModel.id;
				}
			}

			// Check for prefilled voucher codes in the URL (do not overwrite if already set)
			const discountVoucherModel = DiscountModule.getVoucher;
			if (!discountVoucherModel.code) {
				if (urlVars.hasOwnProperty('vc')) {
					// Voucher code found, add to discount state model
					DiscountModule.setVoucher({
						code: urlVars.vc,
					});
				} else {
					const voucherCode = storage.get('vc');
					if (voucherCode) {
						// Voucher code found, add to discount state model
						DiscountModule.setVoucher({
							code: voucherCode,
						});
					}
				}
			}

			const userAttributes: Partial<DB.UserModel> = {};

			if (countryid) {
				userAttributes.countryid = countryid;
			}
			if (currency) {
				userAttributes.currency = currency;
			}
			if (!UserModule.language) {
				userAttributes.language = window.locale;
			}
			if (!UserModule.affiliateid) {
				userAttributes.affiliateid = window.affiliateID;
			}

			if (UserModule.id) {
				return UserModule
					.put({
						data: userAttributes,
					})
					.then(() => undefined)
					.catch(() => undefined);
				// Note: On failure swallow error: no action required
			}

			return UserModule.setAndWaitForProductCategoriesData(userAttributes);
		})
		.catch((error) => {
			if (error instanceof Error && error.message == ERRORS_OFFLINE) {
				return new Promise((res, rej) => {
					const closeError = DialogService.openErrorDialog({
						header: {
							hasCloseButton: false,
							title: window.App.router.$t('dialogHeaderOffline'),
						},
						body: {
							content: window.App.router.$t('dialogTextOffline'),
						},
						footer: {
							buttons: [
								{
									id: 'accept',
									text: window.App.router.$t('dialogButtonOfflineOk'),
									click: () => {
										CheckConnection()
											.catch(() => {
												// Swallow error: no action required
											})
											.finally(() => {
												setup().then(
													res,
													rej,
												);
											});
										closeError();
									},
								},
							],
						},
					});
				});
			}

			throw error;
		});
}

function checkToken(): Promise<void> {
	const parsedUrl = parseUrl(window.location.href);
	const urlVars = parsedUrl.parameters;

	if (urlVars[URL_PARAMETERS.accessCode]
		|| urlVars[URL_PARAMETERS.userToken]
	) {
		const loginData: Record<string, string> = {};

		// eslint-disable-next-line camelcase
		if (urlVars[URL_PARAMETERS.accessCode]) {
			window.App.router.removeURLParameter(URL_PARAMETERS.accessCode);
			// eslint-disable-next-line camelcase
			loginData.access_code = urlVars[URL_PARAMETERS.accessCode];
		// eslint-disable-next-line camelcase
		} else if (urlVars[URL_PARAMETERS.userToken]) {
			window.App.router
				.removeURLParameter(URL_PARAMETERS.userToken)
				.then(() => window.App.router.setLoading(true));
			// eslint-disable-next-line camelcase
			loginData.userAccessToken = urlVars[URL_PARAMETERS.userToken];
		}

		return auth.login(
			loginData,
			false,
		);
	}

	return Promise.resolve();
}

export function configureBugsnag(appInstance: ReturnType<typeof createApp>): void {
	// Setup Bugsnag for error logging
	const enabledBreadcrumbTypes: BreadcrumbType[] = [
		'navigation',
		'request',
		'process',
		'user',
		'state',
		'error',
		'manual',
	];

	if (window.glStack != 'local') {
		enabledBreadcrumbTypes.push('log');
	}

	window.glBugsnagClient = Bugsnag.start({
		apiKey: '7108d26410396261866519684a732fd5',
		appVersion: VERSION,
		appType: ConfigModule['app.name'] || undefined,
		collectUserIp: false,
		releaseStage: window.glStack,
		enabledBreadcrumbTypes,
		enabledReleaseStages: [
			'staging',
			'live',
		],
		onError: (report) => {
			if (LogRocket?.sessionURL) {
				report.addMetadata(
					'LogRocket',
					{
						sessionURL: LogRocket.sessionURL,
					},
				);
			}

			if (UserModule.id) {
				report.setUser(
					UserModule.id.toString(),
				);
			}
		},
		plugins: [
			// @ts-ignore
			new BugsnagPluginVue(),
		],
	});
	const bugsnagVue = Bugsnag.getPlugin('vue');
	appInstance.use(bugsnagVue);
}

export function launch(performFetch = true): Promise<void> {
	const launchTime = new Date().getTime();

	// Save affiliateid cookie
	if (storage.enabled.cookie) {
		if (window.affiliateID) {
			setCookie(
				'aid',
				window.affiliateID,
			);
		} else if (getCookie('aid')) {
			const cookieValue = getCookie('aid');
			if (cookieValue) {
				window.affiliateID = parseInt(
					cookieValue,
					10,
				);
			}
		}
	}

	// Save locale cookie
	if (window.locale) {
		storage.set(
			'locale',
			window.locale,
		);
	}

	// Check for existing anonymous user id
	const deviceId = storage.get('deviceId');

	if (deviceId) {
		// This device already has an id, so overwrite the value in the store
		UserDataModule.setDeviceId(deviceId);
	} else {
		// Device does not have id yet
		// Save the new value to the local storage so we can retrieve it in future sessions
		storage.set(
			'deviceId',
			UserDataModule.getDeviceId,
		);
	}

	// Set online status
	if (navigator.onLine) {
		AppStateModule.setOnline();
	} else {
		AppStateModule.setOffline();
	}

	// Enable autoSave
	// Note: This must be done after setOnline (to prevent AppStateModule.save from triggering in AppStateModule.setOnline)
	AppStateModule.enableAutoSave();

	// Check if required functionality is available in browser
	if (
		!storage.enabled.cookie
		&& !storage.enabled.data
	) {
		// No cookies, and no local storage available
		// Show error message and abort app
		DialogService.openErrorDialog({
			body: {
				content: window.App.router.$t('cookiesDisabled'),
			},
			footer: {
				buttons: undefined,
			},
		});

		return Promise.reject();
	}

	return checkToken()
		// Setup user and app data
		.then(() => User.setup(
			false,
			false,
		))
		.then(() => setup(performFetch))
		.then(() => User.validate())
		.then(() => User.setupSubscriptions())
		// Sync external shopping basket with ours
		.then(() => User.syncExternalCart())
		// Setup analytics tracking
		.then(() => analytics.setup())
		// Setup experiment flags: needs to be done after analytics setup
		// so that initial flag values can be send to analytics controller
		.then(() => experiment.init())
		.then(() => {
			// Send user traits to analytics providers
			analytics.setUserProperties();

			// Setup support integrations
			Support.setup();

			// Setup upload controller
			upload.init();

			// Setup auth controller
			auth.init();

			// Clean storage data and files that are no longer used
			storage.garbageCleaner();

			// Close loader dialog
			DialogService.closeLoaderDialog();

			// Track performance from loading of HTML to launch
			analytics.trackPerformance(
				'Launch time',
				launchTime - window.startTime,
			);

			analytics.trackEvent(
				'App started',
				{
					version: VERSION,
					platform: window.glPlatform,
					path: window.location.pathname,
				},
			);

			/**
			 * Indicate that the app has finalized its launch
			 * and the routing can be enabled
			 */
			enableRouting();

			// Add auto save interval
			const throttle = _.throttle(
				() => {
					AppStateModule.save()
						.catch((e) => {
							// Swallow error: no action required
							if (typeof window.glBugsnagClient !== 'undefined') {
								window.glBugsnagClient.notify(
									e,
									(event) => { event.severity = 'warning'; },
								);
							}
						});
				},
				5000,
				{ leading: false },
			);
			store.watch(
				(state, getters) => getters['productstate/getSaved'],
				(saved) => {
					if (saved === true) {
						throttle.cancel();
					} else if (saved === false && AppStateModule.autoSave) {
						throttle();
					}
				},
			);

			// Check if we have the latest app data on app resume
			EventBus.on(
				'app:resume',
				() => {
					ajax
						.request(
							{
								url: '/webhook/ping',
								method: 'get',
							},
							{
								auth: false,
								debug: {
									log: false,
								},
							},
						)
						.then((resp) => {
							if (resp
							&& resp.data
							&& resp.data['data-version']
							&& resp.data['data-version'] != window.appDataVersion
							) {
								window.dataUpdate = true;
							}
						})
						.catch(() => {
						// Do nothing
						});
				},
			);

			return undefined;
		})
		.catch((e) => {
			// Close loader dialog
			DialogService.closeLoaderDialog();

			// Data could not be loaded
			// Show error dialog
			const closeError = DialogService.openErrorDialog({
				body: {
					content: window.App.router.$t('dialogTextLaunchError'),
				},
				footer: {
					buttons: [
						{
							id: 'accept',
							text: window.App.router.$t('dialogButtonErrorOk'),
							click: () => {
								window.location.reload();
								closeError();
							},
						},
					],
				},
			});

			if (typeof window.glBugsnagClient !== 'undefined') {
				window.glBugsnagClient.notify(
					e,
					(event) => { event.severity = 'error'; },
				);
			}

			throw e;
		});
}
