import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line import/prefer-default-export
export function randomUUID(): string {
	if ('randomUUID' in crypto) {
		return crypto.randomUUID();
	}

	return uuidv4();
}
