<template>
	<div class="offeringVariantSelect">
		<div class="introText">
			{{ $t('dialogTextOfferingVersion') }}
		</div>
		<ul
			class="list"
		>
			<li
				v-for="option in options"
				:key="option.id"
				class="listItem"
				@click="selectOption(option.id)"
			>
				<div class="textNode">
					<div class="textWrapper">
						<span class="itemHeader">
							{{ option.text }}
						</span>
					</div>
					<div
						v-if="option.price"
						class="priceWrapper"
					>
						<span class="priceValue">
							<PriceView
								:cents="option.price"
								:currency="currencyModel ? currencyModel.id : undefined"
							/>
						</span>
					</div>
				</div>
				<div
					v-if="option.image"
					class="imageNode"
				>
					<img
						:src="option.image"
						class="productImage"
						@load="imageLoaded"
					>
				</div>
			</li>
		</ul>
	</div>
</template>

<style lang="less" scoped>
@import '~lessVars';

.list {
    float: left;
	margin: 15px 0 0;
	font-size: @fontSize_XS;
	width: 100%;
	padding: 0;
	display: grid;
	row-gap: 20px;
	column-gap: 20px;
	grid-template-columns: 1fr;
}
.listItem {
	list-style: none;
	float: left;
	cursor: pointer;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;

	background-color: #f0f0f0;
	color: #282828;
	box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);

	& when(@brand="Hema") {
		box-shadow: inset 0 -1px 0 0 rgba(0,0,0,0.1);
		color: #323232;
		background-color: #e5e3de;
		text-transform: lowercase;
	}

	.imageNode {
		text-align: center;
		margin: 0;
		padding: 0;
		background: none;
		max-width: 50%;

		.productImage {
			max-width: 100%;
			max-height: 100px;
			display: block;
		}
	}
	.textNode {
		padding: 10px;

		.textWrapper {
			color: @black;

			& when(@brand="Hema") {
				color: #323232;
				text-transform: none;
			}

			.itemHeader {
				padding: 0 0 5px;
				clear: both;
				display: block;

				font-family: @fontFamilyBold;
				font-weight: @fontWeightBold;
				font-size: @fontSize_S;

				& when(@brand="Hema") {
					font-family: @fontFamilyDemiBold;
					font-weight: @fontWeightDemiBold;
					font-size: @fontSize_XS;
				}
			}
		}

		.priceWrapper {
			float: left;

			.priceValue {
				display: block;
				clear: both;
				color: #393939;
				font-size: @fontSize_XS2;

				& when(@brand="Hema") {
					font-family: @fontFamilyBold;
					font-weight: @fontWeightBold;
				}
			}
		}
	}
}
</style>
