import './defines';
import { toNative } from 'utils/vue-facing-decorator';
import {
	Component,
	Prop,
	Vue,
} from 'vue-facing-decorator';
import icon from './assets/icon.png';
import spinner from './assets/spinner.png';
import Template from './template.vue';

@Component({
	name: 'SpinnerComponent',
	mixins: [Template],
})
class SpinnerComponent extends Vue {
	@Prop({
		default: 100,
		type: Number,
	})
	public readonly size!: number;

	protected get iconSize(): number {
		return 0.65 * this.size;
	}

	protected spinner = spinner;

	protected icon = icon;

	private getPositionStyles = (size: number) => {
		const styles: Partial<CSSStyleDeclaration> = {
			left: `calc(50% - ${size / 2}px)`,
			top: `calc(50% - ${size / 2}px)`,
		};

		return styles;
	};

	protected get spinnerStyles(): Partial<CSSStyleDeclaration> {
		return this.getPositionStyles(this.size);
	}

	protected get iconStyles(): Partial<CSSStyleDeclaration> {
		return this.getPositionStyles(this.iconSize);
	}
}

export default toNative(SpinnerComponent);
