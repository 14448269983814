<template>
	<div class="subscriptionsView">
		<div
			v-show="!newsletterEmailModel && !automationEmailModel"
			class="loadingBar"
		/>
		<div
			v-if="newsletterEmailModel"
			class="checkboxHolder"
		>
			<i
				v-show="savingNewsletter"
				class="fa fa-spinner saveSpinner animationSpin"
			/>
			<input
				v-show="!savingNewsletter"
				id="newsletterEmail"
				v-model="newsletterEmail"
				type="checkbox"
			>
			<label for="newsletterEmail">
				{{ $t('subscriptionsEmailNewsletter') }}
			</label>
		</div>

		<div
			v-if="automationEmailModel"
			class="checkboxHolder"
		>
			<i
				v-show="savingAutomation"
				class="fa fa-spinner saveSpinner animationSpin"
			/>
			<input
				v-show="!savingAutomation"
				id="automationEmail"
				v-model="automationEmail"
				type="checkbox"
			>
			<label for="automationEmail">
				{{ $t('subscriptionsEmailAutomation') }}
			</label>
		</div>
	</div>
</template>

<style lang="less" scoped>
@import '~lessVars';
@import '~root/css/components/animation.less';
@import '~root/css/components/button.less';
@import '~root/css/components/loadbar.less';
@import '~root/css/components/view-wrapper.less';

.subscriptionsView {
	.checkboxHolder {
		float: left;
		width: 100%;
		padding-bottom: 15px;
		text-align: left;
		font-size: @fontSize_XS2;

		input {
			width: auto !important;
		}
	}
	.saveSpinner {
		color: black;
	}
}
</style>
