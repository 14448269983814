/* eslint no-underscore-dangle: ["error", {
	"allow": ['_loaded', '_loading', '_loadedRegular', '_loadedBold', '_loadedItalic', '_loadedBoldItalic', '_attempt', '_failed', '_addModel', '_updateModel']
}] */
import * as urlTools from '@sosocio/frontend-utils/url';
import axios, { AxiosRequestConfig } from 'axios';
import { FontModel } from 'interfaces/app';
import * as DB from 'interfaces/database';
import {
	ERRORS_INVALID_REQUEST_DATA,
	ERRORS_LOAD_FONT,
} from 'settings/errors';
import { ConfigModule } from 'store';
import getBucketUrl from 'tools/get-bucket-url';
import _ from 'underscore';
import { reactive } from 'vue';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';
import WebFont from 'webfontloader';

const defaultsFontModel: Partial<FontModel> = {
	_attempt: 0,
	_attemptDatauri: 0,
	_datauri: '',
	_failed: false,
	_failedDatauri: false,
	_fontUrl: '',
	_loaded: false,
	_loadedBold: false,
	_loadedBoldItalic: false,
	_loadedItalic: false,
	_loadedRegular: false,
	_loading: false,
	_loadingDataUri: false,
};

function getURLContent(
	url: string,
	asUri = false,
): Promise<string> {
	const noCorsUrl = urlTools.appendParameter(
		url,
		'noCorsHeader',
	);
	let config: AxiosRequestConfig | undefined;

	if (asUri) {
		config = {
			responseType: 'blob',
		};
	}

	return axios
		.get(
			noCorsUrl,
			config,
		)
		.then((response) => response.data)
		.then(async (data: Blob | string) => {
			if (typeof data === 'string') {
				const fontURLs = Array
					.from(data.matchAll(/url\(([^ )]+)/gi))
					.reduce(
						(urls, match) => {
							const originalUrl = url;
							const urlMatch = match[1];
							let realUrl = match[1]
								.replace(
									/['"]/g,
									'',
								)
								.replace(
									/^\//,
									`${new URL(url).origin}/`,
								);

							if (!urls.find((currentUrl) => currentUrl.urlMatch === urlMatch)) {
								const maxIterations = 10;
								let iterations = 0;

								while (/^..\//.test(realUrl)) {
									if (iterations >= maxIterations) {
										throw new Error(`Maximum '../' replacement iterations reached for ${originalUrl}`);
									}

									const urlObject = new URL(url);
									let pathname = urlObject.pathname.match(/(.*)(?=\/[^/]*\/[^/]*$)/)?.[0];

									if (!pathname) {
										pathname = '/';
									}

									url = `${urlObject.origin}${pathname}`;
									realUrl = realUrl
										.replace(
											/^..\//,
											'',
										)
										.replace(
											/(^(?!\.).*$)/,
											`${pathname}/$1`,
										)
										.replace(
											/['"]/g,
											'',
										)
										.replace(
											/^\//,
											`${new URL(url).origin}/`,
										);
									iterations += 1;
								}

								urls.push({
									urlMatch,
									realUrl,
								});
							}

							url = originalUrl;

							return urls;
						},
						[] as { urlMatch: string; realUrl: string; }[],
					);

				if (fontURLs.length) {
					// eslint-disable-next-line no-restricted-syntax
					for (const fontUrl of fontURLs) {
						data = data.replace(
							fontUrl.urlMatch,
							// eslint-disable-next-line no-await-in-loop
							await getURLContent(
								fontUrl.realUrl,
								true,
							),
						);
					}
				}
			} else if (asUri) {
				await new Promise<void>((resolve, reject) => {
					try {
						const reader = new FileReader();
						reader.onloadend = () => {
							data = reader.result as string;
							resolve();
						};
						reader.readAsDataURL(data as Blob);
					} catch (error) {
						reject(error);
					}
				});
			}

			return data as string;
		})
		.catch((error) => {
			console.error(
				'Error while fetching font url content',
				error,
			);

			if (typeof window.glBugsnagClient !== 'undefined') {
				window.glBugsnagClient.notify(
					new Error('Error while fetching font url content'),
					(event) => {
						event.errors.push(error);
						event.severity = 'warning';
					},
				);
			}

			throw error;
		});
}

@Module({ namespaced: true, name: 'font' })
export default class Font extends VuexModule {
	public collection = reactive<FontModel[]>([]);

	public get getById(): (id: string) => FontModel | undefined {
		return (id: string) => this.collection.find((fontModel) => fontModel.id === id);
	}

	public get getDefault() {
		return _.findWhere(
			this.collection,
			{ isDefault: true },
		)
			|| this.getPrintable[0];
	}

	public get getModelUrl(): (id: string) => string {
		return (id) => {
			const fontModel = this.getById(id);

			if (!fontModel) {
				throw new Error('Could not find font model');
			}

			const fonts: WebFont.Config = {};

			if (fontModel.url) {
				const bucketUrl = getBucketUrl('webfonts');
				const path = `${bucketUrl}fonts/`;
				const fontUrl = fontModel.url.substring(
					0,
					4,
				) == 'http'
					? fontModel.url
					: path + fontModel.url;

				fonts.custom = {};
				fonts.custom.families = [];
				fonts.custom.urls = [];

				if (!fontModel._loadedRegular) {
					fonts.custom.families.push(`${fontModel.id}:n4`);
					fonts.custom.urls.push(fontUrl);
				}
				if (fontModel.bold && !fontModel._loadedBold) {
					fonts.custom.families.push(`${fontModel.id} Bold:n7`);
					fonts.custom.urls.push(fontUrl);
				}
				if (fontModel.italic && !fontModel._loadedItalic) {
					fonts.custom.families.push(`${fontModel.id} Italic:i4`);
					fonts.custom.urls.push(fontUrl);
				}
				if (fontModel.bolditalic && !fontModel._loadedBoldItalic) {
					fonts.custom.families.push(`${fontModel.id} BoldItalic:i7`);
					fonts.custom.urls.push(fontUrl);
				}
			} else if (fontModel.provider == 'typekit') {
				if (fontModel.kitID) {
					fonts.typekit = {
						id: fontModel.kitID,
					};
				} else {
					throw new Error('Missing font kitID');
				}
			} else if (fontModel.provider == 'google') {
				let families = `${fontModel.id}:`;
				if (!fontModel._loadedRegular) {
					families += '400,';
				}
				if (fontModel.bold && !fontModel._loadedBold) {
					families += '700,';
				}
				if (fontModel.italic && !fontModel._loadedItalic) {
					families += '400italic,';
				}
				if (fontModel.bolditalic && !fontModel._loadedBoldItalic) {
					families += '700italic,';
				}

				if (fontModel.subset && fontModel.subset != 'latin') {
					// Only at the subset to the font url if it is not just latin, otherwise it will fail
					families += `&subset=${fontModel.subset}`;
				}

				fonts.google = {
					families: [families],
				};
			}

			return WebFont.getFontApiUrl(fonts)[0];
		};
	}

	public get getPrintable() {
		return _.filter(
			this.collection,
			(model) => model.print,
		);
	}

	@Mutation
	private _addModel(data: FontModel) {
		this.collection.push(data);
	}

	@Mutation
	private _updateModel(data: Partial<FontModel>) {
		const fontModelIndex = this.collection.findIndex((fontModel) => fontModel.id === data.id);
		const model = this.collection[fontModelIndex];
		this.collection[fontModelIndex] = _.extend(
			model,
			data,
		);
	}

	@Action({ rawError: true })
	public addModel(data: OptionalExceptFor<FontModel, 'id'>): Promise<FontModel> {
		if (!data.id) {
			return Promise.reject(
				new Error(ERRORS_INVALID_REQUEST_DATA),
			);
		}

		data = _.extend(
			JSON.parse(JSON.stringify(defaultsFontModel)),
			data,
		);

		if (this.getById(data.id)) {
			this._updateModel(data);
		} else {
			this._addModel(data as FontModel);
		}

		const fontModel = this.getById(data.id);

		if (!fontModel) {
			return Promise.reject(
				new Error('Could not find font model'),
			);
		}

		if (
			fontModel.autoload
			&& !fontModel._loaded
		) {
			return this
				.loadModel(fontModel.id)
				.then(() => fontModel);
		}

		return Promise.resolve(fontModel);
	}

	@Action({ rawError: true })
	public addModels(arrData: OptionalExceptFor<DB.FontModel, 'id'>[]) {
		const arrPromises: Promise<FontModel>[] = [];
		arrData.forEach((data) => arrPromises.push(this.addModel(data)));

		return Promise.all(arrPromises);
	}

	@Action({ rawError: true })
	public loadModel(id: string): Promise<FontModel> {
		const fontModel = this.getById(id);

		if (!fontModel) {
			return Promise.reject(
				new Error('Could not find font model'),
			);
		}

		if (fontModel._loaded) {
			return Promise.resolve(
				fontModel,
			);
		}

		if (fontModel._loading) {
			// Font is already loading, wait for loaded event
			return new Promise((resolve, reject) => {
				const int = window.setInterval(
					() => {
						if (fontModel._loaded) {
							window.clearInterval(int);
							resolve(fontModel);
						} else if (fontModel._failed) {
							window.clearInterval(int);
							reject(new Error(ERRORS_LOAD_FONT));
						}
					},
					100,
				);
			});
		}

		this._updateModel({
			id: fontModel.id,
			_attempt: (
				fontModel._failed
					? 1
					: fontModel._attempt + 1
			),
			_failed: false,
			_loading: true,
		});

		return new Promise((resolve, reject) => {
			const fonts: WebFont.Config = {
				fontactive: (fontFamily, fvd) => {
					if (fontModel.url) {
						fontFamily = fontFamily.replace(
							' BoldItalic',
							'',
						);
						fontFamily = fontFamily.replace(
							' Bold',
							'',
						);
						fontFamily = fontFamily.replace(
							' Italic',
							'',
						);
					}

					if (fvd == 'n4') {
						// normal
						this._updateModel({
							id: fontModel.id,
							_loadedRegular: true,
						});
					} else if (fvd == 'i4') {
						// italic
						this._updateModel({
							id: fontModel.id,
							_loadedItalic: true,
						});
					} else if (fvd == 'n7') {
						// bold
						this._updateModel({
							id: fontModel.id,
							_loadedBold: true,
						});
					} else if (fvd == 'i7') {
						// bold italic
						this._updateModel({
							id: fontModel.id,
							_loadedBoldItalic: true,
						});
					}

					if (
						fontModel.bold
						&& !fontModel._loadedBold
					) {
						// Do nothing
					} else if (
						fontModel.italic
						&& !fontModel._loadedItalic
					) {
						// Do nothing
					} else if (
						fontModel.bolditalic
						&& !fontModel._loadedBoldItalic
					) {
						// Do nothing
					} else if (!fontModel._loadedRegular) {
						// Do nothing
					} else {
						// Flag font as loaded after a short delay
						window.setTimeout(
							() => {
								this._updateModel({
									id: fontModel.id,
									_loaded: true,
									_loading: false,
								});

								resolve(fontModel);
							},
							ConfigModule['webfont.timeout'],
						);
					}
				},
				fontinactive: () => {
					if (fontModel._attempt === 3) {
						this._updateModel({
							id: fontModel.id,
							_loading: false,
							_failed: true,
						});

						reject(new Error(ERRORS_LOAD_FONT));
					} else {
						this._updateModel({
							id: fontModel.id,
							_loading: false,
						});

						// Try again
						this
							.loadModel(fontModel.id)
							.then(
								resolve,
								reject,
							);
					}
				},
			};

			if (fontModel.url) {
				const bucketUrl = getBucketUrl('webfonts');
				const path = `${bucketUrl}fonts/`;
				const fontUrl = fontModel.url.substring(
					0,
					4,
				) == 'http'
					? fontModel.url
					: path + fontModel.url;

				fonts.custom = {};
				fonts.custom.families = [];
				fonts.custom.urls = [];

				if (!fontModel._loadedRegular) {
					fonts.custom.families.push(`${fontModel.id}:n4`);
					fonts.custom.urls.push(fontUrl);
				}
				if (
					fontModel.bold
					&& !fontModel._loadedBold
				) {
					fonts.custom.families.push(`${fontModel.id} Bold:n7`);
					fonts.custom.urls.push(fontUrl);
				}
				if (
					fontModel.italic
					&& !fontModel._loadedItalic
				) {
					fonts.custom.families.push(`${fontModel.id} Italic:i4`);
					fonts.custom.urls.push(fontUrl);
				}
				if (
					fontModel.bolditalic
					&& !fontModel._loadedBoldItalic
				) {
					fonts.custom.families.push(`${fontModel.id} BoldItalic:i7`);
					fonts.custom.urls.push(fontUrl);
				}
			} else if (fontModel.provider == 'typekit') {
				if (fontModel.kitID) {
					fonts.typekit = {
						id: fontModel.kitID,
					};
				} else {
					throw new Error('Missing font kitID');
				}
			} else if (fontModel.provider == 'google') {
				let families = `${fontModel.id}:`;

				if (!fontModel._loadedRegular) {
					families += '400,';
				}
				if (
					fontModel.bold
					&& !fontModel._loadedBold
				) {
					families += '700,';
				}
				if (
					fontModel.italic
					&& !fontModel._loadedItalic
				) {
					families += '400italic,';
				}
				if (
					fontModel.bolditalic
					&& !fontModel._loadedBoldItalic
				) {
					families += '700italic,';
				}

				if (
					fontModel.subset
					&& fontModel.subset != 'latin'
				) {
					// Only at the subset to the font url if it is not just latin, otherwise it will fail
					families += `&subset=${fontModel.subset}`;
				}

				fonts.google = {
					families: [families],
				};
			}

			// const fontUrl = WebFont.getFontApiUrl(fonts)[0];
			const normalizedFontName = fontModel.id
				.toLowerCase()
				.replace(
					' ',
					'',
				);
			const fontUrl = `https://sosocio-storage.s3-eu-west-1.amazonaws.com/webfonts/css/${normalizedFontName}.css`;

			if (fontUrl) {
				this._updateModel({
					id: fontModel.id,
					_fontUrl: fontUrl,
				});
			}

			WebFont.load(fonts);
		});
	}

	@Action({ rawError: true })
	public loadDataUri(id: string): Promise<FontModel> {
		/* eslint-disable no-underscore-dangle */
		const fontModel = this.getById(id);

		if (!fontModel) {
			return Promise.reject(
				new Error('Could not find font model'),
			);
		}
		if (
			fontModel._datauri
			|| !fontModel._fontUrl
		) {
			return Promise.resolve(fontModel);
		}

		if (fontModel._loadingDataUri) {
			/**
			 * Font data uri is already loading, wait for loaded event
			 */
			return new Promise((resolve) => {
				const int = window.setInterval(
					() => {
						if (
							fontModel._datauri
							|| fontModel._failedDatauri
						) {
							window.clearInterval(int);
							resolve(fontModel);
						}
					},
					100,
				);
			});
		}

		this._updateModel({
			id: fontModel.id,
			_attempt: (
				fontModel._failedDatauri
					? 1
					: fontModel._attemptDatauri + 1
			),
			_failedDatauri: false,
			_loadingDataUri: true,
		});

		const fonts: WebFont.Config = {};

		if (fontModel.url) {
			const bucketUrl = getBucketUrl('webfonts');
			const path = `${bucketUrl}fonts/`;
			const fontUrl = fontModel.url.substring(
				0,
				4,
			) == 'http'
				? fontModel.url
				: path + fontModel.url;

			fonts.custom = {};
			fonts.custom.families = [];
			fonts.custom.urls = [];

			if (!fontModel._loadedRegular) {
				fonts.custom.families.push(`${fontModel.id}:n4`);
				fonts.custom.urls.push(fontUrl);
			}
			if (
				fontModel.bold
				&& !fontModel._loadedBold
			) {
				fonts.custom.families.push(`${fontModel.id} Bold:n7`);
				fonts.custom.urls.push(fontUrl);
			}
			if (
				fontModel.italic
				&& !fontModel._loadedItalic
			) {
				fonts.custom.families.push(`${fontModel.id} Italic:i4`);
				fonts.custom.urls.push(fontUrl);
			}
			if (
				fontModel.bolditalic
				&& !fontModel._loadedBoldItalic
			) {
				fonts.custom.families.push(`${fontModel.id} BoldItalic:i7`);
				fonts.custom.urls.push(fontUrl);
			}
		} else if (fontModel.provider == 'typekit') {
			if (fontModel.kitID) {
				fonts.typekit = {
					id: fontModel.kitID,
				};
			} else {
				throw new Error('Missing font kitID');
			}
		} else if (fontModel.provider == 'google') {
			let families = `${fontModel.id}:`;

			if (!fontModel._loadedRegular) {
				families += '400,';
			}
			if (
				fontModel.bold
				&& !fontModel._loadedBold
			) {
				families += '700,';
			}
			if (
				fontModel.italic
				&& !fontModel._loadedItalic
			) {
				families += '400italic,';
			}
			if (
				fontModel.bolditalic
				&& !fontModel._loadedBoldItalic
			) {
				families += '700italic,';
			}

			if (
				fontModel.subset
				&& fontModel.subset != 'latin'
			) {
				// Only at the subset to the font url if it is not just latin, otherwise it will fail
				families += `&subset=${fontModel.subset}`;
			}

			fonts.google = {
				families: [families],
			};
		}

		return getURLContent(fontModel._fontUrl)
			.then((response) => {
				this._updateModel({
					id: fontModel.id,
					_datauri: response,
					_loadingDataUri: false,
				});
			})
			.catch(() => {
				if (fontModel._attemptDatauri === 3) {
					return this._updateModel({
						id: fontModel.id,
						_loadingDataUri: false,
						_failedDatauri: true,
					});
				}

				this._updateModel({
					id: fontModel.id,
					_loadingDataUri: false,
				});

				return this
					.loadDataUri(fontModel.id)
					.then(() => undefined);
			})
			.then(() => fontModel);
		/* eslint-enable no-underscore-dangle */
	}
}
