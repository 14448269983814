<template>
	<div class="savingProject">
		<svg
			viewBox="0 0 32 32"
			width="64"
			height="64"
		>
			<circle
				id="spinner"
				class="animation-spinner"
				cx="16"
				cy="16"
				r="14"
				fill="none"
			/>
		</svg>
		<span class="textNode mainMessage">
			{{ $t('views.savingProject.mainMessage') }}
		</span>
		<span class="textNode subMessage">
			{{ $t('views.savingProject.subMessage') }}
		</span>
		<span
			v-if="hasCancel"
			class="textNode cancel"
			@click="onCancel"
		>
			{{ $t('views.savingProject.cancel') }}
		</span>
	</div>
</template>

<style lang="less" scoped>
@import '~lessVars';

.savingProject {
	float: left;
	width: 100%;
	text-align: center;
}
.textNode {
	display: block;
	clear: both;
}
.mainMessage {
	margin-top: 10px;
}
.cancel {
	margin-top: 25px;
	text-decoration: underline;
	cursor: pointer;
}

</style>
