import { recomputeTriggers } from 'utils/vue';
import {
	type ComponentCustomOptions,
	defineComponent,
	type MethodOptions,
} from 'vue';
import { toNative as originalToNative } from 'vue-facing-decorator';
import type {
	ComponentSetupFunction,
	Cons,
	OptionSetupFunction,
} from 'vue-facing-decorator/dist/component';
import * as DecoratorCompatible from 'vue-facing-decorator/dist/esm/deco3/utils';
import { build as optionAccessor } from 'vue-facing-decorator/dist/esm/option/accessor';
import { build as optionComputed } from 'vue-facing-decorator/dist/esm/option/computed';
import { build as optionEmit } from 'vue-facing-decorator/dist/esm/option/emit';
import { build as optionInject } from 'vue-facing-decorator/dist/esm/option/inject';
import { build as optionMethodsAndHooks } from 'vue-facing-decorator/dist/esm/option/methodsAndHooks';
import { build as optionProps } from 'vue-facing-decorator/dist/esm/option/props';
import { build as optionProvide } from 'vue-facing-decorator/dist/esm/option/provide';
import { build as optionRef } from 'vue-facing-decorator/dist/esm/option/ref';
import { build as optionSetup } from 'vue-facing-decorator/dist/esm/option/setup';
import { build as optionVModel } from 'vue-facing-decorator/dist/esm/option/vmodel';
import { build as optionWatch } from 'vue-facing-decorator/dist/esm/option/watch';
import {
	excludeNames,
	getProviderFunction,
	getSuperSlot,
	getValidNames,
	makeObject,
	obtainSlot,
} from 'vue-facing-decorator/dist/esm/utils';
import type { OptionBuilder } from 'vue-facing-decorator/dist/optionBuilder';

export type ComponentOption = {
	__scopeId?: string;
	name?: string;
	emits?: string[];
	// eslint-disable-next-line @typescript-eslint/ban-types
	provide?: Record<string, any> | Function;
	components?: Record<string, any>
	directives?: Record<string, any>;
	inheritAttrs?: boolean;
	expose?: string[];
	// eslint-disable-next-line @typescript-eslint/ban-types
	render?: Function;
	modifier?: (raw: any) => any;
	options?: ComponentCustomOptions & Record<string, any>;
	template?: string;
	mixins?: any[];
	setup?: ComponentSetupFunction;
	methods?: MethodOptions;
	computed?: Record<string, any>;
}

type ComponentConsOption = Cons | ComponentOption;

function _Component(
	cb: (
		cons: Cons,
		option: ComponentOption,
	) => any,
	arg: ComponentConsOption,
	ctx?: ClassDecoratorContext,
	// eslint-disable-next-line @typescript-eslint/ban-types
): (arg: any, ctx?: DecoratorContext) => Function {
	if (typeof arg === 'function') {
		return DecoratorCompatible.compatibleClassDecorator((cons: Cons) => cb(
			cons,
			{},
		))(
			arg,
			ctx,
		);
	}

	return DecoratorCompatible.compatibleClassDecorator((cons: Cons) => cb(
		cons,
		arg,
	));
}

function buildComponent(
	cons: Cons,
	arg: ComponentOption,
	extend?: any,
): ReturnType<typeof defineComponent> {
	const option = ComponentOption(
		cons,
		extend,
	);
	const slot = obtainSlot(cons.prototype);
	Object
		.keys(arg)
		.reduce(
			(finalOptions, name: string) => {
				if (['options', 'modifier', 'methods', 'emits', 'setup', 'provide'].includes(name)) {
					return finalOptions;
				}

				finalOptions[name] = arg[name as keyof ComponentOption];

				return finalOptions;
			},
			option as Record<string, any>,
		);

	// apply event emits
	let emits = Array.from(slot.obtainMap('emits').keys());

	if (Array.isArray(arg.emits)) {
		emits = Array.from(new Set([...emits, ...arg.emits]));
	}

	option.emits = emits;

	// merge methods
	if (
		typeof arg.methods === 'object'
		&& !Array.isArray(arg.methods)
		&& arg.methods !== null
	) {
		option.methods ??= {};
		Object.assign(
			option.methods,
			arg.methods,
		);
	}

	// merge setup function
	if (!option.setup) {
		option.setup = arg.setup;
	} else {
		const oldSetup: OptionSetupFunction = option.setup;
		const newSetup: ComponentSetupFunction = (
			arg.setup
			?? function setup() {
				return {};
			}
		);

		const setup: ComponentSetupFunction = function setup(props, ctx) {
			const newRet = newSetup(
				props,
				ctx,
			);
			const oldRet = oldSetup(
				props,
				ctx,
			);

			if (
				oldRet instanceof Promise
				|| newRet instanceof Promise
			) {
				return Promise
					.all([newRet, oldRet])
					.then((arr) => ({
						...arr[0],
						...arr[1],
					}));
			}

			return {
				...newRet,
				...oldRet,
			};
		};

		option.setup = setup;
	}

	// merge provide function
	const oldProvider = getProviderFunction(option.provide);
	const newProvider = getProviderFunction(arg.provide);
	option.provide = function provide() {
		return { ...oldProvider.call(this), ...newProvider.call(this) };
	};

	// custom decorator
	const map = slot.getMap('customDecorator');

	if (
		map
		&& map.size > 0
	) {
		map.forEach((v) => {
			v.forEach((ite) => ite.creator.apply(
				{},
				[option, ite.key],
			));
		});
	}

	// shallow merge options
	if (arg.options) {
		Object.assign(
			option,
			arg.options,
		);
	}

	// apply modifier
	if (arg.modifier) {
		arg.modifier(option);
	}

	return defineComponent(option);
}

function build(
	cons: Cons,
	option: ComponentOption,
): void {
	const slot = obtainSlot(cons.prototype);
	slot.inComponent = true;
	const superSlot = getSuperSlot(cons.prototype);

	if (superSlot) {
		if (!superSlot.inComponent) {
			throw new Error(`Class should be decorated by Component or ComponentBase: ${slot.master}`);
		}

		if (superSlot.cachedVueComponent === null) {
			throw new Error('Component decorator 1');
		}
	}

	const component = buildComponent(
		cons,
		option,
		superSlot === null ? undefined : superSlot.cachedVueComponent,
	);

	component.__vfdConstructor = cons;
	slot.cachedVueComponent = component;
	(cons as any).__vccOpts = component;
}

export function optionData(cons: Cons, optionBuilder: OptionBuilder, vueInstance: any) {
	optionBuilder.data ??= {};
	// eslint-disable-next-line new-cap
	const sample = new cons(
		optionBuilder,
		vueInstance,
	) as any;
	let names = getValidNames(
		sample,
		(des, name) => !!des.enumerable
			&& !optionBuilder.methods?.[name]
			&& !optionBuilder.props?.[name],
	);
	const slot = obtainSlot(cons.prototype);
	names = excludeNames(
		names,
		slot,
		// include these names:
		// provide, user may access field directly
		(mapName) => !['provide'].includes(mapName),
	);
	names = names.filter((name) => typeof sample[name] !== 'undefined');
	Object.assign(
		optionBuilder.data,
		makeObject(
			names,
			sample,
		),
	);
}

function ComponentOption(cons: Cons, extend?: any) {
	const optionBuilder: OptionBuilder = {};
	optionSetup(
		cons as any,
		optionBuilder,
	);
	optionVModel(
		cons as any,
		optionBuilder,
	);
	optionComputed(
		cons as any,
		optionBuilder,
	);// after VModel
	optionWatch(
		cons as any,
		optionBuilder,
	);
	optionProps(
		cons as any,
		optionBuilder,
	);
	optionInject(
		cons as any,
		optionBuilder,
	);
	optionEmit(
		cons as any,
		optionBuilder,
	);
	optionRef(
		cons as any,
		optionBuilder,
	);// after Computed
	optionAccessor(
		cons as any,
		optionBuilder,
	);
	optionMethodsAndHooks(
		cons as any,
		optionBuilder,
	);// the last one
	const raw = {
		name: cons.name,
		setup: optionBuilder.setup,
		data() {
			delete optionBuilder.data;
			optionData(
				cons,
				optionBuilder,
				this,
			);

			return optionBuilder.data ?? {};
		},
		methods: optionBuilder.methods,
		computed: optionBuilder.computed,
		watch: optionBuilder.watch,
		props: optionBuilder.props,
		inject: optionBuilder.inject,
		provide() {
			optionProvide(
				cons as any,
				optionBuilder,
				this,
			);
			return optionBuilder.provide ?? {};
		},
		...optionBuilder.hooks,
		extends: extend,
	};

	return raw as any;
}

export function Component(
	arg: ComponentConsOption,
	ctx?: ClassDecoratorContext,
) {
	return _Component(
		(cons: Cons, option: ComponentOption) => {
			build(
				cons,
				option,
			);

			return cons;
		},
		arg,
		ctx,
	) as any;
}

export function toNative<T>(cons: T): T {
	const vccOpts = (cons as any).__vccOpts as ComponentOption;

	if (vccOpts.computed) {
		const computedKeys = Object.keys(vccOpts.computed);

		// eslint-disable-next-line no-restricted-syntax
		for (const key of computedKeys) {
			const originalGet = vccOpts.computed[key].get;
			vccOpts.computed[key].get = function get() {
				recomputeTriggers.get(key);

				return originalGet.apply(
					this,
					null,
				);
			};
		}
	}

	if (
		!vccOpts.render
		&& vccOpts.mixins
	) {
		const mixinFound = vccOpts.mixins.find((mixin: any) => mixin.render);

		if (mixinFound?.render) {
			vccOpts.render = mixinFound.render;
			vccOpts.mixins.splice(
				vccOpts.mixins.indexOf(mixinFound),
				1,
			);

			if (mixinFound.__scopeId) {
				vccOpts.__scopeId = mixinFound.__scopeId;
				// @ts-ignore
				vccOpts.render.__scopeId = mixinFound.__scopeId;
			}
		}
	}

	if (!vccOpts.__scopeId) {
		vccOpts.__scopeId = (vccOpts.render as any)?.__scopeId;
	}
	if (!vccOpts.__scopeId) {
		vccOpts.__scopeId = vccOpts.mixins?.find((mixin: any) => mixin.__scopeId)?.__scopeId;
	}

	return originalToNative(cons as Cons) as T;
}
