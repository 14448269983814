<template>
	<div class="offeringOptionsView">
		<div
			v-for="selectOption in selectOptions"
			v-show="selectOption.show"
			:key="selectOption.id"
			class="tr"
		>
			<div class="td">
				<label>
					{{ selectOption.label }}
				</label>
			</div>
			<div class="td">
				<select
					:id="selectOption.id"
					:name="selectOption.name"
					:value="selectOption.valueModel.id"
					class="selectBox"
					@input="selectedOptionOutput"
				>
					<option
						v-for="selectValueModel in selectOption.valueModels"
						:key="selectValueModel.id"
						:value="selectValueModel.id"
					>
						{{ getOptionLabel(selectValueModel) }}
					</option>
				</select>
			</div>
		</div>
		<div
			v-if="showPricing && isAvailableInRegion"
			class="tr priceRow"
		>
			<div class="td">
				{{ $t('price') }}
			</div>
			<div class="td productPrice">
				<PriceView
					:cents="selectedOfferingPrice"
				/>
			</div>
		</div>
	</div>
</template>

<style lang="less" scoped>
@import '~lessVars';
@import '~root/css/components/input.less';

.offeringOptionsView {
	float: left;
	width: 100%;

	.tr {
		display: flex;
		align-items: center;
		float: left;
		width: 100%;
		margin: 5px 0;

		@media @size480 {
			flex-wrap: wrap;
		}

		&.priceRow {
			margin-top: 15px;
		}
	}
	.td {
		float: left;

		&:first-child {
			width: 33%;
		}
		&:last-child {
			width: 66%;
		}

		@media @size480 {
			width: 100% !important;
		}
	}
}

.selectBox {
    margin: 2px 0;

    @media @mobile {
        width: 100%;
    }
    @media @desktop {
        width: 330px;
        max-width: 100%;
    }
}
.productPrice {
	font-family: @fontFamilyBold;
	font-weight: @fontWeightBold;
	font-size: @fontSize_M4;
}
</style>
