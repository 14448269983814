import './defines';
import { PhotoModel } from 'interfaces/project';
import { ProductStateModule } from 'store';
import { toNative } from 'utils/vue-facing-decorator';
import {
	Component,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	mixins: [Template],
})
class UploadErrorsView extends Vue {
	protected get items(): PhotoModel[] {
		return ProductStateModule.getPhotosFailed;
	}
}

export default toNative(UploadErrorsView);
