import './defines';
import { Model } from 'utils/decorators';
import { toNative } from 'utils/vue-facing-decorator';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'DialogPrompt',
	mixins: [Template],
})
class DialogPrompt extends Vue {
	@Model({
		required: true,
		type: Array,
	})
	public readonly value!: DialogPromptField[];

	@Prop({
		type: String,
	})
	public readonly content?: string;

	private internalValue: DialogPromptField[] = [];

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.$nextTick(() => {
			this.internalValue = this.value;
		});
	}

	protected onInputChange(
		fieldId: DialogPromptField['id'],
		value: DialogPromptField['value'],
	): void {
		const fieldFound = this.internalValue.find((field) => field.id === fieldId);

		if (fieldFound) {
			fieldFound.value = value;
			this.$emit(
				'update:value',
				this.internalValue,
			);
		}
	}
}

export default toNative(DialogPrompt);
