import ToolbarComponent from 'components/toolbar';
import { ServiceEvent } from 'services/service-event';
import {
	object as objectUtils,
	service as serviceUtils,
	vue as vueUtils,
} from 'utils';
import { type ComponentPublicInstance } from 'vue';
import { type Cons } from 'vue-facing-decorator/dist/component';

export { ServiceEvent } from 'services/service-event';

/* eslint-disable @typescript-eslint/indent */

export function openToolbar<
	BodyComponent extends Cons,
>(
	options: ServiceOptions<
		InstanceType<typeof ToolbarComponent<BodyComponent>>
	>,
): ServiceOpenReturn<
	InstanceType<typeof ToolbarComponent<BodyComponent>>
> {
	type ToolbarComponentType = InstanceType<typeof ToolbarComponent<BodyComponent>>;
	const defaultOptions: Omit<
		Partial<ServiceOptions<ToolbarComponentType>>,
		'body'
	> & { body: Omit<ServiceOptions<ToolbarComponentType>['body'], 'component'> } = {
		body: {
			classes: [],
			listeners: {},
			styles: {},
		},
	};
	let instance!: ComponentPublicInstance<ToolbarComponentType>;
	let fromCloseListener = false;
	const toolbarAPI: ServiceOpenReturn<ToolbarComponentType> = {
		api: {} as any,
		close() {
			const event = new ServiceEvent({
				type: 'close',
			});

			if (!fromCloseListener) {
				instance.close(event);
			} else {
				fromCloseListener = false;
			}

			if (!event.defaultPrevented) {
				toolbarAPI.destroy();
			}
		},
		destroy() {
			instance.$currentInstance.appContext.app.unmount();
			instance.$currentInstance.appContext.app._container?.remove();
		},
		isOpen: false,
	};
	Object.defineProperty(
		toolbarAPI,
		'isOpen',
		{
			enumerable: true,
			get() {
				return instance.$currentInstance.isMounted;
			},
		},
	);

	const finalOptions: typeof options = objectUtils.deepAssign(
		{},
		defaultOptions,
		options,
	);
	let { listeners } = finalOptions;

	const closeWithTransitionHandler = (immediate?: boolean) => {
		if (!immediate) {
			(instance.$el as HTMLElement).addEventListener(
				'transitionend',
				() => {
					toolbarAPI.close();
				},
				{
					once: true,
				},
			);
		}

		toolbarAPI.api.isOpen = false;

		if (immediate) {
			toolbarAPI.close();
		}
	};
	const closeListener = (event: ServiceEvent<boolean | undefined>) => {
		fromCloseListener = true;
		closeWithTransitionHandler(event.payload);
	};
	listeners = serviceUtils.addListener(
		'close',
		closeListener,
		listeners,
	);
	finalOptions.listeners = listeners;

	const parent = (
		options.parent
		|| window.App.router
	);
	const dialogsElement = document.getElementById('dialogs');
	const webAppElement = document.getElementById('webapp');

	const renderComponentOptions = vueUtils.defineRenderComponentOptions({
		component: ToolbarComponent,
		element: (
			dialogsElement?.parentElement
			|| webAppElement
		),
		insertBefore: (
			dialogsElement?.parentElement
				? dialogsElement
				: undefined
		),
		props: finalOptions,
		listeners,
	});

	if (
		options.body
		&& 'component' in options.body
		&& options.body.component
	) {
		if (!renderComponentOptions.slots) {
			renderComponentOptions.slots = {};
		}

		renderComponentOptions.slots.default = {
			component: options.body.component,
			props: options.body.props,
			listeners: options.body.listeners,
		};
	}

	instance = parent.$renderComponent(renderComponentOptions);
	toolbarAPI.api = vueUtils.getInstanceAPI(instance);

	requestAnimationFrame(() => {
		toolbarAPI.api.isOpen = true;
	});

	return toolbarAPI;
}

/* eslint-enable @typescript-eslint/indent */

const ToolbarService = {
	openToolbar,
};

export default ToolbarService;
