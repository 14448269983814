<template>
	<button
		type="button"
		class="facebook-button"
		:disabled="disabled"
		@click="clickButton"
	>
		<span class="facebook-button__icon">
			<FacebookIcon />
		</span>
		<span class="facebook-button__text">
			<slot />
		</span>
	</button>
</template>

<style lang="less" scoped>
@import '~lessVars';

// Facebook branding guideliness
// https://developers.facebook.com/docs/facebook-login/userexperience/#buttondesign

button {
	border: 0;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: none;
	font-family: inherit;
	cursor: pointer;
	color: inherit;
}
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}
.facebook-button {
	height: 40px;
	border-width: 0;
	background: #4267B2;
	color: #fff;
	border-radius: 2px;
	white-space: nowrap;
	box-shadow: 1px 1px 0px 1px rgba(0,0,0,0.05);
	transition-property: background-color, box-shadow;
	transition-duration: 150ms;
	transition-timing-function: ease-in-out;
	padding: 0;

	&:focus,
	&:hover {
		box-shadow: 1px 4px 5px 1px rgba(0,0,0,0.1);
	}

	&:active {
		box-shadow: none;
		transition-duration: 10ms;
	}
}
.facebook-button__icon {
	position: relative;
	padding: 5px;
	display: inline-block;
	vertical-align: middle;
	width: 40px;
	height: 40px;
	box-sizing: border-box;
}
.facebook-button__text {
	display: inline-block;
	vertical-align: middle;
	padding: 0 16px;
	font-size: @fontSize_XS2;
	font-weight: bold;
}
</style>
