import './defines';
import analytics from 'controllers/analytics';
import Support from 'controllers/support';
import openWindow from 'ops/open-window';
import {
	AppDataModule,
	AppStateModule,
	ConfigModule,
} from 'store';
import ClickOutside from 'utils/v-click-outside';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import { Vue } from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'SupportView',
	directives: {
		ClickOutside: ClickOutside.directive,
	},
	mixins: [Template],
})
class SupportView extends Vue {
	protected get fabButtonClasses(): Record<string, boolean> {
		return {
			fabButton: true,
			fabButtonNew: this.isNewEditor,
			open: this.open,
		};
	}

	protected get fabButtonIconClasses(): Record<string, boolean> {
		return {
			open: this.open,
			iconClosed: !this.open,
			iconOpen: this.open,
		};
	}

	protected get fabButtonStyles(): Partial<CSSStyleDeclaration> {
		return {
			bottom: `${this.position.bottom}px`,
			right: `${this.position.right}px`,
		};
	}

	protected get fabMenuClasses(): Record<string, boolean> {
		return {
			fabMenu: true,
			open: this.open,
		};
	}

	protected get fabMenuStyles(): Partial<CSSStyleDeclaration> {
		const spacing = (
			!this.isNewEditor
				? 40
				: 48
		);

		return {
			bottom: `${this.position.bottom + spacing}px`,
			right: `${this.position.right}px`,
		};
	}

	protected get hasFAQ(): boolean {
		const hyperlinkModel = AppDataModule.findHyperlink({
			tag: 'faq',
		});

		return Boolean(hyperlinkModel);
	}

	protected get hasFeedback(): boolean {
		const hyperlinkModel = AppDataModule.findHyperlink(
			{
				tag: 'feedback',
			},
			true,
		);

		return Boolean(hyperlinkModel);
	}

	protected get isNewEditor(): boolean {
		const currentRouteComponent = this.$router.currentRoute?.value.matched?.[0]?.name;

		if (!currentRouteComponent) {
			return false;
		}

		if (currentRouteComponent === 'New Editor Module') {
			return true;
		}

		return false;
	}

	protected get position() {
		return AppStateModule.supportButtonPosition;
	}

	// private chatInterval: number | null = null;

	private hasChat = false;

	protected hasSurvey = true;

	private open = false;

	private surveyBtn: HTMLButtonElement | null = null;

	private zopimChat: HTMLDivElement | null = null;

	private afterEachUnsubscribe?: () => void;

	protected beforeUnmount(): void {
		this.afterEachUnsubscribe?.();
	}

	protected mounted(): void {
		this.afterEachUnsubscribe = this.$router.afterEach(() => {
			this.$forceCompute('isNewEditor');
			this.$forceCompute('fabButtonClasses');
			this.$forceCompute('fabMenuStyles');
		}) as () => void;
	}

	private closeButton() {
		if (this.open) {
			this.open = false;
		}
	}

	private initChat() {
		if (!this.hasChat) {
			if (ConfigModule['support.chat'] === 'salesforce') {
				this.hasChat = (
					'embedded_svc' in window
					&& 'menu' in window.embedded_svc
					&& 'hideButtonAndMenu' in window.embedded_svc.menu
				);

				if (this.hasChat) {
					window.embedded_svc.menu.hideButtonAndMenu();

					/* let ewsMO: HTMLElement | null;
					let ewsFAB: HTMLElement | null;

					if (this.chatInterval) {
						window.clearInterval(this.chatInterval);
					}
					this.chatInterval = window.setInterval(() => {
						ewsMO ??= document.getElementById('esw-modaloverlay');
						ewsFAB ??= document.getElementById('esw-fab');
						if (ewsMO
							&& ewsFAB
							&& ewsMO.className.indexOf('isMaximized') === -1
							&& ewsFAB.className.indexOf('hideAnimation') === -1
						) {
							window.embedded_svc.menu.hideButtonAndMenu();
						}
					}, 1000); */
				}
			} else if (ConfigModule['support.chat'] === 'zopim') {
				this.zopimChat = document.querySelector('.zopim');
				this.hasChat = Boolean(this.zopimChat);

				if (this.hasChat) {
					window.$zopim?.livechat.hideAll();
				}
			}
		}
	}

	private initSurvey() {
		if (!this.surveyBtn) {
			const survey = document.querySelector('#surveyContent') as HTMLDivElement;
			if (!survey) {
				this.hasSurvey = false;
			} else {
				this.hasSurvey = true;

				Object.assign(
					survey.style,
					{
						display: 'none',
					},
				);

				this.surveyBtn = survey.querySelector('button');
			}
		}
	}

	protected toggleButton() {
		this.open = !this.open;

		if (this.open) {
			analytics.trackEvent(
				'Open support',
				{
					category: 'Button',
				},
			);

			this.initSurvey();
			this.initChat();
		}
	}

	protected openChat() {
		analytics.trackEvent(
			'Open chat',
			{
				category: 'Button',
			},
		);

		if (!this.hasChat) {
			this.initChat();
		}

		if (this.hasChat) {
			if (typeof window.embedded_svc !== 'undefined') {
				window.embedded_svc.menu.showButtonAndMenu();
				window.embedded_svc.menu.openChannelMenu();
			} else if (typeof window.$zopim !== 'undefined') {
				window.$zopim.livechat.window.show();
				window.$zopim.livechat.window.onHide(() => {
					window.$zopim?.livechat.hideAll();
				});
			}
		}

		this.closeButton();
	}

	protected openFAQ() {
		analytics.trackEvent(
			'Open FAQ',
			{
				category: 'Button',
			},
		);

		const hyperlinkModel = AppDataModule.findHyperlink({
			tag: 'faq',
		});

		if (hyperlinkModel) {
			openWindow(
				hyperlinkModel.url,
				{
					inApp: true,
				},
			);
		}

		this.closeButton();
	}

	protected openFeedback() {
		analytics.trackEvent(
			'Open Feedback',
			{
				category: 'Button',
			},
		);

		const hyperlinkModel = AppDataModule.findHyperlink(
			{
				tag: 'feedback',
			},
			true,
		);

		if (hyperlinkModel) {
			openWindow(
				hyperlinkModel.url,
				{
					inApp: true,
				},
			);
		}

		this.closeButton();
	}

	protected openSurvey() {
		analytics.trackEvent(
			'Open survey',
			{
				category: 'Button',
			},
		);

		if (!this.surveyBtn) {
			this.initSurvey();
		}

		if (this.surveyBtn) {
			this.surveyBtn.click();
		}

		this.closeButton();
	}

	protected openService() {
		analytics.trackEvent(
			'Open service',
			{
				category: 'Button',
			},
		);

		const hyperlinkModel = AppDataModule.findHyperlink({
			tag: 'support',
		});

		if (hyperlinkModel) {
			openWindow(
				hyperlinkModel.url,
				{
					inApp: true,
				},
			);
		}

		this.closeButton();
	}

	protected openSupport() {
		Support.open();
	}
}

export default toNative(SupportView);
