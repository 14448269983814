<template>
	<div
		:class="modalClass"
		@click="clickModal"
	>
		<div
			:id="dialogModel.cid"
			ref="dialog"
			:class="[
				'dialog',
				{ chrome: showDialogChrome }
			]"
			:style="styleObject"
			@click.stop
		>
			<div
				v-if="showDialogChrome"
				ref="dialogHeader"
				:class="[
					'header',
					{ picker: dialogModel.picker },
				]"
			>
				<div
					:class="[
						{ closer: dialogModel.hasclose }
					]"
				>
					<i
						v-if="dialogModel.hasclose"
						class="material-icons"
						@click="checkClose"
					>
						close
					</i>
				</div>
				<div
					v-if="typeof dialogModel.title !== 'undefined'"
					class="title"
				>
					{{ dialogModel.title }}
				</div>
			</div>
			<div
				ref="dialogBody"
				:class="[
					'body',
					{ noBodyPadding: dialogModel.noBodyPadding }
				]"
				:style="{ 'max-height': maxBodyHeight + 'px', ...bodyStyle }"
			>
				<Component
					:is="dialogModel.vueView"
					v-if="dialogModel.vueView"
					v-bind="componentProps"
					ref="component"
					:dialog-model="dialogModel"
					v-on="{ ...componentListeners }"
					@close-dialog="closeDialog"
					@update-dialog="centerScreen"
				/>
				<Pikaday
					v-if="!dialogModel.vueView && dialogModel.type == 'date'"
					v-bind="componentProps"
					@on-select="changeDate($event)"
				/>
				<div
					v-else-if="!dialogModel.vueView && dialogModel.type == 'prompt'"
				>
					<div
						v-if="bodyText"
						class="bodyBox"
					>
						<pre>{{ bodyText }}</pre>
					</div>
					<div
						v-for="field in dialogModel.data"
						:key="field.id"
						class="promptWrapper"
					>
						<span v-if="field.inputLabel">
							{{ field.inputLabel }}
							<br>
						</span>
						<input
							:ref="field.id"
							:placeholder="field.inputPlaceholder"
							:autofocus="field.autoFocus"
							:value="field.value"
						>
					</div>
				</div>
				<div
					v-else-if="!dialogModel.vueView && dialogModel.type == 'progress'"
				>
					<span>
						{{ progressLabel }}: {{ String(progressValue) }}%
					</span>
					<div
						v-if="progressValue > 0"
						class="progressBar"
					>
						<div
							class="progressValue"
							:style="{ width: progressValue + '%' }"
						>
							&nbsp;
						</div>
					</div>
					<div v-else>
						<br>
						<div class="loadingBar" />
					</div>
				</div>
				<div
					v-else-if="!dialogModel.vueView && bodyText"
					class="bodyBox"
				>
					<pre>{{ bodyText }}</pre>
				</div>
				<div
					v-for="checkBox in dialogModel.checkBoxes"
					:key="checkBox.id"
					class="checkBox"
				>
					<input
						:id="checkBox.id"
						:ref="'checkbox-' + checkBox.id"
						type="checkbox"
					>
					<label :for="checkBox.id">
						{{ checkBox.text }}
					</label>
				</div>
			</div>

			<div
				v-if="buttons.length && showButtons"
				ref="dialogButtons"
				:class="['buttons', buttonHolderClass]"
			>
				<div
					v-for="button in buttons"
					:id="button.id"
					:key="button.id"
					class="dialogButtonWrapper"
				>
					<button
						class="dialogButton"
						@click="selectButton(button.id)"
					>
						<img
							v-if="button.image"
							:src="button.image"
						>
						<br v-if="button.image">
						{{ button.text }}
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script src='./dialog-default.ts'></script>

<style lang="less" scoped>
@import '../../css/components/button.less';
@import '../../css/components/dialog.less';

.dialog {
    .body {
		.bodyBox,
		.checkBox {
            float: left;
			width: 100%;

			pre {
				margin: 0;
				white-space: pre-wrap;
				word-wrap: break-word;
				font-family: inherit;
			}
		}
		.checkBox {
			padding-top: 20px;

			input {
				width: auto;
			}
		}

        hr {
            height: 1px;
            border: 0;
            border-top-width: 1px;
            border-style: solid;
            padding: 0;
            width: 80%;
            border-color: @dialog_body_hr;
        }
		.bodyBox + .promptWrapper {
			margin-top: 10px;
		}
        .promptWrapper {
            float: left;
            width: 100%;
            text-align: left;

            &:not(:last-child) {
                padding-bottom: 15px;
            }
        }

        .or {
            margin-top: 20px;
            text-align: center;
            width: 100%;
            float: left;

            hr {
                width: 100%;
            }

            span {
                background: @dialog_body_bg;
                padding: 0 20px;
                position: relative;
                top: -20px;
            }
        }

        .progressBar {
            height: 15px;
            width: 100%;
            max-width: 500px;
            display: block;
			margin: 5px auto;
			background-color: #f1efeb;
        }
        .progressValue {
            height: inherit;
            display: block;
            background-color: #5dc5e3;
        }
    }

    .buttons {
        padding: @dialog_buttons_padding;
        clear: left;
        float: left;
        width: 100%;
        border-top: @dialog_buttons_bordertop;
        background: @dialog_buttons_bg;
        border-bottom-left-radius: @dialog_radius;
		border-bottom-right-radius: @dialog_radius;
		display: flex;
		justify-content: space-between;

		&.doubleButtons:not(.wrap) {
			.dialogButton {
				max-width: ~"calc(100% - 10px)";

			}
		}
		&.wrap {
			flex-wrap: wrap;

			.dialogButtonWrapper:nth-child(2) {
				margin-top: 20px;
			}
		}

		.dialogButtonWrapper {
			width: 100%;
			text-align: left;

			.dialogButton {
				width: 100%;
				height: 50px;
				padding: 0 20px;
				font-family: @fontFamilyBold;
				font-weight: @fontWeightBold;
				font-size: @fontSize_XS2;
				border-radius: 5px;
				cursor: pointer;
				border: 0;
				box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
				line-height: 1em;

				background-color: #f0f0f0;
				color: #282828;

				& when(@brand="Hema") {
					text-transform: lowercase;
					background-color: #9b9b9b;
					color: #fff;
					font-family: @fontFamilyDemiBold;
					font-weight: @fontWeightDemiBold;
					font-size: @fontSize_XS1
				}
			}

			&:last-child {
				text-align: right;

				.dialogButton {
					background-color: @colorPalette2;

					& when(@brand="Printiki") {
						color: #fff;
					}
					& when(@brand="HelloPrint") {
						color: #fff;
					}
				}
			}
		}
        .imagebutton {
            &:not(:first-child) {
                padding-left: 5px;
            }
            &:not(:last-child) {
                padding-right: 5px;
            }
            img {
                max-width: 100%;
            }
        }
    }
}
</style>
