import './defines';
import ButtonComponent from 'components/button';
import {
	EditorBookFillImagePageOrientation,
} from 'interfaces/app';
import { mobile as mobileTools } from 'tools';
import { Model } from 'utils/decorators';
import {
	Component,
	toNative,
} from 'utils/vue-facing-decorator';
import {
	Prop,
	Vue,
	Watch,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'EditorBuildOptionsView',
	components: {
		ButtonComponent,
	},
	emits: ['close'],
	mixins: [Template],
})
class EditorBuildOptionsView extends Vue {
	@Model({
		default: undefined,
		description: 'Defines the selected autofill model',
		type: Boolean,
	})
	public readonly value?: boolean;

	@Prop({
		acceptedValues: [
			'landscape',
			'portrait',
			'square',
		],
		default: 'landscape',
		description: 'Defines the orientation of the images to show',
		schema: 'EditorBookFillImagePageOrientation',
		type: String,
	})
	public readonly orientation!: EditorBookFillImagePageOrientation;

	@Prop({
		required: true,
		description: 'Label for the product type',
		type: String,
	})
	public readonly productLabel!: string;

	private internalValue: boolean | null = null;

	protected isMobile = mobileTools.isMobile;

	private isMobileUnwatch?: () => void;

	protected beforeUnmount(): void {
		this.isMobileUnwatch?.();
	}

	protected created(): void {
		this.isMobileUnwatch = mobileTools.watch(() => {
			this.isMobile = mobileTools.isMobile;
		});
	}

	protected getImage(
		type: 'autofill' | 'fillyourself',
	) {
		// eslint-disable-next-line
		return require(`./assets/${this.orientation}_${type}.png`).default;
	}

	@Watch(
		'value',
		{
			immediate: true,
		},
	)
	protected onValueChange(): void {
		this.internalValue = this.value ?? null;
	}

	protected onApplyClick() {
		this.$emit(
			'update:value',
			this.internalValue,
		);
	}

	protected onAutoFillClick(value: boolean): void {
		this.internalValue = value;
	}

	protected onCloseClick() {
		this.$emit('close');
	}
}

export default toNative(EditorBuildOptionsView);
