import * as colorUtils from '@sosocio/frontend-utils/color';
import {
	CropFromRatioOptions,
	ZoomObjectResult,
} from 'interfaces/app';
import { OfferingModel } from 'interfaces/database';
import { PageObjectModel } from 'interfaces/project';
import {
	PRINT_COLOR_SPECTRUM_FULL,
	COLOR_FULL,
	OfferingGroups,
} from 'settings/offerings';
import {
	COVER_BACK,
	COVER_BACK_INSIDE,
	COVER_FRONT,
	COVER_FRONT_INSIDE,
} from 'settings/values';

export function getOfferingModelPrintEffectColor(offeringModel: OptionalExceptFor<OfferingModel, 'previewColorSpectrum'>): string | undefined {
	if (!offeringModel.previewColorSpectrum) {
		return undefined;
	}

	if (
		PRINT_COLOR_SPECTRUM_FULL.includes(offeringModel.previewColorSpectrum)
		|| offeringModel.previewColorSpectrum.startsWith('pms_')
	) {
		return undefined;
	}

	try {
		return colorUtils.getHexFromAny(offeringModel.previewColorSpectrum);
	} catch {
		// Shallow error: no action required
	}

	return undefined;
}

export function getOfferingModelColorQuantity(offeringModel: OptionalExceptFor<OfferingModel, 'previewColorSpectrum'>): number {
	if (
		!offeringModel.previewColorSpectrum
		|| PRINT_COLOR_SPECTRUM_FULL.includes(offeringModel.previewColorSpectrum)
	) {
		return COLOR_FULL;
	}

	if (offeringModel.previewColorSpectrum.startsWith('pms_')) {
		return parseInt(
			offeringModel.previewColorSpectrum.replace(
				'pms_',
				'',
			),
			10,
		);
	}

	return 1;
}

export function isPageCoverBack(
	offeringModel: OptionalExceptFor<OfferingModel, 'groupid'>,
	pageId: string,
	pageIndex: number,
): boolean {
	if (
		OfferingGroups(
			offeringModel.groupid,
			'BookTypes',
		)
		&& (
			pageId === COVER_BACK
			|| pageIndex === 0
		)
	) {
		return true;
	}

	return false;
}

export function isPageCoverFront(
	offeringModel: OptionalExceptFor<OfferingModel, 'groupid'>,
	pageId: string,
	pageIndex: number,
): boolean {
	if (
		OfferingGroups(
			offeringModel.groupid,
			'CardGame',
		)
		&& pageIndex === 0
	) {
		return true;
	}

	if (
		OfferingGroups(
			offeringModel.groupid,
			'BookTypes',
		)
		&& (
			pageId === COVER_FRONT
			|| pageIndex === 1
		)
	) {
		return true;
	}

	return false;
}

export function isPageCoverInside(
	offeringModel: OptionalExceptFor<OfferingModel, 'groupid'>,
	pageId: string,
): boolean {
	if (
		OfferingGroups(
			offeringModel.groupid,
			'BookTypes',
		)
		&& (
			pageId === COVER_BACK_INSIDE
			|| pageId === COVER_FRONT_INSIDE
		)
	) {
		return true;
	}

	return false;
}

export function getCropFromRatio(options: CropFromRatioOptions): number {
	const { object } = options;

	if (object.maxheight < object.maxwidth) {
		const cropRatio = object.cropwidth / object.cropheight;

		if (options.type === 'height') {
			return options.value / cropRatio;
		}

		return options.value * cropRatio;
	}

	const cropRatio = object.cropheight / object.cropwidth;

	if (options.type === 'height') {
		return options.value * cropRatio;
	}

	return options.value / cropRatio;
}

export function svgAddPrintEffect(
	svgElement: SVGElement,
	printEffect: OfferingModel['printEffect'],
): void {
	if (
		printEffect === 'embossing'
		|| printEffect === 'engraving'
		|| printEffect === 'laser engraving'
	) {
		const filterElement = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'filter',
		);
		filterElement.id = 'embossing';
		const feCovolveMatrixElement = document.createElementNS(
			'http://www.w3.org/2000/svg',
			'feConvolveMatrix',
		);
		feCovolveMatrixElement.setAttribute(
			'order',
			'3',
		);
		feCovolveMatrixElement.setAttribute(
			'kernelMatrix',
			'0 1 3 0 0 0 -3 0 0',
		);
		feCovolveMatrixElement.setAttribute(
			'divisor',
			'1',
		);
		feCovolveMatrixElement.setAttribute(
			'bias',
			'0',
		);
		filterElement.appendChild(feCovolveMatrixElement);
		svgElement.prepend(filterElement);
		svgElement.style.filter = 'url(#embossing)';
	}
}

/**
 * Calculate the new crop values for an object with a given zoom
 * @param object Page object model
 * @param zoom Zoom percentage (1-100) to apply
 * @returns Calculated crop values
 */
export function zoomObject(
	object: PageObjectModel,
	zoom: number,
): ZoomObjectResult {
	let newCropHeight: number;
	let newCropWidth: number;
	let inverseZoom = 100 - zoom;

	if (inverseZoom < 1) {
		inverseZoom = 1;
	} else if (inverseZoom > 100) {
		inverseZoom = 100;
	}

	if (object.maxheight < object.maxwidth) {
		newCropHeight = (object.maxheight * inverseZoom) / 100;
		newCropWidth = getCropFromRatio({
			object,
			type: 'width',
			value: newCropHeight,
		});
	} else {
		newCropWidth = (object.maxwidth * inverseZoom) / 100;
		newCropHeight = getCropFromRatio({
			object,
			type: 'height',
			value: newCropWidth,
		});
	}

	let newCropX = object.cropx + ((object.cropwidth - newCropWidth) / 2);
	let newCropY = object.cropy + ((object.cropheight - newCropHeight) / 2);
	/**
	 * Fail safe to prevent infinite loop
	 */
	const maxIteration = 1000;
	let iterationNumber = 0;

	while (
		(
			newCropX < 0
			|| newCropY < 0
			|| newCropX + newCropWidth > object.maxwidth
			|| newCropY + newCropHeight > object.maxheight
		)
		&& iterationNumber < maxIteration
	) {
		if (newCropX < 0) {
			newCropWidth -= newCropX;
			newCropHeight = getCropFromRatio({
				object,
				type: 'height',
				value: newCropWidth,
			});
			newCropX = 0;
			newCropY = object.cropy + ((object.cropheight - newCropHeight) / 2);

			if (newCropY < 0) {
				newCropY = 0;
			}
		}
		if (newCropWidth > object.maxwidth) {
			newCropWidth = object.maxwidth;
			newCropHeight = getCropFromRatio({
				object,
				type: 'height',
				value: newCropWidth,
			});
			newCropX = 0;
			newCropY = object.cropy + ((object.cropheight - newCropHeight) / 2);

			if (newCropY < 0) {
				newCropY = 0;
			}
		}
		if (newCropX + newCropWidth > object.maxwidth) {
			newCropX -= (newCropX + newCropWidth) - object.maxwidth;
		}
		if (newCropY < 0) {
			newCropHeight -= newCropY;
			newCropWidth = getCropFromRatio({
				object,
				type: 'width',
				value: newCropHeight,
			});
			newCropY = 0;
			newCropX = object.cropx + ((object.cropwidth - newCropWidth) / 2);

			if (newCropX < 0) {
				newCropX = 0;
			}
		}
		if (newCropHeight > object.maxheight) {
			newCropHeight = object.maxheight;
			newCropWidth = getCropFromRatio({
				object,
				type: 'width',
				value: newCropHeight,
			});
			newCropY = 0;
			newCropX = object.cropx + ((object.cropwidth - newCropWidth) / 2);

			if (newCropX < 0) {
				newCropX = 0;
			}
		}
		if (newCropY + newCropHeight > object.maxheight) {
			newCropY -= (newCropY + newCropHeight) - object.maxheight;
		}

		iterationNumber += 1;
	}

	return {
		cropheight: newCropHeight,
		cropwidth: newCropWidth,
		cropx: newCropX,
		cropy: newCropY,
	};
}
