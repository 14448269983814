import axios from 'axios';
import User from 'classes/user';
import EventBus from 'components/event-bus';
import ajax from 'controllers/ajax';
import analytics from 'controllers/analytics';
import storage from 'controllers/storage';
import { LoginReturnData } from 'interfaces/app';
import * as DB from 'interfaces/database';
import getDeviceDetails from 'ops/device-details';
import * as DialogService from 'services/dialog';
import {
	AppStateModule,
	ConfigModule,
	UserModule,
} from 'store';
import _ from 'underscore';
import type SignupView from 'views/signup';

interface Options {
	first_name: string | null; // eslint-disable-line camelcase
	hasclose: boolean;
	last_name: string | null; // eslint-disable-line camelcase
	method: boolean;
	showGuest: boolean;
	username: string | null;
}

class Auth {
	private options: Options;

	// Flag used to prevent parallel refresh token requests (one would invalidate the other)
	private tokenRequestInProgress = false;

	constructor() {
		this.options = {
			first_name: null,
			hasclose: false,
			last_name: null,
			method: false,
			showGuest: true,
			username: null,
		};
	}

	public init() {
		if (window.glPlatform === 'native') {
			if (!window.nativeToWeb) {
				throw new Error('Missing native bridge');
			}

			window.nativeToWeb.getBearerToken = () => {
				this.getBearerToken(30).then((accessToken) => {
					if (!window.webToNative) {
						throw new Error('Missing WebToNative on window');
					}

					// Send bearer token to native
					window.webToNative.setBearerToken(accessToken);
				});
			};
		}
	}

	public login(
		loginData: {
			username?: string;
			password?: string;
			access_code?: string;
			userAccessToken?: string;
		},
		setupAndValidate = true,
	) {
		const postData: {
			countryid?: DB.CountryModel['id'];
			currency?: string;
			locale: string;
			username?: string;
			password?: string;
			access_code?: string;
			userAccessToken?: string;
			deviceid: string;
			platform?: 'Web' | 'Native';
		} = {
			deviceid: 'web',
			locale: UserModule.language || window.locale,
		};

		if (loginData.username && loginData.password) {
			postData.username = loginData.username;
			postData.password = loginData.password;
		} else if (loginData.access_code) {
			postData.access_code = loginData.access_code;
		} else if (loginData.userAccessToken) {
			postData.userAccessToken = loginData.userAccessToken;
		} else {
			throw new Error('Missing username or password');
		}

		if (UserModule.currency) {
			postData.currency = UserModule.currency;
		}
		if (UserModule.countryid) {
			postData.countryid = UserModule.countryid;
		}

		// Block auto saving (as the userid can change in the meantime)
		AppStateModule.disableAutoSave();

		return getDeviceDetails()
			.then((deviceDetails) => {
				if (deviceDetails.deviceUUID) {
					postData.deviceid = deviceDetails.deviceUUID;
				}
				if (deviceDetails.platform) {
					postData.platform = deviceDetails.platform;
				}

				return ajax
					.request(
						{
							method: 'post',
							url: '/api/auth/login',
							data: postData,
							withCredentials: BASE_URL ? true : undefined,
						},
						{
							// We have to send the optional authorization header, because
							// the user could be on a guest account, and we need the header
							// to merge the guest account with the new login account
							auth: true,
							// Wait for running ajax requests to complete,
							// to avoid data integrity issues when userid changes
							wait: true,
						},
					);
			})
			.then((response) => response.data)
			.then((returnData: LoginReturnData) => {
				storage.set(
					'Authorization',
					{
						access_token: returnData.access_token,
						expires: returnData.expires,
						token_type: returnData.token_type,
					},
				);

				if (window.glPlatform == 'native') {
					if (!window.webToNative) {
						throw new Error('Missing WebToNative on window');
					}

					window.webToNative.setRefreshToken(returnData.refresh_token);
				}

				if (setupAndValidate) {
					return User.setup(true);
				}

				return Promise.resolve();
			})
			.then(() => {
				if (setupAndValidate) {
					return User.validate();
				}

				return Promise.resolve();
			})
			.catch((err) => {
				throw new Error(this.parseError(err));
			})
			.finally(() => {
				AppStateModule.enableAutoSave();
			});
	}

	public open(options?: Partial<Options>) {
		this.options = _.extend(
			this.options,
			options,
		);

		// Close existing dialog (if exists)
		this.closeDialog();

		// Show dialog with available registration methods
		DialogService.openAuthDialog({
			header: {
				hasCloseButton: this.options.hasclose,
			},
			body: {
				props: this.options,
			},
			width: 800,
		});

		analytics.trackEvent(
			'Show auth dialog',
			this.options,
		);
	}

	private parseError(err: Error) {
		let errorMessage = err.message;

		if (errorMessage && typeof errorMessage === 'string') {
			if (ConfigModule.partnerID === 2) {
				if (window.App.router.$i18next.exists(`errors.hema.${errorMessage.toLowerCase()}`)) {
					errorMessage = window.App.router.$i18next.t(`errors.hema.${errorMessage.toLowerCase()}`);
				}
			}
		}

		return errorMessage;
	}

	public getBearerToken(
		leeway = 10,
	): Promise<string> {
		if (window.glPlatform === 'server') {
			return Promise.reject();
		}

		const objMemory = storage.get('Authorization');
		const timestamp = Date.now() / 1000;

		if (!objMemory && window.glPlatform === 'web') {
			return Promise.reject();
		}

		if (objMemory
			&& objMemory.access_token
			&& objMemory.expires
			&& objMemory.expires > timestamp + leeway
		) {
			return Promise.resolve(objMemory.access_token);
		}

		if (this.tokenRequestInProgress) {
			// Wait for running token refresh request to finish
			// Postpone request with 100 ms
			return new Promise((resolve, reject) => {
				window.setTimeout(
					() => {
						this.getBearerToken().then(
							resolve,
							reject,
						);
					},
					100,
				);
			});
		}

		this.tokenRequestInProgress = true;
		return this.refreshBearerToken().finally(() => {
			this.tokenRequestInProgress = false;
		});
	}

	public refreshBearerToken(): Promise<string> {
		// Refresh bearer token
		return axios.create().request({
			url: BASE_URL ? `${BASE_URL}/api/auth/refresh` : '/api/auth/refresh',
			withCredentials: BASE_URL ? true : undefined,
		})
			.then((response) => response.data)
			.then((returnData: LoginReturnData) => {
				storage.set(
					'Authorization',
					{
						access_token: returnData.access_token,
						expires: returnData.expires,
						token_type: returnData.token_type,
					},
				);

				if (window.glPlatform == 'native') {
					if (!window.webToNative) {
						throw new Error('Missing WebToNative on window');
					}

					window.webToNative.setRefreshToken(returnData.refresh_token);
				}

				return returnData.access_token;
			})
			.catch((error) => {
				if (error.response && error.response.status === 401) {
					storage.clear(['Authorization']);
				}

				throw error;
			});
	}

	public showLogin(options?: Partial<Options>) {
		this.options = _.extend(
			this.options,
			options,
			{
				showForm: true,
			},
		);

		// Close existing dialog (if exists)
		this.closeDialog();

		if (ConfigModule['auth.internal']) {
			// The configuration of this label support internal login
			// Show he user the login dialog
			DialogService.openAuthDialog({
				header: {
					hasCloseButton: this.options.hasclose,
				},
				body: {
					props: this.options,
				},
				showLogin: true,
				width: 800,
			});
		} else {
			// The configuration of this label does not support internal login
			// TODO Redirect the user to the external website
		}
	}

	public showSignup(options?: Partial<Options>) {
		this.options = _.extend(
			this.options,
			options,
			{
				showForm: true,
			},
		);

		// Close existing dialog (if exists)
		this.closeDialog();

		if (ConfigModule['auth.internal']) {
			// The configuration of this label support internal login
			// Show user the signup dialog
			DialogService.openAuthDialog({
				header: {
					hasCloseButton: this.options.hasclose,
				},
				body: {
					props: this.options as NonVuePublicOptionalProps<PublicNonFunctionProps<InstanceType<typeof SignupView>>>,
				},
				showSignUp: true,
				width: 800,
			});
		} else {
			// The configuration of this label does not support internal login
			// TODO Redirect the user to the external website
		}
	}

	public signup(
		username: string,
		password: string,
		options?: {
			first_name?: string; // eslint-disable-line camelcase
			last_name?: string; // eslint-disable-line camelcase
		},
	) {
		const postData: {
			countryid?: DB.CountryModel['id'];
			locale: string;
			username: string;
			password: string;
			first_name: string;
			last_name: string;
		} = {
			username,
			password,
			first_name: options && options.first_name ? options.first_name : '',
			last_name: options && options.last_name ? options.last_name : '',
			locale: UserModule.language || window.locale,
		};
		if (UserModule.countryid) {
			postData.countryid = UserModule.countryid;
		}

		return ajax
			.request(
				{
					method: 'post',
					url: '/api/auth/signup',
					data: postData,
				},
				{
				// We have to send the optional authorization header, because
				// the user could be on a guest account, and we need the header
				// to merge the guest account with the new login account
					auth: true,
					// Wait for running ajax requests to complete,
					// to avoid data integrity issues when userid changes
					wait: true,
				},
			)
			.then(() => this.login({
				username,
				password,
			}))
			.catch((err) => {
				throw new Error(this.parseError(err));
			});
	}

	public guest() {
		// close dialog
		this.closeDialog();

		if (UserModule.id === null) {
			// open load dialog
			const closeLoader = DialogService.openLoaderDialog();

			const postData: {
				affiliateid: number;
				countryid?: DB.CountryModel['id'];
				currency?: string;
				locale: string;
				deviceid: string;
			} = {
				affiliateid: window.affiliateID,
				deviceid: 'web',
				locale: UserModule.language || window.locale,
			};

			if (UserModule.currency) {
				postData.currency = UserModule.currency;
			}
			if (UserModule.countryid) {
				postData.countryid = UserModule.countryid;
			}

			getDeviceDetails()
				.then((deviceDetails) => {
					if (deviceDetails.deviceUUID) {
						postData.deviceid = deviceDetails.deviceUUID;
					}

					return ajax
						.request(
							{
								method: 'post',
								url: '/api/auth/guest',
								data: postData,
							},
							{
								auth: false,
								// Wait for running ajax requests to complete,
								// to avoid data integrity issues when userid changes
								wait: true,
							},
						);
				})
				.then((response) => response.data)
				.then((returnData: LoginReturnData) => {
					storage.set(
						'Authorization',
						{
							access_token: returnData.access_token,
							expires: returnData.expires,
							token_type: returnData.token_type,
						},
					);

					if (window.glPlatform == 'native') {
						if (!window.webToNative) {
							throw new Error('Missing WebToNative on window');
						}

						window.webToNative.setRefreshToken(returnData.refresh_token);
					}

					return User.setup(true);
				})
				.then(() => User.validate())
				.then(() => {
					EventBus.emit(
						'auth:login',
						true,
					);
				})
				.catch(() => {
					this.open();
				})
				.finally(() => {
					// close load dialog
					closeLoader();
				});
		} else {
			EventBus.emit(
				'auth:login',
				true,
			);
		}
	}

	public changePassword(
		oldPassword: string,
		newPassword: string,
	) {
		const putData: {
			oldPassword: string;
			newPassword: string;
			locale: string;
			countryid?: DB.CountryModel['id'];
		} = {
			oldPassword,
			newPassword,
			locale: UserModule.language || window.locale,
		};
		if (UserModule.countryid) {
			putData.countryid = UserModule.countryid;
		}

		return ajax.request(
			{
				url: `/api/user/${UserModule.id}/password`,
				method: 'put',
				headers: {
					'content-type': 'application/json; charset=utf-8',
				},
				data: putData,
			},
			{
				auth: true,
			},
		);
	}

	public forgotPassword(email: string) {
		const postData: {
			username: string;
			locale: string;
			countryid?: DB.CountryModel['id'];
		} = {
			username: email,
			locale: UserModule.language || window.locale,
		};
		if (UserModule.countryid) {
			postData.countryid = UserModule.countryid;
		}

		return ajax
			.request(
				{
					url: '/api/user/resetpassword',
					method: 'post',
					headers: {
						'content-type': 'application/json; charset=utf-8',
					},
					data: postData,
				},
				{
					auth: true,
				},
			);
	}

	public cancel() {
		EventBus.emit(
			'auth:login',
			false,
		);
		this.closeDialog();
	}

	public closeDialog() {
		DialogService.closeAuthDialog();
	}
}

export default new Auth();
