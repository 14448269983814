<template>
	<div class="pushSoftAskWrapper">
		<div class="leftBox">
			<div class="logo" />
		</div>
		<div class="rightBox">
			<div class="headerText">
				{{ $t('webPush.softAsk.headerText') }}
			</div>
			<div class="subText">
				{{ $t('webPush.softAsk.subText') }}
			</div>
			<div class="buttonWrapper decline">
				<button
					class="declineButton"
					@click="decline"
				>
					{{ $t('webPush.softAsk.declineButton') }}
				</button>
			</div>
			<div class="buttonWrapper accept">
				<button
					class="acceptButton"
					@click="accept"
				>
					{{ $t('webPush.softAsk.acceptButton') }}
				</button>
			</div>
		</div>
	</div>
</template>

<script src='./index.ts'></script>

<style lang="less" scoped>
@import '~lessVars';
@import '../../../css/components/button.less';

.pushSoftAskWrapper {
	display: flex;
	background: #fff;
	max-width: 100%;
	width: 400px;
	padding: 20px;
	margin-top: 30vh;
	margin-left: auto;
	margin-right: auto;
	text-align: left;

	& when(@brand="Hema") {
		font-size: @fontSize_XS;
		line-height: 23px;
	}
}

.logo {
	position: relative;
	background-size: contain;

	& when(@brand="Hema") {
		background-image: url('../../../img/hema/logo.svg');
		width: 50px;
		height: 50px;
	}
	& when(@brand="Printiki") {
		background-image: url('../../../img/printiki/icon.svg');
		width: 50px;
		height: 50px;
	}
}
.rightBox {
	padding-left: 20px;

	& when(@brand="Hema") {
		padding-top: 60px;
	}

	.headerText,
	.subText {
		width: 100%;
		float: left;
	}
	.headerText {
		font-family: @fontFamilyBold;
		font-weight: @fontWeightBold;

		& when(@brand="Hema") {
			font-family: @fontFamilyDemiBold;
			font-weight: @fontWeightDemiBold;
		}
	}
	.subText {
		margin-top: 15px;
	}
	.buttonWrapper {
		margin: 25px 0 5px;
		width: 50%;
		float: left;

		&.accept {
			padding-left: 10px;
		}
		&.decline {
			padding-right: 10px;
		}
	}
	.declineButton,
	.acceptButton {
		.button;

		float: none;
		display: inline-block;
		border: 0;
		outline: none;
		border-radius: 5px;
		box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
		height: 40px;
		line-height: 40px;
		text-align: center;
		width: 100%;
		color: #fff;

		@media @mobile {
			padding: 0 10px;
		}
		@media @desktop {
			padding: 0 16px;
		}
	}
	.declineButton {
		color: @toolbutton_text_default;
		background-color: @toolbutton_bg_default;
		font: @toolbutton_font;

		& when(@brand="Hema") {
			font-family: @fontFamilyDemiBold;
			font-weight: @fontWeightDemiBold;
			text-transform: lowercase;
		}
	}
	.acceptButton {
		font-family: @fontFamilyBold;
		font-weight: @fontWeightBold;
		background-color: @colorPalette2;
		font-size: @fontSize_S;

		& when(@brand="Hema") {
			background-color: #5dc5e3;
			text-transform: lowercase;
			font-family: @fontFamilyDemiBold;
			font-weight: @fontWeightDemiBold;
		}

		&:hover {
			& when(@brand="Hema") {
				background-color: #5dc5e3;
			}
		}
	}
}
</style>
