import {
	CognitoIdentityClient,
	Credentials,
	GetCredentialsForIdentityCommand,
	IdentityDescription,
} from '@aws-sdk/client-cognito-identity';
import ajax from 'controllers/ajax';
import moment from 'moment';

class AwsCognito {
	private credentials: Credentials | undefined = undefined;

	private identityId: IdentityDescription['IdentityId'] | undefined = undefined;

	public checkCredentials(): Promise<boolean> {
		if (this.credentials?.Expiration
			&& moment(this.credentials?.Expiration).format('X') > moment().format('X')
		) {
			return Promise.resolve(
				false,
			);
		}

		return ajax
			.request(
				{
					url: '/api/user/cognito',
				},
				{
					auth: true,
				},
			)
			.then((response) => {
				const responseData = response.data;

				if (
					responseData.token
					&& responseData.identityId
				) {
					const Logins: Record<string, string> = {};
					Logins['cognito-identity.amazonaws.com'] = responseData.token;

					const cognitoIdentityClient = new CognitoIdentityClient({
						region: AWS_DEFAULT_REGION,
					});

					return cognitoIdentityClient.send(new GetCredentialsForIdentityCommand({
						IdentityId: responseData.identityId,
						Logins,
					}));
				}

				throw new Error('No Cognito data in response');
			})
			.then((data) => {
				this.credentials = data.Credentials;
				this.identityId = data.IdentityId;

				return true;
			});
	}

	public getCredentials(): Credentials | undefined {
		return this.credentials;
	}

	public getIdentityId(): IdentityDescription['IdentityId'] | undefined {
		return this.identityId;
	}
}

export default new AwsCognito();
