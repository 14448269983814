import { AxiosRequestConfig } from 'axios';
import ajax from 'controllers/ajax';
import merge from 'deepmerge';
import { AjaxOptions } from 'interfaces/app';
import * as DB from 'interfaces/database';
import { ERRORS_INVALID_REQUEST_DATA } from 'settings/errors';
import _ from 'underscore';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';

@Module({ namespaced: true, name: 'cart' })
export default class Cart extends VuexModule implements DB.ShoppingCartModel {
	id: number | null = null;

	userid: number | null = null;

	token: string | null = null;

	voucherid: number | null = null;

	billingaddressid: number | null = null;

	shippingaddressid: number | null = null;

	branchid: number | null = null;

	urlRoot = '/api/shoppingcart';

	public get getState(): DB.ShoppingCartModel {
		return {
			id: this.id,
			userid: this.userid,
			token: this.token,
			voucherid: this.voucherid,
			billingaddressid: this.billingaddressid,
			shippingaddressid: this.shippingaddressid,
			branchid: this.branchid,
		};
	}

	@Mutation
	set(payload: Partial<DB.ShoppingCartModel>): void {
		Object.assign(
			this,
			payload,
		);
	}

	@Action({ rawError: true })
	get(options: {
		methodOptions?: AjaxOptions;
		requestOptions?: AxiosRequestConfig;
	} = {}): Promise<DB.ShoppingCartModel> {
		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'get',
			url: `${this.urlRoot}/${this.id}`,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			debug: {
				offline: true,
				dialog: true,
				abort: false,
			},
		};

		const requestOptions = options && options.requestOptions
			? merge(
				defaultRequestOptions,
				options.requestOptions,
			)
			: defaultRequestOptions;
		const methodOptions = options && options.methodOptions
			? merge(
				defaultMethodOptions,
				options.methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				this.set(response.data);
				return this.getState;
			});
	}

	@Action({ rawError: true })
	patch({
		data,
		methodOptions,
		requestOptions,
	}: {
		data: Record<string, any> | null;
		methodOptions?: AjaxOptions;
		requestOptions?: AxiosRequestConfig;
	} = { data: null }): Promise<DB.ShoppingCartModel> {
		if (!this.id) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		if (data) {
			this.set(data);
		}

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'patch',
			url: `${this.urlRoot}/${this.id}`,
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
			data,
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 1,
			debug: {
				offline: true,
				dialog: true,
				abort: false,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				this.set(response.data);
				return this.getState;
			});
	}

	@Action({ rawError: true })
	post({
		data,
		methodOptions,
		requestOptions,
	}: {
		data?: Record<string, any>;
		methodOptions?: AjaxOptions;
		requestOptions?: AxiosRequestConfig;
	}): Promise<DB.ShoppingCartModel> {
		if (data) {
			this.set(data);
		}

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'post',
			url: this.urlRoot,
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
			data: JSON.parse(JSON.stringify(this.context.state)),
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 1,
			debug: {
				offline: true,
				dialog: true,
				abort: false,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				this.set(response.data);
				return this.getState;
			});
	}

	@Action({ rawError: true })
	put({
		data,
		methodOptions,
		requestOptions,
	}: {
		data?: Record<string, any>;
		methodOptions?: AjaxOptions;
		requestOptions?: AxiosRequestConfig;
	}): Promise<DB.ShoppingCartModel> {
		if (!this.id) {
			throw new Error(ERRORS_INVALID_REQUEST_DATA);
		}

		if (data) {
			this.set(data);
		}

		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'put',
			url: `${this.urlRoot}/${this.id}`,
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
			data: JSON.parse(JSON.stringify(this.context.state)),
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 1,
			debug: {
				offline: true,
				dialog: true,
				abort: false,
			},
		};

		requestOptions = requestOptions
			? merge(
				defaultRequestOptions,
				requestOptions,
			)
			: defaultRequestOptions;
		methodOptions = methodOptions
			? merge(
				defaultMethodOptions,
				methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				this.set(response.data);
				return this.getState;
			});
	}

	@Action({ rawError: true })
	save(options: {
		data?: Record<string, any>;
		methodOptions?: AjaxOptions;
		requestOptions?: AxiosRequestConfig;
	}): Promise<DB.ShoppingCartModel> {
		if (this.id) {
			return this.put(options);
		}

		return this.post(options);
	}

	@Action({ rawError: true })
	snapshot(options?: {
		data?: Record<string, any>;
		methodOptions?: AjaxOptions;
		requestOptions?: AxiosRequestConfig;
	}): Promise<DB.ShoppingCartItemModel[]> {
		const defaultRequestOptions: AxiosRequestConfig = {
			method: 'POST',
			url: `${this.urlRoot}/${this.id}/snapshot`,
			data: options?.data || {},
		};
		const defaultMethodOptions: AjaxOptions = {
			auth: true,
			retry: 1,
			debug: {
				offline: true,
				dialog: true,
				abort: false,
			},
		};

		const requestOptions = options?.requestOptions
			? merge(
				defaultRequestOptions,
				options?.requestOptions,
			)
			: defaultRequestOptions;
		const methodOptions = options?.methodOptions
			? merge(
				defaultMethodOptions,
				options?.methodOptions,
			)
			: defaultMethodOptions;

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => _.toArray(response.data.items));
	}

	@Action({ rawError: true })
	submit(): Promise<Record<string, any>> {
		const { rootState } = this.context;

		const requestOptions: AxiosRequestConfig = {
			url: `${this.urlRoot}/${this.id}/submit`,
			method: 'post',
			data: {
				currency: rootState.user.currency,
				locale: rootState.user.language,
			},
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
		};
		const methodOptions: AjaxOptions = {
			auth: true,
			debug: {
				offline: true,
				dialog: true,
				abort: false,
			},
		};

		return ajax.request(
			requestOptions,
			methodOptions,
		).then((response) => response.data);
	}

	@Action({ rawError: true })
	checkVoucher(voucherCode: string): Promise<{
		discount_producttypes: DB.DiscountOfferingModel[]; // eslint-disable-line camelcase
		discount_country: DB.DiscountShippingModel[]; // eslint-disable-line camelcase
		discount: DB.DiscountModel;
		voucher: DB.DiscountVoucherModel;
	}> {
		const requestOptions: AxiosRequestConfig = {
			url: `${this.urlRoot}/${this.id}/voucher`,
			method: 'post',
			headers: {
				'content-type': 'application/json; charset=utf-8',
			},
			data: {
				code: voucherCode,
				trackerid: window.affiliateID,
			},
		};
		const methodOptions: AjaxOptions = {
			auth: true,
			debug: {
				offline: true,
				dialog: false,
			},
		};

		return ajax
			.request(
				requestOptions,
				methodOptions,
			).then((response) => response.data);
	}
}
