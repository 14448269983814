<template>
	<div>
		{{ $t('dialogTextDownloadError') }}
		<br><br>
		<div class="itemsWrapper">
			<div
				v-for="item in items"
				:key="item.id"
				class="preview imageNode"
			>
				<img
					v-if="item.thumb_url || item.url || item.full_url"
					:src="item.thumb_url || item.url || item.full_url"
					class="image"
				>
				<span v-if="item._error">
					{{ item._error.message }}
				</span>
			</div>
		</div>
	</div>
</template>

<style lang="less" scoped>
.itemsWrapper {
    max-height: 250px;
    overflow-y: auto;

    .preview {
        display: inline-block;
        margin: 10px;
    }

    .image {
		max-width: 100px;
		max-height: 100px;
	}
}
</style>
