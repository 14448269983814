import './defines';
import { toNative } from 'utils/vue-facing-decorator';
import {
	Component,
	Prop,
	Vue,
} from 'vue-facing-decorator';
import Template from './template.vue';

@Component({
	name: 'DialogLoaderComponent',
	mixins: [Template],
})
class DialogLoaderComponent extends Vue {
	@Prop({
		default: undefined,
		type: String,
	})
	public readonly content?: string;

	@Prop({
		default: '#ccc',
		type: String,
	})
	public readonly textColor?: CSSStyleDeclaration['color'];
}

export default toNative(DialogLoaderComponent);
