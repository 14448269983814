<template>
	<div
		:class="{ modal: dialogModel.modal }"
		@click="clickModal()"
	>
		<div
			:id="dialogModel.cid"
			class="loadingDialog"
		>
			<svg
				viewBox="0 0 32 32"
				width="64"
				height="64"
			>
				<circle
					id="spinner"
					cx="16"
					cy="16"
					r="14"
					fill="none"
				/>
			</svg>
			<span class="loaderText">
				{{ dialogModel.text }}
			</span>
		</div>
	</div>
</template>

<script src='./dialog-loader.ts'></script>

<style lang="less" scoped>
@import '../../css/components/button.less';
@import '../../css/components/dialog.less';

.loadingDialog {
	margin: 0;
	position: fixed;
	top: ~"calc(50% - 32px)";
	left: ~"calc(50% - 32px)";
    font-size: 1em;
    width: 64px;
    height: 64px;

    .loaderText {
        display: inline-block;
        width: 100%;
        text-align: center;
        color: #ccc;
        padding-top: 5px;
    }
}
</style>
