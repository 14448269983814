// import SupportView from '../views/support';
import store, { UserModule, AppStateModule } from '../store/index';
import getViewport from '../tools/get-viewport';
import openWindow from '../ops/open-window';
import EventBus from '../components/event-bus';

class Support {
	active: {
		zopim: boolean;
	} = {
			zopim: false,
		};

	chat: {
		status: string;
	} = {
			status: 'offline',
		};

	setup() {
		const viewport = getViewport();

		if (store.state.config['support.zopim.apikey']) {
			/* eslint-disable */
			window.$zopim || (function (d,s) {
				// @ts-ignore
				var z = window.$zopim = function (c){z._.push(c)},$ = z.s = d.createElement(s),e = d.getElementsByTagName(s)[0];
				// @ts-ignore
				z.set = function (o){z.set._.push(o)};z._ = [];z.set._ = [];$.async = !0;$.setAttribute('charset','utf-8');
				// @ts-ignore
				$.src = `https://v2.zopim.com/?${store.state.config['support.zopim.apikey']}`;z.t = +new Date;$.
				// @ts-ignore
				type = 'text/javascript';e.parentNode.insertBefore($,e)
			})(document,'script');
			/* eslint-enable */

			this.active.zopim = true;

			// Activate javascript API
			window.$zopim?.(() => {
				window.$zopim?.livechat.set({
					language: window.locale,
				});

				// Hide all Zopim chat buttons and windows
				window.$zopim?.livechat.hideAll();

				window.$zopim?.livechat.setOnStatus((status: string) => {
					this.chat.status = status;
				});

				// Show chat widget when a message has been received
				window.$zopim?.livechat.setOnUnreadMsgs((count: number) => {
					if (count > 0) {
						this.open();
					}
				});

				if (store.state.config['support.zopim.tag']) {
					window.$zopim?.livechat.addTags(store.state.config['support.zopim.tag']);
				}
			});
		}

		if (this.active.zopim) {
			if (viewport.width >= 640 && !window.App.standalone) {
				/* new SupportView({
					el: '#chatButtonRegion',
					model: self.chat,
				}); */
			} else {
				// Mobile interface, show help button in top bar
			}
		}
	}

	open() {
		if (typeof window.$zopim !== 'undefined') {
			// Prefill user name in chat box
			if (UserModule.first_name) {
				window.$zopim.livechat.setName(UserModule.first_name);
			}

			// Open chat window
			window.$zopim.livechat.window.show();

			// Hide chat window
			EventBus.once(
				'mouse:down',
				this.hide.bind(this),
			);
		} else if (AppStateModule.online && this.active.zopim) {
			const url = `http://v2.zopim.com/widget/livechat.html?key=${store.state.config['support.zopim.apikey']}`;
			openWindow(url);
		}
	}

	hide() {
		if (typeof window.$zopim !== 'undefined') {
			window.$zopim(() => {
				// Hide all Zopim chat buttons and windows
				window.$zopim?.livechat.hideAll();
			});
		}
	}
}

export default new Support();
