import './defines';
import * as DB from 'interfaces/database';
import * as PI from 'interfaces/project';
import { toNative } from 'utils/vue-facing-decorator';
import {
	Component,
	Prop,
	Vue,
	Watch,
} from 'vue-facing-decorator';
import ProgressBar from 'components/progress-bar';
import SpinnerComponent from 'components/spinner';
import Template from './template.vue';

@Component({
	components: {
		SpinnerComponent,
		ProgressBar,
	},
	emits: ['closeDialog'],
	mixins: [Template],
})
class FileConversionView extends Vue {
	@Prop({
		required: true,
		type: String,
	})
	public readonly offeringType!: DB.OfferingModel['type'];

	@Prop({
		required: true,
		type: Array,
	})
	public readonly photosProcessed!: PI.PhotoModel[];

	@Prop({
		required: true,
		type: Number,
	})
	public readonly progressPercentage!: number;

	@Prop({
		required: true,
		type: Number,
	})
	public readonly submittedCount!: number;

	@Watch('progressPercentage')
	protected autoClose() {
		if (this.progressPercentage >= 100) {
			this.$emit('closeDialog');
		}
	}
}

export default toNative(FileConversionView);
