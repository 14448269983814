import merge from 'deepmerge';
import { FontModel } from 'interfaces/app';
import * as PI from 'interfaces/project';
import breakText from 'tools/break-text';
import defaults from '../defaults';
import * as svgTextHelper from './svg-text';

interface Options {
	resizeFont: {
		up: number;
		down: number;
	};
	resizeObject: {
		up: boolean;
		down: boolean;
	};
}

export default function resizeObjectText(
	objectModel: OptionalExceptFor<PI.PageObjectModel, 'height' | 'width'>,
	fontModel: FontModel,
	opts?: Partial<Options>,
): Partial<PI.PageObjectModel> {
	const subset = fontModel.subset.split(',');
	const patt = new RegExp('<% .+? %>');
	const defaultOptions: Options = {
		resizeObject: {
			up: false,
			down: false,
		},
		resizeFont: {
			up: 0,
			down: 0,
		},
	};

	const options: Options = opts
		? merge(
			defaultOptions,
			opts,
		)
		: defaultOptions;
	let pointsize = objectModel.pointsize || defaults.pointsize;
	const changeObject: Partial<PI.PageObjectModel> = {
		text_svg: '',
		text_formatted: '',
		text_formatted_for_canvas: '',
	};

	if (objectModel.id) {
		changeObject.id = objectModel.id;
	}

	if (objectModel.text) {
		const response = (
			patt.test(objectModel.text)
				? null
				: breakText({
					phrase: objectModel.text,
					maxPxLength: objectModel.width,
					maxPxHeight: objectModel.height,
					fontface: objectModel.fontface || 'Reenie Beanie',
					bold: Boolean(objectModel.fontbold) || false,
					italic: Boolean(objectModel.fontitalic) || false,
					pointsize,
					resize: options.resizeFont,
					subset,
				})
		);

		if (response) {
			changeObject.text_formatted = response.text_formatted;
			changeObject.text_formatted_for_canvas = response.text_formatted_for_canvas;

			if (options.resizeObject.up && options.resizeObject.down) {
				changeObject.height = Math.max(
					10,
					response.textheight,
				);
			} else if (options.resizeObject.up) {
				changeObject.height = Math.max(
					10,
					Math.max(
						response.textheight,
						objectModel.height || 0,
					),
				);
			} else if (options.resizeObject.down) {
				changeObject.height = Math.max(
					10,
					Math.min(
						response.textheight,
						objectModel.height || 0,
					),
				);
			} else {
				changeObject.cropy = Math.max(
					((objectModel.height || 0) - response.textheight) / 2,
					0,
				);
			}

			if (response.pointsize) {
				changeObject.pointsize = response.pointsize;
				pointsize = changeObject.pointsize;
			}
		}
	}

	changeObject.text_svg = svgTextHelper.createText({
		...objectModel,
		...changeObject,
	});
	changeObject._image = null;

	return changeObject;
}
