import analytics from 'controllers/analytics';
import auth from 'controllers/auth';
import { toNative } from 'utils/vue-facing-decorator';
import {
	Component,
	Prop,
	Vue,
} from 'vue-facing-decorator';

@Component({})
class AuthView extends Vue {
	@Prop({ default: true, type: Boolean })
	readonly showGuest!: boolean;

	anonymousLogin() {
		analytics.trackEvent(
			'Login as guest',
			{
				category: 'Button',
			},
		);

		auth.guest();
	}

	login() {
		auth.showLogin();
	}

	signup() {
		auth.showSignup();
	}
}

export default toNative(AuthView);
