import { AxiosRequestConfig } from 'axios';
import ajax from 'controllers/ajax';
import {
	AjaxOptions,
} from 'interfaces/app';
import * as DB from 'interfaces/database';
import {
	ERRORS_INVALID_REQUEST_DATA,
	ERRORS_INVALID_RETURN_DATA,
} from 'settings/errors';
import _ from 'underscore';
import { reactive } from 'vue';
import {
	Action,
	Module,
	Mutation,
	VuexModule,
} from 'vuex-module-decorators';
import {
	photoPositionModelAttributes,
	textPositionModelAttributes,
} from './defaults';

@Module({ namespaced: true, name: 'themedata' })
export default class ThemeData extends VuexModule {
	'fetched' = false;

	'borderimages' = reactive<DB.BorderImageModel[]>([]);

	'id': number | null = null;

	'layouts' = reactive<DB.LayoutModel[]>([]);

	'layouttemplatelinks' = reactive<DB.LayoutTemplateLinkModel[]>([]);

	'loadedThemes' = reactive<number[]>([]);

	'objects' = reactive<DB.PageObjectModel[]>([]);

	'pagebackgrounds' = reactive<DB.PageBackgroundModel[]>([]);

	'pages' = reactive<DB.PageModel[]>([]);

	'photoobjects' = reactive<DB.PageObjectPhotoModel[]>([]);

	'photopositions' = reactive<DB.TemplatePositionPhotoModel[]>([]);

	'templates' = reactive<DB.TemplateModel[]>([]);

	'templatePositionsStates' = reactive<DB.TemplatePositionStateModel[]>([]);

	'textobjects' = reactive<DB.PageObjectTextModel[]>([]);

	'textpositions' = reactive<DB.TemplatePositionTextModel[]>([]);

	'themecategories' = reactive<DB.ThemeCategoryModel[]>([]);

	'themecategorylinks' = reactive<DB.ThemeCategoryLinkModel[]>([]);

	'themelayoutlinks' = reactive<DB.ThemeLayoutLinkModel[]>([]);

	'themepagelinks' = reactive<DB.ThemePageLinkModel[]>([]);

	'themes' = reactive<DB.ThemeModel[]>([]);

	'themevariants' = reactive<DB.ThemeVariantModel[]>([]);

	public get collectionUrl() {
		let url = `${window.glAppUrl}api/app/themedata`;
		if (window.glStack !== 'live') {
			// We always want to get the latest data from the server when in dev/test/staging mode
			url += `?version=${new Date().getTime() / 1000}`;
		} else if (window.appDataVersion) {
			// In production we want to make use of cloudFront caching, so we need a version number
			url += `?version=${window.appDataVersion}`;
		}

		return url;
	}

	public get modelUrl() {
		let url = `${window.glAppUrl}api/theme/${this.id}/all`;
		if (window.glStack !== 'live') {
			// We always want to get the latest data from the server when in dev/test/staging mode
			url += `?version=${new Date().getTime() / 1000}`;
		} else if (window.appDataVersion) {
			// In production we want to make use of cloudFront caching, so we need a version number
			url += `?version=${window.appDataVersion}`;
		}

		return url;
	}

	private get _findWhereThemeCategoryLink() {
		return (props: object) => _.findWhere(
			this.themecategorylinks,
			props,
		);
	}

	public get findThemeLayoutLinks() {
		return (props: Partial<DB.ThemeLayoutLinkModel>) => _.where(
			this.themelayoutlinks,
			props,
		);
	}

	public get getBorderImage() {
		return (id: number) => _.findWhere(
			this.borderimages,
			{ id },
		);
	}

	public get getFetched() {
		return this.fetched;
	}

	public get findLayoutsByThemeId() {
		return (themeid: DB.ThemeModel['id']) => {
			const layouts: DB.LayoutModel[] = [];

			const themeLayoutLinks = _.where(
				this.themelayoutlinks,
				{ themeid },
			);
			if (themeLayoutLinks) {
				themeLayoutLinks.forEach((themeLayoutLink) => {
					const layout = _.findWhere(
						this.layouts,
						{ id: themeLayoutLink.layoutid },
					);
					if (layout) {
						layouts.push(layout);
					}
				});
			}

			return layouts;
		};
	}

	public get findLayoutTemplateLinks() {
		return (props: Partial<DB.LayoutTemplateLinkModel>) => _.where(
			this.layouttemplatelinks,
			props,
		);
	}

	public get findWherePhotoObjects() {
		return (props: object) => _.findWhere(
			this.photoobjects,
			props,
		);
	}

	public get findWhereTextObjects() {
		return (props: object) => _.findWhere(
			this.textobjects,
			props,
		);
	}

	public get findWhereVariant() {
		return (props: object) => _.findWhere(
			this.themevariants,
			props,
		);
	}

	public get getLayoutById() {
		return (layoutId: number) => _.findWhere(
			this.layouts,
			{ id: layoutId },
		);
	}

	get themeCategoryName() {
		return (id: number) => {
			const themeCategoryModel = _.findWhere(
				this.themecategories,
				{ id },
			);
			const defaultName = themeCategoryModel
				? themeCategoryModel.name
				: '';

			return window.App.router.$i18next.t(
				`themeCategories:${id}.name`,
				defaultName,
			);
		};
	}

	get themeDescription() {
		return (id: number) => {
			const themeModel = _.findWhere(
				this.themes,
				{ id },
			);
			const defaultName = themeModel
				? (themeModel.description) as string
				: '';

			return window.App.router.$i18next.t(
				`themes:${id}.description`,
				defaultName,
			);
		};
	}

	public get whereObjects() {
		return (properties: object) => _.where(
			this.objects,
			properties,
		);
	}

	public get whereTheme() {
		return (properties: object) => _.where(
			this.themes,
			properties,
		);
	}

	public get whereThemePageLink() {
		return (properties: object) => _.where(
			this.themepagelinks,
			properties,
		);
	}

	public get whereVariant() {
		return (properties: object) => _.where(
			this.themevariants,
			properties,
		);
	}

	public get getPage() {
		return (id: string) => _.findWhere(
			this.pages,
			{ id },
		);
	}

	public get getPageBackgrounds() {
		return _.sortBy(
			this.pagebackgrounds,
			'serialnumber',
		);
	}

	public get getTemplate(): (id: number) => DB.TemplateModel | undefined {
		return (id: number) => _.findWhere(
			this.templates,
			{ id },
		);
	}

	public get getTheme() {
		return (id: number) => _.findWhere(
			this.themes,
			{ id },
		);
	}

	public get getCategoriesByOfferingId() {
		return (offeringid: number) => {
			let locale = this.context.rootState.user.language
				? this.context.rootState.user.language
				: window.locale;
			const offeringModel = this.context.rootGetters['appdata/getOffering'](offeringid);

			// Filter theme categories for offering
			let themecategories = this.themecategories.filter((themecategory) => {
				const hasTheme = this._findWhereThemeCategoryLink({ categoryid: themecategory.id });
				return hasTheme
					&& themecategory.groupid == offeringModel.groupid
					&& themecategory.typeid == offeringModel.typeid
					&& (!themecategory.locale || themecategory.locale == locale);
			});

			if (themecategories.length === 0 && this.context.rootGetters['appdata/findLanguageWhere']({ default: 1 })) {
				locale = this.context.rootGetters['appdata/findLanguageWhere']({ default: 1 }).id;

				// Check again for themes with default locale
				themecategories = this.themecategories.filter((themecategory) => {
					const hasTheme = this._findWhereThemeCategoryLink({ categoryid: themecategory.id });
					return themecategory.groupid == offeringModel.groupid
						&& themecategory.typeid == offeringModel.typeid
						&& hasTheme
						&& (!themecategory.locale || themecategory.locale == locale);
				});
			}

			if (themecategories.length === 0) {
				// Check again for themes with different locale
				themecategories = this.themecategories.filter((themecategory) => {
					const hasTheme = this._findWhereThemeCategoryLink({ categoryid: themecategory.id });
					return themecategory.groupid == offeringModel.groupid && themecategory.typeid == offeringModel.typeid && hasTheme;
				});
			}

			return _.sortBy(
				themecategories,
				'serialnumber',
			);
		};
	}

	public get getThemesRelated() {
		return (themeid: DB.ThemeModel['id']) => {
			let themeIds: number[] = [];
			const themeModels: DB.ThemeModel[] = [];
			const linkModels = _.where(
				this.themecategorylinks,
				{ themeid },
			);
			_.each(
				linkModels,
				(linkModel) => {
					const themes = this.getThemesByCategoryId(linkModel.categoryid);
					themeIds = themeIds.concat(_.pluck(
						themes,
						'id',
					));
				},
			);
			_.each(
				_.uniq(themeIds),
				(themeId) => {
					const themeModel = this.getTheme(themeId);
					if (themeModel) {
						themeModels.push(themeModel);
					}
				},
			);

			return themeModels;
		};
	}

	public get getThemesByCategoryId() {
		return (categoryid: number) => {
			const themes: DB.ThemeModel[] = [];
			const linkModels = _.where(
				this.themecategorylinks,
				{ categoryid },
			);
			_.each(
				_.sortBy(
					linkModels,
					'serialnumber',
				),
				(linkModel) => {
					const themeModel = _.findWhere(
						this.themes,
						{ id: linkModel.themeid },
					);
					if (themeModel) {
						themes.push(themeModel);
					}
				},
			);
			return themes;
		};
	}

	public get getThemesByOfferingId() {
		return (offeringid: number) => {
			const themes: DB.ThemeModel[] = [];

			const themeCategories = this.getCategoriesByOfferingId(offeringid);
			_.each(
				themeCategories,
				(themeCategory) => {
					const categoryThemes = this.getThemesByCategoryId(themeCategory.id);
					_.each(
						categoryThemes,
						(theme) => {
							if (!_.findWhere(
								themes,
								{ id: theme.id },
							)) {
								themes.push(theme);
							}
						},
					);
				},
			);

			return themes;
		};
	}

	public get themeLoaded() {
		return (themeid: DB.ThemeModel['id']) => _.indexOf(
			this.loadedThemes,
			themeid,
		) != -1;
	}

	@Mutation
	private _addBorderImage(model: DB.BorderImageModel) {
		if (!_.findWhere(
			this.borderimages,
			{ id: model.id },
		)) {
			this.borderimages.push(model);
		}
	}

	@Mutation
	private _addBorderImages(models: DB.BorderImageModel[]) {
		_.each(
			models,
			(model) => {
				if (!_.findWhere(
					this.borderimages,
					{ id: model.id },
				)) {
					this.borderimages.push(model);
				}
			},
		);
	}

	@Mutation
	private _addLayouts(models: DB.LayoutModel[]) {
		models.forEach((model) => {
			if (!_.findWhere(
				this.layouts,
				{ id: model.id },
			)) {
				this.layouts.push(model);
			}
		});
	}

	@Mutation
	private _addLayoutTemplates(models: DB.LayoutTemplateLinkModel[]) {
		models.forEach((model) => {
			if (!_.findWhere(
				this.layouttemplatelinks,
				{ id: model.id },
			)) {
				this.layouttemplatelinks.push(model);
			}
		});
	}

	@Mutation
	private _addObjects(models: DB.PageObjectModel[]) {
		_.each(
			models,
			(model) => {
				if (!_.findWhere(
					this.objects,
					{ id: model.id },
				)) {
					this.objects.push(model);
				}
			},
		);
	}

	@Mutation
	private _addPageBackgrounds(models: DB.PageBackgroundModel[]) {
		_.each(
			models,
			(model) => {
				if (!_.findWhere(
					this.pagebackgrounds,
					{ id: model.id },
				)) {
					this.pagebackgrounds.push(model);
				}
			},
		);
	}

	@Mutation
	private _addPages(models: DB.PageModel[]) {
		_.each(
			models,
			(model) => {
				if (!_.findWhere(
					this.pages,
					{ id: model.id },
				)) {
					this.pages.push(model);
				}
			},
		);
	}

	@Mutation
	private _addPhotoObjects(models: DB.PageObjectPhotoModel[]) {
		_.each(
			models,
			(model) => {
				if (!_.findWhere(
					this.photoobjects,
					{ id: model.id },
				)) {
					this.photoobjects.push(model);
				}
			},
		);
	}

	@Mutation
	private _addPhotoPositions(models: DB.TemplatePositionPhotoModel[]) {
		_.each(
			models,
			(model) => {
				if (!_.findWhere(
					this.photopositions,
					{ id: model.id },
				)) {
					this.photopositions.push({
						...photoPositionModelAttributes,
						...model,
					});
				}
			},
		);
	}

	@Mutation
	private _addTemplates(models: DB.TemplateModel[]) {
		models.forEach(
			(model) => {
				if (!this.templates.find(
					(m) => m.id === model.id,
				)) {
					this.templates.push(model);
				}
			},
		);
	}

	@Mutation
	private _addTemplatePositionStates(models: DB.TemplatePositionStateModel[]) {
		models.forEach(
			(model) => {
				if (!_.findWhere(
					this.templatePositionsStates,
					{ id: model.id },
				)) {
					this.templatePositionsStates.push(model);
				}
			},
		);
	}

	@Mutation
	private _addTextObjects(models: DB.PageObjectTextModel[]) {
		_.each(
			models,
			(model) => {
				if (!_.findWhere(
					this.textobjects,
					{ id: model.id },
				)) {
					this.textobjects.push(model);
				}
			},
		);
	}

	@Mutation
	private _addTextPositions(models: DB.TemplatePositionTextModel[]) {
		_.each(
			models,
			(model) => {
				if (!_.findWhere(
					this.textpositions,
					{ id: model.id },
				)) {
					this.textpositions.push({
						...textPositionModelAttributes,
						...model,
					});
				}
			},
		);
	}

	@Mutation
	private _addTheme(model: DB.ThemeModel) {
		if (!_.findWhere(
			this.themes,
			{ id: model.id },
		)) {
			this.themes.push(model);
		}
	}

	@Mutation
	private _addThemeLayouts(models: DB.ThemeLayoutLinkModel[]) {
		models.forEach((model) => {
			if (!_.findWhere(
				this.themelayoutlinks,
				{ id: model.id },
			)) {
				this.themelayoutlinks.push(model);
			}
		});
	}

	@Mutation
	private _addThemes(models: DB.ThemeModel[]) {
		_.each(
			models,
			(model) => {
				if (!_.findWhere(
					this.themes,
					{ id: model.id },
				)) {
					this.themes.push(model);
				}
			},
		);
	}

	@Mutation
	private _addThemeCategories(models: DB.ThemeCategoryModel[]) {
		_.each(
			models,
			(model) => {
				if (!_.findWhere(
					this.themecategories,
					{ id: model.id },
				)) {
					this.themecategories.push(model);
				}
			},
		);
	}

	@Mutation
	private _addThemeCategoryLinks(models: DB.ThemeCategoryLinkModel[]) {
		_.each(
			models,
			(model) => {
				if (!_.findWhere(
					this.themecategorylinks,
					{ id: model.id },
				)) {
					this.themecategorylinks.push(model);
				}
			},
		);
	}

	@Mutation
	private _addThemePageLinks(models: DB.ThemePageLinkModel[]) {
		_.each(
			models,
			(model) => {
				if (!_.findWhere(
					this.themepagelinks,
					{ id: model.id },
				)) {
					this.themepagelinks.push(model);
				}
			},
		);
	}

	@Mutation
	private _addThemeVariants(models: DB.ThemeVariantModel[]) {
		_.each(
			models,
			(model) => {
				if (!_.findWhere(
					this.themevariants,
					{ id: model.id },
				)) {
					this.themevariants.push(model);
				}
			},
		);
	}

	@Mutation
	private _loadedTheme(themeid: DB.ThemeModel['id']) {
		if (_.indexOf(
			this.loadedThemes,
			themeid,
		) != 1) {
			this.loadedThemes.push(themeid);
		}
	}

	@Mutation
	private _resetThemeCategories() {
		this.themecategories = [];
	}

	@Mutation
	private _resetThemeCategoryLinks() {
		this.themecategorylinks = [];
	}

	@Mutation
	private _resetThemes() {
		this.themes = [];
	}

	@Mutation
	public setBorderImages(models: DB.BorderImageModel[]) {
		this.borderimages = models;
	}

	@Mutation
	private _setFetched() {
		this.fetched = true;
	}

	@Mutation
	private _setThemeId(id: number) {
		this.id = id;
	}

	@Mutation
	public unloadTheme(themeid: DB.ThemeModel['id']) {
		this.loadedThemes = _.without(
			this.loadedThemes,
			themeid,
		);
	}

	@Action({ rawError: true })
	public fetch(): Promise<void> {
		const { getters, commit } = this.context;

		return new Promise((resolve, reject) => {
			const requestOptions: AxiosRequestConfig = {
				method: 'get',
				url: getters.collectionUrl,
			};
			const methodOptions: AjaxOptions = {
				auth: false,
				debug: {
					dialog: true,
				},
			};

			ajax
				.request(
					requestOptions,
					methodOptions,
				)
				.then((response) => {
					if (response.data) {
						const responseData = response.data;

						commit('_setFetched');
						commit('_resetThemes');
						commit('_resetThemeCategories');
						commit('_resetThemeCategoryLinks');
						commit(
							'_addThemes',
							_.toArray(responseData.themes),
						);
						commit(
							'_addThemeCategories',
							_.toArray(responseData.themecategories),
						);
						commit(
							'_addThemeCategoryLinks',
							_.toArray(responseData.theme_themecategorylinks),
						);
						commit(
							'_addThemePageLinks',
							_.toArray(responseData.theme_page),
						);
						commit(
							'_addThemeVariants',
							_.toArray(responseData.themevariant),
						);
						commit(
							'_addBorderImages',
							_.toArray(responseData.borderimage),
						);
						commit(
							'_addPhotoPositions',
							_.toArray(responseData.template_position_photo),
						);
						commit(
							'_addTextPositions',
							_.toArray(responseData.template_position_text),
						);
						commit(
							'_addTemplates',
							_.toArray(responseData.template),
						);
						commit(
							'_addTemplatePositionStates',
							_.toArray(responseData.template_state),
						);
						commit(
							'_addPageBackgrounds',
							responseData.pagebg,
						);
						commit(
							'_addPages',
							_.toArray(responseData.page),
						);
						commit(
							'_addObjects',
							_.toArray(responseData.object),
						);
						commit(
							'_addPhotoObjects',
							_.toArray(responseData.object_photo),
						);
						commit(
							'_addTextObjects',
							_.toArray(responseData.object_text),
						);

						if (responseData.translations) {
							responseData.translations.forEach((translationRecord: DB.TranslationModel) => {
								window.App.router.$i18next.addResourceBundle(
									translationRecord.language,
									translationRecord.namespace,
									translationRecord.bundle,
								);
							});
						}

						resolve();
					} else {
						throw new Error(ERRORS_INVALID_RETURN_DATA);
					}
				})
				.catch(reject);
		});
	}

	@Action({ rawError: true })
	public fetchModel({
		themeid,
	}: {
		themeid: DB.ThemeModel['id'];
	}): Promise<void> {
		if (!themeid) {
			return Promise.reject(
				new Error(ERRORS_INVALID_REQUEST_DATA),
			);
		}

		this._setThemeId(
			themeid,
		);

		const requestOptions: AxiosRequestConfig = {
			url: this.modelUrl,
			method: 'get',
		};
		const methodOptions: AjaxOptions = {
			auth: false,
		};

		return ajax
			.request(
				requestOptions,
				methodOptions,
			)
			.then((response) => {
				if (!response.data) {
					throw new Error(ERRORS_INVALID_RETURN_DATA);
				}

				const responseData = response.data;

				this._addObjects(
					_.toArray(responseData.object),
				);
				this._addPhotoObjects(
					_.toArray(responseData.object_photo),
				);
				this._addTextObjects(
					_.toArray(responseData.object_text),
				);
				this._addPages(
					_.toArray(responseData.page),
				);
				this._addTemplates(
					_.toArray(responseData.template),
				);
				this._addTemplatePositionStates(
					_.toArray(responseData.template_state),
				);
				this._addBorderImages(
					_.toArray(responseData.borderimage),
				);
				this._addPhotoPositions(
					_.toArray(responseData.template_position_photo),
				);
				this._addTextPositions(
					_.toArray(responseData.template_position_text),
				);
				this._addTheme(
					responseData.theme,
				);
				this._addThemePageLinks(
					_.toArray(responseData.theme_page),
				);
				this._addThemeVariants(
					_.toArray(responseData.themevariant),
				);
				this._loadedTheme(
					themeid,
				);
				if (responseData.layout) {
					this._addLayouts(
						responseData.layout,
					);
				}
				if (responseData.theme_layout) {
					this._addThemeLayouts(
						responseData.theme_layout,
					);
				}
				if (responseData.layout_template) {
					this._addLayoutTemplates(
						responseData.layout_template,
					);
				}
			});
	}

	@Action({ rawError: true })
	public fetchBorderImage(id: number): Promise<void> {
		const { commit } = this.context;

		return new Promise((resolve, reject) => {
			ajax
				.request(
					{
						url: `/api/borderimage/${id}`,
					},
					{
						auth: true,
					},
				)
				.then(
					(response) => {
						commit(
							'_addBorderImage',
							response,
						);
						resolve();
					},
					reject,
				);
		});
	}
}
