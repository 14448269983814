import mitt, {
	Emitter,
	EventHandlerMap,
	EventType,
	Handler,
} from 'mitt';

interface MittWithOnce<Events extends Record<EventType, unknown>> extends Emitter<Events> {
	once<Key extends keyof Events>(
		type: Key,
		handler: Handler<Events[Key]>,
	): void;
}

function mittWithOnce<Events extends Record<EventType, unknown>>(all?: EventHandlerMap<Events>): MittWithOnce<Events> {
	const inst = mitt<Events>(all) as MittWithOnce<Events>;
	inst.once = (type, fn) => {
		inst.on(
			type,
			(...args) => {
				inst.off(
					type,
					fn,
				);
				fn(...args);
			},
		);
	};

	return inst;
}

const EventBus = mittWithOnce<Record<string, any>>();
export default EventBus;
