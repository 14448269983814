<template>
	<div class="layoutPicker">
		<div
			v-if="isLoading"
			class="loadWrapper"
		>
			<div class="loading">
				<span>{{ $t('loading') }}</span>
				<div class="loadingBar" />
			</div>
		</div>
		<ul
			v-else
			class="picker two"
		>
			<LayoutPickerItemView
				v-for="layoutModel in collection"
				:key="layoutModel.id"
				:layout-model="layoutModel"
				:show-select-icon="isSwitch"
				class="pickerItem"
				@image-loaded="imageLoaded"
				@select-layout="selectLayout"
			/>
		</ul>
	</div>
</template>

<style lang="less" scoped>
@import '~lessVars';
@import '~root/css/components/loadbar.less';
@import '~root/css/components/picker.less';
</style>
