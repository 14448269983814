<template>
	<div class="virtualOfferingSelect">
		<div class="boxOne">
			<div class="header mobileOnly">
				{{ headerText }}
			</div>
			<div class="imageNode">
				<img
					v-if="img"
					:src="img"
				>
				<svg
					v-else
					class="loadIconSvg"
					viewBox="0 0 32 32"
					width="64"
					height="64"
				>
					<circle
						class="loadIcon"
						cx="16"
						cy="16"
						r="14"
						fill="none"
					/>
				</svg>
			</div>
		</div>
		<div class="boxTwo">
			<div class="header desktopOnly">
				{{ headerText }}
			</div>
			<div class="selectBox">
				<ul
					v-if="step === 'size' || step === 'finish'"
					class="list"
				>
					<li
						v-if="showBestFit"
						:class="[
							{ selected: isOfferingSelected(null) }
						]"
						@click="selectOffering('bestFit')"
					>
						<div class="text">
							<span class="offeringLabel">
								{{ $t('bestFit') }}
							</span>
							<span>&nbsp;</span>
							<span class="priceLabel">
								{{ $t('bestFitCaption') }}
							</span>
						</div>
						<div class="icon">
							<i
								v-if="isOfferingSelected(null)"
								class="material-icons"
							>
								checkmark
							</i>
						</div>
					</li>
					<li
						v-for="offeringModel in offeringModels"
						:key="offeringModel.id"
						:class="[
							{ selected: isOfferingSelected(offeringModel) }
						]"
						@click="selectOffering(offeringModel.id)"
					>
						<div class="text">
							<span class="offeringLabel">
								{{ getOfferingLabel(offeringModel) }}
							</span>
							<span class="priceLabel">
								{{ getPrice(offeringModel) ? $t('priceLabelPerPiece', {
									price: getPrice(offeringModel)
								}) : '' }}
							</span>
						</div>
						<div
							v-for="badge in getOfferingBadges(offeringModel.id)"
							:key="badge.id"
							class="badge"
						>
							<img
								:src="badge.url"
							>
						</div>
						<div class="icon">
							<i
								v-if="isOfferingSelected(offeringModel)"
								class="material-icons"
							>
								checkmark
							</i>
						</div>
					</li>
				</ul>
				<ul
					v-else
					class="list"
				>
					<li
						v-for="layoutModel in layoutModels"
						:key="layoutModel.id"
						:class="[
							{ selected: isLayoutSelected(layoutModel.id) }
						]"
						@click="selectLayoutId(layoutModel.id)"
					>
						<div class="text">
							<span class="offeringLabel">
								{{ getLayoutLabel(layoutModel) }}
							</span>
							<span class="priceLabel" />
						</div>
						<div class="icon">
							<i
								v-if="isLayoutSelected(layoutModel.id)"
								class="material-icons"
							>
								checkmark
							</i>
						</div>
					</li>
				</ul>
			</div>
			<button
				class="actionbutton full altColor desktopOnly"
				@click="clickSelect"
			>
				{{ buttonText }}
			</button>
		</div>
		<div class="boxThree mobileOnly">
			<button
				class="actionbutton full altColor"
				@click="clickSelect"
			>
				{{ buttonText }}
			</button>
		</div>
	</div>
</template>

<style lang="less" scoped>
@import '~lessVars';
@import '~root/css/components/button.less';

.virtualOfferingSelect {
	width: 100%;
	display: flex;
	max-height: inherit;
	position: relative;
	overflow-y: hidden;

	@media @desktop {
		align-items: stretch;
	}
	@media @mobile {
		flex-direction: column;
	}
}
.boxOne,
.boxTwo {
	float: left;
	display: flex;
	flex-direction: column;

	@media @desktop {
		width: 50%;
	}
	@media @mobile {
		width: 100%;
	}
}
.boxOne {
	text-align: center;

	@media @mobile {
		height: 200px;
	}

	.header {
		@media @mobile {
			width: 100%;
			text-align: left;
			font-size: @fontSize_M;
			font-family: @fontFamilyBold;
			font-weight: @fontWeightBold;
		}
	}

	.imageNode {
		width: 100%;
		min-height: 100px;
		position: relative;

		@media @mobile {
			height: 200px;
		}
		@media @desktop {
			padding-right: 20px;
		}
	}

	img {
		max-width: 100%;

		@media @mobile {
			max-height: 200px;
		}
	}
	.loadIconSvg {
		position: absolute;
		top: ~"calc(50% - 32px)";
		left: ~"calc(50% - 32px)";
	}
	.loadIcon {
		.animation-spinner;

		box-sizing: border-box;
		stroke: #A9A9A9;
		stroke-width: 3px;
		transform-origin: 50%;
	}
}
.boxTwo {
	position: relative;
	overflow-x: hidden;
	padding-bottom: 50px;

	@media @desktop {
		overflow-y: hidden;
	}
	@media @mobile {
		overflow-y: auto;
	}

	.header {
		@media @desktop {
			font-size: @fontSize_XL;
			font-family: @fontFamilyBold;
			font-weight: @fontWeightBold;
			line-height: 1em;
		}
	}

	.selectBox {
		@media @desktop {
			overflow-x: hidden;
			overflow-y: auto;
		}
	}

	.list {
		width: 100%;
		list-style: none;
		margin: 0;
		padding: 0;
		font-size: @fontSize_XS2;
		color: #393939;

		@media @desktop {
			padding-top: 30px;
		}

		li {
			cursor: pointer;
			padding: 20px 0;
			height: 70px;
			border-bottom: 1px solid rgba(151, 151, 151, 0.2);
			display: flex;
			width: 100%;
			align-items: center;
			justify-content: space-between;

			&.selected {
				.offeringLabel {
					font-family: @fontFamilyBold;
					font-weight: @fontWeightBold;
				}
			}

			.priceLabel {
				color: #9b9b9b;
			}
			.icon {
				width: 30px;
				color: #5dc5e3;
				text-align: right;
			}
		}
	}

	button {
		position: absolute;
		bottom: 0;
	}
}
.boxThree {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 50px;
}
</style>
