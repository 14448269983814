<template>
	<div class="coverViewWrapper">
		<div class="imageWrap">
			<div
				v-if="error"
				class="retryWrap"
			>
				<i
					class="material-icons statusIcon"
					@click="clickImage"
				>
					warning
				</i>
				<span
					v-if="showRetry && fetchAttempts === 0"
					class="retry"
					@click.stop="retry"
				>
					<i class="material-icons">
						refresh
					</i>
					<span>Retry</span>
				</span>
			</div>
			<img
				v-else
				v-show="loaded"
				:src="imageUrl"
				@error="onLoadError"
				@load="onLoad"
				@click="clickImage"
			>

			<img
				v-if="!loaded && showPlaceHolder"
				class="placeholderImg"
				:src="placeholderImg"
			>

			<svg
				v-if="!error && !loaded"
				class="loaderWrap"
				viewBox="0 0 32 32"
				width="64"
				height="64"
			>
				<circle
					id="spinner"
					cx="16"
					cy="16"
					r="14"
					fill="none"
				/>
			</svg>
		</div>
	</div>
</template>

<script src='./cover.ts'></script>

<style lang="less" scoped>
@import '../../css/components/animation.less';

.coverViewWrapper {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
}
.imageWrap {
	height: inherit;
	width: 100%;
}
.placeholderImg {
	width: 100%;
	border: 1px solid #ccc;
}
.statusIcon {
	position: absolute;
	left: 0;
	text-align: center;
	width: 100%;
	top: 35%;
}
.retryWrap {
	.retry {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		text-align: center;
		width: 100%;
		cursor: pointer;
		font-size: 0.5em;
		left: 0;
		bottom: 10px;

		i {
			margin-bottom: auto;
		}
	}
}
.imageWrap {
	position: relative;
}
img {
	max-width: 100%;
	max-height: 100%;
}
.loaderWrap {
	width: 64px;
	height: 64px;
	position: absolute;
	bottom: ~"calc(50% - 32px)";
	left: ~"calc(50% - 32px)";
}
</style>
