<template>
	<ul class="list">
		<UpsellSelectItemView
			v-for="upsellModel in upsellModels"
			:key="upsellModel.id"
			:upsell-model="upsellModel"
			:cart-item-model="cartItemModel()"
			:quantity="quantity"
			:price-label="priceLabel(upsellModel)"
			:product-id="productId"
			:cart-item-id="cartItemId"
			@find-cart-item-upsell="findCartItemUpsell"
			@handle-cart-item-upsell="handleCartItemUpsell"
		/>
	</ul>
</template>

<style lang="less" scoped>
@import '~lessVars';

.list {
    float: left;
	margin: 0;
	width: 100%;
	padding: 0;
}
</style>
